// import update from 'immutability-helper';

/**
 * Constants
 */

// const ACTION_CONSTANT = 'ACTION_CONSTANT'

/**
 * Actions
 */
// export function func(args) {
//     return (dispatch, getState) => {
//         dispatch({
//             type: ACTION_CONSTANT,
//             payload: {payload}
//         });
//     }
// }
/**
 * Reducer
 */

const initialState = {
    items: [
        {
            id: 1,
            name: 'Tom\'s Shirt / The Proof of Concept',
            contract_address: '0xa5ce9d01d04cb2a62c845bd1b60659938e11879f',
            total_supply: 1,
            owner_address: '0x1147dc7cb3ea19c986d5a071318339c2dfcbd295',
            img_src: 'tom_shirt_1.png',
            qr_code_src: 'tom_shirt_qr.png'
        }
    ]
}

export default function(state = initialState, action) {
    switch(action.type) {
        default:
            return state;
    }
}
