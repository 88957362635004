import React, { useState } from 'react';

import Modal from 'react-modal';

import SplashModal from './SplashModal';

Modal.setAppElement('#root');

function Splash() {
  const [modalIsOpen, setIsOpen] = useState(false);
  function openModal(modalType) {
    setIsOpen(modalType);
  }

  function closeModal(){
    setIsOpen(false);
  }

  let headerText, subText, buttonText, buttonAction, cancelText
  let showInput = false
  let showProfilePics = false
  let showFooter = false
  switch(modalIsOpen) {
    case 'wlt':
      headerText = 'WLT'
      subText = 'The best mobile crypto wallet for NFTs & more. Coming soon. Sign up for notifications.'
      buttonText = 'get notified'
      cancelText = 'No thanks'
      showInput = true
      break
    case 'mntnft':
      headerText = 'MNTNFT'
      subText = 'Mint NFTs with your own name on the smart contract on the Ethereum & Tezos blockchains. \nWeb app coming soon. Sign up for notifications.'
      buttonText = 'i\'m interested'
      cancelText = 'No thanks'
      showInput = true
      break
    case 'consult':
      headerText = 'CONSULT'
      subText = 'We can help you with your NFT project. \n\nCrypto & NFT education, strategy, ideation, and hands-on design and development work.'
      buttonText = 'send us an email'
      buttonAction = () => { window.location.href = "mailto:hi@satoshiscloset.com" }
      cancelText = 'No thanks'
      break
    case 'about':
      headerText = 'ABOUT US'
      subText = 'Satoshi\'s Closet made its first mark on the blockchain in 2019 as the first project to create ERC-721 tokenized streetwear. We\'ve helped artists mint custom smart contracts and NFTs, created our own pieces, and aped into some jpegs. \n\nToday we\'re creating consumer and enterprise NFT applications that will bring the crypto economy into the real world.'
      buttonText = 'send us an email'
      buttonAction = () => { window.location.href = "mailto:hi@satoshiscloset.com" }
      cancelText = 'Close'
      showFooter = true
      showProfilePics = true
      break

  }
  const modalDiv = (
    <SplashModal
      headerText={headerText}
      subText={subText}
      buttonText={buttonText}
      buttonAction={buttonAction}
      cancelText={cancelText}
      closeModal={closeModal}
      showInput={showInput}
      showFooter={showFooter}
      showProfilePics={showProfilePics}
    />
  )

  return (
    <div className='new-home'>
      <div className='new-home__header'>
        <img src='/logo.svg' height='72' width='72' />
        <div className='new-home__header__social'>
          <a href='https://www.instagram.com/satoshiscloset' target='_blank' rel="noopener noreferrer">
              <img alt="Satoshi's Closet Instagram" src='/instagram.svg' />
          </a>
          <a href='https://www.twitter.com/satoshiscloset_' target='_blank' rel="noopener noreferrer">
              <img alt="Satoshi's Closet Twitter" src={require('../../assets/images/twitter.svg')} />
          </a>
        </div>
      </div>
      <div>
        <div className='new-home__section first'>
          <div className='new-home__section__text'>
            <div className='new-home__section__header'>WLT</div>
            <div className='new-home__section__subheader'>The best mobile crypto wallet for NFTs</div>
            <div className='new-home__section__button-container'>
              <a href='https://wlt.satoshiscloset.com' target='_blank'><div className='button green'>go to wlt ➜</div></a>
            </div>
            {/*<div className='new-home__section__button-sub'><a href='https://gllry.st/satoshiscloset' target='_blank'>Check out Satoshi's Closet on GLLRY ></a></div>*/}
          </div>
          <div className='new-home__section__img'>
            <img src='/home2.png' />
          </div>
        </div>
        <div className='new-home__section dark'>
          <div className='new-home__section__img img2'>
            <img src='/home1.png' />
          </div>
          <div className='new-home__section__text right'>
            <div className='new-home__section__header'>GLLRY</div>
            <div className='new-home__section__subheader'>Multi-chain NFT web gallery app</div>
            <div style={{paddingTop: '16px'}}><img height='24' src='/blockchains.svg' /></div>
            <div className='new-home__section__button-container right'>
              <a href='https://gllry.st' target='_blank'><div className='button green'>go to gllry ➜</div></a>
            </div>
          </div>
          <div className='new-home__section__img img2-mobile'>
            <img src='/home1.png' />
          </div>
        </div>
        <div className='new-home__section padding-top'>
          <div className='new-home__section__text'>
            <div className='new-home__section__header'>MNTNFT</div>
            <div className='new-home__section__subheader'>Mint NFTs from your own smart contract</div>
            <div className='new-home__section__button-container'>
              <div className='button green' onClick={() => openModal('mntnft')}>coming soon</div>
            </div>
          </div>
          <div className='new-home__section__img img3'>
            <img src='/home3.png' />
          </div>
        </div>
        <div className='new-home__section-container'>
          <div className='new-home__section dark no-padding'>
            <div className='new-home__section__text-alt'>
              <div className='new-home__section__header small'>CONSULT</div>
              <div className='new-home__section__subheader small'>Let's talk about your NFT project needs</div>
              <div className='new-home__section__button-container' onClick={() => openModal('consult')}>
                <div className='button green'>contact us</div>
              </div>
            </div>
          </div>
          <div className='new-home__section__divider'><img src='/divider.png' /></div>
          <div className='new-home__section no-padding'>
            <div className='new-home__section__text-alt right'>
              <div className='new-home__section__header small'>ABOUT US</div>
              <div className='new-home__section__subheader small'>First scene on blockchain 04-2019</div>
              <div className='new-home__section__button-container right'>
                <div className='button green' onClick={() => openModal('about')}>learn more</div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className='new-home__footer'>
        <div className='footer__social-container'>
            <a href='https://www.instagram.com/satoshiscloset' target='_blank' rel="noopener noreferrer">
                <img alt="Satoshi's Closet Instagram" src='/instagram.svg' />
            </a>
            <a href='https://www.twitter.com/satoshiscloset_' target='_blank' rel="noopener noreferrer">
                <img alt="Satoshi's Closet Twitter" src={require('../../assets/images/twitter.svg')} />
            </a>
        </div>
        <div className='footer__copyright'>&#169; 2022 Satoshi's Closet</div>
      </div>
      <Modal
        isOpen={modalIsOpen.length > 0}
        onRequestClose={closeModal}
      >
        <div className='modal'>{modalDiv}</div>
      </Modal>
    </div>
  )
}

export default Splash
