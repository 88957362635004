import React, { Component } from 'react';

import './index.scss';

import { sendEmail } from '../../../services/aws';

class Footer extends Component {
  state = {}

  onChange(event) {
    const value = event.target.value
    this.setState({'email': value});
  }

  validateEmail(email) {
      const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      return re.test(String(email).toLowerCase());
  }

  sendClicked() {
    const {email, submitting, success} = this.state;
    if (!email) {
        this.setState({error: 'Please enter an email address'});
        return;
    }
    if (!this.validateEmail(email)) {
      this.setState({error: 'Please enter a valid email address'});
      return;
    }
    if (submitting || success) {
      return;
    }
    this.setState({submitting: true, error: null}, () => this.sendEmail(email));
  }

  async sendEmail(email) {
    const {landing} = this.props;
    try {
      await sendEmail(landing ? 'wlt-subscriber' : 'subscriber', email, this.onSuccess.bind(this));
    } catch (err) {
      console.error(err);
    }
  }

  onSuccess() {
      this.setState({success: true, submitting: false});
  }

  render() {
    const {landing} = this.props;
    const {success, submitting, error} = this.state;
    const buttonText = success ? 'Success!' : submitting ? 'Sending...' : landing ? 'Sign up' : 'subscribe'
    return (
      <div className={landing ? "footer landing" : "footer"}>
        <div className={landing ? "footer__inner landing" : "footer__inner"}>
          {!landing &&
            <div className='footer__header'>Sign up for our email list</div>
          }
          {!landing &&
            <div className='footer__subheader'>we promise that you'll like what we send, or else unsubscribe.</div>
          }
          <div className='footer__input-header'>email address</div>
          <div className='footer__input'>
            <input type='text' placeholder='Enter email address' onChange={this.onChange.bind(this)} />
          </div>
          <div className='footer__button-container'>
              <div className={landing ? 'footer__button landing' : 'footer__button'} onClick={this.sendClicked.bind(this)}>{buttonText}</div>
              {success &&
                  <div style={{marginTop: 10, fontSize: 12}}>Thanks! You've been added to our email list.</div>
              }
              {error &&
                  <div style={{marginTop: 10, fontSize: 12, color: 'red'}}>{error}</div>
              }
          </div>
          {!landing &&
            <div className='footer__social-container'>
                <a href='https://www.instagram.com/satoshiscloset' target='_blank' rel="noopener noreferrer">
                    <img alt="Satoshi's Closet Instagram" src={require('../../../assets/images/instagram.svg')} />
                </a>
                <a href='https://www.twitter.com/satoshiscloset_' target='_blank' rel="noopener noreferrer">
                    <img alt="Satoshi's Closet Twitter" src={require('../../../assets/images/twitter.svg')} />
                </a>
            </div>
          }
          {!landing &&
            <div className='footer__copyright'>&#169; 2022 Satoshi's Closet</div>
          }
        </div>
      </div>
    );
  }
}

export default Footer;
