import React, { Component } from 'react';
import { connect } from 'react-redux'
import { Redirect } from 'react-router-dom'

import Header from '../shared/Header'
import Footer from '../shared/Footer'

import './index.scss';


class Item extends Component {
    componentDidMount() {
        document.title = this.props.item.name
    }

    _getItem() {
        const {items} = this.props
        let {contract_id} = this.props.match.params

        if (!contract_id) {
            contract_id = '0xa5ce9d01d04cb2a62c845bd1b60659938e11879f'
        }

        return items.filter(i => i.contract_address === contract_id)[0];
    }

    render() {
        const item = this._getItem()
        // if (!item) {
        //     return (<Redirect to='/item/0xd082fb53d43123807a7c901c57aa5cec3a9d3e43/1' />)
        // }
        return (
            <div>
                <Header/>
                <div className='item'>
                    <div className='item__inner'>
                        <div className='item__image'>
                            <img alt="Satoshi's Closet Tom's Shirt" src={require('../../assets/images/' + item.img_src)} />
                        </div>
                        <div className='details'>
                            <div className='details__name'>{item.name}</div>
                            <div><span>TOKEN ID:</span> {item.id}</div>
                            <div><span>TOTAL SUPPLY:</span> {item.total_supply}</div>
                            <div><span>CONTRACT:</span> {item.contract_address}</div>
                            <div><span>OWNER:</span> {item.owner_address}</div>

                            <div className='details__link'>
                                <a className='link' target='_blank' rel="noopener noreferrer" href='https://etherscan.io/token/0xa5ce9d01d04cb2a62c845bd1b60659938e11879f?a=1'>View on Etherscan</a>
                            </div>
                            <div className='qr_code_container'>
                                <img height={84} width={84} alt="Satoshi's Closet Tom's Shirt QR Code" src={require('../../assets/images/' + item.qr_code_src)} />
                            </div>
                        </div>
                    </div>
                </div>
                <Footer/>
            </div>
        );
    }
}

function mapStateToProps(state) {
    const {Tom} = state
    return {
        items: Tom.items,
        item: Tom.items[0]
    }
}
export default connect(mapStateToProps)(Item)
