import React from 'react';

import './index.scss';

function App() {
  return (
    <div className='launch-container'>
      <div className='launch-container__inner'>
        <div className='launch-container__left'>
          <div className='main-text-container'>
            <div className='main-text'></div>
            <div className='main-text-2'>Limited quantity remaining</div>
          </div>
          <div className='shirt-mobile iso-shirt'>
            <img alt="Satoshi's Closet ISO Shirt" src={require('../../assets/images/iso_shirt.png')} />
          </div>
          <div className='sub-text-container'>
            <div className='sub-text'>THE WORLD’S FIRST APPAREL DROP ON BLOCKCHAIN.</div>
            <div className='sub-text-2'>HARD CAP / LIMITED EDITION OF 50.</div>
          </div>
          <div className='button-container'>
            <div className='button'><a href='https://store.satoshiscloset.com/collections/featured-collection/products/the-initial-shirt-offering'>view details</a></div>
            <div className='button-text'>
              <div className='button-text__bold'>we accept:</div>
              <div>$USD, BTC, or ETH</div>
            </div>
          </div>
        </div>
        <div className='launch-container__right'>
          <img alt="Satoshi's Closet ISO Shirt" src={require('../../assets/images/iso_shirt.png')} />
        </div>
      </div>
    </div>
  );
}

export default App;
