import React, { Component } from 'react'

import Header from '../shared/Header'
import Footer from '../shared/Footer'

import './index.scss';

class Token extends Component {
  state = {}

  componentWillMount() {
    this.getTokenInfo();
  }

  componentDidMount() {
      document.title = 'Satoshi\'s Closet';
  }

  async getTokenInfo() {
    const { contract_id, item_id } = this.props.match.params;
    const url = 'https://api.opensea.io/api/v1/assets/?asset_contract_address=' + contract_id + '&token_ids=' + item_id;
    const self = this;
    fetch(url).then(resp => resp.json()).then(data => {
      self.setState({asset: data.assets[0]})
    });
  }

  render() {
    const { asset } = this.state;
    const { contract_id, item_id } = this.props.match.params;

    const item = {
      name: asset ? asset.name : 'RETRIEVING DATA...',
      etherscan_url: 'https://etherscan.io/token/' + contract_id + '?a=' + item_id,
    }
    const traits = {}
    if (asset) {
      document.title = asset.asset_contract.name;
      asset.traits.forEach(t => {
        traits[t.trait_type] = t.value
      })
    }
    console.log(asset)
    return (
        <div>
            <Header/>
            <div className='item iso_token'>
                <div className='item__inner'>
                    <div className='item__image iso_token'>
                      {asset &&
                        <img alt="Satoshi's Closet Brand Swag" src={asset ? asset.image_url : ''} />
                      }
                    </div>
                    <div className='details'>
                        <div className='details__name'>{item.name}</div>
                        <div><span>TOKEN ID:</span> {item_id}</div>
                        <div><span>CONTRACT:</span> {contract_id}</div>
                        {asset &&
                          <div>
                            <div style={{marginBottom: 7}}><span>OWNER:</span> {asset.owner.address}</div>
                            {Object.keys(traits).map(key => {
                              let value = traits[key];
                              if (key === "original list price") {
                                value += ' eth'
                              }
                              return (<div key={key}><span>{key.toUpperCase()}:</span> {value && value.toUpperCase()}</div>)
                            })}
                          </div>
                        }

                        <div className='details__link'>
                            <a className='link' target='_blank' rel="noopener noreferrer" href={item.etherscan_url}>View on Etherscan</a>
                        </div>
                        {/*<div className='qr_code_container'>
                            <img height={84} width={84} alt="Satoshi's Closet Tom's Shirt QR Code" src={require('../../assets/images/tom_shirt_qr.png')} />
                        </div>*/}
                    </div>
                </div>
            </div>
            <Footer/>
        </div>
    );
  }
}

export default Token
