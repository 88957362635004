import React from 'react';
import { render } from 'react-dom';
import { Provider } from 'react-redux'
import { BrowserRouter as Router, Route, Redirect, Switch } from 'react-router-dom';
import configureStore from './store';

import './index.css';
import Splash from './components/App/Splash';
import WltSplash from './components/App/WltSplash';
import App from './components/App';
import About from './components/About';
import Contact from './components/Contact';
import Terms from './components/Terms';
import Privacy from './components/Privacy';
import Item from './components/Item';
import Tom from './components/Tom';
import Iso from './components/Iso';
import Secondary from './components/Secondary';
import Rsvp from './components/Rsvp';
import Onboard from './components/Onboard';
import Token from './components/Iso/Token';
import Blog from './components/Blog';
import BlogPost from './components/Blog/BlogPost';
import IsoLookbook from './components/Blog/IsoLookbook';
import IsoLaunchParty from './components/Blog/IsoLaunchParty';
import OurCryptoFuture1 from './components/Blog/OurCryptoFuture1';
import NftBuyingGuide from './components/Blog/NftBuyingGuide';
import ScrollToTop from './components/shared/ScrollToTop';

import * as serviceWorker from './serviceWorker';

render(
    <Provider store={configureStore()}>
        <Router>
            <ScrollToTop />
            <Route path='/' exact component={Splash} />
            <Route path='/home' exact component={Splash} />
            <Route path='/about' exact component={About} />
            <Route path='/contact' exact component={Contact} />
            <Route path='/terms' exact component={Terms} />
            <Route path='/privacy' exact component={Privacy} />
            <Route path='/rsvp' exact component={Rsvp} />
            <Route path='/onboard' exact component={Onboard} />
            <Route path='/nft-buying-guide' exact component={NftBuyingGuide} />

            <Switch>
                <Route path='/the-secondary/0x95207e061a302f1cfd0907c51b2caaba8b0a492e' component={Secondary} />
                <Route path='/the-secondary' component={() => (
                    <Redirect to="/the-secondary/0x95207e061a302f1cfd0907c51b2caaba8b0a492e"/>
                )} />
            </Switch>

            <Switch>
                <Route path='/blog/ISO-lookbook' exact component={IsoLookbook} />
                <Route path='/blog/ISO-launchparty' exact component={IsoLaunchParty} />
                <Route path='/blog/nft-buying-guide' exact component={NftBuyingGuide} />
                <Route path='/blog/our-crypto-future-pt1' exact component={OurCryptoFuture1} />
                <Route path='/blog/:blog_id' exact component={BlogPost} />
                <Route path='/blog' exact component={Blog} />
            </Switch>

            <Switch>
                <Route path='/item/0xa5ce9d01d04cb2a62c845bd1b60659938e11879f' component={Tom} />
                <Route path='/item/:contract_id/:item_id' component={Item} />
                <Route path='/item' component={() => (
                    <Redirect to="/item/0xd082fb53d43123807a7c901c57aa5cec3a9d3e43/1"/>
                )} />
            </Switch>

            <Switch>
                <Route path='/ISO/:contract_id/:token_id' component={Token} />
                <Route path='/ISO/:contract_id' component={Iso} />
                <Route path='/ISO' component={App} />
            </Switch>
        </Router>
    </Provider>,
    document.getElementById('root')
);
// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
