import React, {Component} from 'react';
import './index.scss';

import Item from './Item';

class ItemTray extends Component{
  render() {
    const onboard = this.props.onboard
    const header = onboard ? 'Onboard the next person into crypto' : 'Brand swag from Satoshi\'s Closet'
    const description = onboard ?
      "Welcome to Our Crypto Future. Now it's your turn to bring the next person aboard. Use code 'WELCOME' at checkout to get 25% off your purchase for a friend, family member, or known associate. 🤑🕴️"
      : "Satoshi's Closet is unique in the world of streetwear. Each item in our catalog comes with a digital \"non-fungible token\" (also called an NFT) that proves its authenticity on the blockchain. You'll get a unique NFT with each purchase that we'll send to your crypto wallet, or we'll set you up with a new wallet so you can show it off.";
    const link = onboard ? (<span></span>) : (<a href='https://satoshiscloset.com/blog/putting-apparel-on-blockchain'>Learn more.</a>)

    return (
      <div className='item-tray-container' id='brand-swag'>
        <div className='item-tray-container__header'>{header}</div>
        <div className='item-tray-container__description'>{description} {link}</div>
        <div className='item-tray'>
          <Item
            name='Long sleeve logo tee'
            price='$35'
            onboard={onboard}
            image_url='tee.jpg'
            store_url='https://store.satoshiscloset.com/products/brand-swag-long-sleeve-logo-tee'
          />
          <Item
            name='5-panel logo cap'
            price='$25'
            onboard={onboard}
            image_url='hat.jpg'
            store_url='https://store.satoshiscloset.com/products/brand-swag-5-panel-logo-cap'
          />
          <Item
            name='Homeboy crop hoodie'
            price='$50'
            onboard={onboard}
            image_url='hoodie.jpg'
            store_url='https://store.satoshiscloset.com/products/brand-swag-satoshi-is-my-homeboy-crop-hoodie'
          />
        </div>
      </div>
    );
  }
}

export default ItemTray;
