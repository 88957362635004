import aws from 'aws-sdk';

export function sendEmail(type, data, onSuccess) {
    aws.config.update({
      region: 'us-east-1',
      accessKeyId: 'AKIAJOYPXEIVV5XMNDHQ',
      secretAccessKey: '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',
    });

    const ses = new aws.SES({ apiVersion: 'latest' });
    return new Promise((resolve, reject) => {
        ses.sendEmail(
          {
            Source: 'hi@satoshiscloset.com',
            Destination: {
              // CcAddresses: options.cc,
              ToAddresses: ['hi@satoshiscloset.com'],
            },
            Message: {
              Subject: {
                Data: `Interest in ${type} from website`,
              },
              Body: {
                Html: {
                  Data: data,
                },
              },
            },
          },
          (err, info) => {
            if (err) {
                reject(err);
            } else {
                resolve(info);
                if (onSuccess) {
                    onSuccess();
                }
            }
          },
        )
    });
}
