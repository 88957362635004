import React from 'react';
import { Link } from 'react-router-dom'
import './index.scss';

function Header() {
  return (
    <div className="header">
        <div className="header__inner">
            <div className="header__left">
                <Link to='/'>
                    <img alt="Satoshi's Closet Logo" src={require('../../../assets/images/logo_new.png')} />
                </Link>
            </div>
            <div className="header__right">
                <div><span><Link to='/about'>about</Link></span></div>
                <div><span><Link to='/blog'>blog</Link></span></div>
                <div><span><Link to='/contact'>contact</Link></span></div>
            </div>
        </div>
    </div>
  );
}

export default Header;
