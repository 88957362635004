import React, {Component} from 'react';
import { Link } from 'react-router-dom'

import './index.scss';

import Header from '../shared/Header';
import Footer from '../shared/Footer';

class NFTBuyingGuide extends Component{
  render() {
    return (
      <div>
        <Header />
        <div className='blog'>
          <div className='blog__inner launchparty'>
            <div>
              <div className='blog__header'>
                NFT Buying Guide
              </div>
              <div className='blog__subheader show-links line-height'>
                In order to buy an NFT, you’ll need two things: some cryptocurrency, and a “self-custodial” crypto wallet. Once you have those two things, you’ll be able to engage with a variety of blockchain-based applications and platforms, including those for buying and selling NFT’s.
                <br/><br/>
                The first necessary step - obtaining some cryptocurrency - is fairly simple. There are a number of different places you can buy the most popular cryptocurrencies using a credit/debit card or bank account, including the crypto you’ll most likely need to buy NFT’s - Ethereum (ETH). Popular cryptocurrency exchanges for buying ETH in the U.S. include <a target="_blank" href="https://www.coinbase.com/">Coinbase</a> and <a target="_blank" href="https://www.gemini.com/">Gemini</a>. Much like when signing up for a bank account, you’ll need to share some basic personal info including a photo ID when you create your exchange account.
                <br/><br/>
                After you buy the ETH that you need to make your NFT purchase, you’ll need to send it from your exchange account to a self-custodial crypto wallet (more on that below). Self-custodial means you keep your cryptocurrency in your own possession instead of it technically being in the possession of the crypto exchange. This type of wallet is necessary for transacting in the broader crypto world, including for purchasing cryptoassets like NFT’s. You’ll want to take privacy and security seriously since you’ll be in custody of your own funds.
                <br/><br/>
                Downloading a self-custodial crypto wallet: Desktop users are recommended to use the <a target="_blank" href="https://metamask.io/">Metamask</a> browser extension for Chrome. Mobile users are recommended to use <a target="_blank" href="https://wallet.coinbase.com/">Coinbase Wallet</a> (iOS and Android) or <a target="_blank" href="https://trustwallet.com/">Trust Wallet</a> (Android). All of these wallet options will enable you to receive cryptocurrency like Ethereum (ETH) from your exchange account, as well as transact on NFT marketplaces. In your new self-custodial wallet, find your Ethereum address (it starts with “0x”) and copy it to your device’s clipboard. Then open your exchange account where you bought ETH, navigate to Send, and paste in the address you just copied as the recipient. The transfer of funds will take a few minutes to complete.
                <br/><br/>
                Once your new wallet is set up, navigate to an NFT marketplace like <a target="_blank" href="https://opensea.io">OpenSea.io</a>, or directly to an NFT listing page. Desktop users will do this in their browser where Metamask is installed. Mobile users will do this from the ‘DApps browser’ tab in their mobile wallet app. From the listing page, you will be able to buy the selected NFT, or depending on platform and item status, place a bid or make an offer for an item.
                <br/><br/>
                When you place your transaction, your crypto wallet will prompt you with a summary of the transaction including network fees, and allow you to approve or reject the transaction. After you approve, the transaction will take a few minutes to process and your ETH will be exchanged simultaneously when your NFT reaches your wallet. That’s it! You have purchased your first NFT.
                <br/><br/>
              </div>
            </div>
          </div>
        </div>
        <Footer />
      </div>
    );
  }
}

export default NFTBuyingGuide;
