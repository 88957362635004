import React, { useState } from 'react';

import * as EmailValidator from 'email-validator';

import { sendEmail } from '../../services/aws';

function SplashModal(props) {
  const [email, setEmail] = useState("")
  const [error, setError] = useState("")
  const [success, setSuccess] = useState("")
  const [submitting, setSubmitting] = useState(false)

  const {
    headerText, subText, buttonText, buttonAction, cancelText,
    closeModal, showInput, showFooter, showProfilePics
  } = props

  const onChange = (e) => {
    const value = e.target.value
    if (value.length === 0) {
      setError(false)
    }
    setEmail(value)
  }

  const invokeSendEmail = async (email) => {
    try {
      await sendEmail(headerText, email, () => { setSuccess('Email submitted!'); setError(''); setSubmitting(false)});
    } catch (err) {
      console.error(err);
    }
  }

  const onClick = () => {
    if (showInput) {
      setSubmitting(true)
      if (!email) {
        setError('Please enter an email address')
        setSubmitting(false)
        return;
      }
      if (!EmailValidator.validate(email)) {
        setError('Please enter a valid email address')
        setSubmitting(false)
        return;
      }
      if (submitting) {
        return;
      }
      invokeSendEmail(email)
    } else if (buttonAction) {
      buttonAction()
    } else {
      closeModal()
    }
  }

  let buttonCls = 'button green'
  if (submitting) {
    buttonCls += ' disabled'
  }
  if (buttonText && buttonText.length > 12) {
    buttonCls += ' padding'
  }

  return (
    <div className='modal-container'>
      <div className='modal-container__header'>{headerText}</div>
      {showProfilePics &&
        <div className='modal-container__profiles'>
          <div>
            <img src='/mpastko.jpg' />
            <span><a href='https://twitter.com/mpastko' target='_blank'>@mpastko</a></span>
          </div>
          <div>
            <img src='/kitdesai.png' />
            <span><a href='https://twitter.com/kitdesai' target='_blank'>@kitdesai</a></span>
          </div>
        </div>
      }
      <div className='modal-container__subheader'>{subText}</div>
      {showInput &&
        <div className='modal-container__input-container'>
          <div className='modal-container__input-container__header'>Email Address</div>
          <div><input type='text' value={email} onChange={onChange} /></div>
          {error && <div className='modal-container__input-container__error'>{error}</div>}
          {success && <div className='modal-container__input-container__success'>{success}</div>}
        </div>
      }
      {showFooter &&
        <div className='new-home__footer less-margin'>
          <div className='footer__social-container'>
              <a href='https://www.instagram.com/satoshiscloset' target='_blank' rel="noopener noreferrer">
                  <img alt="Satoshi's Closet Instagram" src='/instagram.svg' />
              </a>
              <a href='https://www.twitter.com/satoshiscloset_' target='_blank' rel="noopener noreferrer">
                  <img alt="Satoshi's Closet Twitter" src={require('../../assets/images/twitter.svg')} />
              </a>
          </div>
        </div>
      }
      <div className='modal-container__button-container'>
        <div className={buttonCls} onClick={onClick}>{buttonText}</div>
        <div className='cancel-text' onClick={closeModal}>{cancelText}</div>
      </div>
    </div>
  )
}
export default SplashModal;
