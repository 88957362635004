export function getBlogPost(id) {
  let post = initialState.posts.filter(p => p.id === id)[0]
  if (!post) {
      return {html: 'Post not found'}
  }
  return post
}
/**
 * Reducer
 */

 const POST10 = `<!doctype html>
<html xmlns="http://www.w3.org/1999/xhtml" xmlns:v="urn:schemas-microsoft-com:vml" xmlns:o="urn:schemas-microsoft-com:office:office">
    <head>
        <!-- NAME: 1 COLUMN -->
        <!--[if gte mso 15]>
        <xml>
            <o:OfficeDocumentSettings>
            <o:AllowPNG/>
            <o:PixelsPerInch>96</o:PixelsPerInch>
            </o:OfficeDocumentSettings>
        </xml>
        <![endif]-->
        <meta charset="UTF-8">
        <meta http-equiv="X-UA-Compatible" content="IE=edge">
        <meta name="viewport" content="width=device-width, initial-scale=1">
        <title>Putting apparel on blockchain / How the ISO works</title>

    <style type="text/css">
        p{
            margin:10px 0;
            padding:0;
        }
        table{
            border-collapse:collapse;
        }
        h1,h2,h3,h4,h5,h6{
            display:block;
            margin:0;
            padding:0;
        }
        img,a img{
            border:0;
            height:auto;
            outline:none;
            text-decoration:none;
        }
        body,#bodyTable,#bodyCell{
            height:100%;
            margin:0;
            padding:0;
            width:100%;
        }
        .mcnPreviewText{
            display:none !important;
        }
        #outlook a{
            padding:0;
        }
        img{
            -ms-interpolation-mode:bicubic;
        }
        table{
            mso-table-lspace:0pt;
            mso-table-rspace:0pt;
        }
        .ReadMsgBody{
            width:100%;
        }
        .ExternalClass{
            width:100%;
        }
        p,a,li,td,blockquote{
            mso-line-height-rule:exactly;
        }
        a[href^=tel],a[href^=sms]{
            color:inherit;
            cursor:default;
            text-decoration:none;
        }
        p,a,li,td,body,table,blockquote{
            -ms-text-size-adjust:100%;
            -webkit-text-size-adjust:100%;
        }
        .ExternalClass,.ExternalClass p,.ExternalClass td,.ExternalClass div,.ExternalClass span,.ExternalClass font{
            line-height:100%;
        }
        a[x-apple-data-detectors]{
            color:inherit !important;
            text-decoration:none !important;
            font-size:inherit !important;
            font-family:inherit !important;
            font-weight:inherit !important;
            line-height:inherit !important;
        }
        #bodyCell{
            padding:10px;
        }
        .templateContainer{
            max-width:600px !important;
        }
        a.mcnButton{
            display:block;
        }
        .mcnImage,.mcnRetinaImage{
            vertical-align:bottom;
        }
        .mcnTextContent{
            word-break:break-word;
        }
        .mcnTextContent img{
            height:auto !important;
        }
        .mcnDividerBlock{
            table-layout:fixed !important;
        }
        body,#bodyTable{
            background-color:#ffffff;
        }
        #bodyCell{
            border-top:0;
        }
        .templateContainer{
            border:0;
        }
        h1{
            color:#202020;
            font-family:Helvetica;
            font-size:26px;
            font-style:normal;
            font-weight:bold;
            line-height:125%;
            letter-spacing:normal;
            text-align:left;
        }
        h2{
            color:#202020;
            font-family:Helvetica;
            font-size:22px;
            font-style:normal;
            font-weight:bold;
            line-height:125%;
            letter-spacing:normal;
            text-align:left;
        }
        h3{
            color:#202020;
            font-family:Helvetica;
            font-size:20px;
            font-style:normal;
            font-weight:bold;
            line-height:125%;
            letter-spacing:normal;
            text-align:left;
        }
        h4{
            color:#202020;
            font-family:Helvetica;
            font-size:18px;
            font-style:normal;
            font-weight:bold;
            line-height:125%;
            letter-spacing:normal;
            text-align:left;
        }
        #templatePreheader{
            background-color:#FAFAFA;
            background-image:none;
            background-repeat:no-repeat;
            background-position:center;
            background-size:cover;
            border-top:0;
            border-bottom:0;
            padding-top:9px;
            padding-bottom:9px;
        }
        #templatePreheader .mcnTextContent,#templatePreheader .mcnTextContent p{
            color:#656565;
            font-family:Helvetica;
            font-size:12px;
            line-height:150%;
            text-align:left;
        }
        #templatePreheader .mcnTextContent a,#templatePreheader .mcnTextContent p a{
            color:#656565;
            font-weight:normal;
            text-decoration:underline;
        }
        #templateHeader{
            background-color:#ffffff;
            background-image:none;
            background-repeat:no-repeat;
            background-position:center;
            background-size:cover;
            border-top:0;
            border-bottom:0;
            padding-top:18px;
            padding-bottom:0px;
        }
        #templateHeader .mcnTextContent,#templateHeader .mcnTextContent p{
            color:#202020;
            font-family:Helvetica;
            font-size:12px;
            line-height:125%;
            text-align:left;
        }
        #templateHeader .mcnTextContent a,#templateHeader .mcnTextContent p a{
            color:#007C89;
            font-weight:normal;
            text-decoration:underline;
        }
        #templateBody{
            background-color:#ffffff;
            background-image:none;
            background-repeat:no-repeat;
            background-position:center;
            background-size:cover;
            border-top:0;
            border-bottom:2px solid #EAEAEA;
            padding-top:0;
            padding-bottom:9px;
        }
        #templateBody .mcnTextContent,#templateBody .mcnTextContent p{
            color:#202020;
            font-family:'Noticia Text', Georgia, 'Times New Roman', serif;
            font-size:16px;
            line-height:150%;
            text-align:left;
        }
        #templateBody .mcnTextContent a,#templateBody .mcnTextContent p a{
            color:#42702f;
            font-weight:normal;
            text-decoration:underline;
        }
        #templateFooter{
            background-color:#ffffff;
            background-image:none;
            background-repeat:no-repeat;
            background-position:center;
            background-size:cover;
            border-top:0;
            border-bottom:0;
            padding-top:9px;
            padding-bottom:9px;
        }
        #templateFooter .mcnTextContent,#templateFooter .mcnTextContent p{
            color:#656565;
            font-family:Helvetica;
            font-size:12px;
            line-height:150%;
            text-align:center;
        }
        #templateFooter .mcnTextContent a,#templateFooter .mcnTextContent p a{
            color:#656565;
            font-weight:normal;
            text-decoration:underline;
        }
    @media only screen and (min-width:768px){
        .templateContainer{
            width:600px !important;
        }

}   @media only screen and (max-width: 480px){
        body,table,td,p,a,li,blockquote{
            -webkit-text-size-adjust:none !important;
        }

}   @media only screen and (max-width: 480px){
        body{
            width:100% !important;
            min-width:100% !important;
        }

}   @media only screen and (max-width: 480px){
        #bodyCell{
            padding-top:10px !important;
        }

}   @media only screen and (max-width: 480px){
        .mcnRetinaImage{
            max-width:100% !important;
        }

}   @media only screen and (max-width: 480px){
        .mcnImage{
            width:100% !important;
        }

}   @media only screen and (max-width: 480px){
        .mcnCartContainer,.mcnCaptionTopContent,.mcnRecContentContainer,.mcnCaptionBottomContent,.mcnTextContentContainer,.mcnBoxedTextContentContainer,.mcnImageGroupContentContainer,.mcnCaptionLeftTextContentContainer,.mcnCaptionRightTextContentContainer,.mcnCaptionLeftImageContentContainer,.mcnCaptionRightImageContentContainer,.mcnImageCardLeftTextContentContainer,.mcnImageCardRightTextContentContainer,.mcnImageCardLeftImageContentContainer,.mcnImageCardRightImageContentContainer{
            max-width:100% !important;
            width:100% !important;
        }

}   @media only screen and (max-width: 480px){
        .mcnBoxedTextContentContainer{
            min-width:100% !important;
        }

}   @media only screen and (max-width: 480px){
        .mcnImageGroupContent{
            padding:9px !important;
        }

}   @media only screen and (max-width: 480px){
        .mcnCaptionLeftContentOuter .mcnTextContent,.mcnCaptionRightContentOuter .mcnTextContent{
            padding-top:9px !important;
        }

}   @media only screen and (max-width: 480px){
        .mcnImageCardTopImageContent,.mcnCaptionBottomContent:last-child .mcnCaptionBottomImageContent,.mcnCaptionBlockInner .mcnCaptionTopContent:last-child .mcnTextContent{
            padding-top:18px !important;
        }

}   @media only screen and (max-width: 480px){
        .mcnImageCardBottomImageContent{
            padding-bottom:9px !important;
        }

}   @media only screen and (max-width: 480px){
        .mcnImageGroupBlockInner{
            padding-top:0 !important;
            padding-bottom:0 !important;
        }

}   @media only screen and (max-width: 480px){
        .mcnImageGroupBlockOuter{
            padding-top:9px !important;
            padding-bottom:9px !important;
        }

}   @media only screen and (max-width: 480px){
        .mcnTextContent,.mcnBoxedTextContentColumn{
            padding-right:18px !important;
            padding-left:18px !important;
        }

}   @media only screen and (max-width: 480px){
        .mcnImageCardLeftImageContent,.mcnImageCardRightImageContent{
            padding-right:18px !important;
            padding-bottom:0 !important;
            padding-left:18px !important;
        }

}   @media only screen and (max-width: 480px){
        .mcpreview-image-uploader{
            display:none !important;
            width:100% !important;
        }

}   @media only screen and (max-width: 480px){
        h1{
            font-size:22px !important;
            line-height:125% !important;
        }

}   @media only screen and (max-width: 480px){
        h2{
            font-size:20px !important;
            line-height:125% !important;
        }

}   @media only screen and (max-width: 480px){
        h3{
            font-size:18px !important;
            line-height:125% !important;
        }

}   @media only screen and (max-width: 480px){
        h4{
            font-size:16px !important;
            line-height:150% !important;
        }

}   @media only screen and (max-width: 480px){
        .mcnBoxedTextContentContainer .mcnTextContent,.mcnBoxedTextContentContainer .mcnTextContent p{
            font-size:14px !important;
            line-height:150% !important;
        }

}   @media only screen and (max-width: 480px){
        #templatePreheader{
            display:block !important;
        }

}   @media only screen and (max-width: 480px){
        #templatePreheader .mcnTextContent,#templatePreheader .mcnTextContent p{
            font-size:14px !important;
            line-height:150% !important;
        }

}   @media only screen and (max-width: 480px){
        #templateHeader .mcnTextContent,#templateHeader .mcnTextContent p{
            font-size:16px !important;
            line-height:150% !important;
        }

}   @media only screen and (max-width: 480px){
        #templateBody .mcnTextContent,#templateBody .mcnTextContent p{
            font-size:16px !important;
            line-height:150% !important;
        }

}   @media only screen and (max-width: 480px){
        #templateFooter .mcnTextContent,#templateFooter .mcnTextContent p{
            font-size:14px !important;
            line-height:150% !important;
        }

}</style><!--[if !mso]><!--><link href="https://fonts.googleapis.com/css?family=Noticia+Text:400,400i,700,700i" rel="stylesheet"><!--<![endif]--></head>
    <body style="height: 100%;margin: 0;padding: 0;width: 100%;-ms-text-size-adjust: 100%;-webkit-text-size-adjust: 100%;background-color: #ffffff;">
        <!---->
        <!--[if !gte mso 9]><!----><span class="mcnPreviewText" style="display:none; font-size:0px; line-height:0px; max-height:0px; max-width:0px; opacity:0; overflow:hidden; visibility:hidden; mso-hide:all;">Crypto and $#i®T, delivered periodically</span><!--<![endif]-->
        <!---->
        <center>
            <table align="center" border="0" cellpadding="0" cellspacing="0" height="100%" width="100%" id="bodyTable" style="border-collapse: collapse;mso-table-lspace: 0pt;mso-table-rspace: 0pt;-ms-text-size-adjust: 100%;-webkit-text-size-adjust: 100%;height: 100%;margin: 0;padding: 0;width: 100%;background-color: #ffffff;">
                <tr>
                    <td align="center" valign="top" id="bodyCell" style="mso-line-height-rule: exactly;-ms-text-size-adjust: 100%;-webkit-text-size-adjust: 100%;height: 100%;margin: 0;padding: 10px;width: 100%;border-top: 0;">
                        <!-- BEGIN TEMPLATE // -->
                        <!--[if (gte mso 9)|(IE)]>
                        <table align="center" border="0" cellspacing="0" cellpadding="0" width="600" style="width:600px;">
                        <tr>
                        <td align="center" valign="top" width="600" style="width:600px;">
                        <![endif]-->
                        <table border="0" cellpadding="0" cellspacing="0" width="100%" class="templateContainer" style="border-collapse: collapse;mso-table-lspace: 0pt;mso-table-rspace: 0pt;-ms-text-size-adjust: 100%;-webkit-text-size-adjust: 100%;border: 0;max-width: 600px !important;">
                                                        <tr>
                                <td valign="top" id="templateHeader" style="background:#FFFFFF none no-repeat center/cover;mso-line-height-rule: exactly;-ms-text-size-adjust: 100%;-webkit-text-size-adjust: 100%;background-color: #FFFFFF;background-image: none;background-repeat: no-repeat;background-position: center;background-size: cover;border-top: 0;border-bottom: 0;padding-top: 9px;padding-bottom: 0;"><table border="0" cellpadding="0" cellspacing="0" width="100%" class="mcnImageBlock" style="min-width: 100%;border-collapse: collapse;mso-table-lspace: 0pt;mso-table-rspace: 0pt;-ms-text-size-adjust: 100%;-webkit-text-size-adjust: 100%;">
    <tbody class="mcnImageBlockOuter">
            <tr>
                <td valign="top" style="padding: 9px;mso-line-height-rule: exactly;-ms-text-size-adjust: 100%;-webkit-text-size-adjust: 100%;" class="mcnImageBlockInner">
                    <table align="left" width="100%" border="0" cellpadding="0" cellspacing="0" class="mcnImageContentContainer" style="min-width: 100%;border-collapse: collapse;mso-table-lspace: 0pt;mso-table-rspace: 0pt;-ms-text-size-adjust: 100%;-webkit-text-size-adjust: 100%;">
                        <tbody><tr>
                            <td class="mcnImageContent" valign="top" style="padding-right: 9px;padding-left: 9px;padding-top: 0;padding-bottom: 0;text-align: center;mso-line-height-rule: exactly;-ms-text-size-adjust: 100%;-webkit-text-size-adjust: 100%;">


                                        <img align="center" alt="" src="https://gallery.mailchimp.com/adee9c5618cae148bdcf8f762/images/f14577ff-fc89-48c4-9434-f27c27c120fd.png" width="564" style="max-width: 960px;padding-bottom: 0;display: inline !important;vertical-align: bottom;border: 0;height: auto;outline: none;text-decoration: none;-ms-interpolation-mode: bicubic;" class="mcnImage">


                            </td>
                        </tr>
                    </tbody></table>
                </td>
            </tr>
    </tbody>
</table></td>
                            </tr>
                            <tr>
                                <td valign="top" id="templateBody" style="background:#FFFFFF none no-repeat center/cover;mso-line-height-rule: exactly;-ms-text-size-adjust: 100%;-webkit-text-size-adjust: 100%;background-color: #FFFFFF;background-image: none;background-repeat: no-repeat;background-position: center;background-size: cover;border-top: 0;border-bottom: 2px solid #EAEAEA;padding-top: 0;padding-bottom: 9px;"><table border="0" cellpadding="0" cellspacing="0" width="100%" class="mcnTextBlock" style="min-width: 100%;border-collapse: collapse;mso-table-lspace: 0pt;mso-table-rspace: 0pt;-ms-text-size-adjust: 100%;-webkit-text-size-adjust: 100%;">
    <tbody class="mcnTextBlockOuter">
        <tr>
            <td valign="top" class="mcnTextBlockInner" style="padding-top: 9px;mso-line-height-rule: exactly;-ms-text-size-adjust: 100%;-webkit-text-size-adjust: 100%;">
                <!--[if mso]>
                <table align="left" border="0" cellspacing="0" cellpadding="0" width="100%" style="width:100%;">
                <tr>
                <![endif]-->

                <!--[if mso]>
                <td valign="top" width="600" style="width:600px;">
                <![endif]-->
                <table align="left" border="0" cellpadding="0" cellspacing="0" style="max-width: 100%;min-width: 100%;border-collapse: collapse;mso-table-lspace: 0pt;mso-table-rspace: 0pt;-ms-text-size-adjust: 100%;-webkit-text-size-adjust: 100%;" width="100%" class="mcnTextContentContainer">
                    <tbody><tr>

                        <td valign="top" class="mcnTextContent" style="padding-top: 0;padding-right: 18px;padding-bottom: 9px;padding-left: 18px;mso-line-height-rule: exactly;-ms-text-size-adjust: 100%;-webkit-text-size-adjust: 100%;word-break: break-word;color: #202020;font-family: Helvetica;font-size: 16px;line-height: 150%;text-align: left;">

                            <span style="font-family:noticia text,georgia,times new roman,serif">When Satoshi’s Closet launched publicly on 10-04-19 with the unveiling of <em>The Initial Shirt Offering</em>, we were proud to tout it as the world’s first apparel drop on blockchain.<br>
<br>
But what does it mean to put a physical item such as a t-shirt “on blockchain” when blockchain is a purely digital medium? And how exactly does the <em>ISO</em> work?</span>
                        </td>
                    </tr>
                </tbody></table>
                <!--[if mso]>
                </td>
                <![endif]-->

                <!--[if mso]>
                </tr>
                </table>
                <![endif]-->
            </td>
        </tr>
    </tbody>
</table><table border="0" cellpadding="0" cellspacing="0" width="100%" class="mcnImageBlock" style="min-width: 100%;border-collapse: collapse;mso-table-lspace: 0pt;mso-table-rspace: 0pt;-ms-text-size-adjust: 100%;-webkit-text-size-adjust: 100%;">
    <tbody class="mcnImageBlockOuter">
            <tr>
                <td valign="top" style="padding: 9px;mso-line-height-rule: exactly;-ms-text-size-adjust: 100%;-webkit-text-size-adjust: 100%;" class="mcnImageBlockInner">
                    <table align="left" width="100%" border="0" cellpadding="0" cellspacing="0" class="mcnImageContentContainer" style="min-width: 100%;border-collapse: collapse;mso-table-lspace: 0pt;mso-table-rspace: 0pt;-ms-text-size-adjust: 100%;-webkit-text-size-adjust: 100%;">
                        <tbody><tr>
                            <td class="mcnImageContent" valign="top" style="padding-right: 9px;padding-left: 9px;padding-top: 0;padding-bottom: 0;text-align: center;mso-line-height-rule: exactly;-ms-text-size-adjust: 100%;-webkit-text-size-adjust: 100%;">


                                        <img align="center" alt="How the ISO works" src="https://satoshiscloset.s3.amazonaws.com/stcl-apparel-blog-post-1.png" width="564" style="max-width: 984px;padding-bottom: 0;display: inline !important;vertical-align: bottom;border: 0;height: auto;outline: none;text-decoration: none;-ms-interpolation-mode: bicubic;" class="mcnImage">


                            </td>
                        </tr>
                    </tbody></table>
                </td>
            </tr>
    </tbody>
</table><table border="0" cellpadding="0" cellspacing="0" width="100%" class="mcnTextBlock" style="min-width: 100%;border-collapse: collapse;mso-table-lspace: 0pt;mso-table-rspace: 0pt;-ms-text-size-adjust: 100%;-webkit-text-size-adjust: 100%;">
    <tbody class="mcnTextBlockOuter">
        <tr>
            <td valign="top" class="mcnTextBlockInner" style="padding-top: 9px;mso-line-height-rule: exactly;-ms-text-size-adjust: 100%;-webkit-text-size-adjust: 100%;">
                <!--[if mso]>
                <table align="left" border="0" cellspacing="0" cellpadding="0" width="100%" style="width:100%;">
                <tr>
                <![endif]-->

                <!--[if mso]>
                <td valign="top" width="600" style="width:600px;">
                <![endif]-->
                <table align="left" border="0" cellpadding="0" cellspacing="0" style="max-width: 100%;min-width: 100%;border-collapse: collapse;mso-table-lspace: 0pt;mso-table-rspace: 0pt;-ms-text-size-adjust: 100%;-webkit-text-size-adjust: 100%;" width="100%" class="mcnTextContentContainer">
                    <tbody><tr>

                        <td valign="top" class="mcnTextContent" style="padding-top: 0;padding-right: 18px;padding-bottom: 9px;padding-left: 18px;mso-line-height-rule: exactly;-ms-text-size-adjust: 100%;-webkit-text-size-adjust: 100%;word-break: break-word;color: #202020;font-family: Helvetica;font-size: 16px;line-height: 150%;text-align: left;">

                            <br>
<span style="font-family:noticia text,georgia,times new roman,serif"><span style="font-size:18px"><strong>What is blockchain?</strong></span><br>
<br>
First, let’s briefly answer an even more fundamental question: what is blockchain? Blockchain is an Internet technology that uses a decentralized network of computers that read and write to the same database - a global ledger that cannot be tampered with nor erased since each computer on the network validates the entire <em>blockchain</em> history of events, with each new event added as a new block on the chain.<br>
<br>
This makes for an ideal technology to track the record of an item’s ownership as it moves from party to party. Further, when these digital records are tied to physical goods, it enables novel and valuable use cases in supply chain and commerce.<br>
<br>
<strong><span style="font-size:18px">How does <em>The Initial Shirt Offering</em> use blockchain?</span></strong><br>
<br>
<em>The Initial Shirt Offering</em> uses a blockchain platform called Ethereum, specifically an Ethereum standard called ERC721 that enables the creation of unique objects called “tokens” on the Ethereum blockchain. This is the ideal technology for the <em>ISO</em> since it provides for the creation of 50 unique tokens for 50 uniquely numbered shirts.<br>
<br>
All 50 <em>ISO</em> tokens were produced under the same Ethereum “smart contract” - a piece of code that establishes the rules for each of the tokens, for example how they can be transferred from one party to another. This smart contract code cannot be changed once it is committed to the blockchain - so you better not make any mistakes!<br>
<br>
In the case of the <em>ISO</em>, the primary rule of the smart contract is that it can mint 50 tokens, and <em>only</em> 50 tokens. This is why we say the <em>ISO</em> release was “hard capped” at 50 tokens (and t-shirts). There cannot be a token #51.<br>
<br>
That covers the blockchain component, so next is explaining how that ties into physical goods. All ERC721 tokens and their governing smart contracts have a unique address on the blockchain, and each can be represented by a long string of letters and numbers. The <em>ISO</em> smart contract lives on the Ethereum blockchain at 0x7abe4b30a1f61c02e43b17515b266736dd938dd7. And each corresponding token is found by adding its number at the end of the smart contract address. For token 21, for instance, the address is 0x7abe4b30a1f61c02e43b17515b266736dd938dd7?a=21.<br>
<br>
Here comes the essential magic: <em>if you can attach that string of letters and numbers to a physical item, you can just as easily locate its corresponding identity on blockchain</em>. Voila!<br>
<br>
But because that’s quite a long string of characters to fit on a physical object or type into a textbox on a computer to look up, we commonly use other means to represent and communicate the address. You could, for instance, affix a tiny piece of hardware embedded with that address to a physical item. Or, you could slap a QR code on it. <em>BAM</em>.<br>
<br>
We think QR codes are the next best thing to magic, because each is a <em>physical</em> representation of a unique <em>digital</em> address - whether it’s an address on blockchain or the address of a website. For <em>The Initial Shirt Offering</em>, it's actually both:</span>
                        </td>
                    </tr>
                </tbody></table>
                <!--[if mso]>
                </td>
                <![endif]-->

                <!--[if mso]>
                </tr>
                </table>
                <![endif]-->
            </td>
        </tr>
    </tbody>
</table><table border="0" cellpadding="0" cellspacing="0" width="100%" class="mcnImageBlock" style="min-width: 100%;border-collapse: collapse;mso-table-lspace: 0pt;mso-table-rspace: 0pt;-ms-text-size-adjust: 100%;-webkit-text-size-adjust: 100%;">
    <tbody class="mcnImageBlockOuter">
            <tr>
                <td valign="top" style="padding: 9px;mso-line-height-rule: exactly;-ms-text-size-adjust: 100%;-webkit-text-size-adjust: 100%;" class="mcnImageBlockInner">
                    <table align="left" width="100%" border="0" cellpadding="0" cellspacing="0" class="mcnImageContentContainer" style="min-width: 100%;border-collapse: collapse;mso-table-lspace: 0pt;mso-table-rspace: 0pt;-ms-text-size-adjust: 100%;-webkit-text-size-adjust: 100%;">
                        <tbody><tr>
                            <td class="mcnImageContent" valign="top" style="padding-right: 9px;padding-left: 9px;padding-top: 0;padding-bottom: 0;text-align: center;mso-line-height-rule: exactly;-ms-text-size-adjust: 100%;-webkit-text-size-adjust: 100%;">


                                        <img align="center" alt="ISO landing page" src="https://gallery.mailchimp.com/adee9c5618cae148bdcf8f762/images/77e41f36-86bc-4201-a1f0-d51e9d533392.png" width="564" style="max-width: 984px;padding-bottom: 0;display: inline !important;vertical-align: bottom;border: 0;height: auto;outline: none;text-decoration: none;-ms-interpolation-mode: bicubic;" class="mcnImage">


                            </td>
                        </tr>
                    </tbody></table>
                </td>
            </tr>
    </tbody>
</table><table border="0" cellpadding="0" cellspacing="0" width="100%" class="mcnTextBlock" style="min-width: 100%;border-collapse: collapse;mso-table-lspace: 0pt;mso-table-rspace: 0pt;-ms-text-size-adjust: 100%;-webkit-text-size-adjust: 100%;">
    <tbody class="mcnTextBlockOuter">
        <tr>
            <td valign="top" class="mcnTextBlockInner" style="padding-top: 9px;mso-line-height-rule: exactly;-ms-text-size-adjust: 100%;-webkit-text-size-adjust: 100%;">
                <!--[if mso]>
                <table align="left" border="0" cellspacing="0" cellpadding="0" width="100%" style="width:100%;">
                <tr>
                <![endif]-->

                <!--[if mso]>
                <td valign="top" width="600" style="width:600px;">
                <![endif]-->
                <table align="left" border="0" cellpadding="0" cellspacing="0" style="max-width: 100%;min-width: 100%;border-collapse: collapse;mso-table-lspace: 0pt;mso-table-rspace: 0pt;-ms-text-size-adjust: 100%;-webkit-text-size-adjust: 100%;" width="100%" class="mcnTextContentContainer">
                    <tbody><tr>

                        <td valign="top" class="mcnTextContent" style="padding-top: 0;padding-right: 18px;padding-bottom: 9px;padding-left: 18px;mso-line-height-rule: exactly;-ms-text-size-adjust: 100%;-webkit-text-size-adjust: 100%;word-break: break-word;color: #202020;font-family: Helvetica;font-size: 16px;line-height: 150%;text-align: left;">

                            <span style="font-family:noticia text,georgia,times new roman,serif">The QR code featured prominently on the back of the <em>ISO</em> shirt links users to a landing page on the Satoshi’s Closet website where the digital ownership of each of the 50 shirts can be tracked. Quickly punch in the shirt number, 1-50, and view the wallet address of the owner of the shirt.<br>
<br>
Not only that, the URL embedded in the QR code contains the address of the <em>ISO</em> smart contract, so even if the Satoshi’s Closet website ceases to exist, you could still look up the smart contract and each token’s permanent record on the Ethereum blockchain using a blockchain explorer site such as <a href="https://etherscan.io/address/0x7abe4b30a1f61c02e43b17515b266736dd938dd7" target="_blank" style="mso-line-height-rule: exactly;-ms-text-size-adjust: 100%;-webkit-text-size-adjust: 100%;color: #007C89;font-weight: normal;text-decoration: underline;">Etherscan</a>.<br>
<br>
<strong><span style="font-size:18px">How do buyers receive their <em>ISO</em> tokens?</span></strong><br>
<br>
With each uniquely numbered <em>ISO</em> shirt, each buyer also receives a corresponding numbered <em>ISO</em> token. The buyer of shirt #21, which is physically imprinted with that number, also receives token #21.<br>
<br>
The most secure way to do this would be for each buyer to create their own blockchain wallet to receive the token, but this is a pretty complex process for anyone lacking experience with blockchain and crypto assets. We take a simpler approach with <em>The Initial Shirt Offering</em>:</span>
                        </td>
                    </tr>
                </tbody></table>
                <!--[if mso]>
                </td>
                <![endif]-->

                <!--[if mso]>
                </tr>
                </table>
                <![endif]-->
            </td>
        </tr>
    </tbody>
</table><table border="0" cellpadding="0" cellspacing="0" width="100%" class="mcnImageBlock" style="min-width: 100%;border-collapse: collapse;mso-table-lspace: 0pt;mso-table-rspace: 0pt;-ms-text-size-adjust: 100%;-webkit-text-size-adjust: 100%;">
    <tbody class="mcnImageBlockOuter">
            <tr>
                <td valign="top" style="padding: 9px;mso-line-height-rule: exactly;-ms-text-size-adjust: 100%;-webkit-text-size-adjust: 100%;" class="mcnImageBlockInner">
                    <table align="left" width="100%" border="0" cellpadding="0" cellspacing="0" class="mcnImageContentContainer" style="min-width: 100%;border-collapse: collapse;mso-table-lspace: 0pt;mso-table-rspace: 0pt;-ms-text-size-adjust: 100%;-webkit-text-size-adjust: 100%;">
                        <tbody><tr>
                            <td class="mcnImageContent" valign="top" style="padding-right: 9px;padding-left: 9px;padding-top: 0;padding-bottom: 0;text-align: center;mso-line-height-rule: exactly;-ms-text-size-adjust: 100%;-webkit-text-size-adjust: 100%;">


                                        <img align="center" alt="Secret wallet recovery phrase" src="https://satoshiscloset.s3.amazonaws.com/stcl-apparel-blog-post-2.png" width="564" style="max-width: 984px;padding-bottom: 0;display: inline !important;vertical-align: bottom;border: 0;height: auto;outline: none;text-decoration: none;-ms-interpolation-mode: bicubic;" class="mcnImage">


                            </td>
                        </tr>
                    </tbody></table>
                </td>
            </tr>
    </tbody>
</table><table border="0" cellpadding="0" cellspacing="0" width="100%" class="mcnTextBlock" style="min-width: 100%;border-collapse: collapse;mso-table-lspace: 0pt;mso-table-rspace: 0pt;-ms-text-size-adjust: 100%;-webkit-text-size-adjust: 100%;">
    <tbody class="mcnTextBlockOuter">
        <tr>
            <td valign="top" class="mcnTextBlockInner" style="padding-top: 9px;mso-line-height-rule: exactly;-ms-text-size-adjust: 100%;-webkit-text-size-adjust: 100%;">
                <!--[if mso]>
                <table align="left" border="0" cellspacing="0" cellpadding="0" width="100%" style="width:100%;">
                <tr>
                <![endif]-->

                <!--[if mso]>
                <td valign="top" width="600" style="width:600px;">
                <![endif]-->
                <table align="left" border="0" cellpadding="0" cellspacing="0" style="max-width: 100%;min-width: 100%;border-collapse: collapse;mso-table-lspace: 0pt;mso-table-rspace: 0pt;-ms-text-size-adjust: 100%;-webkit-text-size-adjust: 100%;" width="100%" class="mcnTextContentContainer">
                    <tbody><tr>

                        <td valign="top" class="mcnTextContent" style="padding-top: 0;padding-right: 18px;padding-bottom: 9px;padding-left: 18px;mso-line-height-rule: exactly;-ms-text-size-adjust: 100%;-webkit-text-size-adjust: 100%;word-break: break-word;color: #202020;font-family: Helvetica;font-size: 16px;line-height: 150%;text-align: left;">

                            <span style="font-family:noticia text,georgia,times new roman,serif">The token handoff occurs through a physical artifact - something that’s technically not a blockchain “paper wallet” but for all intents and purposes serves the same function.<br>
<br>
Fundamentally, a blockchain wallet is nothing more than a cryptographically-secured digital address, to which the owner has the only digital key (typically called a “private key”). While this isn’t a post about cryptography - which to be honest we wouldn’t be qualified to write, anyway - suffice it to say that one way your private key can be cryptographically communicated is with a unique 12-word phrase.<br>
<br>
This phrase can get entered into a blockchain wallet app to load the wallet and its contents. We provide each <em>ISO</em> buyer with a stamped, sealed envelope containing their unique 12-word phrase and instructions on how to access their wallet and view their <em>ISO</em> token — which we’ve pre-loaded in their wallet — through a relatively easy-to-use third-party mobile app.<br>
<br>
This method lacks some essential security since the Satoshi’s Closet team handled the buyer’s 12-word phrase (and therefore access to their new wallet) before physically passing it along to them. We aim to eventually create methods that combine the ease of this token transfer experience (made even easier) with the security benefits of buyers setting up their own blockchain wallets and us never handling that information.<br>
<br>
In the meantime we hope our supporters trust us with this process, and we recommend you don’t load significant amounts of cryptocurrency and valuable digital assets onto the wallet we created for you. Create a new wallet and transfer the <em>ISO</em> token to it. #ProMoves</span>
                        </td>
                    </tr>
                </tbody></table>
                <!--[if mso]>
                </td>
                <![endif]-->

                <!--[if mso]>
                </tr>
                </table>
                <![endif]-->
            </td>
        </tr>
    </tbody>
</table><table border="0" cellpadding="0" cellspacing="0" width="100%" class="mcnImageBlock" style="min-width: 100%;border-collapse: collapse;mso-table-lspace: 0pt;mso-table-rspace: 0pt;-ms-text-size-adjust: 100%;-webkit-text-size-adjust: 100%;">
    <tbody class="mcnImageBlockOuter">
            <tr>
                <td valign="top" style="padding: 9px;mso-line-height-rule: exactly;-ms-text-size-adjust: 100%;-webkit-text-size-adjust: 100%;" class="mcnImageBlockInner">
                    <table align="left" width="100%" border="0" cellpadding="0" cellspacing="0" class="mcnImageContentContainer" style="min-width: 100%;border-collapse: collapse;mso-table-lspace: 0pt;mso-table-rspace: 0pt;-ms-text-size-adjust: 100%;-webkit-text-size-adjust: 100%;">
                        <tbody><tr>
                            <td class="mcnImageContent" valign="top" style="padding-right: 9px;padding-left: 9px;padding-top: 0;padding-bottom: 0;text-align: center;mso-line-height-rule: exactly;-ms-text-size-adjust: 100%;-webkit-text-size-adjust: 100%;">


                                        <img align="center" alt="" src="https://gallery.mailchimp.com/adee9c5618cae148bdcf8f762/images/ebfa30ff-098d-4a13-8eda-b1b12a8475c5.png" width="564" style="max-width: 984px;padding-bottom: 0;display: inline !important;vertical-align: bottom;border: 0;height: auto;outline: none;text-decoration: none;-ms-interpolation-mode: bicubic;" class="mcnImage">


                            </td>
                        </tr>
                    </tbody></table>
                </td>
            </tr>
    </tbody>
</table><table border="0" cellpadding="0" cellspacing="0" width="100%" class="mcnTextBlock" style="min-width: 100%;border-collapse: collapse;mso-table-lspace: 0pt;mso-table-rspace: 0pt;-ms-text-size-adjust: 100%;-webkit-text-size-adjust: 100%;">
    <tbody class="mcnTextBlockOuter">
        <tr>
            <td valign="top" class="mcnTextBlockInner" style="padding-top: 9px;mso-line-height-rule: exactly;-ms-text-size-adjust: 100%;-webkit-text-size-adjust: 100%;">
                <!--[if mso]>
                <table align="left" border="0" cellspacing="0" cellpadding="0" width="100%" style="width:100%;">
                <tr>
                <![endif]-->

                <!--[if mso]>
                <td valign="top" width="600" style="width:600px;">
                <![endif]-->
                <table align="left" border="0" cellpadding="0" cellspacing="0" style="max-width: 100%;min-width: 100%;border-collapse: collapse;mso-table-lspace: 0pt;mso-table-rspace: 0pt;-ms-text-size-adjust: 100%;-webkit-text-size-adjust: 100%;" width="100%" class="mcnTextContentContainer">
                    <tbody><tr>

                        <td valign="top" class="mcnTextContent" style="padding-top: 0;padding-right: 18px;padding-bottom: 9px;padding-left: 18px;mso-line-height-rule: exactly;-ms-text-size-adjust: 100%;-webkit-text-size-adjust: 100%;word-break: break-word;color: #202020;font-family: Helvetica;font-size: 16px;line-height: 150%;text-align: left;">

                            <br>
<span style="font-family:noticia text,georgia,times new roman,serif"><span style="font-size:18px"><strong>What about the fun part?</strong></span><br>
<em>What do you mean, “the fun part?” Wasn’t reading this lengthy explanatory text the fun part?</em><br>
<br>
Once buyers set up their blockchain wallet app via their 12-word phrase (sometimes called a seed phrase, or a recovery phrase), they can view a digital representation of their precious <em>ISO</em> token at any time through their wallet app. It can also be viewed online through a blockchain asset directory site such as <a href="https://opensea.io/category/stcl-the-initial-shirt-offering/" target="_blank" style="mso-line-height-rule: exactly;-ms-text-size-adjust: 100%;-webkit-text-size-adjust: 100%;color: #007C89;font-weight: normal;text-decoration: underline;">OpenSea</a>.<br>
<br>
Though anybody can view <em>The Initial Shirt Offering</em> smart contract and tokens on OpenSea or Etherscan, you as the owner are the only person in the world that can view your token in your blockchain wallet and prove your ownership 🤑🕴️<br>
<br>
We hope you found this informative. We will cover more blockchain and crypto topics as we unveil more Satoshi’s Closet apparel on blockchain 📒💡👊<br>
<br>
<em>Questions? Email us at <a href="mailto:hi@satoshiscloset.com" target="_blank" style="mso-line-height-rule: exactly;-ms-text-size-adjust: 100%;-webkit-text-size-adjust: 100%;color: #007C89;font-weight: normal;text-decoration: underline;">hi@satoshiscloset.com</a>.</em></span>
                        </td>
                    </tr>
                </tbody></table>
                <!--[if mso]>
                </td>
                <![endif]-->

                <!--[if mso]>
                </tr>
                </table>
                <![endif]-->
            </td>
        </tr>
    </tbody>
</table>
            </td>
        </tr>
    </tbody>
</table></td>
                            </tr>

                        </table>
                    </td>
                </tr>
            </table>
        </center>
    </body>
</html>
`

 const POST9 = `
    <!doctype html>
    <html xmlns="http://www.w3.org/1999/xhtml" xmlns:v="urn:schemas-microsoft-com:vml" xmlns:o="urn:schemas-microsoft-com:office:office">
        <head>
            <!-- NAME: 1 COLUMN -->
            <!--[if gte mso 15]>
            <xml>
                <o:OfficeDocumentSettings>
                <o:AllowPNG/>
                <o:PixelsPerInch>96</o:PixelsPerInch>
                </o:OfficeDocumentSettings>
            </xml>
            <![endif]-->
            <meta charset="UTF-8">
            <meta http-equiv="X-UA-Compatible" content="IE=edge">
            <meta name="viewport" content="width=device-width, initial-scale=1">
            <title>[009-09-2019] Stepping Out</title>

        <style type="text/css">
            p{
                margin:10px 0;
                padding:0;
            }
            table{
                border-collapse:collapse;
            }
            h1,h2,h3,h4,h5,h6{
                display:block;
                margin:0;
                padding:0;
            }
            img,a img{
                border:0;
                height:auto;
                outline:none;
                text-decoration:none;
            }
            body,#bodyTable,#bodyCell{
                height:100%;
                margin:0;
                padding:0;
                width:100%;
            }
            .mcnPreviewText{
                display:none !important;
            }
            #outlook a{
                padding:0;
            }
            img{
                -ms-interpolation-mode:bicubic;
            }
            table{
                mso-table-lspace:0pt;
                mso-table-rspace:0pt;
            }
            .ReadMsgBody{
                width:100%;
            }
            .ExternalClass{
                width:100%;
            }
            p,a,li,td,blockquote{
                mso-line-height-rule:exactly;
            }
            a[href^=tel],a[href^=sms]{
                color:inherit;
                cursor:default;
                text-decoration:none;
            }
            p,a,li,td,body,table,blockquote{
                -ms-text-size-adjust:100%;
                -webkit-text-size-adjust:100%;
            }
            .ExternalClass,.ExternalClass p,.ExternalClass td,.ExternalClass div,.ExternalClass span,.ExternalClass font{
                line-height:100%;
            }
            a[x-apple-data-detectors]{
                color:inherit !important;
                text-decoration:none !important;
                font-size:inherit !important;
                font-family:inherit !important;
                font-weight:inherit !important;
                line-height:inherit !important;
            }
            #bodyCell{
                padding:10px;
            }
            .templateContainer{
                max-width:600px !important;
            }
            a.mcnButton{
                display:block;
            }
            .mcnImage,.mcnRetinaImage{
                vertical-align:bottom;
            }
            .mcnTextContent{
                word-break:break-word;
            }
            .mcnTextContent img{
                height:auto !important;
            }
            .mcnDividerBlock{
                table-layout:fixed !important;
            }
            body,#bodyTable{
                background-color:#ffffff;
            }
            #bodyCell{
                border-top:0;
            }
            .templateContainer{
                border:0;
            }
            h1{
                color:#202020;
                font-family:Helvetica;
                font-size:26px;
                font-style:normal;
                font-weight:bold;
                line-height:125%;
                letter-spacing:normal;
                text-align:left;
            }
            h2{
                color:#202020;
                font-family:Helvetica;
                font-size:22px;
                font-style:normal;
                font-weight:bold;
                line-height:125%;
                letter-spacing:normal;
                text-align:left;
            }
            h3{
                color:#202020;
                font-family:Helvetica;
                font-size:20px;
                font-style:normal;
                font-weight:bold;
                line-height:125%;
                letter-spacing:normal;
                text-align:left;
            }
            h4{
                color:#202020;
                font-family:Helvetica;
                font-size:18px;
                font-style:normal;
                font-weight:bold;
                line-height:125%;
                letter-spacing:normal;
                text-align:left;
            }
            #templatePreheader{
                background-color:#FAFAFA;
                background-image:none;
                background-repeat:no-repeat;
                background-position:center;
                background-size:cover;
                border-top:0;
                border-bottom:0;
                padding-top:9px;
                padding-bottom:9px;
            }
            #templatePreheader .mcnTextContent,#templatePreheader .mcnTextContent p{
                color:#656565;
                font-family:Helvetica;
                font-size:12px;
                line-height:150%;
                text-align:left;
            }
            #templatePreheader .mcnTextContent a,#templatePreheader .mcnTextContent p a{
                color:#656565;
                font-weight:normal;
                text-decoration:underline;
            }
            #templateHeader{
                background-color:#ffffff;
                background-image:none;
                background-repeat:no-repeat;
                background-position:center;
                background-size:cover;
                border-top:0;
                border-bottom:0;
                padding-top:18px;
                padding-bottom:0px;
            }
            #templateHeader .mcnTextContent,#templateHeader .mcnTextContent p{
                color:#202020;
                font-family:Helvetica;
                font-size:12px;
                line-height:125%;
                text-align:left;
            }
            #templateHeader .mcnTextContent a,#templateHeader .mcnTextContent p a{
                color:#007C89;
                font-weight:normal;
                text-decoration:underline;
            }
            #templateBody{
                background-color:#ffffff;
                background-image:none;
                background-repeat:no-repeat;
                background-position:center;
                background-size:cover;
                border-top:0;
                border-bottom:2px solid #EAEAEA;
                padding-top:0;
                padding-bottom:9px;
            }
            #templateBody .mcnTextContent,#templateBody .mcnTextContent p{
                color:#202020;
                font-family:'Noticia Text', Georgia, 'Times New Roman', serif;
                font-size:16px;
                line-height:150%;
                text-align:left;
            }
            #templateBody .mcnTextContent a,#templateBody .mcnTextContent p a{
                color:#42702f;
                font-weight:normal;
                text-decoration:underline;
            }
            #templateFooter{
                background-color:#ffffff;
                background-image:none;
                background-repeat:no-repeat;
                background-position:center;
                background-size:cover;
                border-top:0;
                border-bottom:0;
                padding-top:9px;
                padding-bottom:9px;
            }
            #templateFooter .mcnTextContent,#templateFooter .mcnTextContent p{
                color:#656565;
                font-family:Helvetica;
                font-size:12px;
                line-height:150%;
                text-align:center;
            }
            #templateFooter .mcnTextContent a,#templateFooter .mcnTextContent p a{
                color:#656565;
                font-weight:normal;
                text-decoration:underline;
            }
        @media only screen and (min-width:768px){
            .templateContainer{
                width:600px !important;
            }

    }   @media only screen and (max-width: 480px){
            body,table,td,p,a,li,blockquote{
                -webkit-text-size-adjust:none !important;
            }

    }   @media only screen and (max-width: 480px){
            body{
                width:100% !important;
                min-width:100% !important;
            }

    }   @media only screen and (max-width: 480px){
            #bodyCell{
                padding-top:10px !important;
            }

    }   @media only screen and (max-width: 480px){
            .mcnRetinaImage{
                max-width:100% !important;
            }

    }   @media only screen and (max-width: 480px){
            .mcnImage{
                width:100% !important;
            }

    }   @media only screen and (max-width: 480px){
            .mcnCartContainer,.mcnCaptionTopContent,.mcnRecContentContainer,.mcnCaptionBottomContent,.mcnTextContentContainer,.mcnBoxedTextContentContainer,.mcnImageGroupContentContainer,.mcnCaptionLeftTextContentContainer,.mcnCaptionRightTextContentContainer,.mcnCaptionLeftImageContentContainer,.mcnCaptionRightImageContentContainer,.mcnImageCardLeftTextContentContainer,.mcnImageCardRightTextContentContainer,.mcnImageCardLeftImageContentContainer,.mcnImageCardRightImageContentContainer{
                max-width:100% !important;
                width:100% !important;
            }

    }   @media only screen and (max-width: 480px){
            .mcnBoxedTextContentContainer{
                min-width:100% !important;
            }

    }   @media only screen and (max-width: 480px){
            .mcnImageGroupContent{
                padding:9px !important;
            }

    }   @media only screen and (max-width: 480px){
            .mcnCaptionLeftContentOuter .mcnTextContent,.mcnCaptionRightContentOuter .mcnTextContent{
                padding-top:9px !important;
            }

    }   @media only screen and (max-width: 480px){
            .mcnImageCardTopImageContent,.mcnCaptionBottomContent:last-child .mcnCaptionBottomImageContent,.mcnCaptionBlockInner .mcnCaptionTopContent:last-child .mcnTextContent{
                padding-top:18px !important;
            }

    }   @media only screen and (max-width: 480px){
            .mcnImageCardBottomImageContent{
                padding-bottom:9px !important;
            }

    }   @media only screen and (max-width: 480px){
            .mcnImageGroupBlockInner{
                padding-top:0 !important;
                padding-bottom:0 !important;
            }

    }   @media only screen and (max-width: 480px){
            .mcnImageGroupBlockOuter{
                padding-top:9px !important;
                padding-bottom:9px !important;
            }

    }   @media only screen and (max-width: 480px){
            .mcnTextContent,.mcnBoxedTextContentColumn{
                padding-right:18px !important;
                padding-left:18px !important;
            }

    }   @media only screen and (max-width: 480px){
            .mcnImageCardLeftImageContent,.mcnImageCardRightImageContent{
                padding-right:18px !important;
                padding-bottom:0 !important;
                padding-left:18px !important;
            }

    }   @media only screen and (max-width: 480px){
            .mcpreview-image-uploader{
                display:none !important;
                width:100% !important;
            }

    }   @media only screen and (max-width: 480px){
            h1{
                font-size:22px !important;
                line-height:125% !important;
            }

    }   @media only screen and (max-width: 480px){
            h2{
                font-size:20px !important;
                line-height:125% !important;
            }

    }   @media only screen and (max-width: 480px){
            h3{
                font-size:18px !important;
                line-height:125% !important;
            }

    }   @media only screen and (max-width: 480px){
            h4{
                font-size:16px !important;
                line-height:150% !important;
            }

    }   @media only screen and (max-width: 480px){
            .mcnBoxedTextContentContainer .mcnTextContent,.mcnBoxedTextContentContainer .mcnTextContent p{
                font-size:14px !important;
                line-height:150% !important;
            }

    }   @media only screen and (max-width: 480px){
            #templatePreheader{
                display:block !important;
            }

    }   @media only screen and (max-width: 480px){
            #templatePreheader .mcnTextContent,#templatePreheader .mcnTextContent p{
                font-size:14px !important;
                line-height:150% !important;
            }

    }   @media only screen and (max-width: 480px){
            #templateHeader .mcnTextContent,#templateHeader .mcnTextContent p{
                font-size:16px !important;
                line-height:150% !important;
            }

    }   @media only screen and (max-width: 480px){
            #templateBody .mcnTextContent,#templateBody .mcnTextContent p{
                font-size:16px !important;
                line-height:150% !important;
            }

    }   @media only screen and (max-width: 480px){
            #templateFooter .mcnTextContent,#templateFooter .mcnTextContent p{
                font-size:14px !important;
                line-height:150% !important;
            }

    }</style><!--[if !mso]><!--><link href="https://fonts.googleapis.com/css?family=Noticia+Text:400,400i,700,700i" rel="stylesheet"><!--<![endif]--></head>
        <body style="height: 100%;margin: 0;padding: 0;width: 100%;-ms-text-size-adjust: 100%;-webkit-text-size-adjust: 100%;background-color: #ffffff;">
            <!---->
            <!--[if !gte mso 9]><!----><span class="mcnPreviewText" style="display:none; font-size:0px; line-height:0px; max-height:0px; max-width:0px; opacity:0; overflow:hidden; visibility:hidden; mso-hide:all;">Crypto and $#i®T, delivered periodically</span><!--<![endif]-->
            <!---->
            <center>
                <table align="center" border="0" cellpadding="0" cellspacing="0" height="100%" width="100%" id="bodyTable" style="border-collapse: collapse;mso-table-lspace: 0pt;mso-table-rspace: 0pt;-ms-text-size-adjust: 100%;-webkit-text-size-adjust: 100%;height: 100%;margin: 0;padding: 0;width: 100%;background-color: #ffffff;">
                    <tr>
                        <td align="center" valign="top" id="bodyCell" style="mso-line-height-rule: exactly;-ms-text-size-adjust: 100%;-webkit-text-size-adjust: 100%;height: 100%;margin: 0;padding: 10px;width: 100%;border-top: 0;">
                            <!-- BEGIN TEMPLATE // -->
                            <!--[if (gte mso 9)|(IE)]>
                            <table align="center" border="0" cellspacing="0" cellpadding="0" width="600" style="width:600px;">
                            <tr>
                            <td align="center" valign="top" width="600" style="width:600px;">
                            <![endif]-->
                            <table border="0" cellpadding="0" cellspacing="0" width="100%" class="templateContainer" style="border-collapse: collapse;mso-table-lspace: 0pt;mso-table-rspace: 0pt;-ms-text-size-adjust: 100%;-webkit-text-size-adjust: 100%;border: 0;max-width: 600px !important;">
                                <tr>
                                    <td valign="top" id="templateHeader" style="background:#ffffff none no-repeat center/cover;mso-line-height-rule: exactly;-ms-text-size-adjust: 100%;-webkit-text-size-adjust: 100%;background-color: #ffffff;background-image: none;background-repeat: no-repeat;background-position: center;background-size: cover;border-top: 0;border-bottom: 0;padding-top: 18px;padding-bottom: 0px;"><table border="0" cellpadding="0" cellspacing="0" width="100%" class="mcnImageBlock" style="min-width: 100%;border-collapse: collapse;mso-table-lspace: 0pt;mso-table-rspace: 0pt;-ms-text-size-adjust: 100%;-webkit-text-size-adjust: 100%;">
        <tbody class="mcnImageBlockOuter">
                <tr>
                    <td valign="top" style="padding: 9px;mso-line-height-rule: exactly;-ms-text-size-adjust: 100%;-webkit-text-size-adjust: 100%;" class="mcnImageBlockInner">
                        <table align="left" width="100%" border="0" cellpadding="0" cellspacing="0" class="mcnImageContentContainer" style="min-width: 100%;border-collapse: collapse;mso-table-lspace: 0pt;mso-table-rspace: 0pt;-ms-text-size-adjust: 100%;-webkit-text-size-adjust: 100%;">
                            <tbody><tr>
                                <td class="mcnImageContent" valign="top" style="padding-right: 9px;padding-left: 9px;padding-top: 0;padding-bottom: 0;text-align: center;mso-line-height-rule: exactly;-ms-text-size-adjust: 100%;-webkit-text-size-adjust: 100%;">


                                            <img align="center" alt="" src="https://gallery.mailchimp.com/adee9c5618cae148bdcf8f762/images/6336cc44-7754-490e-8252-c774b0b5ae25.jpg" width="564" style="max-width: 960px;padding-bottom: 0;display: inline !important;vertical-align: bottom;border: 0;height: auto;outline: none;text-decoration: none;-ms-interpolation-mode: bicubic;" class="mcnImage">


                                </td>
                            </tr>
                        </tbody></table>
                    </td>
                </tr>
        </tbody>
    </table></td>
                                </tr>
                                <tr>
                                    <td valign="top" id="templateBody" style="background:#ffffff none no-repeat center/cover;mso-line-height-rule: exactly;-ms-text-size-adjust: 100%;-webkit-text-size-adjust: 100%;background-color: #ffffff;background-image: none;background-repeat: no-repeat;background-position: center;background-size: cover;border-top: 0;border-bottom: 2px solid #EAEAEA;padding-top: 0;padding-bottom: 9px;"><table border="0" cellpadding="0" cellspacing="0" width="100%" class="mcnDividerBlock" style="min-width: 100%;border-collapse: collapse;mso-table-lspace: 0pt;mso-table-rspace: 0pt;-ms-text-size-adjust: 100%;-webkit-text-size-adjust: 100%;table-layout: fixed !important;">
        <tbody class="mcnDividerBlockOuter">
            <tr>
                <td class="mcnDividerBlockInner" style="min-width: 100%;padding: 8px 18px 0px;mso-line-height-rule: exactly;-ms-text-size-adjust: 100%;-webkit-text-size-adjust: 100%;">
                    <table class="mcnDividerContent" border="0" cellpadding="0" cellspacing="0" width="100%" style="min-width: 100%;border-top: 2px solid #EAEAEA;border-collapse: collapse;mso-table-lspace: 0pt;mso-table-rspace: 0pt;-ms-text-size-adjust: 100%;-webkit-text-size-adjust: 100%;">
                        <tbody><tr>
                            <td style="mso-line-height-rule: exactly;-ms-text-size-adjust: 100%;-webkit-text-size-adjust: 100%;">
                                <span></span>
                            </td>
                        </tr>
                    </tbody></table>
    <!--
                    <td class="mcnDividerBlockInner" style="padding: 18px;">
                    <hr class="mcnDividerContent" style="border-bottom-color:none; border-left-color:none; border-right-color:none; border-bottom-width:0; border-left-width:0; border-right-width:0; margin-top:0; margin-right:0; margin-bottom:0; margin-left:0;" />
    -->
                </td>
            </tr>
        </tbody>
    </table><table border="0" cellpadding="0" cellspacing="0" width="100%" class="mcnTextBlock" style="min-width: 100%;border-collapse: collapse;mso-table-lspace: 0pt;mso-table-rspace: 0pt;-ms-text-size-adjust: 100%;-webkit-text-size-adjust: 100%;">
        <tbody class="mcnTextBlockOuter">
            <tr>
                <td valign="top" class="mcnTextBlockInner" style="padding-top: 9px;mso-line-height-rule: exactly;-ms-text-size-adjust: 100%;-webkit-text-size-adjust: 100%;">
                    <!--[if mso]>
                    <table align="left" border="0" cellspacing="0" cellpadding="0" width="100%" style="width:100%;">
                    <tr>
                    <![endif]-->

                    <!--[if mso]>
                    <td valign="top" width="600" style="width:600px;">
                    <![endif]-->
                    <table align="left" border="0" cellpadding="0" cellspacing="0" style="max-width: 100%;min-width: 100%;border-collapse: collapse;mso-table-lspace: 0pt;mso-table-rspace: 0pt;-ms-text-size-adjust: 100%;-webkit-text-size-adjust: 100%;" width="100%" class="mcnTextContentContainer">
                        <tbody><tr>

                            <td valign="top" class="mcnTextContent" style="padding: 0px 18px 9px;font-family: &quot;Noticia Text&quot;, Georgia, &quot;Times New Roman&quot;, serif;line-height: 150%;mso-line-height-rule: exactly;-ms-text-size-adjust: 100%;-webkit-text-size-adjust: 100%;word-break: break-word;color: #202020;font-size: 16px;text-align: left;">

                                <div style="text-align: left;"><strong><span style="font-size:12px"><span style="font-family:noticia text,georgia,times new roman,serif">Announcing the $STCL ISO • Launch party! • Cool $#i®T • Airdrops!</span></span></strong></div>

                            </td>
                        </tr>
                    </tbody></table>
                    <!--[if mso]>
                    </td>
                    <![endif]-->

                    <!--[if mso]>
                    </tr>
                    </table>
                    <![endif]-->
                </td>
            </tr>
        </tbody>
    </table><table border="0" cellpadding="0" cellspacing="0" width="100%" class="mcnDividerBlock" style="min-width: 100%;border-collapse: collapse;mso-table-lspace: 0pt;mso-table-rspace: 0pt;-ms-text-size-adjust: 100%;-webkit-text-size-adjust: 100%;table-layout: fixed !important;">
        <tbody class="mcnDividerBlockOuter">
            <tr>
                <td class="mcnDividerBlockInner" style="min-width: 100%;padding: 2px 18px 9px;mso-line-height-rule: exactly;-ms-text-size-adjust: 100%;-webkit-text-size-adjust: 100%;">
                    <table class="mcnDividerContent" border="0" cellpadding="0" cellspacing="0" width="100%" style="min-width: 100%;border-top: 2px solid #EAEAEA;border-collapse: collapse;mso-table-lspace: 0pt;mso-table-rspace: 0pt;-ms-text-size-adjust: 100%;-webkit-text-size-adjust: 100%;">
                        <tbody><tr>
                            <td style="mso-line-height-rule: exactly;-ms-text-size-adjust: 100%;-webkit-text-size-adjust: 100%;">
                                <span></span>
                            </td>
                        </tr>
                    </tbody></table>
    <!--
                    <td class="mcnDividerBlockInner" style="padding: 18px;">
                    <hr class="mcnDividerContent" style="border-bottom-color:none; border-left-color:none; border-right-color:none; border-bottom-width:0; border-left-width:0; border-right-width:0; margin-top:0; margin-right:0; margin-bottom:0; margin-left:0;" />
    -->
                </td>
            </tr>
        </tbody>
    </table><table border="0" cellpadding="0" cellspacing="0" width="100%" class="mcnTextBlock" style="min-width: 100%;border-collapse: collapse;mso-table-lspace: 0pt;mso-table-rspace: 0pt;-ms-text-size-adjust: 100%;-webkit-text-size-adjust: 100%;">
        <tbody class="mcnTextBlockOuter">
            <tr>
                <td valign="top" class="mcnTextBlockInner" style="padding-top: 9px;mso-line-height-rule: exactly;-ms-text-size-adjust: 100%;-webkit-text-size-adjust: 100%;">
                    <!--[if mso]>
                    <table align="left" border="0" cellspacing="0" cellpadding="0" width="100%" style="width:100%;">
                    <tr>
                    <![endif]-->

                    <!--[if mso]>
                    <td valign="top" width="600" style="width:600px;">
                    <![endif]-->
                    <table align="left" border="0" cellpadding="0" cellspacing="0" style="max-width: 100%;min-width: 100%;border-collapse: collapse;mso-table-lspace: 0pt;mso-table-rspace: 0pt;-ms-text-size-adjust: 100%;-webkit-text-size-adjust: 100%;" width="100%" class="mcnTextContentContainer">
                        <tbody><tr>

                            <td valign="top" class="mcnTextContent" style="padding: 0px 18px 9px;font-family: &quot;Noticia Text&quot;, Georgia, &quot;Times New Roman&quot;, serif;line-height: 150%;mso-line-height-rule: exactly;-ms-text-size-adjust: 100%;-webkit-text-size-adjust: 100%;word-break: break-word;color: #202020;font-size: 16px;text-align: left;">

                                <p dir="ltr" style="font-family: &quot;Noticia Text&quot;, Georgia, &quot;Times New Roman&quot;, serif;line-height: 150%;margin: 10px 0;padding: 0;mso-line-height-rule: exactly;-ms-text-size-adjust: 100%;-webkit-text-size-adjust: 100%;color: #202020;font-size: 16px;text-align: left;"><span style="font-size:18px"><strong>$STCL</strong></span><br>
    <br>
    After months of work quietly creating (HODL’n &amp; BUIDL’n) behind the scenes, we are excited to announce the debut of the next generation of streetwear and blockchain tech from Satoshi’s Closet. Sitting at the intersection of crypto, blockchain, fashion, and culture, we invite you to join us in creating Our Crypto Future through apparel that pushes the boundaries of physical/digital ownership, and communicates &amp; realizes the potential of cryptocurrency and blockchain.<br>
    <br>
    How is this taking shape? We’re doing an <a href="https://cointelegraph.com/explained/ico-explained" target="_blank" style="mso-line-height-rule: exactly;-ms-text-size-adjust: 100%;-webkit-text-size-adjust: 100%;color: #42702f;font-weight: normal;text-decoration: underline;">ICO</a>. (Just kidding. And they’re called <a href="https://en.bitcoinwiki.org/wiki/Security_Token_Offering" target="_blank" style="mso-line-height-rule: exactly;-ms-text-size-adjust: 100%;-webkit-text-size-adjust: 100%;color: #42702f;font-weight: normal;text-decoration: underline;">STO’s</a> now, anyway #rebrand.) We’re actually doing an ISO – an Initial Shirt Offering. Actually, make that <em>The</em> Initial Shirt Offering, dropping on October 4.<br>
    <br>
    Because we’re an apparel company. That’s also a tech company.<br>
    <br>
    The Initial Shirt Offering is a hard-capped / limited edition release of 50 shirts, each backed by a non-fungible ERC-721 crypto token. Counterfeiting these digital tokens is impossible. Each shirt is uniquely numbered and includes a crypto paper wallet containing the one-of-a-kind digital token corresponding to that shirt. If a buyer ever desires to give away or resell their shirt, they’ll need to be able to show and transfer the corresponding token to the new buyer to show their shirt is legit.<br>
    <br>
    Streetwear offers the perfect application for both blockchain and crypto (two distinct though related topics that we will untangle over time). First, as a vehicle to both educate and cultivate adoption through cultural permeation – streetwear is a $175 billion dollar global market – and second for practical reasons, as the global market of counterfeit goods (which includes streetwear and luxury fashion) is in the hundreds of billions of dollars and exemplifies a problem that blockchain is perfectly positioned to solve. Someone can knock off a shirt or a hat or whatever, but they can’t forge a unique crypto token.<br>
    <br>
    Fun, right? 🤑<br>
    <br>
    Read more about what we’re doing and why we’re doing it: <a href="https://satoshiscloset.com/manifesto.pdf" target="_blank" style="mso-line-height-rule: exactly;-ms-text-size-adjust: 100%;-webkit-text-size-adjust: 100%;color: #42702f;font-weight: normal;text-decoration: underline;">The Manifesto / White Paper</a></p>

                            </td>
                        </tr>
                    </tbody></table>
                    <!--[if mso]>
                    </td>
                    <![endif]-->

                    <!--[if mso]>
                    </tr>
                    </table>
                    <![endif]-->
                </td>
            </tr>
        </tbody>
    </table><table border="0" cellpadding="0" cellspacing="0" width="100%" class="mcnDividerBlock" style="min-width: 100%;border-collapse: collapse;mso-table-lspace: 0pt;mso-table-rspace: 0pt;-ms-text-size-adjust: 100%;-webkit-text-size-adjust: 100%;table-layout: fixed !important;">
        <tbody class="mcnDividerBlockOuter">
            <tr>
                <td class="mcnDividerBlockInner" style="min-width: 100%;padding: 24px 18px;mso-line-height-rule: exactly;-ms-text-size-adjust: 100%;-webkit-text-size-adjust: 100%;">
                    <table class="mcnDividerContent" border="0" cellpadding="0" cellspacing="0" width="100%" style="min-width: 100%;border-top: 2px solid #EAEAEA;border-collapse: collapse;mso-table-lspace: 0pt;mso-table-rspace: 0pt;-ms-text-size-adjust: 100%;-webkit-text-size-adjust: 100%;">
                        <tbody><tr>
                            <td style="mso-line-height-rule: exactly;-ms-text-size-adjust: 100%;-webkit-text-size-adjust: 100%;">
                                <span></span>
                            </td>
                        </tr>
                    </tbody></table>
    <!--
                    <td class="mcnDividerBlockInner" style="padding: 18px;">
                    <hr class="mcnDividerContent" style="border-bottom-color:none; border-left-color:none; border-right-color:none; border-bottom-width:0; border-left-width:0; border-right-width:0; margin-top:0; margin-right:0; margin-bottom:0; margin-left:0;" />
    -->
                </td>
            </tr>
        </tbody>
    </table><table border="0" cellpadding="0" cellspacing="0" width="100%" class="mcnTextBlock" style="min-width: 100%;border-collapse: collapse;mso-table-lspace: 0pt;mso-table-rspace: 0pt;-ms-text-size-adjust: 100%;-webkit-text-size-adjust: 100%;">
        <tbody class="mcnTextBlockOuter">
            <tr>
                <td valign="top" class="mcnTextBlockInner" style="padding-top: 9px;mso-line-height-rule: exactly;-ms-text-size-adjust: 100%;-webkit-text-size-adjust: 100%;">
                    <!--[if mso]>
                    <table align="left" border="0" cellspacing="0" cellpadding="0" width="100%" style="width:100%;">
                    <tr>
                    <![endif]-->

                    <!--[if mso]>
                    <td valign="top" width="600" style="width:600px;">
                    <![endif]-->
                    <table align="left" border="0" cellpadding="0" cellspacing="0" style="max-width: 100%;min-width: 100%;border-collapse: collapse;mso-table-lspace: 0pt;mso-table-rspace: 0pt;-ms-text-size-adjust: 100%;-webkit-text-size-adjust: 100%;" width="100%" class="mcnTextContentContainer">
                        <tbody><tr>

                            <td valign="top" class="mcnTextContent" style="padding: 0px 18px 9px;font-family: &quot;Noticia Text&quot;, Georgia, &quot;Times New Roman&quot;, serif;line-height: 150%;mso-line-height-rule: exactly;-ms-text-size-adjust: 100%;-webkit-text-size-adjust: 100%;word-break: break-word;color: #202020;font-size: 16px;text-align: left;">

                                <p dir="ltr" style="font-family: &quot;Noticia Text&quot;, Georgia, &quot;Times New Roman&quot;, serif;line-height: 150%;margin: 10px 0;padding: 0;mso-line-height-rule: exactly;-ms-text-size-adjust: 100%;-webkit-text-size-adjust: 100%;color: #202020;font-size: 16px;text-align: left;"><span style="font-size:18px"><strong>Launch Party!</strong></span></p>

                            </td>
                        </tr>
                    </tbody></table>
                    <!--[if mso]>
                    </td>
                    <![endif]-->

                    <!--[if mso]>
                    </tr>
                    </table>
                    <![endif]-->
                </td>
            </tr>
        </tbody>
    </table><table border="0" cellpadding="0" cellspacing="0" width="100%" class="mcnImageCardBlock" style="border-collapse: collapse;mso-table-lspace: 0pt;mso-table-rspace: 0pt;-ms-text-size-adjust: 100%;-webkit-text-size-adjust: 100%;">
        <tbody class="mcnImageCardBlockOuter">
            <tr>
                <td class="mcnImageCardBlockInner" valign="top" style="padding-top: 9px;padding-right: 18px;padding-bottom: 9px;padding-left: 18px;mso-line-height-rule: exactly;-ms-text-size-adjust: 100%;-webkit-text-size-adjust: 100%;">

    <table align="left" border="0" cellpadding="0" cellspacing="0" class="mcnImageCardBottomContent" width="100%" style="border-collapse: collapse;mso-table-lspace: 0pt;mso-table-rspace: 0pt;-ms-text-size-adjust: 100%;-webkit-text-size-adjust: 100%;">
        <tbody><tr>
            <td class="mcnImageCardBottomImageContent" align="center" valign="top" style="padding-top: 0px;padding-right: 0px;padding-bottom: 0;padding-left: 0px;mso-line-height-rule: exactly;-ms-text-size-adjust: 100%;-webkit-text-size-adjust: 100%;">



                <img alt="" src="https://gallery.mailchimp.com/adee9c5618cae148bdcf8f762/images/2c807f4e-5b10-4319-9b82-e296f7cf681d.png" width="564" style="max-width: 1080px;border: 0;height: auto;outline: none;text-decoration: none;-ms-interpolation-mode: bicubic;vertical-align: bottom;" class="mcnImage">


            </td>
        </tr>
        <tr>
            <td class="mcnTextContent" valign="top" style="padding: 9px 18px;color: #747474;font-family: &quot;Noticia Text&quot;, Georgia, &quot;Times New Roman&quot;, serif;font-size: 14px;font-weight: normal;text-align: center;mso-line-height-rule: exactly;-ms-text-size-adjust: 100%;-webkit-text-size-adjust: 100%;word-break: break-word;line-height: 150%;" width="546">

            </td>
        </tr>
    </tbody></table>




                </td>
            </tr>
        </tbody>
    </table><table border="0" cellpadding="0" cellspacing="0" width="100%" class="mcnTextBlock" style="min-width: 100%;border-collapse: collapse;mso-table-lspace: 0pt;mso-table-rspace: 0pt;-ms-text-size-adjust: 100%;-webkit-text-size-adjust: 100%;">
        <tbody class="mcnTextBlockOuter">
            <tr>
                <td valign="top" class="mcnTextBlockInner" style="padding-top: 9px;mso-line-height-rule: exactly;-ms-text-size-adjust: 100%;-webkit-text-size-adjust: 100%;">
                    <!--[if mso]>
                    <table align="left" border="0" cellspacing="0" cellpadding="0" width="100%" style="width:100%;">
                    <tr>
                    <![endif]-->

                    <!--[if mso]>
                    <td valign="top" width="600" style="width:600px;">
                    <![endif]-->
                    <table align="left" border="0" cellpadding="0" cellspacing="0" style="max-width: 100%;min-width: 100%;border-collapse: collapse;mso-table-lspace: 0pt;mso-table-rspace: 0pt;-ms-text-size-adjust: 100%;-webkit-text-size-adjust: 100%;" width="100%" class="mcnTextContentContainer">
                        <tbody><tr>

                            <td valign="top" class="mcnTextContent" style="padding-top: 0;padding-right: 18px;padding-bottom: 9px;padding-left: 18px;mso-line-height-rule: exactly;-ms-text-size-adjust: 100%;-webkit-text-size-adjust: 100%;word-break: break-word;color: #202020;font-family: 'Noticia Text', Georgia, 'Times New Roman', serif;font-size: 16px;line-height: 150%;text-align: left;">

                                <div style="text-align: center;">RSVP @ <a href="http://www.satoshiscloset.com/RSVP" target="_blank" style="mso-line-height-rule: exactly;-ms-text-size-adjust: 100%;-webkit-text-size-adjust: 100%;color: #42702f;font-weight: normal;text-decoration: underline;">satoshiscloset.com/RSVP</a></div>

                            </td>
                        </tr>
                    </tbody></table>
                    <!--[if mso]>
                    </td>
                    <![endif]-->

                    <!--[if mso]>
                    </tr>
                    </table>
                    <![endif]-->
                </td>
            </tr>
        </tbody>
    </table><table border="0" cellpadding="0" cellspacing="0" width="100%" class="mcnDividerBlock" style="min-width: 100%;border-collapse: collapse;mso-table-lspace: 0pt;mso-table-rspace: 0pt;-ms-text-size-adjust: 100%;-webkit-text-size-adjust: 100%;table-layout: fixed !important;">
        <tbody class="mcnDividerBlockOuter">
            <tr>
                <td class="mcnDividerBlockInner" style="min-width: 100%;padding: 24px 18px;mso-line-height-rule: exactly;-ms-text-size-adjust: 100%;-webkit-text-size-adjust: 100%;">
                    <table class="mcnDividerContent" border="0" cellpadding="0" cellspacing="0" width="100%" style="min-width: 100%;border-top: 2px solid #EAEAEA;border-collapse: collapse;mso-table-lspace: 0pt;mso-table-rspace: 0pt;-ms-text-size-adjust: 100%;-webkit-text-size-adjust: 100%;">
                        <tbody><tr>
                            <td style="mso-line-height-rule: exactly;-ms-text-size-adjust: 100%;-webkit-text-size-adjust: 100%;">
                                <span></span>
                            </td>
                        </tr>
                    </tbody></table>
    <!--
                    <td class="mcnDividerBlockInner" style="padding: 18px;">
                    <hr class="mcnDividerContent" style="border-bottom-color:none; border-left-color:none; border-right-color:none; border-bottom-width:0; border-left-width:0; border-right-width:0; margin-top:0; margin-right:0; margin-bottom:0; margin-left:0;" />
    -->
                </td>
            </tr>
        </tbody>
    </table><table border="0" cellpadding="0" cellspacing="0" width="100%" class="mcnTextBlock" style="min-width: 100%;border-collapse: collapse;mso-table-lspace: 0pt;mso-table-rspace: 0pt;-ms-text-size-adjust: 100%;-webkit-text-size-adjust: 100%;">
        <tbody class="mcnTextBlockOuter">
            <tr>
                <td valign="top" class="mcnTextBlockInner" style="padding-top: 9px;mso-line-height-rule: exactly;-ms-text-size-adjust: 100%;-webkit-text-size-adjust: 100%;">
                    <!--[if mso]>
                    <table align="left" border="0" cellspacing="0" cellpadding="0" width="100%" style="width:100%;">
                    <tr>
                    <![endif]-->

                    <!--[if mso]>
                    <td valign="top" width="600" style="width:600px;">
                    <![endif]-->
                    <table align="left" border="0" cellpadding="0" cellspacing="0" style="max-width: 100%;min-width: 100%;border-collapse: collapse;mso-table-lspace: 0pt;mso-table-rspace: 0pt;-ms-text-size-adjust: 100%;-webkit-text-size-adjust: 100%;" width="100%" class="mcnTextContentContainer">
                        <tbody><tr>

                            <td valign="top" class="mcnTextContent" style="padding: 0px 18px 9px;font-family: &quot;Noticia Text&quot;, Georgia, &quot;Times New Roman&quot;, serif;line-height: 150%;mso-line-height-rule: exactly;-ms-text-size-adjust: 100%;-webkit-text-size-adjust: 100%;word-break: break-word;color: #202020;font-size: 16px;text-align: left;">

                                <span style="font-size:18px"><span style="font-family:noticia text,georgia,times new roman,serif"><strong>Cool $#i®T of the week</strong></span></span>
                            </td>
                        </tr>
                    </tbody></table>
                    <!--[if mso]>
                    </td>
                    <![endif]-->

                    <!--[if mso]>
                    </tr>
                    </table>
                    <![endif]-->
                </td>
            </tr>
        </tbody>
    </table><table border="0" cellpadding="0" cellspacing="0" width="100%" class="mcnTextBlock" style="min-width: 100%;border-collapse: collapse;mso-table-lspace: 0pt;mso-table-rspace: 0pt;-ms-text-size-adjust: 100%;-webkit-text-size-adjust: 100%;">
        <tbody class="mcnTextBlockOuter">
            <tr>
                <td valign="top" class="mcnTextBlockInner" style="padding-top: 9px;mso-line-height-rule: exactly;-ms-text-size-adjust: 100%;-webkit-text-size-adjust: 100%;">
                    <!--[if mso]>
                    <table align="left" border="0" cellspacing="0" cellpadding="0" width="100%" style="width:100%;">
                    <tr>
                    <![endif]-->

                    <!--[if mso]>
                    <td valign="top" width="600" style="width:600px;">
                    <![endif]-->
                    <table align="left" border="0" cellpadding="0" cellspacing="0" style="max-width: 100%;min-width: 100%;border-collapse: collapse;mso-table-lspace: 0pt;mso-table-rspace: 0pt;-ms-text-size-adjust: 100%;-webkit-text-size-adjust: 100%;" width="100%" class="mcnTextContentContainer">
                        <tbody><tr>

                            <td valign="top" class="mcnTextContent" style="padding: 0px 18px 9px;font-family: &quot;Noticia Text&quot;, Georgia, &quot;Times New Roman&quot;, serif;line-height: 150%;mso-line-height-rule: exactly;-ms-text-size-adjust: 100%;-webkit-text-size-adjust: 100%;word-break: break-word;color: #202020;font-size: 16px;text-align: left;">

                                <p dir="ltr" style="font-family: &quot;Noticia Text&quot;, Georgia, &quot;Times New Roman&quot;, serif;line-height: 150%;margin: 10px 0;padding: 0;mso-line-height-rule: exactly;-ms-text-size-adjust: 100%;-webkit-text-size-adjust: 100%;color: #202020;font-size: 16px;text-align: left;">If you’ve been following us since [001-01-2019], then you know most of our past email newsletters (now <a href="http://www.satoshiscloset.com/blog" target="_blank" style="mso-line-height-rule: exactly;-ms-text-size-adjust: 100%;-webkit-text-size-adjust: 100%;color: #42702f;font-weight: normal;text-decoration: underline;">blog posts</a>) feature a Cool $#i®T of the Week. Today, we are featuring our own Initial Shirt Offering. Well, sort of. It’s not dropping until October 4, so until then, just trust us when we tell you that we’re HUGE on Rinkeby.</p>

                            </td>
                        </tr>
                    </tbody></table>
                    <!--[if mso]>
                    </td>
                    <![endif]-->

                    <!--[if mso]>
                    </tr>
                    </table>
                    <![endif]-->
                </td>
            </tr>
        </tbody>
    </table><table border="0" cellpadding="0" cellspacing="0" width="100%" class="mcnImageCardBlock" style="border-collapse: collapse;mso-table-lspace: 0pt;mso-table-rspace: 0pt;-ms-text-size-adjust: 100%;-webkit-text-size-adjust: 100%;">
        <tbody class="mcnImageCardBlockOuter">
            <tr>
                <td class="mcnImageCardBlockInner" valign="top" style="padding-top: 9px;padding-right: 18px;padding-bottom: 9px;padding-left: 18px;mso-line-height-rule: exactly;-ms-text-size-adjust: 100%;-webkit-text-size-adjust: 100%;">

    <table align="right" border="0" cellpadding="0" cellspacing="0" class="mcnImageCardBottomContent" width="100%" style="border-collapse: collapse;mso-table-lspace: 0pt;mso-table-rspace: 0pt;-ms-text-size-adjust: 100%;-webkit-text-size-adjust: 100%;">
        <tbody><tr>
            <td class="mcnImageCardBottomImageContent" align="center" valign="top" style="padding-top: 0px;padding-right: 0px;padding-bottom: 0;padding-left: 0px;mso-line-height-rule: exactly;-ms-text-size-adjust: 100%;-webkit-text-size-adjust: 100%;">


                <a href="https://www.complex.com/sports/2019/02/adam-silver-showcases-nba-jerseys-of-the-future-at-tech-summit" title="" class="" target="_blank" style="mso-line-height-rule: exactly;-ms-text-size-adjust: 100%;-webkit-text-size-adjust: 100%;">


                <img alt="" src="https://gallery.mailchimp.com/adee9c5618cae148bdcf8f762/images/27ceb20e-a728-49d7-aa63-43e40db2d0a9.png" width="564" style="max-width: 746px;border: 0;height: auto;outline: none;text-decoration: none;-ms-interpolation-mode: bicubic;vertical-align: bottom;" class="mcnImage">
                </a>

            </td>
        </tr>
        <tr>
            <td class="mcnTextContent" valign="top" style="padding: 9px 18px;color: #747474;font-family: &quot;Noticia Text&quot;, Georgia, &quot;Times New Roman&quot;, serif;font-size: 14px;font-weight: normal;text-align: center;mso-line-height-rule: exactly;-ms-text-size-adjust: 100%;-webkit-text-size-adjust: 100%;word-break: break-word;line-height: 150%;" width="546">

            </td>
        </tr>
    </tbody></table>




                </td>
            </tr>
        </tbody>
    </table><table border="0" cellpadding="0" cellspacing="0" width="100%" class="mcnDividerBlock" style="min-width: 100%;border-collapse: collapse;mso-table-lspace: 0pt;mso-table-rspace: 0pt;-ms-text-size-adjust: 100%;-webkit-text-size-adjust: 100%;table-layout: fixed !important;">
        <tbody class="mcnDividerBlockOuter">
            <tr>
                <td class="mcnDividerBlockInner" style="min-width: 100%;padding: 18px;mso-line-height-rule: exactly;-ms-text-size-adjust: 100%;-webkit-text-size-adjust: 100%;">
                    <table class="mcnDividerContent" border="0" cellpadding="0" cellspacing="0" width="100%" style="min-width: 100%;border-top: 2px solid #EAEAEA;border-collapse: collapse;mso-table-lspace: 0pt;mso-table-rspace: 0pt;-ms-text-size-adjust: 100%;-webkit-text-size-adjust: 100%;">
                        <tbody><tr>
                            <td style="mso-line-height-rule: exactly;-ms-text-size-adjust: 100%;-webkit-text-size-adjust: 100%;">
                                <span></span>
                            </td>
                        </tr>
                    </tbody></table>
    <!--
                    <td class="mcnDividerBlockInner" style="padding: 18px;">
                    <hr class="mcnDividerContent" style="border-bottom-color:none; border-left-color:none; border-right-color:none; border-bottom-width:0; border-left-width:0; border-right-width:0; margin-top:0; margin-right:0; margin-bottom:0; margin-left:0;" />
    -->
                </td>
            </tr>
        </tbody>
    </table><table border="0" cellpadding="0" cellspacing="0" width="100%" class="mcnTextBlock" style="min-width: 100%;border-collapse: collapse;mso-table-lspace: 0pt;mso-table-rspace: 0pt;-ms-text-size-adjust: 100%;-webkit-text-size-adjust: 100%;">
        <tbody class="mcnTextBlockOuter">
            <tr>
                <td valign="top" class="mcnTextBlockInner" style="padding-top: 9px;mso-line-height-rule: exactly;-ms-text-size-adjust: 100%;-webkit-text-size-adjust: 100%;">
                    <!--[if mso]>
                    <table align="left" border="0" cellspacing="0" cellpadding="0" width="100%" style="width:100%;">
                    <tr>
                    <![endif]-->

                    <!--[if mso]>
                    <td valign="top" width="600" style="width:600px;">
                    <![endif]-->
                    <table align="left" border="0" cellpadding="0" cellspacing="0" style="max-width: 100%;min-width: 100%;border-collapse: collapse;mso-table-lspace: 0pt;mso-table-rspace: 0pt;-ms-text-size-adjust: 100%;-webkit-text-size-adjust: 100%;" width="100%" class="mcnTextContentContainer">
                        <tbody><tr>

                            <td valign="top" class="mcnTextContent" style="padding: 0px 18px 9px;font-family: &quot;Noticia Text&quot;, Georgia, &quot;Times New Roman&quot;, serif;line-height: 150%;mso-line-height-rule: exactly;-ms-text-size-adjust: 100%;-webkit-text-size-adjust: 100%;word-break: break-word;color: #202020;font-size: 16px;text-align: left;">

                                <div><strong><span style="font-size:24px">Airdrops! 🚀📦💰</span></strong></div>

    <div>&nbsp;</div>

    <div>If you’ve been following us since [001-01-2019], then you probably also know that our weekly email newsletter went quiet several months back. (Did we mention back issues are now available on the <a href="http://www.satoshiscloset.com/blog" target="_blank" style="mso-line-height-rule: exactly;-ms-text-size-adjust: 100%;-webkit-text-size-adjust: 100%;color: #42702f;font-weight: normal;text-decoration: underline;">blog</a>?)<br>
    <br>
    We love some of the longer-form commentary and original writing we produced, and we look forward to producing it again soon-ish – and until then we are sharing the same style of informative content and commentary in shorter-form through our Airdrops® Instagram posts.<br>
    <br>
    Give us a follow, won’t you? <a href="http://www.instagram.com/satoshiscloset" target="_blank" style="mso-line-height-rule: exactly;-ms-text-size-adjust: 100%;-webkit-text-size-adjust: 100%;color: #42702f;font-weight: normal;text-decoration: underline;">www.instagram.com/satoshiscloset</a>.</div>

                            </td>
                        </tr>
                    </tbody></table>
                    <!--[if mso]>
                    </td>
                    <![endif]-->

                    <!--[if mso]>
                    </tr>
                    </table>
                    <![endif]-->
                </td>
            </tr>
        </tbody>
    </table><table border="0" cellpadding="0" cellspacing="0" width="100%" class="mcnDividerBlock" style="min-width: 100%;border-collapse: collapse;mso-table-lspace: 0pt;mso-table-rspace: 0pt;-ms-text-size-adjust: 100%;-webkit-text-size-adjust: 100%;table-layout: fixed !important;">
        <tbody class="mcnDividerBlockOuter">
            <tr>
                <td class="mcnDividerBlockInner" style="min-width: 100%;padding: 18px;mso-line-height-rule: exactly;-ms-text-size-adjust: 100%;-webkit-text-size-adjust: 100%;">
                    <table class="mcnDividerContent" border="0" cellpadding="0" cellspacing="0" width="100%" style="min-width: 100%;border-top: 2px solid #EAEAEA;border-collapse: collapse;mso-table-lspace: 0pt;mso-table-rspace: 0pt;-ms-text-size-adjust: 100%;-webkit-text-size-adjust: 100%;">
                        <tbody><tr>
                            <td style="mso-line-height-rule: exactly;-ms-text-size-adjust: 100%;-webkit-text-size-adjust: 100%;">
                                <span></span>
                            </td>
                        </tr>
                    </tbody></table>
    <!--
                    <td class="mcnDividerBlockInner" style="padding: 18px;">
                    <hr class="mcnDividerContent" style="border-bottom-color:none; border-left-color:none; border-right-color:none; border-bottom-width:0; border-left-width:0; border-right-width:0; margin-top:0; margin-right:0; margin-bottom:0; margin-left:0;" />
    -->
                </td>
            </tr>
        </tbody>
    </table><table border="0" cellpadding="0" cellspacing="0" width="100%" class="mcnTextBlock" style="min-width: 100%;border-collapse: collapse;mso-table-lspace: 0pt;mso-table-rspace: 0pt;-ms-text-size-adjust: 100%;-webkit-text-size-adjust: 100%;">
        <tbody class="mcnTextBlockOuter">
            <tr>
                <td valign="top" class="mcnTextBlockInner" style="padding-top: 9px;mso-line-height-rule: exactly;-ms-text-size-adjust: 100%;-webkit-text-size-adjust: 100%;">
                    <!--[if mso]>
                    <table align="left" border="0" cellspacing="0" cellpadding="0" width="100%" style="width:100%;">
                    <tr>
                    <![endif]-->

                    <!--[if mso]>
                    <td valign="top" width="600" style="width:600px;">
                    <![endif]-->
                    <table align="left" border="0" cellpadding="0" cellspacing="0" style="max-width: 100%;min-width: 100%;border-collapse: collapse;mso-table-lspace: 0pt;mso-table-rspace: 0pt;-ms-text-size-adjust: 100%;-webkit-text-size-adjust: 100%;" width="100%" class="mcnTextContentContainer">
                        <tbody><tr>

                            <td valign="top" class="mcnTextContent" style="padding: 0px 18px 9px;font-family: &quot;Noticia Text&quot;, Georgia, &quot;Times New Roman&quot;, serif;line-height: 150%;mso-line-height-rule: exactly;-ms-text-size-adjust: 100%;-webkit-text-size-adjust: 100%;word-break: break-word;color: #202020;font-size: 16px;text-align: left;">

                                <div style="text-align: center;"><strong><em>C’est tout pour maintenant!</em></strong><br>
    <br>
    Tips and leads for us? Hit us up at <a href="mailto:hi@satoshiscloset.com?subject=Tips%20and%20leads" target="_blank" style="mso-line-height-rule: exactly;-ms-text-size-adjust: 100%;-webkit-text-size-adjust: 100%;color: #42702f;font-weight: normal;text-decoration: underline;">hi@satoshiscloset.com</a>. ✌️<br>
    <br>
    <img data-file-id="4326517" height="128" src="https://gallery.mailchimp.com/adee9c5618cae148bdcf8f762/images/98c6e2b1-a7da-4b2a-8718-799bda1c4658.gif" style="border: 0px;width: 128px;height: 128px;margin: 0px;outline: none;text-decoration: none;-ms-interpolation-mode: bicubic;" width="128"><br>
    <strong>Check out previous issues:</strong><br>
    <a href="https://satoshiscloset.com/blog" target="_blank" style="mso-line-height-rule: exactly;-ms-text-size-adjust: 100%;-webkit-text-size-adjust: 100%;color: #42702f;font-weight: normal;text-decoration: underline;">https://satoshiscloset.com/blog</a></div>
    </td>
                        </tr>
                    </tbody></table>
                </td>
            </tr>
        </tbody>
    </table></td>
                                </tr>

                            </table>
                        </td>
                    </tr>
                </table>
            </center>
        </body>
    </html>
 `

 const POST1 = `
     <!doctype html>
     <html xmlns="http://www.w3.org/1999/xhtml" xmlns:v="urn:schemas-microsoft-com:vml" xmlns:o="urn:schemas-microsoft-com:office:office">
         <head>
             <!-- NAME: 1 COLUMN -->
             <!--[if gte mso 15]>
             <xml>
                 <o:OfficeDocumentSettings>
                 <o:AllowPNG/>
                 <o:PixelsPerInch>96</o:PixelsPerInch>
                 </o:OfficeDocumentSettings>
             </xml>
             <![endif]-->
             <meta charset="UTF-8">
             <meta http-equiv="X-UA-Compatible" content="IE=edge">
             <meta name="viewport" content="width=device-width, initial-scale=1">
             <title>*|MC:SUBJECT|*</title>

         <style type="text/css">
             p{
                 margin:10px 0;
                 padding:0;
             }
             table{
                 border-collapse:collapse;
             }
             h1,h2,h3,h4,h5,h6{
                 display:block;
                 margin:0;
                 padding:0;
             }
             img,a img{
                 border:0;
                 height:auto;
                 outline:none;
                 text-decoration:none;
             }
             body,#bodyTable,#bodyCell{
                 height:100%;
                 margin:0;
                 padding:0;
                 width:100%;
             }
             .mcnPreviewText{
                 display:none !important;
             }
             #outlook a{
                 padding:0;
             }
             img{
                 -ms-interpolation-mode:bicubic;
             }
             table{
                 mso-table-lspace:0pt;
                 mso-table-rspace:0pt;
             }
             .ReadMsgBody{
                 width:100%;
             }
             .ExternalClass{
                 width:100%;
             }
             p,a,li,td,blockquote{
                 mso-line-height-rule:exactly;
             }
             a[href^=tel],a[href^=sms]{
                 color:inherit;
                 cursor:default;
                 text-decoration:none;
             }
             p,a,li,td,body,table,blockquote{
                 -ms-text-size-adjust:100%;
                 -webkit-text-size-adjust:100%;
             }
             .ExternalClass,.ExternalClass p,.ExternalClass td,.ExternalClass div,.ExternalClass span,.ExternalClass font{
                 line-height:100%;
             }
             a[x-apple-data-detectors]{
                 color:inherit !important;
                 text-decoration:none !important;
                 font-size:inherit !important;
                 font-family:inherit !important;
                 font-weight:inherit !important;
                 line-height:inherit !important;
             }
             #bodyCell{
                 padding:10px;
             }
             .templateContainer{
                 max-width:600px !important;
             }
             a.mcnButton{
                 display:block;
             }
             .mcnImage,.mcnRetinaImage{
                 vertical-align:bottom;
             }
             .mcnTextContent{
                 word-break:break-word;
             }
             .mcnTextContent img{
                 height:auto !important;
             }
             .mcnDividerBlock{
                 table-layout:fixed !important;
             }
             body,#bodyTable{
                 background-color:#ffffff;
             }
             #bodyCell{
                 border-top:0;
             }
             .templateContainer{
                 border:0;
             }
             h1{
                 color:#202020;
                 font-family:Helvetica;
                 font-size:26px;
                 font-style:normal;
                 font-weight:bold;
                 line-height:125%;
                 letter-spacing:normal;
                 text-align:left;
             }
             h2{
                 color:#202020;
                 font-family:Helvetica;
                 font-size:22px;
                 font-style:normal;
                 font-weight:bold;
                 line-height:125%;
                 letter-spacing:normal;
                 text-align:left;
             }
             h3{
                 color:#202020;
                 font-family:Helvetica;
                 font-size:20px;
                 font-style:normal;
                 font-weight:bold;
                 line-height:125%;
                 letter-spacing:normal;
                 text-align:left;
             }
             h4{
                 color:#202020;
                 font-family:Helvetica;
                 font-size:18px;
                 font-style:normal;
                 font-weight:bold;
                 line-height:125%;
                 letter-spacing:normal;
                 text-align:left;
             }
             #templatePreheader{
                 background-color:#FAFAFA;
                 background-image:none;
                 background-repeat:no-repeat;
                 background-position:center;
                 background-size:cover;
                 border-top:0;
                 border-bottom:0;
                 padding-top:9px;
                 padding-bottom:9px;
             }
             #templatePreheader .mcnTextContent,#templatePreheader .mcnTextContent p{
                 color:#656565;
                 font-family:Helvetica;
                 font-size:12px;
                 line-height:150%;
                 text-align:left;
             }
             #templatePreheader .mcnTextContent a,#templatePreheader .mcnTextContent p a{
                 color:#656565;
                 font-weight:normal;
                 text-decoration:underline;
             }
             #templateHeader{
                 background-color:#ffffff;
                 background-image:none;
                 background-repeat:no-repeat;
                 background-position:center;
                 background-size:cover;
                 border-top:0;
                 border-bottom:0;
                 padding-top:18px;
                 padding-bottom:0px;
             }
             #templateHeader .mcnTextContent,#templateHeader .mcnTextContent p{
                 color:#202020;
                 font-family:Helvetica;
                 font-size:12px;
                 line-height:125%;
                 text-align:left;
             }
             #templateHeader .mcnTextContent a,#templateHeader .mcnTextContent p a{
                 color:#2BAADF;
                 font-weight:normal;
                 text-decoration:underline;
             }
             #templateBody{
                 background-color:#ffffff;
                 background-image:none;
                 background-repeat:no-repeat;
                 background-position:center;
                 background-size:cover;
                 border-top:0;
                 border-bottom:2px solid #EAEAEA;
                 padding-top:0;
                 padding-bottom:9px;
             }
             #templateBody .mcnTextContent,#templateBody .mcnTextContent p{
                 color:#202020;
                 font-family:'Noticia Text', Georgia, 'Times New Roman', serif;
                 font-size:16px;
                 line-height:150%;
                 text-align:left;
             }
             #templateBody .mcnTextContent a,#templateBody .mcnTextContent p a{
                 color:#42702f;
                 font-weight:normal;
                 text-decoration:underline;
             }
             #templateFooter{
                 background-color:#ffffff;
                 background-image:none;
                 background-repeat:no-repeat;
                 background-position:center;
                 background-size:cover;
                 border-top:0;
                 border-bottom:0;
                 padding-top:9px;
                 padding-bottom:9px;
             }
             #templateFooter .mcnTextContent,#templateFooter .mcnTextContent p{
                 color:#656565;
                 font-family:Helvetica;
                 font-size:12px;
                 line-height:150%;
                 text-align:center;
             }
             #templateFooter .mcnTextContent a,#templateFooter .mcnTextContent p a{
                 color:#656565;
                 font-weight:normal;
                 text-decoration:underline;
             }
         @media only screen and (min-width:768px){
             .templateContainer{
                 width:600px !important;
             }

     }   @media only screen and (max-width: 480px){
             body,table,td,p,a,li,blockquote{
                 -webkit-text-size-adjust:none !important;
             }

     }   @media only screen and (max-width: 480px){
             body{
                 width:100% !important;
                 min-width:100% !important;
             }

     }   @media only screen and (max-width: 480px){
             #bodyCell{
                 padding-top:10px !important;
             }

     }   @media only screen and (max-width: 480px){
             .mcnRetinaImage{
                 max-width:100% !important;
             }

     }   @media only screen and (max-width: 480px){
             .mcnImage{
                 width:100% !important;
             }

     }   @media only screen and (max-width: 480px){
             .mcnCartContainer,.mcnCaptionTopContent,.mcnRecContentContainer,.mcnCaptionBottomContent,.mcnTextContentContainer,.mcnBoxedTextContentContainer,.mcnImageGroupContentContainer,.mcnCaptionLeftTextContentContainer,.mcnCaptionRightTextContentContainer,.mcnCaptionLeftImageContentContainer,.mcnCaptionRightImageContentContainer,.mcnImageCardLeftTextContentContainer,.mcnImageCardRightTextContentContainer,.mcnImageCardLeftImageContentContainer,.mcnImageCardRightImageContentContainer{
                 max-width:100% !important;
                 width:100% !important;
             }

     }   @media only screen and (max-width: 480px){
             .mcnBoxedTextContentContainer{
                 min-width:100% !important;
             }

     }   @media only screen and (max-width: 480px){
             .mcnImageGroupContent{
                 padding:9px !important;
             }

     }   @media only screen and (max-width: 480px){
             .mcnCaptionLeftContentOuter .mcnTextContent,.mcnCaptionRightContentOuter .mcnTextContent{
                 padding-top:9px !important;
             }

     }   @media only screen and (max-width: 480px){
             .mcnImageCardTopImageContent,.mcnCaptionBottomContent:last-child .mcnCaptionBottomImageContent,.mcnCaptionBlockInner .mcnCaptionTopContent:last-child .mcnTextContent{
                 padding-top:18px !important;
             }

     }   @media only screen and (max-width: 480px){
             .mcnImageCardBottomImageContent{
                 padding-bottom:9px !important;
             }

     }   @media only screen and (max-width: 480px){
             .mcnImageGroupBlockInner{
                 padding-top:0 !important;
                 padding-bottom:0 !important;
             }

     }   @media only screen and (max-width: 480px){
             .mcnImageGroupBlockOuter{
                 padding-top:9px !important;
                 padding-bottom:9px !important;
             }

     }   @media only screen and (max-width: 480px){
             .mcnTextContent,.mcnBoxedTextContentColumn{
                 padding-right:18px !important;
                 padding-left:18px !important;
             }

     }   @media only screen and (max-width: 480px){
             .mcnImageCardLeftImageContent,.mcnImageCardRightImageContent{
                 padding-right:18px !important;
                 padding-bottom:0 !important;
                 padding-left:18px !important;
             }

     }   @media only screen and (max-width: 480px){
             .mcpreview-image-uploader{
                 display:none !important;
                 width:100% !important;
             }

     }   @media only screen and (max-width: 480px){
             h1{
                 font-size:22px !important;
                 line-height:125% !important;
             }

     }   @media only screen and (max-width: 480px){
             h2{
                 font-size:20px !important;
                 line-height:125% !important;
             }

     }   @media only screen and (max-width: 480px){
             h3{
                 font-size:18px !important;
                 line-height:125% !important;
             }

     }   @media only screen and (max-width: 480px){
             h4{
                 font-size:16px !important;
                 line-height:150% !important;
             }

     }   @media only screen and (max-width: 480px){
             .mcnBoxedTextContentContainer .mcnTextContent,.mcnBoxedTextContentContainer .mcnTextContent p{
                 font-size:14px !important;
                 line-height:150% !important;
             }

     }   @media only screen and (max-width: 480px){
             #templatePreheader{
                 display:block !important;
             }

     }   @media only screen and (max-width: 480px){
             #templatePreheader .mcnTextContent,#templatePreheader .mcnTextContent p{
                 font-size:14px !important;
                 line-height:150% !important;
             }

     }   @media only screen and (max-width: 480px){
             #templateHeader .mcnTextContent,#templateHeader .mcnTextContent p{
                 font-size:16px !important;
                 line-height:150% !important;
             }

     }   @media only screen and (max-width: 480px){
             #templateBody .mcnTextContent,#templateBody .mcnTextContent p{
                 font-size:16px !important;
                 line-height:150% !important;
             }

     }   @media only screen and (max-width: 480px){
             #templateFooter .mcnTextContent,#templateFooter .mcnTextContent p{
                 font-size:14px !important;
                 line-height:150% !important;
             }

     }</style><!--[if !mso]><!--><link href="https://fonts.googleapis.com/css?family=Noticia+Text:400,400i,700,700i" rel="stylesheet"><!--<![endif]--></head>
         <body style="height: 100%;margin: 0;padding: 0;width: 100%;-ms-text-size-adjust: 100%;-webkit-text-size-adjust: 100%;background-color: #ffffff;">
             <!--*|IF:MC_PREVIEW_TEXT|*-->
             <!--[if !gte mso 9]><!----><span class="mcnPreviewText" style="display:none; font-size:0px; line-height:0px; max-height:0px; max-width:0px; opacity:0; overflow:hidden; visibility:hidden; mso-hide:all;">*|MC_PREVIEW_TEXT|*</span><!--<![endif]-->
             <!--*|END:IF|*-->
             <center>
                 <table align="center" border="0" cellpadding="0" cellspacing="0" height="100%" width="100%" id="bodyTable" style="border-collapse: collapse;mso-table-lspace: 0pt;mso-table-rspace: 0pt;-ms-text-size-adjust: 100%;-webkit-text-size-adjust: 100%;height: 100%;margin: 0;padding: 0;width: 100%;background-color: #ffffff;">
                     <tr>
                         <td align="center" valign="top" id="bodyCell" style="mso-line-height-rule: exactly;-ms-text-size-adjust: 100%;-webkit-text-size-adjust: 100%;height: 100%;margin: 0;padding: 10px;width: 100%;border-top: 0;">
                             <!-- BEGIN TEMPLATE // -->
                             <!--[if (gte mso 9)|(IE)]>
                             <table align="center" border="0" cellspacing="0" cellpadding="0" width="600" style="width:600px;">
                             <tr>
                             <td align="center" valign="top" width="600" style="width:600px;">
                             <![endif]-->
                             <table border="0" cellpadding="0" cellspacing="0" width="100%" class="templateContainer" style="border-collapse: collapse;mso-table-lspace: 0pt;mso-table-rspace: 0pt;-ms-text-size-adjust: 100%;-webkit-text-size-adjust: 100%;border: 0;max-width: 600px !important;">

                                 <tr>
                                     <td valign="top" id="templateHeader" style="background:#ffffff none no-repeat center/cover;mso-line-height-rule: exactly;-ms-text-size-adjust: 100%;-webkit-text-size-adjust: 100%;background-color: #ffffff;background-image: none;background-repeat: no-repeat;background-position: center;background-size: cover;border-top: 0;border-bottom: 0;padding-top: 18px;padding-bottom: 0px;"><table border="0" cellpadding="0" cellspacing="0" width="100%" class="mcnImageBlock" style="min-width: 100%;border-collapse: collapse;mso-table-lspace: 0pt;mso-table-rspace: 0pt;-ms-text-size-adjust: 100%;-webkit-text-size-adjust: 100%;">
         <tbody class="mcnImageBlockOuter">
                 <tr>
                     <td valign="top" style="padding: 9px;mso-line-height-rule: exactly;-ms-text-size-adjust: 100%;-webkit-text-size-adjust: 100%;" class="mcnImageBlockInner">
                         <table align="left" width="100%" border="0" cellpadding="0" cellspacing="0" class="mcnImageContentContainer" style="min-width: 100%;border-collapse: collapse;mso-table-lspace: 0pt;mso-table-rspace: 0pt;-ms-text-size-adjust: 100%;-webkit-text-size-adjust: 100%;">
                             <tbody><tr>
                                 <td class="mcnImageContent" valign="top" style="padding-right: 9px;padding-left: 9px;padding-top: 0;padding-bottom: 0;text-align: center;mso-line-height-rule: exactly;-ms-text-size-adjust: 100%;-webkit-text-size-adjust: 100%;">


                                             <img align="center" alt="" src="https://gallery.mailchimp.com/adee9c5618cae148bdcf8f762/images/4eba291c-0673-404f-8b85-7fca3391da87.png" width="480" style="max-width: 960px;padding-bottom: 0;display: inline !important;vertical-align: bottom;border: 0;height: auto;outline: none;text-decoration: none;-ms-interpolation-mode: bicubic;" class="mcnRetinaImage">


                                 </td>
                             </tr>
                         </tbody></table>
                     </td>
                 </tr>
         </tbody>
     </table></td>
                                 </tr>
                                 <tr>
                                     <td valign="top" id="templateBody" style="background:#ffffff none no-repeat center/cover;mso-line-height-rule: exactly;-ms-text-size-adjust: 100%;-webkit-text-size-adjust: 100%;background-color: #ffffff;background-image: none;background-repeat: no-repeat;background-position: center;background-size: cover;border-top: 0;border-bottom: 2px solid #EAEAEA;padding-top: 0;padding-bottom: 9px;"><table border="0" cellpadding="0" cellspacing="0" width="100%" class="mcnDividerBlock" style="min-width: 100%;border-collapse: collapse;mso-table-lspace: 0pt;mso-table-rspace: 0pt;-ms-text-size-adjust: 100%;-webkit-text-size-adjust: 100%;table-layout: fixed !important;">
         <tbody class="mcnDividerBlockOuter">
             <tr>
                 <td class="mcnDividerBlockInner" style="min-width: 100%;padding: 8px 18px 0px;mso-line-height-rule: exactly;-ms-text-size-adjust: 100%;-webkit-text-size-adjust: 100%;">
                     <table class="mcnDividerContent" border="0" cellpadding="0" cellspacing="0" width="100%" style="min-width: 100%;border-top: 2px solid #EAEAEA;border-collapse: collapse;mso-table-lspace: 0pt;mso-table-rspace: 0pt;-ms-text-size-adjust: 100%;-webkit-text-size-adjust: 100%;">
                         <tbody><tr>
                             <td style="mso-line-height-rule: exactly;-ms-text-size-adjust: 100%;-webkit-text-size-adjust: 100%;">
                                 <span></span>
                             </td>
                         </tr>
                     </tbody></table>
     <!--
                     <td class="mcnDividerBlockInner" style="padding: 18px;">
                     <hr class="mcnDividerContent" style="border-bottom-color:none; border-left-color:none; border-right-color:none; border-bottom-width:0; border-left-width:0; border-right-width:0; margin-top:0; margin-right:0; margin-bottom:0; margin-left:0;" />
     -->
                 </td>
             </tr>
         </tbody>
     </table><table border="0" cellpadding="0" cellspacing="0" width="100%" class="mcnTextBlock" style="min-width: 100%;border-collapse: collapse;mso-table-lspace: 0pt;mso-table-rspace: 0pt;-ms-text-size-adjust: 100%;-webkit-text-size-adjust: 100%;">
         <tbody class="mcnTextBlockOuter">
             <tr>
                 <td valign="top" class="mcnTextBlockInner" style="padding-top: 9px;mso-line-height-rule: exactly;-ms-text-size-adjust: 100%;-webkit-text-size-adjust: 100%;">
                     <!--[if mso]>
                     <table align="left" border="0" cellspacing="0" cellpadding="0" width="100%" style="width:100%;">
                     <tr>
                     <![endif]-->

                     <!--[if mso]>
                     <td valign="top" width="600" style="width:600px;">
                     <![endif]-->
                     <table align="left" border="0" cellpadding="0" cellspacing="0" style="max-width: 100%;min-width: 100%;border-collapse: collapse;mso-table-lspace: 0pt;mso-table-rspace: 0pt;-ms-text-size-adjust: 100%;-webkit-text-size-adjust: 100%;" width="100%" class="mcnTextContentContainer">
                         <tbody><tr>

                             <td valign="top" class="mcnTextContent" style="padding-top: 0;padding-right: 18px;padding-bottom: 9px;padding-left: 18px;mso-line-height-rule: exactly;-ms-text-size-adjust: 100%;-webkit-text-size-adjust: 100%;word-break: break-word;color: #202020;font-family: 'Noticia Text', Georgia, 'Times New Roman', serif;font-size: 16px;line-height: 150%;text-align: left;">

                                 <div style="text-align: left;"><strong><span style="font-size:12px"><span style="font-family:noticia text,georgia,times new roman,serif">Intro • a VC on BTC • FloraChain exclusive interview • .rar, Matey! • FREE BTC!</span></span></strong></div>

                             </td>
                         </tr>
                     </tbody></table>
                     <!--[if mso]>
                     </td>
                     <![endif]-->

                     <!--[if mso]>
                     </tr>
                     </table>
                     <![endif]-->
                 </td>
             </tr>
         </tbody>
     </table><table border="0" cellpadding="0" cellspacing="0" width="100%" class="mcnDividerBlock" style="min-width: 100%;border-collapse: collapse;mso-table-lspace: 0pt;mso-table-rspace: 0pt;-ms-text-size-adjust: 100%;-webkit-text-size-adjust: 100%;table-layout: fixed !important;">
         <tbody class="mcnDividerBlockOuter">
             <tr>
                 <td class="mcnDividerBlockInner" style="min-width: 100%;padding: 2px 18px 9px;mso-line-height-rule: exactly;-ms-text-size-adjust: 100%;-webkit-text-size-adjust: 100%;">
                     <table class="mcnDividerContent" border="0" cellpadding="0" cellspacing="0" width="100%" style="min-width: 100%;border-top: 2px solid #EAEAEA;border-collapse: collapse;mso-table-lspace: 0pt;mso-table-rspace: 0pt;-ms-text-size-adjust: 100%;-webkit-text-size-adjust: 100%;">
                         <tbody><tr>
                             <td style="mso-line-height-rule: exactly;-ms-text-size-adjust: 100%;-webkit-text-size-adjust: 100%;">
                                 <span></span>
                             </td>
                         </tr>
                     </tbody></table>
     <!--
                     <td class="mcnDividerBlockInner" style="padding: 18px;">
                     <hr class="mcnDividerContent" style="border-bottom-color:none; border-left-color:none; border-right-color:none; border-bottom-width:0; border-left-width:0; border-right-width:0; margin-top:0; margin-right:0; margin-bottom:0; margin-left:0;" />
     -->
                 </td>
             </tr>
         </tbody>
     </table><table border="0" cellpadding="0" cellspacing="0" width="100%" class="mcnTextBlock" style="min-width: 100%;border-collapse: collapse;mso-table-lspace: 0pt;mso-table-rspace: 0pt;-ms-text-size-adjust: 100%;-webkit-text-size-adjust: 100%;">
         <tbody class="mcnTextBlockOuter">
             <tr>
                 <td valign="top" class="mcnTextBlockInner" style="padding-top: 9px;mso-line-height-rule: exactly;-ms-text-size-adjust: 100%;-webkit-text-size-adjust: 100%;">
                     <!--[if mso]>
                     <table align="left" border="0" cellspacing="0" cellpadding="0" width="100%" style="width:100%;">
                     <tr>
                     <![endif]-->

                     <!--[if mso]>
                     <td valign="top" width="600" style="width:600px;">
                     <![endif]-->
                     <table align="left" border="0" cellpadding="0" cellspacing="0" style="max-width: 100%;min-width: 100%;border-collapse: collapse;mso-table-lspace: 0pt;mso-table-rspace: 0pt;-ms-text-size-adjust: 100%;-webkit-text-size-adjust: 100%;" width="100%" class="mcnTextContentContainer">
                         <tbody><tr>

                             <td valign="top" class="mcnTextContent" style="padding: 0px 18px 9px;font-family: "Noticia Text", Georgia, "Times New Roman", serif;line-height: 150%;mso-line-height-rule: exactly;-ms-text-size-adjust: 100%;-webkit-text-size-adjust: 100%;word-break: break-word;color: #202020;font-size: 16px;text-align: left;">

                                 <p dir="ltr" style="font-family: "Noticia Text", Georgia, "Times New Roman", serif;line-height: 150%;margin: 10px 0;padding: 0;mso-line-height-rule: exactly;-ms-text-size-adjust: 100%;-webkit-text-size-adjust: 100%;color: #202020;font-size: 16px;text-align: left;">If you’ve received this email, it’s because someone in your email contacts may or may not be Satoshi. Probably not, but some of you can’t be 100% sure, either... you know some <em>very</em> smart people. 🕴️<br>
     <br>
     You’re probably also involved in crypto, tech, and/or the creative spheres.<br>
     <br>
     That or you’re my uncle, and even though he doesn’t understand “all that bitcoin stuff,” <em>he’s still your uncle and he wants to know what you’re up to, young man!</em></p>

                             </td>
                         </tr>
                     </tbody></table>
                     <!--[if mso]>
                     </td>
                     <![endif]-->

                     <!--[if mso]>
                     </tr>
                     </table>
                     <![endif]-->
                 </td>
             </tr>
         </tbody>
     </table><table border="0" cellpadding="0" cellspacing="0" width="100%" class="mcnTextBlock" style="min-width: 100%;border-collapse: collapse;mso-table-lspace: 0pt;mso-table-rspace: 0pt;-ms-text-size-adjust: 100%;-webkit-text-size-adjust: 100%;">
         <tbody class="mcnTextBlockOuter">
             <tr>
                 <td valign="top" class="mcnTextBlockInner" style="padding-top: 9px;mso-line-height-rule: exactly;-ms-text-size-adjust: 100%;-webkit-text-size-adjust: 100%;">
                     <!--[if mso]>
                     <table align="left" border="0" cellspacing="0" cellpadding="0" width="100%" style="width:100%;">
                     <tr>
                     <![endif]-->

                     <!--[if mso]>
                     <td valign="top" width="600" style="width:600px;">
                     <![endif]-->
                     <table align="left" border="0" cellpadding="0" cellspacing="0" style="max-width: 100%;min-width: 100%;border-collapse: collapse;mso-table-lspace: 0pt;mso-table-rspace: 0pt;-ms-text-size-adjust: 100%;-webkit-text-size-adjust: 100%;" width="100%" class="mcnTextContentContainer">
                         <tbody><tr>

                             <td valign="top" class="mcnTextContent" style="padding: 0px 18px 9px;font-family: "Noticia Text", Georgia, "Times New Roman", serif;line-height: 125%;mso-line-height-rule: exactly;-ms-text-size-adjust: 100%;-webkit-text-size-adjust: 100%;word-break: break-word;color: #202020;font-size: 16px;text-align: left;">

                                 <p dir="ltr" style="text-align: center;font-family: "Noticia Text", Georgia, "Times New Roman", serif;line-height: 125%;margin: 10px 0;padding: 0;mso-line-height-rule: exactly;-ms-text-size-adjust: 100%;-webkit-text-size-adjust: 100%;color: #202020;font-size: 16px;"><em><img data-file-id="4326521" height="48" src="https://gallery.mailchimp.com/adee9c5618cae148bdcf8f762/images/166bf3ec-1f68-4064-aa76-5c39a4f33c8f.gif" style="border: 0px;width: 48px;height: 48px;margin: 0px;outline: none;text-decoration: none;-ms-interpolation-mode: bicubic;" width="48"><img data-file-id="4326517" height="48" src="https://gallery.mailchimp.com/adee9c5618cae148bdcf8f762/images/98c6e2b1-a7da-4b2a-8718-799bda1c4658.gif" style="border: 0px;width: 48px;height: 48px;margin: 0px 24px;outline: none;text-decoration: none;-ms-interpolation-mode: bicubic;" width="48"><img data-file-id="4326513" height="48" src="https://gallery.mailchimp.com/adee9c5618cae148bdcf8f762/images/e3f19135-a9e2-4e58-9bd2-1986130c5adb.gif" style="border: 0px;width: 48px;height: 48px;margin: 0px;outline: none;text-decoration: none;-ms-interpolation-mode: bicubic;" width="48"></em></p>

                             </td>
                         </tr>
                     </tbody></table>
                     <!--[if mso]>
                     </td>
                     <![endif]-->

                     <!--[if mso]>
                     </tr>
                     </table>
                     <![endif]-->
                 </td>
             </tr>
         </tbody>
     </table><table border="0" cellpadding="0" cellspacing="0" width="100%" class="mcnTextBlock" style="min-width: 100%;border-collapse: collapse;mso-table-lspace: 0pt;mso-table-rspace: 0pt;-ms-text-size-adjust: 100%;-webkit-text-size-adjust: 100%;">
         <tbody class="mcnTextBlockOuter">
             <tr>
                 <td valign="top" class="mcnTextBlockInner" style="padding-top: 9px;mso-line-height-rule: exactly;-ms-text-size-adjust: 100%;-webkit-text-size-adjust: 100%;">
                     <!--[if mso]>
                     <table align="left" border="0" cellspacing="0" cellpadding="0" width="100%" style="width:100%;">
                     <tr>
                     <![endif]-->

                     <!--[if mso]>
                     <td valign="top" width="600" style="width:600px;">
                     <![endif]-->
                     <table align="left" border="0" cellpadding="0" cellspacing="0" style="max-width: 100%;min-width: 100%;border-collapse: collapse;mso-table-lspace: 0pt;mso-table-rspace: 0pt;-ms-text-size-adjust: 100%;-webkit-text-size-adjust: 100%;" width="100%" class="mcnTextContentContainer">
                         <tbody><tr>

                             <td valign="top" class="mcnTextContent" style="padding: 0px 18px 9px;font-family: "Noticia Text", Georgia, "Times New Roman", serif;line-height: 150%;mso-line-height-rule: exactly;-ms-text-size-adjust: 100%;-webkit-text-size-adjust: 100%;word-break: break-word;color: #202020;font-size: 16px;text-align: left;">

                                 <p dir="ltr" style="font-family: "Noticia Text", Georgia, "Times New Roman", serif;line-height: 150%;margin: 10px 0;padding: 0;mso-line-height-rule: exactly;-ms-text-size-adjust: 100%;-webkit-text-size-adjust: 100%;color: #202020;font-size: 16px;text-align: left;">This will be a weekly newsletter that we intend for your delight.<br>
      </p>

     <p dir="ltr" style="text-align: left;font-family: "Noticia Text", Georgia, "Times New Roman", serif;line-height: 150%;margin: 10px 0;padding: 0;mso-line-height-rule: exactly;-ms-text-size-adjust: 100%;-webkit-text-size-adjust: 100%;color: #202020;font-size: 16px;"><em>If you do NOT want to receive this again, <a href="*|UNSUB|*" style="mso-line-height-rule: exactly;-ms-text-size-adjust: 100%;-webkit-text-size-adjust: 100%;color: #42702f;font-weight: normal;text-decoration: underline;">click here to unsubscribe</a>.</em></p>

                             </td>
                         </tr>
                     </tbody></table>
                     <!--[if mso]>
                     </td>
                     <![endif]-->

                     <!--[if mso]>
                     </tr>
                     </table>
                     <![endif]-->
                 </td>
             </tr>
         </tbody>
     </table><table border="0" cellpadding="0" cellspacing="0" width="100%" class="mcnDividerBlock" style="min-width: 100%;border-collapse: collapse;mso-table-lspace: 0pt;mso-table-rspace: 0pt;-ms-text-size-adjust: 100%;-webkit-text-size-adjust: 100%;table-layout: fixed !important;">
         <tbody class="mcnDividerBlockOuter">
             <tr>
                 <td class="mcnDividerBlockInner" style="min-width: 100%;padding: 24px 18px;mso-line-height-rule: exactly;-ms-text-size-adjust: 100%;-webkit-text-size-adjust: 100%;">
                     <table class="mcnDividerContent" border="0" cellpadding="0" cellspacing="0" width="100%" style="min-width: 100%;border-top: 2px solid #EAEAEA;border-collapse: collapse;mso-table-lspace: 0pt;mso-table-rspace: 0pt;-ms-text-size-adjust: 100%;-webkit-text-size-adjust: 100%;">
                         <tbody><tr>
                             <td style="mso-line-height-rule: exactly;-ms-text-size-adjust: 100%;-webkit-text-size-adjust: 100%;">
                                 <span></span>
                             </td>
                         </tr>
                     </tbody></table>
     <!--
                     <td class="mcnDividerBlockInner" style="padding: 18px;">
                     <hr class="mcnDividerContent" style="border-bottom-color:none; border-left-color:none; border-right-color:none; border-bottom-width:0; border-left-width:0; border-right-width:0; margin-top:0; margin-right:0; margin-bottom:0; margin-left:0;" />
     -->
                 </td>
             </tr>
         </tbody>
     </table><table border="0" cellpadding="0" cellspacing="0" width="100%" class="mcnTextBlock" style="min-width: 100%;border-collapse: collapse;mso-table-lspace: 0pt;mso-table-rspace: 0pt;-ms-text-size-adjust: 100%;-webkit-text-size-adjust: 100%;">
         <tbody class="mcnTextBlockOuter">
             <tr>
                 <td valign="top" class="mcnTextBlockInner" style="padding-top: 9px;mso-line-height-rule: exactly;-ms-text-size-adjust: 100%;-webkit-text-size-adjust: 100%;">
                     <!--[if mso]>
                     <table align="left" border="0" cellspacing="0" cellpadding="0" width="100%" style="width:100%;">
                     <tr>
                     <![endif]-->

                     <!--[if mso]>
                     <td valign="top" width="600" style="width:600px;">
                     <![endif]-->
                     <table align="left" border="0" cellpadding="0" cellspacing="0" style="max-width: 100%;min-width: 100%;border-collapse: collapse;mso-table-lspace: 0pt;mso-table-rspace: 0pt;-ms-text-size-adjust: 100%;-webkit-text-size-adjust: 100%;" width="100%" class="mcnTextContentContainer">
                         <tbody><tr>

                             <td valign="top" class="mcnTextContent" style="padding-top: 0;padding-right: 18px;padding-bottom: 9px;padding-left: 18px;mso-line-height-rule: exactly;-ms-text-size-adjust: 100%;-webkit-text-size-adjust: 100%;word-break: break-word;color: #202020;font-family: 'Noticia Text', Georgia, 'Times New Roman', serif;font-size: 16px;line-height: 150%;text-align: left;">

                                 <span style="font-size:18px"><span style="font-family:noticia text,georgia,times new roman,serif"><strong>Sure, but he made most of his money in fiat...</strong></span></span>
                             </td>
                         </tr>
                     </tbody></table>
                     <!--[if mso]>
                     </td>
                     <![endif]-->

                     <!--[if mso]>
                     </tr>
                     </table>
                     <![endif]-->
                 </td>
             </tr>
         </tbody>
     </table><table border="0" cellpadding="0" cellspacing="0" width="100%" class="mcnImageCardBlock" style="border-collapse: collapse;mso-table-lspace: 0pt;mso-table-rspace: 0pt;-ms-text-size-adjust: 100%;-webkit-text-size-adjust: 100%;">
         <tbody class="mcnImageCardBlockOuter">
             <tr>
                 <td class="mcnImageCardBlockInner" valign="top" style="padding-top: 9px;padding-right: 18px;padding-bottom: 9px;padding-left: 18px;mso-line-height-rule: exactly;-ms-text-size-adjust: 100%;-webkit-text-size-adjust: 100%;">

     <table align="right" border="0" cellpadding="0" cellspacing="0" class="mcnImageCardBottomContent" width="100%" style="background-color: #FFFFFF;border-collapse: collapse;mso-table-lspace: 0pt;mso-table-rspace: 0pt;-ms-text-size-adjust: 100%;-webkit-text-size-adjust: 100%;">
         <tbody><tr>
             <td class="mcnImageCardBottomImageContent" align="center" valign="top" style="padding-top: 0px;padding-right: 0px;padding-bottom: 0;padding-left: 0px;mso-line-height-rule: exactly;-ms-text-size-adjust: 100%;-webkit-text-size-adjust: 100%;">



                 <img alt="" src="https://gallery.mailchimp.com/adee9c5618cae148bdcf8f762/images/7b4757cf-86ef-4146-b359-f3dfdef065f1.jpeg" width="325" style="max-width: 325px;border: 0;height: auto;outline: none;text-decoration: none;-ms-interpolation-mode: bicubic;vertical-align: bottom;" class="mcnImage">


             </td>
         </tr>
         <tr>
             <td class="mcnTextContent" valign="top" style="padding: 9px 18px;color: #747474;font-family: "Noticia Text", Georgia, "Times New Roman", serif;font-size: 14px;font-style: normal;font-weight: normal;text-align: center;mso-line-height-rule: exactly;-ms-text-size-adjust: 100%;-webkit-text-size-adjust: 100%;word-break: break-word;line-height: 150%;" width="546">
                 <p dir="ltr" style="color: #747474;font-family: "Noticia Text", Georgia, "Times New Roman", serif;font-size: 14px;font-style: normal;font-weight: normal;text-align: center;margin: 10px 0;padding: 0;mso-line-height-rule: exactly;-ms-text-size-adjust: 100%;-webkit-text-size-adjust: 100%;line-height: 150%;">alt text: “If he was worth his weight in BTC, he’d be… undefined?”</p>

             </td>
         </tr>
     </tbody></table>




                 </td>
             </tr>
         </tbody>
     </table><table border="0" cellpadding="0" cellspacing="0" width="100%" class="mcnTextBlock" style="min-width: 100%;border-collapse: collapse;mso-table-lspace: 0pt;mso-table-rspace: 0pt;-ms-text-size-adjust: 100%;-webkit-text-size-adjust: 100%;">
         <tbody class="mcnTextBlockOuter">
             <tr>
                 <td valign="top" class="mcnTextBlockInner" style="padding-top: 9px;mso-line-height-rule: exactly;-ms-text-size-adjust: 100%;-webkit-text-size-adjust: 100%;">
                     <!--[if mso]>
                     <table align="left" border="0" cellspacing="0" cellpadding="0" width="100%" style="width:100%;">
                     <tr>
                     <![endif]-->

                     <!--[if mso]>
                     <td valign="top" width="600" style="width:600px;">
                     <![endif]-->
                     <table align="left" border="0" cellpadding="0" cellspacing="0" style="max-width: 100%;min-width: 100%;border-collapse: collapse;mso-table-lspace: 0pt;mso-table-rspace: 0pt;-ms-text-size-adjust: 100%;-webkit-text-size-adjust: 100%;" width="100%" class="mcnTextContentContainer">
                         <tbody><tr>

                             <td valign="top" class="mcnTextContent" style="padding: 0px 18px 9px;font-family: "Noticia Text", Georgia, "Times New Roman", serif;line-height: 200%;mso-line-height-rule: exactly;-ms-text-size-adjust: 100%;-webkit-text-size-adjust: 100%;word-break: break-word;color: #202020;font-size: 16px;text-align: left;">

                                 <p dir="ltr" style="font-family: "Noticia Text", Georgia, "Times New Roman", serif;line-height: 200%;margin: 10px 0;padding: 0;mso-line-height-rule: exactly;-ms-text-size-adjust: 100%;-webkit-text-size-adjust: 100%;color: #202020;font-size: 16px;text-align: left;">Fred Wilson, top New York City venture capitalist, remains bullish on crypto amid his other predictions of global and market tumult in 2019. Does that mean crypto winter is over? We're not sure, we don’t watch <em>Game of Thrones</em> (did Winter finally come?) so instead read Wilson’s take: </p>

     <div style="border-left:3px solid #e0e0e0; padding-left:24px; margin-top:28px;">
     <p dir="ltr" style="font-family: "Noticia Text", Georgia, "Times New Roman", serif;line-height: 200%;margin: 10px 0;padding: 0;mso-line-height-rule: exactly;-ms-text-size-adjust: 100%;-webkit-text-size-adjust: 100%;color: #202020;font-size: 16px;text-align: left;">“I think we are in the process of finding the bottom on the large, liquid, and lasting crypto-tokens. But I think that process could take much of 2019 to play out. I expect we will see some bullish runs, followed by selling pressures taking us back to retest the lows. I think this bottoming out process will end sometime in 2019 and we will slowly enter a new bullish phase in crypto.”</p>
     </div>

     <p dir="ltr" style="font-family: "Noticia Text", Georgia, "Times New Roman", serif;line-height: 200%;margin: 10px 0;padding: 0;mso-line-height-rule: exactly;-ms-text-size-adjust: 100%;-webkit-text-size-adjust: 100%;color: #202020;font-size: 16px;text-align: left;"><br>
     Read Fred’s full post which includes other economic indicators & charts and such: <a href="https://avc.com/2019/01/what-is-going-to-happen-in-2019/" style="mso-line-height-rule: exactly;-ms-text-size-adjust: 100%;-webkit-text-size-adjust: 100%;color: #42702f;font-weight: normal;text-decoration: underline;">https://avc.com/2019/01/what-is-going-to-happen-in-2019/</a></p>

                             </td>
                         </tr>
                     </tbody></table>
                     <!--[if mso]>
                     </td>
                     <![endif]-->

                     <!--[if mso]>
                     </tr>
                     </table>
                     <![endif]-->
                 </td>
             </tr>
         </tbody>
     </table><table border="0" cellpadding="0" cellspacing="0" width="100%" class="mcnDividerBlock" style="min-width: 100%;border-collapse: collapse;mso-table-lspace: 0pt;mso-table-rspace: 0pt;-ms-text-size-adjust: 100%;-webkit-text-size-adjust: 100%;table-layout: fixed !important;">
         <tbody class="mcnDividerBlockOuter">
             <tr>
                 <td class="mcnDividerBlockInner" style="min-width: 100%;padding: 18px 18px 24px;mso-line-height-rule: exactly;-ms-text-size-adjust: 100%;-webkit-text-size-adjust: 100%;">
                     <table class="mcnDividerContent" border="0" cellpadding="0" cellspacing="0" width="100%" style="min-width: 100%;border-top: 2px solid #EAEAEA;border-collapse: collapse;mso-table-lspace: 0pt;mso-table-rspace: 0pt;-ms-text-size-adjust: 100%;-webkit-text-size-adjust: 100%;">
                         <tbody><tr>
                             <td style="mso-line-height-rule: exactly;-ms-text-size-adjust: 100%;-webkit-text-size-adjust: 100%;">
                                 <span></span>
                             </td>
                         </tr>
                     </tbody></table>
     <!--
                     <td class="mcnDividerBlockInner" style="padding: 18px;">
                     <hr class="mcnDividerContent" style="border-bottom-color:none; border-left-color:none; border-right-color:none; border-bottom-width:0; border-left-width:0; border-right-width:0; margin-top:0; margin-right:0; margin-bottom:0; margin-left:0;" />
     -->
                 </td>
             </tr>
         </tbody>
     </table><table border="0" cellpadding="0" cellspacing="0" width="100%" class="mcnTextBlock" style="min-width: 100%;border-collapse: collapse;mso-table-lspace: 0pt;mso-table-rspace: 0pt;-ms-text-size-adjust: 100%;-webkit-text-size-adjust: 100%;">
         <tbody class="mcnTextBlockOuter">
             <tr>
                 <td valign="top" class="mcnTextBlockInner" style="padding-top: 9px;mso-line-height-rule: exactly;-ms-text-size-adjust: 100%;-webkit-text-size-adjust: 100%;">
                     <!--[if mso]>
                     <table align="left" border="0" cellspacing="0" cellpadding="0" width="100%" style="width:100%;">
                     <tr>
                     <![endif]-->

                     <!--[if mso]>
                     <td valign="top" width="600" style="width:600px;">
                     <![endif]-->
                     <table align="left" border="0" cellpadding="0" cellspacing="0" style="max-width: 100%;min-width: 100%;border-collapse: collapse;mso-table-lspace: 0pt;mso-table-rspace: 0pt;-ms-text-size-adjust: 100%;-webkit-text-size-adjust: 100%;" width="100%" class="mcnTextContentContainer">
                         <tbody><tr>

                             <td valign="top" class="mcnTextContent" style="padding: 0px 18px 9px;font-family: "Noticia Text", Georgia, "Times New Roman", serif;font-size: 16px;line-height: 200%;mso-line-height-rule: exactly;-ms-text-size-adjust: 100%;-webkit-text-size-adjust: 100%;word-break: break-word;color: #202020;text-align: left;">

                                 <div><span style="font-size:18px"><strong>Off-chain // On-record // 001</strong></span><br>
     <em>E    X    C    L    U    S    I    V    E<br>
     *** Every week we aim to bring you industry insights you won’t find anywhere else on the web. We are going directly to the source, starting with pieces like this one: ***</em></div>

                             </td>
                         </tr>
                     </tbody></table>
                     <!--[if mso]>
                     </td>
                     <![endif]-->

                     <!--[if mso]>
                     </tr>
                     </table>
                     <![endif]-->
                 </td>
             </tr>
         </tbody>
     </table><table border="0" cellpadding="0" cellspacing="0" width="100%" class="mcnTextBlock" style="min-width: 100%;border-collapse: collapse;mso-table-lspace: 0pt;mso-table-rspace: 0pt;-ms-text-size-adjust: 100%;-webkit-text-size-adjust: 100%;">
         <tbody class="mcnTextBlockOuter">
             <tr>
                 <td valign="top" class="mcnTextBlockInner" style="padding-top: 9px;mso-line-height-rule: exactly;-ms-text-size-adjust: 100%;-webkit-text-size-adjust: 100%;">
                     <!--[if mso]>
                     <table align="left" border="0" cellspacing="0" cellpadding="0" width="100%" style="width:100%;">
                     <tr>
                     <![endif]-->

                     <!--[if mso]>
                     <td valign="top" width="600" style="width:600px;">
                     <![endif]-->
                     <table align="left" border="0" cellpadding="0" cellspacing="0" style="max-width: 100%;min-width: 100%;border-collapse: collapse;mso-table-lspace: 0pt;mso-table-rspace: 0pt;-ms-text-size-adjust: 100%;-webkit-text-size-adjust: 100%;" width="100%" class="mcnTextContentContainer">
                         <tbody><tr>

                             <td valign="top" class="mcnTextContent" style="padding: 0px 18px 9px;font-family: "Noticia Text", Georgia, "Times New Roman", serif;font-size: 16px;line-height: 200%;text-align: left;mso-line-height-rule: exactly;-ms-text-size-adjust: 100%;-webkit-text-size-adjust: 100%;word-break: break-word;color: #202020;">

                                 <p dir="ltr" style="font-family: "Noticia Text", Georgia, "Times New Roman", serif;font-size: 16px;line-height: 200%;text-align: left;margin: 10px 0;padding: 0;mso-line-height-rule: exactly;-ms-text-size-adjust: 100%;-webkit-text-size-adjust: 100%;color: #202020;">We asked our friend <a href="https://www.linkedin.com/in/tbkanda/" target="_blank" style="mso-line-height-rule: exactly;-ms-text-size-adjust: 100%;-webkit-text-size-adjust: 100%;color: #42702f;font-weight: normal;text-decoration: underline;">Brian Kanda, Co-Founder of FloraChain</a>, how the crypto market downturn has impacted his business. His remarkable candor and insight impressed:</p>

     <div style="border-left:3px solid #e0e0e0; padding-left:24px; margin:18px 42px 18px 0px;">
     <p dir="ltr" style="font-family: "Noticia Text", Georgia, "Times New Roman", serif;font-size: 16px;line-height: 200%;text-align: left;margin: 10px 0;padding: 0;mso-line-height-rule: exactly;-ms-text-size-adjust: 100%;-webkit-text-size-adjust: 100%;color: #202020;">“FloraChain has been forced to be on hold as we re-evaluate the fundraise and long-term viability of a single project token, due to massive reduction in overall market cap of cryptocurrencies.</p>

     <p dir="ltr" style="font-family: "Noticia Text", Georgia, "Times New Roman", serif;font-size: 16px;line-height: 200%;text-align: left;margin: 10px 0;padding: 0;mso-line-height-rule: exactly;-ms-text-size-adjust: 100%;-webkit-text-size-adjust: 100%;color: #202020;">Not only has that driven institutional investors away from these type of investments, but it also drove a second huge problem which is the plethora of projects that raised some sort of funding in the last 9 months and did not list a token on an exchange due to the market slump.</p>
     <p dir="ltr" style="font-family: "Noticia Text", Georgia, "Times New Roman", serif;font-size: 16px;line-height: 200%;text-align: left;margin: 10px 0;padding: 0;mso-line-height-rule: exactly;-ms-text-size-adjust: 100%;-webkit-text-size-adjust: 100%;color: #202020;">These tokens are all under immense pressure from their investors to list, and as the crypto market recovers, there will be a huge amount of new tokens being listed - I estimate about 300 projects that will need to list.</p>

     <p dir="ltr" style="font-family: "Noticia Text", Georgia, "Times New Roman", serif;font-size: 16px;line-height: 200%;text-align: left;margin: 10px 0;padding: 0;mso-line-height-rule: exactly;-ms-text-size-adjust: 100%;-webkit-text-size-adjust: 100%;color: #202020;">This will create immense pressure for the liquidity available in the market and my guess is eventually lead to another bust in the alt coins category.”</p>
     </div>
     Wow, that’s huge - we think a shrewd maneuver - to pause to re-evaluate and strategize in the face of current market dynamics. Thanks for sharing with us, Brian. And thanks for the altcoin market hot take  What comes next for you then, my dude?

     <div style="border-left:3px solid #e0e0e0; padding-left:24px; margin:18px 42px 18px 0px;">
     <p dir="ltr" style="font-family: "Noticia Text", Georgia, "Times New Roman", serif;font-size: 16px;line-height: 200%;text-align: left;margin: 10px 0;padding: 0;mso-line-height-rule: exactly;-ms-text-size-adjust: 100%;-webkit-text-size-adjust: 100%;color: #202020;">My thinking now is to try to form a consortium for supply chain based projects be it you are in trade finance, trade insurance, logistics, track/track or projects focused on supply (like FloraChain) to come together and form a group of sorts. But we don’t have individual tokens but instead rely on the consortium token to have access to a variety of services.</p>

     <p dir="ltr" style="font-family: "Noticia Text", Georgia, "Times New Roman", serif;font-size: 16px;line-height: 200%;text-align: left;margin: 10px 0;padding: 0;mso-line-height-rule: exactly;-ms-text-size-adjust: 100%;-webkit-text-size-adjust: 100%;color: #202020;">This will put us firmly in the realm of being a true utility token and also reduce investor risk because you are not just buying into one project but actually it’s a global supply chain project with multiple parties. Kinda like an OPEC for blockchain supply chain.</p>
     </div>
     If Brian is starting a cartel, I want in. Find him on LinkedIn: <a href="https://www.linkedin.com/in/tbkanda/" target="_blank" style="mso-line-height-rule: exactly;-ms-text-size-adjust: 100%;-webkit-text-size-adjust: 100%;color: #42702f;font-weight: normal;text-decoration: underline;">https://www.linkedin.com/in/tbkanda/</a>
                             </td>
                         </tr>
                     </tbody></table>
                     <!--[if mso]>
                     </td>
                     <![endif]-->

                     <!--[if mso]>
                     </tr>
                     </table>
                     <![endif]-->
                 </td>
             </tr>
         </tbody>
     </table><table border="0" cellpadding="0" cellspacing="0" width="100%" class="mcnDividerBlock" style="min-width: 100%;border-collapse: collapse;mso-table-lspace: 0pt;mso-table-rspace: 0pt;-ms-text-size-adjust: 100%;-webkit-text-size-adjust: 100%;table-layout: fixed !important;">
         <tbody class="mcnDividerBlockOuter">
             <tr>
                 <td class="mcnDividerBlockInner" style="min-width: 100%;padding: 24px 18px;mso-line-height-rule: exactly;-ms-text-size-adjust: 100%;-webkit-text-size-adjust: 100%;">
                     <table class="mcnDividerContent" border="0" cellpadding="0" cellspacing="0" width="100%" style="min-width: 100%;border-top: 2px solid #EAEAEA;border-collapse: collapse;mso-table-lspace: 0pt;mso-table-rspace: 0pt;-ms-text-size-adjust: 100%;-webkit-text-size-adjust: 100%;">
                         <tbody><tr>
                             <td style="mso-line-height-rule: exactly;-ms-text-size-adjust: 100%;-webkit-text-size-adjust: 100%;">
                                 <span></span>
                             </td>
                         </tr>
                     </tbody></table>
     <!--
                     <td class="mcnDividerBlockInner" style="padding: 18px;">
                     <hr class="mcnDividerContent" style="border-bottom-color:none; border-left-color:none; border-right-color:none; border-bottom-width:0; border-left-width:0; border-right-width:0; margin-top:0; margin-right:0; margin-bottom:0; margin-left:0;" />
     -->
                 </td>
             </tr>
         </tbody>
     </table><table border="0" cellpadding="0" cellspacing="0" width="100%" class="mcnTextBlock" style="min-width: 100%;border-collapse: collapse;mso-table-lspace: 0pt;mso-table-rspace: 0pt;-ms-text-size-adjust: 100%;-webkit-text-size-adjust: 100%;">
         <tbody class="mcnTextBlockOuter">
             <tr>
                 <td valign="top" class="mcnTextBlockInner" style="padding-top: 9px;mso-line-height-rule: exactly;-ms-text-size-adjust: 100%;-webkit-text-size-adjust: 100%;">
                     <!--[if mso]>
                     <table align="left" border="0" cellspacing="0" cellpadding="0" width="100%" style="width:100%;">
                     <tr>
                     <![endif]-->

                     <!--[if mso]>
                     <td valign="top" width="600" style="width:600px;">
                     <![endif]-->
                     <table align="left" border="0" cellpadding="0" cellspacing="0" style="max-width: 100%;min-width: 100%;border-collapse: collapse;mso-table-lspace: 0pt;mso-table-rspace: 0pt;-ms-text-size-adjust: 100%;-webkit-text-size-adjust: 100%;" width="100%" class="mcnTextContentContainer">
                         <tbody><tr>

                             <td valign="top" class="mcnTextContent" style="padding-top: 0;padding-right: 18px;padding-bottom: 9px;padding-left: 18px;mso-line-height-rule: exactly;-ms-text-size-adjust: 100%;-webkit-text-size-adjust: 100%;word-break: break-word;color: #202020;font-family: 'Noticia Text', Georgia, 'Times New Roman', serif;font-size: 16px;line-height: 150%;text-align: left;">

                                 <span style="font-size:18px"><span style="font-family:noticia text,georgia,times new roman,serif"><strong>Pirate like it’s 1999</strong></span></span>
                             </td>
                         </tr>
                     </tbody></table>
                     <!--[if mso]>
                     </td>
                     <![endif]-->

                     <!--[if mso]>
                     </tr>
                     </table>
                     <![endif]-->
                 </td>
             </tr>
         </tbody>
     </table><table border="0" cellpadding="0" cellspacing="0" width="100%" class="mcnImageCardBlock" style="border-collapse: collapse;mso-table-lspace: 0pt;mso-table-rspace: 0pt;-ms-text-size-adjust: 100%;-webkit-text-size-adjust: 100%;">
         <tbody class="mcnImageCardBlockOuter">
             <tr>
                 <td class="mcnImageCardBlockInner" valign="top" style="padding-top: 9px;padding-right: 18px;padding-bottom: 9px;padding-left: 18px;mso-line-height-rule: exactly;-ms-text-size-adjust: 100%;-webkit-text-size-adjust: 100%;">

     <table align="right" border="0" cellpadding="0" cellspacing="0" class="mcnImageCardBottomContent" width="100%" style="background-color: #FFFFFF;border-collapse: collapse;mso-table-lspace: 0pt;mso-table-rspace: 0pt;-ms-text-size-adjust: 100%;-webkit-text-size-adjust: 100%;">
         <tbody><tr>
             <td class="mcnImageCardBottomImageContent" align="center" valign="top" style="padding-top: 18px;padding-right: 18px;padding-bottom: 0;padding-left: 18px;mso-line-height-rule: exactly;-ms-text-size-adjust: 100%;-webkit-text-size-adjust: 100%;">



                 <img alt="" src="https://gallery.mailchimp.com/adee9c5618cae148bdcf8f762/images/f084e419-cf1c-4540-ad0b-d4acca7186f0.jpeg" width="216" style="max-width: 216px;border: 0;height: auto;outline: none;text-decoration: none;-ms-interpolation-mode: bicubic;vertical-align: bottom;" class="mcnImage">


             </td>
         </tr>
         <tr>
             <td class="mcnTextContent" valign="top" style="padding: 9px 18px;color: #747474;font-family: "Noticia Text", Georgia, "Times New Roman", serif;font-size: 14px;font-style: normal;font-weight: normal;text-align: center;mso-line-height-rule: exactly;-ms-text-size-adjust: 100%;-webkit-text-size-adjust: 100%;word-break: break-word;line-height: 150%;" width="528">
                 <p dir="ltr" style="color: #747474;font-family: "Noticia Text", Georgia, "Times New Roman", serif;font-size: 14px;font-style: normal;font-weight: normal;text-align: center;margin: 10px 0;padding: 0;mso-line-height-rule: exactly;-ms-text-size-adjust: 100%;-webkit-text-size-adjust: 100%;line-height: 150%;">alt text: “There it is! The Island of Kazaa!”</p>

             </td>
         </tr>
     </tbody></table>




                 </td>
             </tr>
         </tbody>
     </table><table border="0" cellpadding="0" cellspacing="0" width="100%" class="mcnTextBlock" style="min-width: 100%;border-collapse: collapse;mso-table-lspace: 0pt;mso-table-rspace: 0pt;-ms-text-size-adjust: 100%;-webkit-text-size-adjust: 100%;">
         <tbody class="mcnTextBlockOuter">
             <tr>
                 <td valign="top" class="mcnTextBlockInner" style="padding-top: 9px;mso-line-height-rule: exactly;-ms-text-size-adjust: 100%;-webkit-text-size-adjust: 100%;">
                     <!--[if mso]>
                     <table align="left" border="0" cellspacing="0" cellpadding="0" width="100%" style="width:100%;">
                     <tr>
                     <![endif]-->

                     <!--[if mso]>
                     <td valign="top" width="600" style="width:600px;">
                     <![endif]-->
                     <table align="left" border="0" cellpadding="0" cellspacing="0" style="max-width: 100%;min-width: 100%;border-collapse: collapse;mso-table-lspace: 0pt;mso-table-rspace: 0pt;-ms-text-size-adjust: 100%;-webkit-text-size-adjust: 100%;" width="100%" class="mcnTextContentContainer">
                         <tbody><tr>

                             <td valign="top" class="mcnTextContent" style="padding: 0px 18px 9px;font-family: "Noticia Text", Georgia, "Times New Roman", serif;line-height: 200%;mso-line-height-rule: exactly;-ms-text-size-adjust: 100%;-webkit-text-size-adjust: 100%;word-break: break-word;color: #202020;font-size: 16px;text-align: left;">

                                 <p dir="ltr" style="font-family: "Noticia Text", Georgia, "Times New Roman", serif;line-height: 200%;margin: 10px 0;padding: 0;mso-line-height-rule: exactly;-ms-text-size-adjust: 100%;-webkit-text-size-adjust: 100%;color: #202020;font-size: 16px;text-align: left;">We haven’t watched <em>Bandersnatch</em> yet - DON’T YOU RUIN IT FOR US - but we’re assuming the title translates to ‘<em>surprisingly-difficult to snatch</em>.’ The Netflix choose-your-own-adventure style film under the <em>Black Mirror</em> banner is proving quite the headache for pirates to disseminate through common torrenting sites like The Pirate Bay, given the innovative production’s myriad storytelling paths.</p>

     <p dir="ltr" style="font-family: "Noticia Text", Georgia, "Times New Roman", serif;line-height: 200%;margin: 10px 0;padding: 0;mso-line-height-rule: exactly;-ms-text-size-adjust: 100%;-webkit-text-size-adjust: 100%;color: #202020;font-size: 16px;text-align: left;">@ us if we’re missing something, but people are still torrenting? Join the 21st century and beg to get on your parents’ Netflix plan, brah. Or pony up and carry <em>them</em> for a change, you’re still on their Verizon plan, after all.</p>

     <p dir="ltr" style="font-family: "Noticia Text", Georgia, "Times New Roman", serif;line-height: 200%;margin: 10px 0;padding: 0;mso-line-height-rule: exactly;-ms-text-size-adjust: 100%;-webkit-text-size-adjust: 100%;color: #202020;font-size: 16px;text-align: left;">Read a short bit more on HYPEBEAAASSSTTTT!!!!</p>

     <p dir="ltr" style="font-family: "Noticia Text", Georgia, "Times New Roman", serif;line-height: 200%;margin: 10px 0;padding: 0;mso-line-height-rule: exactly;-ms-text-size-adjust: 100%;-webkit-text-size-adjust: 100%;color: #202020;font-size: 16px;text-align: left;"><a href="https://hypebeast.com/2019/1/black-mirror-bandersnatch-piracy" target="_blank" style="mso-line-height-rule: exactly;-ms-text-size-adjust: 100%;-webkit-text-size-adjust: 100%;color: #42702f;font-weight: normal;text-decoration: underline;">https://hypebeast.com/2019/1/black-mirror-bandersnatch-piracy</a></p>

                             </td>
                         </tr>
                     </tbody></table>
                     <!--[if mso]>
                     </td>
                     <![endif]-->

                     <!--[if mso]>
                     </tr>
                     </table>
                     <![endif]-->
                 </td>
             </tr>
         </tbody>
     </table><table border="0" cellpadding="0" cellspacing="0" width="100%" class="mcnDividerBlock" style="min-width: 100%;border-collapse: collapse;mso-table-lspace: 0pt;mso-table-rspace: 0pt;-ms-text-size-adjust: 100%;-webkit-text-size-adjust: 100%;table-layout: fixed !important;">
         <tbody class="mcnDividerBlockOuter">
             <tr>
                 <td class="mcnDividerBlockInner" style="min-width: 100%;padding: 18px;mso-line-height-rule: exactly;-ms-text-size-adjust: 100%;-webkit-text-size-adjust: 100%;">
                     <table class="mcnDividerContent" border="0" cellpadding="0" cellspacing="0" width="100%" style="min-width: 100%;border-top: 2px solid #EAEAEA;border-collapse: collapse;mso-table-lspace: 0pt;mso-table-rspace: 0pt;-ms-text-size-adjust: 100%;-webkit-text-size-adjust: 100%;">
                         <tbody><tr>
                             <td style="mso-line-height-rule: exactly;-ms-text-size-adjust: 100%;-webkit-text-size-adjust: 100%;">
                                 <span></span>
                             </td>
                         </tr>
                     </tbody></table>
     <!--
                     <td class="mcnDividerBlockInner" style="padding: 18px;">
                     <hr class="mcnDividerContent" style="border-bottom-color:none; border-left-color:none; border-right-color:none; border-bottom-width:0; border-left-width:0; border-right-width:0; margin-top:0; margin-right:0; margin-bottom:0; margin-left:0;" />
     -->
                 </td>
             </tr>
         </tbody>
     </table><table border="0" cellpadding="0" cellspacing="0" width="100%" class="mcnTextBlock" style="min-width: 100%;border-collapse: collapse;mso-table-lspace: 0pt;mso-table-rspace: 0pt;-ms-text-size-adjust: 100%;-webkit-text-size-adjust: 100%;">
         <tbody class="mcnTextBlockOuter">
             <tr>
                 <td valign="top" class="mcnTextBlockInner" style="padding-top: 9px;mso-line-height-rule: exactly;-ms-text-size-adjust: 100%;-webkit-text-size-adjust: 100%;">
                     <!--[if mso]>
                     <table align="left" border="0" cellspacing="0" cellpadding="0" width="100%" style="width:100%;">
                     <tr>
                     <![endif]-->

                     <!--[if mso]>
                     <td valign="top" width="600" style="width:600px;">
                     <![endif]-->
                     <table align="left" border="0" cellpadding="0" cellspacing="0" style="max-width: 100%;min-width: 100%;border-collapse: collapse;mso-table-lspace: 0pt;mso-table-rspace: 0pt;-ms-text-size-adjust: 100%;-webkit-text-size-adjust: 100%;" width="100%" class="mcnTextContentContainer">
                         <tbody><tr>

                             <td valign="top" class="mcnTextContent" style="padding-top: 0;padding-right: 18px;padding-bottom: 9px;padding-left: 18px;mso-line-height-rule: exactly;-ms-text-size-adjust: 100%;-webkit-text-size-adjust: 100%;word-break: break-word;color: #202020;font-family: 'Noticia Text', Georgia, 'Times New Roman', serif;font-size: 16px;line-height: 150%;text-align: left;">

                                 <span style="font-size:18px"><span style="font-family:noticia text,georgia,times new roman,serif"><strong>Airdrops! </strong></span></span><strong id="docs-internal-guid-977df075-7fff-9eb1-e6e5-518295343eba"></strong>
                             </td>
                         </tr>
                     </tbody></table>
                     <!--[if mso]>
                     </td>
                     <![endif]-->

                     <!--[if mso]>
                     </tr>
                     </table>
                     <![endif]-->
                 </td>
             </tr>
         </tbody>
     </table><table border="0" cellpadding="0" cellspacing="0" width="100%" class="mcnTextBlock" style="min-width: 100%;border-collapse: collapse;mso-table-lspace: 0pt;mso-table-rspace: 0pt;-ms-text-size-adjust: 100%;-webkit-text-size-adjust: 100%;">
         <tbody class="mcnTextBlockOuter">
             <tr>
                 <td valign="top" class="mcnTextBlockInner" style="padding-top: 9px;mso-line-height-rule: exactly;-ms-text-size-adjust: 100%;-webkit-text-size-adjust: 100%;">
                     <!--[if mso]>
                     <table align="left" border="0" cellspacing="0" cellpadding="0" width="100%" style="width:100%;">
                     <tr>
                     <![endif]-->

                     <!--[if mso]>
                     <td valign="top" width="600" style="width:600px;">
                     <![endif]-->
                     <table align="left" border="0" cellpadding="0" cellspacing="0" style="max-width: 100%;min-width: 100%;border-collapse: collapse;mso-table-lspace: 0pt;mso-table-rspace: 0pt;-ms-text-size-adjust: 100%;-webkit-text-size-adjust: 100%;" width="100%" class="mcnTextContentContainer">
                         <tbody><tr>

                             <td valign="top" class="mcnTextContent" style="padding: 0px 18px 9px;font-family: "Noticia Text", Georgia, "Times New Roman", serif;line-height: 200%;mso-line-height-rule: exactly;-ms-text-size-adjust: 100%;-webkit-text-size-adjust: 100%;word-break: break-word;color: #202020;font-size: 16px;text-align: left;">

                                 <p dir="ltr" style="font-family: "Noticia Text", Georgia, "Times New Roman", serif;line-height: 200%;margin: 10px 0;padding: 0;mso-line-height-rule: exactly;-ms-text-size-adjust: 100%;-webkit-text-size-adjust: 100%;color: #202020;font-size: 16px;text-align: left;"><strong>🤑</strong> Can’t afford that Lambo you’ve had your eyes on? Now you can buy a tokenized fraction of Tesla stock instead! Coming to the US later this year, read more at The Block: <a href="https://www.theblockcrypto.com/tiny/nasdaq-powered-european-crypto-exchange-to-launch-next-week-with-tokenized-stock-offering/" target="_blank" style="mso-line-height-rule: exactly;-ms-text-size-adjust: 100%;-webkit-text-size-adjust: 100%;color: #42702f;font-weight: normal;text-decoration: underline;">https://www.theblockcrypto.com/tiny/nasdaq-powered-european-crypto-exchange-to-launch-next-week-with-tokenized-stock-offering/</a><br>
     <br>
      Power to the People! The Ethereum platform is aiming to reduce its energy consumption by 99 percent. Read more on ieee.org: <a href="https://spectrum.ieee.org/computing/networks/ethereum-plans-to-cut-its-absurd-energy-consumption-by-99-percent" target="_blank" style="mso-line-height-rule: exactly;-ms-text-size-adjust: 100%;-webkit-text-size-adjust: 100%;color: #42702f;font-weight: normal;text-decoration: underline;">https://spectrum.ieee.org/computing/networks/ethereum-plans-to-cut-its-absurd-energy-consumption-by-99-percent</a><br>
     <br>
      Super cool generative art that we dig: <a href="http://www.thepluspaper.com/2018/12/16/creating-codes-for-being-creative/" target="_blank" style="mso-line-height-rule: exactly;-ms-text-size-adjust: 100%;-webkit-text-size-adjust: 100%;color: #42702f;font-weight: normal;text-decoration: underline;">http://www.thepluspaper.com/2018/12/16/creating-codes-for-being-creative/</a></p>

                             </td>
                         </tr>
                     </tbody></table>
                     <!--[if mso]>
                     </td>
                     <![endif]-->

                     <!--[if mso]>
                     </tr>
                     </table>
                     <![endif]-->
                 </td>
             </tr>
         </tbody>
     </table><table border="0" cellpadding="0" cellspacing="0" width="100%" class="mcnDividerBlock" style="min-width: 100%;border-collapse: collapse;mso-table-lspace: 0pt;mso-table-rspace: 0pt;-ms-text-size-adjust: 100%;-webkit-text-size-adjust: 100%;table-layout: fixed !important;">
         <tbody class="mcnDividerBlockOuter">
             <tr>
                 <td class="mcnDividerBlockInner" style="min-width: 100%;padding: 18px;mso-line-height-rule: exactly;-ms-text-size-adjust: 100%;-webkit-text-size-adjust: 100%;">
                     <table class="mcnDividerContent" border="0" cellpadding="0" cellspacing="0" width="100%" style="min-width: 100%;border-top: 2px solid #EAEAEA;border-collapse: collapse;mso-table-lspace: 0pt;mso-table-rspace: 0pt;-ms-text-size-adjust: 100%;-webkit-text-size-adjust: 100%;">
                         <tbody><tr>
                             <td style="mso-line-height-rule: exactly;-ms-text-size-adjust: 100%;-webkit-text-size-adjust: 100%;">
                                 <span></span>
                             </td>
                         </tr>
                     </tbody></table>
     <!--
                     <td class="mcnDividerBlockInner" style="padding: 18px;">
                     <hr class="mcnDividerContent" style="border-bottom-color:none; border-left-color:none; border-right-color:none; border-bottom-width:0; border-left-width:0; border-right-width:0; margin-top:0; margin-right:0; margin-bottom:0; margin-left:0;" />
     -->
                 </td>
             </tr>
         </tbody>
     </table><table border="0" cellpadding="0" cellspacing="0" width="100%" class="mcnTextBlock" style="min-width: 100%;border-collapse: collapse;mso-table-lspace: 0pt;mso-table-rspace: 0pt;-ms-text-size-adjust: 100%;-webkit-text-size-adjust: 100%;">
         <tbody class="mcnTextBlockOuter">
             <tr>
                 <td valign="top" class="mcnTextBlockInner" style="padding-top: 9px;mso-line-height-rule: exactly;-ms-text-size-adjust: 100%;-webkit-text-size-adjust: 100%;">
                     <!--[if mso]>
                     <table align="left" border="0" cellspacing="0" cellpadding="0" width="100%" style="width:100%;">
                     <tr>
                     <![endif]-->

                     <!--[if mso]>
                     <td valign="top" width="600" style="width:600px;">
                     <![endif]-->
                     <table align="left" border="0" cellpadding="0" cellspacing="0" style="max-width: 100%;min-width: 100%;border-collapse: collapse;mso-table-lspace: 0pt;mso-table-rspace: 0pt;-ms-text-size-adjust: 100%;-webkit-text-size-adjust: 100%;" width="100%" class="mcnTextContentContainer">
                         <tbody><tr>

                             <td valign="top" class="mcnTextContent" style="padding-top: 0;padding-right: 18px;padding-bottom: 9px;padding-left: 18px;mso-line-height-rule: exactly;-ms-text-size-adjust: 100%;-webkit-text-size-adjust: 100%;word-break: break-word;color: #202020;font-family: 'Noticia Text', Georgia, 'Times New Roman', serif;font-size: 16px;line-height: 150%;text-align: left;">

                                 <div style="text-align: center;"><strong><span style="font-size:24px">NEXT WEEK WE ARE GIVING AWAY BITCOIN</span></strong><br>
     <br>
     Tell your friends to subscribe if they want to be eligible.<br>
     Plz forward: <a href="http://eepurl.com/gdbiTD" target="_blank" style="mso-line-height-rule: exactly;-ms-text-size-adjust: 100%;-webkit-text-size-adjust: 100%;color: #42702f;font-weight: normal;text-decoration: underline;">http://eepurl.com/gdbiTD</a><br>
     <br>
     <img data-file-id="4326537" height="240" src="https://gallery.mailchimp.com/adee9c5618cae148bdcf8f762/images/43cc4e4a-fecd-408d-8651-4e0563a50e3f.gif" style="border: 0px;width: 320px;height: 240px;margin: 0px;outline: none;text-decoration: none;-ms-interpolation-mode: bicubic;" width="320"></div>

     <div style="text-align: center;"><br>
     Tips and leads for next week's edition? Email them to us at <a href="mailto:hi@satoshiscloset.com?subject=Newsletter%20tips%20and%20leads" target="_blank" style="mso-line-height-rule: exactly;-ms-text-size-adjust: 100%;-webkit-text-size-adjust: 100%;color: #42702f;font-weight: normal;text-decoration: underline;">hi@satoshiscloset.com</a>.</div>

                             </td>
                         </tr>
                     </tbody></table>
                     <!--[if mso]>
                     </td>
                     <![endif]-->

                     <!--[if mso]>
                     </tr>
                     </table>
                     <![endif]-->
                 </td>
             </tr>
         </tbody>
     </table></td>
                 </table>
             </center>
         </body>
     </html>`
 const POST2 = `<!doctype html>
 <html xmlns="http://www.w3.org/1999/xhtml" xmlns:v="urn:schemas-microsoft-com:vml" xmlns:o="urn:schemas-microsoft-com:office:office">
     <head>
         <!-- NAME: 1 COLUMN -->
         <!--[if gte mso 15]>
         <xml>
             <o:OfficeDocumentSettings>
             <o:AllowPNG/>
             <o:PixelsPerInch>96</o:PixelsPerInch>
             </o:OfficeDocumentSettings>
         </xml>
         <![endif]-->
         <meta charset="UTF-8">
         <meta http-equiv="X-UA-Compatible" content="IE=edge">
         <meta name="viewport" content="width=device-width, initial-scale=1">
         <title>*|MC:SUBJECT|*</title>

     <style type="text/css">
         p{
             margin:10px 0;
             padding:0;
         }
         table{
             border-collapse:collapse;
         }
         h1,h2,h3,h4,h5,h6{
             display:block;
             margin:0;
             padding:0;
         }
         img,a img{
             border:0;
             height:auto;
             outline:none;
             text-decoration:none;
         }
         body,#bodyTable,#bodyCell{
             height:100%;
             margin:0;
             padding:0;
             width:100%;
         }
         .mcnPreviewText{
             display:none !important;
         }
         #outlook a{
             padding:0;
         }
         img{
             -ms-interpolation-mode:bicubic;
         }
         table{
             mso-table-lspace:0pt;
             mso-table-rspace:0pt;
         }
         .ReadMsgBody{
             width:100%;
         }
         .ExternalClass{
             width:100%;
         }
         p,a,li,td,blockquote{
             mso-line-height-rule:exactly;
         }
         a[href^=tel],a[href^=sms]{
             color:inherit;
             cursor:default;
             text-decoration:none;
         }
         p,a,li,td,body,table,blockquote{
             -ms-text-size-adjust:100%;
             -webkit-text-size-adjust:100%;
         }
         .ExternalClass,.ExternalClass p,.ExternalClass td,.ExternalClass div,.ExternalClass span,.ExternalClass font{
             line-height:100%;
         }
         a[x-apple-data-detectors]{
             color:inherit !important;
             text-decoration:none !important;
             font-size:inherit !important;
             font-family:inherit !important;
             font-weight:inherit !important;
             line-height:inherit !important;
         }
         #bodyCell{
             padding:10px;
         }
         .templateContainer{
             max-width:600px !important;
         }
         a.mcnButton{
             display:block;
         }
         .mcnImage,.mcnRetinaImage{
             vertical-align:bottom;
         }
         .mcnTextContent{
             word-break:break-word;
         }
         .mcnTextContent img{
             height:auto !important;
         }
         .mcnDividerBlock{
             table-layout:fixed !important;
         }
         body,#bodyTable{
             background-color:#ffffff;
         }
         #bodyCell{
             border-top:0;
         }
         .templateContainer{
             border:0;
         }
         h1{
             color:#202020;
             font-family:Helvetica;
             font-size:26px;
             font-style:normal;
             font-weight:bold;
             line-height:125%;
             letter-spacing:normal;
             text-align:left;
         }
         h2{
             color:#202020;
             font-family:Helvetica;
             font-size:22px;
             font-style:normal;
             font-weight:bold;
             line-height:125%;
             letter-spacing:normal;
             text-align:left;
         }
         h3{
             color:#202020;
             font-family:Helvetica;
             font-size:20px;
             font-style:normal;
             font-weight:bold;
             line-height:125%;
             letter-spacing:normal;
             text-align:left;
         }
         h4{
             color:#202020;
             font-family:Helvetica;
             font-size:18px;
             font-style:normal;
             font-weight:bold;
             line-height:125%;
             letter-spacing:normal;
             text-align:left;
         }
         #templatePreheader{
             background-color:#FAFAFA;
             background-image:none;
             background-repeat:no-repeat;
             background-position:center;
             background-size:cover;
             border-top:0;
             border-bottom:0;
             padding-top:9px;
             padding-bottom:9px;
         }
         #templatePreheader .mcnTextContent,#templatePreheader .mcnTextContent p{
             color:#656565;
             font-family:Helvetica;
             font-size:12px;
             line-height:150%;
             text-align:left;
         }
         #templatePreheader .mcnTextContent a,#templatePreheader .mcnTextContent p a{
             color:#656565;
             font-weight:normal;
             text-decoration:underline;
         }
         #templateHeader{
             background-color:#ffffff;
             background-image:none;
             background-repeat:no-repeat;
             background-position:center;
             background-size:cover;
             border-top:0;
             border-bottom:0;
             padding-top:18px;
             padding-bottom:0px;
         }
         #templateHeader .mcnTextContent,#templateHeader .mcnTextContent p{
             color:#202020;
             font-family:Helvetica;
             font-size:12px;
             line-height:125%;
             text-align:left;
         }
         #templateHeader .mcnTextContent a,#templateHeader .mcnTextContent p a{
             color:#007C89;
             font-weight:normal;
             text-decoration:underline;
         }
         #templateBody{
             background-color:#ffffff;
             background-image:none;
             background-repeat:no-repeat;
             background-position:center;
             background-size:cover;
             border-top:0;
             border-bottom:2px solid #EAEAEA;
             padding-top:0;
             padding-bottom:9px;
         }
         #templateBody .mcnTextContent,#templateBody .mcnTextContent p{
             color:#202020;
             font-family:'Noticia Text', Georgia, 'Times New Roman', serif;
             font-size:16px;
             line-height:150%;
             text-align:left;
         }
         #templateBody .mcnTextContent a,#templateBody .mcnTextContent p a{
             color:#42702f;
             font-weight:normal;
             text-decoration:underline;
         }
         #templateFooter{
             background-color:#ffffff;
             background-image:none;
             background-repeat:no-repeat;
             background-position:center;
             background-size:cover;
             border-top:0;
             border-bottom:0;
             padding-top:9px;
             padding-bottom:9px;
         }
         #templateFooter .mcnTextContent,#templateFooter .mcnTextContent p{
             color:#656565;
             font-family:Helvetica;
             font-size:12px;
             line-height:150%;
             text-align:center;
         }
         #templateFooter .mcnTextContent a,#templateFooter .mcnTextContent p a{
             color:#656565;
             font-weight:normal;
             text-decoration:underline;
         }
     @media only screen and (min-width:768px){
         .templateContainer{
             width:600px !important;
         }

 }   @media only screen and (max-width: 480px){
         body,table,td,p,a,li,blockquote{
             -webkit-text-size-adjust:none !important;
         }

 }   @media only screen and (max-width: 480px){
         body{
             width:100% !important;
             min-width:100% !important;
         }

 }   @media only screen and (max-width: 480px){
         #bodyCell{
             padding-top:10px !important;
         }

 }   @media only screen and (max-width: 480px){
         .mcnRetinaImage{
             max-width:100% !important;
         }

 }   @media only screen and (max-width: 480px){
         .mcnImage{
             width:100% !important;
         }

 }   @media only screen and (max-width: 480px){
         .mcnCartContainer,.mcnCaptionTopContent,.mcnRecContentContainer,.mcnCaptionBottomContent,.mcnTextContentContainer,.mcnBoxedTextContentContainer,.mcnImageGroupContentContainer,.mcnCaptionLeftTextContentContainer,.mcnCaptionRightTextContentContainer,.mcnCaptionLeftImageContentContainer,.mcnCaptionRightImageContentContainer,.mcnImageCardLeftTextContentContainer,.mcnImageCardRightTextContentContainer,.mcnImageCardLeftImageContentContainer,.mcnImageCardRightImageContentContainer{
             max-width:100% !important;
             width:100% !important;
         }

 }   @media only screen and (max-width: 480px){
         .mcnBoxedTextContentContainer{
             min-width:100% !important;
         }

 }   @media only screen and (max-width: 480px){
         .mcnImageGroupContent{
             padding:9px !important;
         }

 }   @media only screen and (max-width: 480px){
         .mcnCaptionLeftContentOuter .mcnTextContent,.mcnCaptionRightContentOuter .mcnTextContent{
             padding-top:9px !important;
         }

 }   @media only screen and (max-width: 480px){
         .mcnImageCardTopImageContent,.mcnCaptionBottomContent:last-child .mcnCaptionBottomImageContent,.mcnCaptionBlockInner .mcnCaptionTopContent:last-child .mcnTextContent{
             padding-top:18px !important;
         }

 }   @media only screen and (max-width: 480px){
         .mcnImageCardBottomImageContent{
             padding-bottom:9px !important;
         }

 }   @media only screen and (max-width: 480px){
         .mcnImageGroupBlockInner{
             padding-top:0 !important;
             padding-bottom:0 !important;
         }

 }   @media only screen and (max-width: 480px){
         .mcnImageGroupBlockOuter{
             padding-top:9px !important;
             padding-bottom:9px !important;
         }

 }   @media only screen and (max-width: 480px){
         .mcnTextContent,.mcnBoxedTextContentColumn{
             padding-right:18px !important;
             padding-left:18px !important;
         }

 }   @media only screen and (max-width: 480px){
         .mcnImageCardLeftImageContent,.mcnImageCardRightImageContent{
             padding-right:18px !important;
             padding-bottom:0 !important;
             padding-left:18px !important;
         }

 }   @media only screen and (max-width: 480px){
         .mcpreview-image-uploader{
             display:none !important;
             width:100% !important;
         }

 }   @media only screen and (max-width: 480px){
         h1{
             font-size:22px !important;
             line-height:125% !important;
         }

 }   @media only screen and (max-width: 480px){
         h2{
             font-size:20px !important;
             line-height:125% !important;
         }

 }   @media only screen and (max-width: 480px){
         h3{
             font-size:18px !important;
             line-height:125% !important;
         }

 }   @media only screen and (max-width: 480px){
         h4{
             font-size:16px !important;
             line-height:150% !important;
         }

 }   @media only screen and (max-width: 480px){
         .mcnBoxedTextContentContainer .mcnTextContent,.mcnBoxedTextContentContainer .mcnTextContent p{
             font-size:14px !important;
             line-height:150% !important;
         }

 }   @media only screen and (max-width: 480px){
         #templatePreheader{
             display:block !important;
         }

 }   @media only screen and (max-width: 480px){
         #templatePreheader .mcnTextContent,#templatePreheader .mcnTextContent p{
             font-size:14px !important;
             line-height:150% !important;
         }

 }   @media only screen and (max-width: 480px){
         #templateHeader .mcnTextContent,#templateHeader .mcnTextContent p{
             font-size:16px !important;
             line-height:150% !important;
         }

 }   @media only screen and (max-width: 480px){
         #templateBody .mcnTextContent,#templateBody .mcnTextContent p{
             font-size:16px !important;
             line-height:150% !important;
         }

 }   @media only screen and (max-width: 480px){
         #templateFooter .mcnTextContent,#templateFooter .mcnTextContent p{
             font-size:14px !important;
             line-height:150% !important;
         }

 }</style><!--[if !mso]><!--><link href="https://fonts.googleapis.com/css?family=Noticia+Text:400,400i,700,700i" rel="stylesheet"><!--<![endif]--></head>
     <body style="height: 100%;margin: 0;padding: 0;width: 100%;-ms-text-size-adjust: 100%;-webkit-text-size-adjust: 100%;background-color: #ffffff;">
         <!--*|IF:MC_PREVIEW_TEXT|*-->
         <!--[if !gte mso 9]><!----><span class="mcnPreviewText" style="display:none; font-size:0px; line-height:0px; max-height:0px; max-width:0px; opacity:0; overflow:hidden; visibility:hidden; mso-hide:all;">*|MC_PREVIEW_TEXT|*</span><!--<![endif]-->
         <!--*|END:IF|*-->
         <center>
             <table align="center" border="0" cellpadding="0" cellspacing="0" height="100%" width="100%" id="bodyTable" style="border-collapse: collapse;mso-table-lspace: 0pt;mso-table-rspace: 0pt;-ms-text-size-adjust: 100%;-webkit-text-size-adjust: 100%;height: 100%;margin: 0;padding: 0;width: 100%;background-color: #ffffff;">
                 <tr>
                     <td align="center" valign="top" id="bodyCell" style="mso-line-height-rule: exactly;-ms-text-size-adjust: 100%;-webkit-text-size-adjust: 100%;height: 100%;margin: 0;padding: 10px;width: 100%;border-top: 0;">
                         <!-- BEGIN TEMPLATE // -->
                         <!--[if (gte mso 9)|(IE)]>
                         <table align="center" border="0" cellspacing="0" cellpadding="0" width="600" style="width:600px;">
                         <tr>
                         <td align="center" valign="top" width="600" style="width:600px;">
                         <![endif]-->
                         <table border="0" cellpadding="0" cellspacing="0" width="100%" class="templateContainer" style="border-collapse: collapse;mso-table-lspace: 0pt;mso-table-rspace: 0pt;-ms-text-size-adjust: 100%;-webkit-text-size-adjust: 100%;border: 0;max-width: 600px !important;">
                             <tr>
                                 <td valign="top" id="templateHeader" style="background:#ffffff none no-repeat center/cover;mso-line-height-rule: exactly;-ms-text-size-adjust: 100%;-webkit-text-size-adjust: 100%;background-color: #ffffff;background-image: none;background-repeat: no-repeat;background-position: center;background-size: cover;border-top: 0;border-bottom: 0;padding-top: 18px;padding-bottom: 0px;"><table border="0" cellpadding="0" cellspacing="0" width="100%" class="mcnImageBlock" style="min-width: 100%;border-collapse: collapse;mso-table-lspace: 0pt;mso-table-rspace: 0pt;-ms-text-size-adjust: 100%;-webkit-text-size-adjust: 100%;">
     <tbody class="mcnImageBlockOuter">
             <tr>
                 <td valign="top" style="padding: 9px;mso-line-height-rule: exactly;-ms-text-size-adjust: 100%;-webkit-text-size-adjust: 100%;" class="mcnImageBlockInner">
                     <table align="left" width="100%" border="0" cellpadding="0" cellspacing="0" class="mcnImageContentContainer" style="min-width: 100%;border-collapse: collapse;mso-table-lspace: 0pt;mso-table-rspace: 0pt;-ms-text-size-adjust: 100%;-webkit-text-size-adjust: 100%;">
                         <tbody><tr>
                             <td class="mcnImageContent" valign="top" style="padding-right: 9px;padding-left: 9px;padding-top: 0;padding-bottom: 0;text-align: center;mso-line-height-rule: exactly;-ms-text-size-adjust: 100%;-webkit-text-size-adjust: 100%;">


                                         <img align="center" alt="" src="https://gallery.mailchimp.com/adee9c5618cae148bdcf8f762/images/968dddf3-cd2a-4aaf-a27c-2d1ce4344f94.jpg" width="564" style="max-width: 960px;padding-bottom: 0;display: inline !important;vertical-align: bottom;border: 0;height: auto;outline: none;text-decoration: none;-ms-interpolation-mode: bicubic;" class="mcnImage">


                             </td>
                         </tr>
                     </tbody></table>
                 </td>
             </tr>
     </tbody>
 </table></td>
                             </tr>
                             <tr>
                                 <td valign="top" id="templateBody" style="background:#ffffff none no-repeat center/cover;mso-line-height-rule: exactly;-ms-text-size-adjust: 100%;-webkit-text-size-adjust: 100%;background-color: #ffffff;background-image: none;background-repeat: no-repeat;background-position: center;background-size: cover;border-top: 0;border-bottom: 2px solid #EAEAEA;padding-top: 0;padding-bottom: 9px;"><table border="0" cellpadding="0" cellspacing="0" width="100%" class="mcnDividerBlock" style="min-width: 100%;border-collapse: collapse;mso-table-lspace: 0pt;mso-table-rspace: 0pt;-ms-text-size-adjust: 100%;-webkit-text-size-adjust: 100%;table-layout: fixed !important;">
     <tbody class="mcnDividerBlockOuter">
         <tr>
             <td class="mcnDividerBlockInner" style="min-width: 100%;padding: 8px 18px 0px;mso-line-height-rule: exactly;-ms-text-size-adjust: 100%;-webkit-text-size-adjust: 100%;">
                 <table class="mcnDividerContent" border="0" cellpadding="0" cellspacing="0" width="100%" style="min-width: 100%;border-top: 2px solid #EAEAEA;border-collapse: collapse;mso-table-lspace: 0pt;mso-table-rspace: 0pt;-ms-text-size-adjust: 100%;-webkit-text-size-adjust: 100%;">
                     <tbody><tr>
                         <td style="mso-line-height-rule: exactly;-ms-text-size-adjust: 100%;-webkit-text-size-adjust: 100%;">
                             <span></span>
                         </td>
                     </tr>
                 </tbody></table>
 <!--
                 <td class="mcnDividerBlockInner" style="padding: 18px;">
                 <hr class="mcnDividerContent" style="border-bottom-color:none; border-left-color:none; border-right-color:none; border-bottom-width:0; border-left-width:0; border-right-width:0; margin-top:0; margin-right:0; margin-bottom:0; margin-left:0;" />
 -->
             </td>
         </tr>
     </tbody>
 </table><table border="0" cellpadding="0" cellspacing="0" width="100%" class="mcnTextBlock" style="min-width: 100%;border-collapse: collapse;mso-table-lspace: 0pt;mso-table-rspace: 0pt;-ms-text-size-adjust: 100%;-webkit-text-size-adjust: 100%;">
     <tbody class="mcnTextBlockOuter">
         <tr>
             <td valign="top" class="mcnTextBlockInner" style="padding-top: 9px;mso-line-height-rule: exactly;-ms-text-size-adjust: 100%;-webkit-text-size-adjust: 100%;">
                 <!--[if mso]>
                 <table align="left" border="0" cellspacing="0" cellpadding="0" width="100%" style="width:100%;">
                 <tr>
                 <![endif]-->

                 <!--[if mso]>
                 <td valign="top" width="600" style="width:600px;">
                 <![endif]-->
                 <table align="left" border="0" cellpadding="0" cellspacing="0" style="max-width: 100%;min-width: 100%;border-collapse: collapse;mso-table-lspace: 0pt;mso-table-rspace: 0pt;-ms-text-size-adjust: 100%;-webkit-text-size-adjust: 100%;" width="100%" class="mcnTextContentContainer">
                     <tbody><tr>

                         <td valign="top" class="mcnTextContent" style="padding-top: 0;padding-right: 18px;padding-bottom: 9px;padding-left: 18px;mso-line-height-rule: exactly;-ms-text-size-adjust: 100%;-webkit-text-size-adjust: 100%;word-break: break-word;color: #202020;font-family: 'Noticia Text', Georgia, 'Times New Roman', serif;font-size: 16px;line-height: 150%;text-align: left;">

                             <div style="text-align: left;"><strong><span style="font-size:12px"><span style="font-family:noticia text,georgia,times new roman,serif">Revolutions? Need? Rules? [Editorial] • Viva Block Vegas • Cool $#i®T of the week</span></span></strong></div>

                         </td>
                     </tr>
                 </tbody></table>
                 <!--[if mso]>
                 </td>
                 <![endif]-->

                 <!--[if mso]>
                 </tr>
                 </table>
                 <![endif]-->
             </td>
         </tr>
     </tbody>
 </table><table border="0" cellpadding="0" cellspacing="0" width="100%" class="mcnDividerBlock" style="min-width: 100%;border-collapse: collapse;mso-table-lspace: 0pt;mso-table-rspace: 0pt;-ms-text-size-adjust: 100%;-webkit-text-size-adjust: 100%;table-layout: fixed !important;">
     <tbody class="mcnDividerBlockOuter">
         <tr>
             <td class="mcnDividerBlockInner" style="min-width: 100%;padding: 2px 18px 9px;mso-line-height-rule: exactly;-ms-text-size-adjust: 100%;-webkit-text-size-adjust: 100%;">
                 <table class="mcnDividerContent" border="0" cellpadding="0" cellspacing="0" width="100%" style="min-width: 100%;border-top: 2px solid #EAEAEA;border-collapse: collapse;mso-table-lspace: 0pt;mso-table-rspace: 0pt;-ms-text-size-adjust: 100%;-webkit-text-size-adjust: 100%;">
                     <tbody><tr>
                         <td style="mso-line-height-rule: exactly;-ms-text-size-adjust: 100%;-webkit-text-size-adjust: 100%;">
                             <span></span>
                         </td>
                     </tr>
                 </tbody></table>
 <!--
                 <td class="mcnDividerBlockInner" style="padding: 18px;">
                 <hr class="mcnDividerContent" style="border-bottom-color:none; border-left-color:none; border-right-color:none; border-bottom-width:0; border-left-width:0; border-right-width:0; margin-top:0; margin-right:0; margin-bottom:0; margin-left:0;" />
 -->
             </td>
         </tr>
     </tbody>
 </table><table border="0" cellpadding="0" cellspacing="0" width="100%" class="mcnTextBlock" style="min-width: 100%;border-collapse: collapse;mso-table-lspace: 0pt;mso-table-rspace: 0pt;-ms-text-size-adjust: 100%;-webkit-text-size-adjust: 100%;">
     <tbody class="mcnTextBlockOuter">
         <tr>
             <td valign="top" class="mcnTextBlockInner" style="padding-top: 9px;mso-line-height-rule: exactly;-ms-text-size-adjust: 100%;-webkit-text-size-adjust: 100%;">
                 <!--[if mso]>
                 <table align="left" border="0" cellspacing="0" cellpadding="0" width="100%" style="width:100%;">
                 <tr>
                 <![endif]-->

                 <!--[if mso]>
                 <td valign="top" width="600" style="width:600px;">
                 <![endif]-->
                 <table align="left" border="0" cellpadding="0" cellspacing="0" style="max-width: 100%;min-width: 100%;border-collapse: collapse;mso-table-lspace: 0pt;mso-table-rspace: 0pt;-ms-text-size-adjust: 100%;-webkit-text-size-adjust: 100%;" width="100%" class="mcnTextContentContainer">
                     <tbody><tr>

                         <td valign="top" class="mcnTextContent" style="padding: 0px 18px 9px;font-family: "Noticia Text", Georgia, "Times New Roman", serif;line-height: 150%;mso-line-height-rule: exactly;-ms-text-size-adjust: 100%;-webkit-text-size-adjust: 100%;word-break: break-word;color: #202020;font-size: 16px;text-align: left;">

                             <p dir="ltr" style="font-family: "Noticia Text", Georgia, "Times New Roman", serif;line-height: 150%;margin: 10px 0;padding: 0;mso-line-height-rule: exactly;-ms-text-size-adjust: 100%;-webkit-text-size-adjust: 100%;color: #202020;font-size: 16px;text-align: left;"><span style="font-size:18px"><strong>Revolutions? Need? Rules?</strong></span><br>
 <br>
 New Yorkers this week were greeted by <a href="https://www.wsj.com/articles/winklevosses-cryptocurrency-exchange-says-the-revolution-needs-rules-11546599600" target="_blank" style="mso-line-height-rule: exactly;-ms-text-size-adjust: 100%;-webkit-text-size-adjust: 100%;color: #42702f;font-weight: normal;text-decoration: underline;">various print and outdoor ads</a> for one of the world’s leading cryptocurrency exchanges, Gemini, founded in 2014 by Tyler and Cameron Winklevoss of Facebook/The Social Network fame. They’re also <a href="https://www.youtube.com/watch?v=RzAegKadbfM" target="_blank" style="mso-line-height-rule: exactly;-ms-text-size-adjust: 100%;-webkit-text-size-adjust: 100%;color: #42702f;font-weight: normal;text-decoration: underline;">Olympians</a> 🏅!  And they’ve made quite the name for themselves in the world of crypto, having amassed an estimated 1% of the world’s share of Bitcoin, and through their founding of Gemini and pursuit of a crypto ETF to list in mainstream capital markets.</p>

                         </td>
                     </tr>
                 </tbody></table>
                 <!--[if mso]>
                 </td>
                 <![endif]-->

                 <!--[if mso]>
                 </tr>
                 </table>
                 <![endif]-->
             </td>
         </tr>
     </tbody>
 </table><table border="0" cellpadding="0" cellspacing="0" width="100%" class="mcnImageBlock" style="min-width: 100%;border-collapse: collapse;mso-table-lspace: 0pt;mso-table-rspace: 0pt;-ms-text-size-adjust: 100%;-webkit-text-size-adjust: 100%;">
     <tbody class="mcnImageBlockOuter">
             <tr>
                 <td valign="top" style="padding: 9px;mso-line-height-rule: exactly;-ms-text-size-adjust: 100%;-webkit-text-size-adjust: 100%;" class="mcnImageBlockInner">
                     <table align="left" width="100%" border="0" cellpadding="0" cellspacing="0" class="mcnImageContentContainer" style="min-width: 100%;border-collapse: collapse;mso-table-lspace: 0pt;mso-table-rspace: 0pt;-ms-text-size-adjust: 100%;-webkit-text-size-adjust: 100%;">
                         <tbody><tr>
                             <td class="mcnImageContent" valign="top" style="padding-right: 9px;padding-left: 9px;padding-top: 0;padding-bottom: 0;text-align: center;mso-line-height-rule: exactly;-ms-text-size-adjust: 100%;-webkit-text-size-adjust: 100%;">


                                         <img align="center" alt="" src="https://gallery.mailchimp.com/adee9c5618cae148bdcf8f762/images/6af4cb00-8204-44e8-9fbe-8bfaf25fd01f.jpg" width="540" style="max-width: 540px;padding-bottom: 0;display: inline !important;vertical-align: bottom;border: 0;height: auto;outline: none;text-decoration: none;-ms-interpolation-mode: bicubic;" class="mcnImage">


                             </td>
                         </tr>
                     </tbody></table>
                 </td>
             </tr>
     </tbody>
 </table><table border="0" cellpadding="0" cellspacing="0" width="100%" class="mcnTextBlock" style="min-width: 100%;border-collapse: collapse;mso-table-lspace: 0pt;mso-table-rspace: 0pt;-ms-text-size-adjust: 100%;-webkit-text-size-adjust: 100%;">
     <tbody class="mcnTextBlockOuter">
         <tr>
             <td valign="top" class="mcnTextBlockInner" style="padding-top: 9px;mso-line-height-rule: exactly;-ms-text-size-adjust: 100%;-webkit-text-size-adjust: 100%;">
                 <!--[if mso]>
                 <table align="left" border="0" cellspacing="0" cellpadding="0" width="100%" style="width:100%;">
                 <tr>
                 <![endif]-->

                 <!--[if mso]>
                 <td valign="top" width="600" style="width:600px;">
                 <![endif]-->
                 <table align="left" border="0" cellpadding="0" cellspacing="0" style="max-width: 100%;min-width: 100%;border-collapse: collapse;mso-table-lspace: 0pt;mso-table-rspace: 0pt;-ms-text-size-adjust: 100%;-webkit-text-size-adjust: 100%;" width="100%" class="mcnTextContentContainer">
                     <tbody><tr>

                         <td valign="top" class="mcnTextContent" style="padding: 0px 18px 9px;font-family: "Noticia Text", Georgia, "Times New Roman", serif;line-height: 150%;mso-line-height-rule: exactly;-ms-text-size-adjust: 100%;-webkit-text-size-adjust: 100%;word-break: break-word;color: #202020;font-size: 16px;text-align: left;">

                             <p dir="ltr" style="font-family: "Noticia Text", Georgia, "Times New Roman", serif;line-height: 150%;margin: 10px 0;padding: 0;mso-line-height-rule: exactly;-ms-text-size-adjust: 100%;-webkit-text-size-adjust: 100%;color: #202020;font-size: 16px;text-align: left;">The Gemini approach is one of coloring inside the lines, playing by the rules. Tyler and Cameron are Harvard graduates, after all, not dropouts. And we have to agree it makes sense strategically to play the white knight in a world that is more commonly imagined by the public as lawless and chaotic. But is the approach, or at least the ad campaign, antithetical to what crypto is all about?</p>

 <p dir="ltr" style="text-align: center;font-family: "Noticia Text", Georgia, "Times New Roman", serif;line-height: 150%;margin: 10px 0;padding: 0;mso-line-height-rule: exactly;-ms-text-size-adjust: 100%;-webkit-text-size-adjust: 100%;color: #202020;font-size: 16px;"><em>The Revolution Needs Rules.</em><br>
 <em>Crypto Needs Rules.</em></p>

 <p dir="ltr" style="text-align: left;font-family: "Noticia Text", Georgia, "Times New Roman", serif;line-height: 150%;margin: 10px 0;padding: 0;mso-line-height-rule: exactly;-ms-text-size-adjust: 100%;-webkit-text-size-adjust: 100%;color: #202020;font-size: 16px;">These are the bold messages declared by billboards, subway cars, a Gemini-branded chartered #cryptobus, phone booth ads, and taxi cabs. Some of these ads are adorned by the famous scene of George Washington crossing the Delaware River, which BTW is our favorite artwork depicting the American Revolution. Shout out to New Jersey for the back of your quarter, it’s also our fave. But did the American Revolution need rules? I mean, there was a Declaration of Independence and Articles of Confederation, so yeah there were some rules and guideposts. But there was also war waged on the battlefield, a place in which over 37,000 casualties were suffered by both sides, and guerilla tactics were instrumental in defeating an enemy engaged in traditional methods.</p>

                         </td>
                     </tr>
                 </tbody></table>
                 <!--[if mso]>
                 </td>
                 <![endif]-->

                 <!--[if mso]>
                 </tr>
                 </table>
                 <![endif]-->
             </td>
         </tr>
     </tbody>
 </table><table border="0" cellpadding="0" cellspacing="0" width="100%" class="mcnImageCardBlock" style="border-collapse: collapse;mso-table-lspace: 0pt;mso-table-rspace: 0pt;-ms-text-size-adjust: 100%;-webkit-text-size-adjust: 100%;">
     <tbody class="mcnImageCardBlockOuter">
         <tr>
             <td class="mcnImageCardBlockInner" valign="top" style="padding-top: 9px;padding-right: 18px;padding-bottom: 9px;padding-left: 18px;mso-line-height-rule: exactly;-ms-text-size-adjust: 100%;-webkit-text-size-adjust: 100%;">

 <table align="right" border="0" cellpadding="0" cellspacing="0" class="mcnImageCardBottomContent" width="100%" style="border-collapse: collapse;mso-table-lspace: 0pt;mso-table-rspace: 0pt;-ms-text-size-adjust: 100%;-webkit-text-size-adjust: 100%;">
     <tbody><tr>
         <td class="mcnImageCardBottomImageContent" align="center" valign="top" style="padding-top: 0px;padding-right: 0px;padding-bottom: 0;padding-left: 0px;mso-line-height-rule: exactly;-ms-text-size-adjust: 100%;-webkit-text-size-adjust: 100%;">



             <img alt="" src="https://gallery.mailchimp.com/adee9c5618cae148bdcf8f762/images/fd499361-ddb3-473e-b991-adc683bbb71d.jpg" width="540" style="max-width: 540px;border: 0;height: auto;outline: none;text-decoration: none;-ms-interpolation-mode: bicubic;vertical-align: bottom;" class="mcnImage">


         </td>
     </tr>
     <tr>
         <td class="mcnTextContent" valign="top" style="padding: 9px 18px;color: #747474;font-family: "Noticia Text", Georgia, "Times New Roman", serif;font-size: 14px;font-weight: normal;text-align: center;mso-line-height-rule: exactly;-ms-text-size-adjust: 100%;-webkit-text-size-adjust: 100%;word-break: break-word;line-height: 150%;" width="546">
             alt text: "But can the revolution fix the MTA?"
         </td>
     </tr>
 </tbody></table>




             </td>
         </tr>
     </tbody>
 </table><table border="0" cellpadding="0" cellspacing="0" width="100%" class="mcnTextBlock" style="min-width: 100%;border-collapse: collapse;mso-table-lspace: 0pt;mso-table-rspace: 0pt;-ms-text-size-adjust: 100%;-webkit-text-size-adjust: 100%;">
     <tbody class="mcnTextBlockOuter">
         <tr>
             <td valign="top" class="mcnTextBlockInner" style="padding-top: 9px;mso-line-height-rule: exactly;-ms-text-size-adjust: 100%;-webkit-text-size-adjust: 100%;">
                 <!--[if mso]>
                 <table align="left" border="0" cellspacing="0" cellpadding="0" width="100%" style="width:100%;">
                 <tr>
                 <![endif]-->

                 <!--[if mso]>
                 <td valign="top" width="600" style="width:600px;">
                 <![endif]-->
                 <table align="left" border="0" cellpadding="0" cellspacing="0" style="max-width: 100%;min-width: 100%;border-collapse: collapse;mso-table-lspace: 0pt;mso-table-rspace: 0pt;-ms-text-size-adjust: 100%;-webkit-text-size-adjust: 100%;" width="100%" class="mcnTextContentContainer">
                     <tbody><tr>

                         <td valign="top" class="mcnTextContent" style="padding-top: 0;padding-right: 18px;padding-bottom: 9px;padding-left: 18px;mso-line-height-rule: exactly;-ms-text-size-adjust: 100%;-webkit-text-size-adjust: 100%;word-break: break-word;color: #202020;font-family: 'Noticia Text', Georgia, 'Times New Roman', serif;font-size: 16px;line-height: 150%;text-align: left;">

                             <p dir="ltr" style="margin: 10px 0;padding: 0;mso-line-height-rule: exactly;-ms-text-size-adjust: 100%;-webkit-text-size-adjust: 100%;color: #202020;font-family: 'Noticia Text', Georgia, 'Times New Roman', serif;font-size: 16px;line-height: 150%;text-align: left;">Is crypto about playing by the rules? Is the crypto revolution about playing by the rules? These are two distinct questions, whether or not that was intended by the marketing team at Gemini and the agency behind the ad campaign.<br>
 <br>
 Crypto Needs Rules, the billboards say. Crypto is rules, we say. It’s kind of the whole point. Transactions and contracts based on mutually agreed upon terms, executed by two parties, validated through various means of network affirmation.<br>
 <br>
 The Revolution Needs Rules, the billboards say. We say, kind of! Because it isn’t so much that crypto needs rules, it’s that people need rules. The same goes with people and traditional money - in an ideal state money is a simple means of exchange of value; in reality it tends to bring out the worst in some people. Thieves, scammers, crooks, and the just-plain-greedy come out to prey. Do consumers need protections in the marketplace? Yes, we’re of the mind that a marketplace must assure participants a significant level of safety and security to reach critical mass.</p>

                         </td>
                     </tr>
                 </tbody></table>
                 <!--[if mso]>
                 </td>
                 <![endif]-->

                 <!--[if mso]>
                 </tr>
                 </table>
                 <![endif]-->
             </td>
         </tr>
     </tbody>
 </table><table border="0" cellpadding="0" cellspacing="0" width="100%" class="mcnImageCardBlock" style="border-collapse: collapse;mso-table-lspace: 0pt;mso-table-rspace: 0pt;-ms-text-size-adjust: 100%;-webkit-text-size-adjust: 100%;">
     <tbody class="mcnImageCardBlockOuter">
         <tr>
             <td class="mcnImageCardBlockInner" valign="top" style="padding-top: 9px;padding-right: 18px;padding-bottom: 9px;padding-left: 18px;mso-line-height-rule: exactly;-ms-text-size-adjust: 100%;-webkit-text-size-adjust: 100%;">

 <table align="left" border="0" cellpadding="0" cellspacing="0" class="mcnImageCardBottomContent" width="100%" style="border-collapse: collapse;mso-table-lspace: 0pt;mso-table-rspace: 0pt;-ms-text-size-adjust: 100%;-webkit-text-size-adjust: 100%;">
     <tbody><tr>
         <td class="mcnImageCardBottomImageContent" align="center" valign="top" style="padding-top: 0px;padding-right: 0px;padding-bottom: 0;padding-left: 0px;mso-line-height-rule: exactly;-ms-text-size-adjust: 100%;-webkit-text-size-adjust: 100%;">



             <img alt="" src="https://gallery.mailchimp.com/adee9c5618cae148bdcf8f762/images/c8b50f94-f740-40dc-aa45-fbd0eb7ba894.jpg" width="540" style="max-width: 540px;border: 0;height: auto;outline: none;text-decoration: none;-ms-interpolation-mode: bicubic;vertical-align: bottom;" class="mcnImage">


         </td>
     </tr>
     <tr>
         <td class="mcnTextContent" valign="top" style="padding: 9px 18px;color: #747474;font-family: "Noticia Text", Georgia, "Times New Roman", serif;font-size: 14px;font-weight: normal;text-align: center;mso-line-height-rule: exactly;-ms-text-size-adjust: 100%;-webkit-text-size-adjust: 100%;word-break: break-word;line-height: 150%;" width="546">
             alt text “A perfectly-executed hard fork.”
         </td>
     </tr>
 </tbody></table>




             </td>
         </tr>
     </tbody>
 </table><table border="0" cellpadding="0" cellspacing="0" width="100%" class="mcnTextBlock" style="min-width: 100%;border-collapse: collapse;mso-table-lspace: 0pt;mso-table-rspace: 0pt;-ms-text-size-adjust: 100%;-webkit-text-size-adjust: 100%;">
     <tbody class="mcnTextBlockOuter">
         <tr>
             <td valign="top" class="mcnTextBlockInner" style="padding-top: 9px;mso-line-height-rule: exactly;-ms-text-size-adjust: 100%;-webkit-text-size-adjust: 100%;">
                 <!--[if mso]>
                 <table align="left" border="0" cellspacing="0" cellpadding="0" width="100%" style="width:100%;">
                 <tr>
                 <![endif]-->

                 <!--[if mso]>
                 <td valign="top" width="600" style="width:600px;">
                 <![endif]-->
                 <table align="left" border="0" cellpadding="0" cellspacing="0" style="max-width: 100%;min-width: 100%;border-collapse: collapse;mso-table-lspace: 0pt;mso-table-rspace: 0pt;-ms-text-size-adjust: 100%;-webkit-text-size-adjust: 100%;" width="100%" class="mcnTextContentContainer">
                     <tbody><tr>

                         <td valign="top" class="mcnTextContent" style="padding-top: 0;padding-right: 18px;padding-bottom: 9px;padding-left: 18px;mso-line-height-rule: exactly;-ms-text-size-adjust: 100%;-webkit-text-size-adjust: 100%;word-break: break-word;color: #202020;font-family: 'Noticia Text', Georgia, 'Times New Roman', serif;font-size: 16px;line-height: 150%;text-align: left;">

                             So, given that people need rules in a marketplace, the question becomes, who makes the rules? If we revisit the premise that crypto is rules, then we also need to ask, who writes the crypto rules? Especially today when the rules are still being written at the protocol level. We’re still so early in this game, why accede to the rules of the existing global financial power structure? Doesn’t making crypto’s rules subservient to the existing financial system defeat the purpose? Reasonable minds may differ, as will response to <a href="https://medium.com/gemini/geminis-principles-for-the-crypto-revolution-1e42a8bea3af" target="_blank" style="mso-line-height-rule: exactly;-ms-text-size-adjust: 100%;-webkit-text-size-adjust: 100%;color: #42702f;font-weight: normal;text-decoration: underline;">Gemini’s Principles and approach</a>. We say that Our Crypto Future is about decentralization of power and authority, about empowering the individual and their financial freedom, and by extension their social freedom. You know, freedom freedom. The kind of stuff that moves a Continental Army across a frozen river at midnight on Christmas.<br>
 <br>
 But if we’re just playing by the rules and bowing to existing power structures, then we’re only cutting and pasting crypto into the global economy where fiat once stood. Would the world be a better place if that’s where crypto’s story ends? Perhaps. But it sure as hell isn’t a revolution. Us, though, we’re in this thing for more than just trading our quarters for Bitcoin. We love our <a href="https://www.usmint.gov/learn/coin-and-medal-programs/50-state-quarters" target="_blank" style="mso-line-height-rule: exactly;-ms-text-size-adjust: 100%;-webkit-text-size-adjust: 100%;color: #42702f;font-weight: normal;text-decoration: underline;">50 States Quarters</a> collection way too much for that.
                         </td>
                     </tr>
                 </tbody></table>
                 <!--[if mso]>
                 </td>
                 <![endif]-->

                 <!--[if mso]>
                 </tr>
                 </table>
                 <![endif]-->
             </td>
         </tr>
     </tbody>
 </table><table border="0" cellpadding="0" cellspacing="0" width="100%" class="mcnImageBlock" style="min-width: 100%;border-collapse: collapse;mso-table-lspace: 0pt;mso-table-rspace: 0pt;-ms-text-size-adjust: 100%;-webkit-text-size-adjust: 100%;">
     <tbody class="mcnImageBlockOuter">
             <tr>
                 <td valign="top" style="padding: 9px;mso-line-height-rule: exactly;-ms-text-size-adjust: 100%;-webkit-text-size-adjust: 100%;" class="mcnImageBlockInner">
                     <table align="left" width="100%" border="0" cellpadding="0" cellspacing="0" class="mcnImageContentContainer" style="min-width: 100%;border-collapse: collapse;mso-table-lspace: 0pt;mso-table-rspace: 0pt;-ms-text-size-adjust: 100%;-webkit-text-size-adjust: 100%;">
                         <tbody><tr>
                             <td class="mcnImageContent" valign="top" style="padding-right: 9px;padding-left: 9px;padding-top: 0;padding-bottom: 0;text-align: center;mso-line-height-rule: exactly;-ms-text-size-adjust: 100%;-webkit-text-size-adjust: 100%;">


                                         <img align="center" alt="" src="https://gallery.mailchimp.com/adee9c5618cae148bdcf8f762/images/7380f37d-3d35-4730-a9d4-13757b8f3bb5.jpg" width="200" style="max-width: 400px;padding-bottom: 0;display: inline !important;vertical-align: bottom;border: 0;height: auto;outline: none;text-decoration: none;-ms-interpolation-mode: bicubic;" class="mcnRetinaImage">


                             </td>
                         </tr>
                     </tbody></table>
                 </td>
             </tr>
     </tbody>
 </table><table border="0" cellpadding="0" cellspacing="0" width="100%" class="mcnDividerBlock" style="min-width: 100%;border-collapse: collapse;mso-table-lspace: 0pt;mso-table-rspace: 0pt;-ms-text-size-adjust: 100%;-webkit-text-size-adjust: 100%;table-layout: fixed !important;">
     <tbody class="mcnDividerBlockOuter">
         <tr>
             <td class="mcnDividerBlockInner" style="min-width: 100%;padding: 24px 18px;mso-line-height-rule: exactly;-ms-text-size-adjust: 100%;-webkit-text-size-adjust: 100%;">
                 <table class="mcnDividerContent" border="0" cellpadding="0" cellspacing="0" width="100%" style="min-width: 100%;border-top: 2px solid #EAEAEA;border-collapse: collapse;mso-table-lspace: 0pt;mso-table-rspace: 0pt;-ms-text-size-adjust: 100%;-webkit-text-size-adjust: 100%;">
                     <tbody><tr>
                         <td style="mso-line-height-rule: exactly;-ms-text-size-adjust: 100%;-webkit-text-size-adjust: 100%;">
                             <span></span>
                         </td>
                     </tr>
                 </tbody></table>
 <!--
                 <td class="mcnDividerBlockInner" style="padding: 18px;">
                 <hr class="mcnDividerContent" style="border-bottom-color:none; border-left-color:none; border-right-color:none; border-bottom-width:0; border-left-width:0; border-right-width:0; margin-top:0; margin-right:0; margin-bottom:0; margin-left:0;" />
 -->
             </td>
         </tr>
     </tbody>
 </table><table border="0" cellpadding="0" cellspacing="0" width="100%" class="mcnTextBlock" style="min-width: 100%;border-collapse: collapse;mso-table-lspace: 0pt;mso-table-rspace: 0pt;-ms-text-size-adjust: 100%;-webkit-text-size-adjust: 100%;">
     <tbody class="mcnTextBlockOuter">
         <tr>
             <td valign="top" class="mcnTextBlockInner" style="padding-top: 9px;mso-line-height-rule: exactly;-ms-text-size-adjust: 100%;-webkit-text-size-adjust: 100%;">
                 <!--[if mso]>
                 <table align="left" border="0" cellspacing="0" cellpadding="0" width="100%" style="width:100%;">
                 <tr>
                 <![endif]-->

                 <!--[if mso]>
                 <td valign="top" width="600" style="width:600px;">
                 <![endif]-->
                 <table align="left" border="0" cellpadding="0" cellspacing="0" style="max-width: 100%;min-width: 100%;border-collapse: collapse;mso-table-lspace: 0pt;mso-table-rspace: 0pt;-ms-text-size-adjust: 100%;-webkit-text-size-adjust: 100%;" width="100%" class="mcnTextContentContainer">
                     <tbody><tr>

                         <td valign="top" class="mcnTextContent" style="padding-top: 0;padding-right: 18px;padding-bottom: 9px;padding-left: 18px;mso-line-height-rule: exactly;-ms-text-size-adjust: 100%;-webkit-text-size-adjust: 100%;word-break: break-word;color: #202020;font-family: 'Noticia Text', Georgia, 'Times New Roman', serif;font-size: 16px;line-height: 150%;text-align: left;">

                             <span style="font-size:18px"><span style="font-family:noticia text,georgia,times new roman,serif"><strong>Blockchain-backed contracts? Bet on it!</strong></span></span>
                         </td>
                     </tr>
                 </tbody></table>
                 <!--[if mso]>
                 </td>
                 <![endif]-->

                 <!--[if mso]>
                 </tr>
                 </table>
                 <![endif]-->
             </td>
         </tr>
     </tbody>
 </table><table border="0" cellpadding="0" cellspacing="0" width="100%" class="mcnImageCardBlock" style="border-collapse: collapse;mso-table-lspace: 0pt;mso-table-rspace: 0pt;-ms-text-size-adjust: 100%;-webkit-text-size-adjust: 100%;">
     <tbody class="mcnImageCardBlockOuter">
         <tr>
             <td class="mcnImageCardBlockInner" valign="top" style="padding-top: 9px;padding-right: 18px;padding-bottom: 9px;padding-left: 18px;mso-line-height-rule: exactly;-ms-text-size-adjust: 100%;-webkit-text-size-adjust: 100%;">

 <table align="right" border="0" cellpadding="0" cellspacing="0" class="mcnImageCardBottomContent" width="100%" style="border-collapse: collapse;mso-table-lspace: 0pt;mso-table-rspace: 0pt;-ms-text-size-adjust: 100%;-webkit-text-size-adjust: 100%;">
     <tbody><tr>
         <td class="mcnImageCardBottomImageContent" align="center" valign="top" style="padding-top: 0px;padding-right: 0px;padding-bottom: 0;padding-left: 0px;mso-line-height-rule: exactly;-ms-text-size-adjust: 100%;-webkit-text-size-adjust: 100%;">



             <img alt="" src="https://gallery.mailchimp.com/adee9c5618cae148bdcf8f762/images/d7559bd7-f3c9-431b-911f-e8f9251e527a.jpeg" width="564" style="max-width: 912px;border: 0;height: auto;outline: none;text-decoration: none;-ms-interpolation-mode: bicubic;vertical-align: bottom;" class="mcnImage">


         </td>
     </tr>
     <tr>
         <td class="mcnTextContent" valign="top" style="padding: 9px 18px;color: #747474;font-family: "Noticia Text", Georgia, "Times New Roman", serif;font-size: 14px;font-weight: normal;text-align: center;mso-line-height-rule: exactly;-ms-text-size-adjust: 100%;-webkit-text-size-adjust: 100%;word-break: break-word;line-height: 150%;" width="546">
             alt text “This magnet brought to you by parents’ refrigerators everywhere.”
         </td>
     </tr>
 </tbody></table>




             </td>
         </tr>
     </tbody>
 </table><table border="0" cellpadding="0" cellspacing="0" width="100%" class="mcnTextBlock" style="min-width: 100%;border-collapse: collapse;mso-table-lspace: 0pt;mso-table-rspace: 0pt;-ms-text-size-adjust: 100%;-webkit-text-size-adjust: 100%;">
     <tbody class="mcnTextBlockOuter">
         <tr>
             <td valign="top" class="mcnTextBlockInner" style="padding-top: 9px;mso-line-height-rule: exactly;-ms-text-size-adjust: 100%;-webkit-text-size-adjust: 100%;">
                 <!--[if mso]>
                 <table align="left" border="0" cellspacing="0" cellpadding="0" width="100%" style="width:100%;">
                 <tr>
                 <![endif]-->

                 <!--[if mso]>
                 <td valign="top" width="600" style="width:600px;">
                 <![endif]-->
                 <table align="left" border="0" cellpadding="0" cellspacing="0" style="max-width: 100%;min-width: 100%;border-collapse: collapse;mso-table-lspace: 0pt;mso-table-rspace: 0pt;-ms-text-size-adjust: 100%;-webkit-text-size-adjust: 100%;" width="100%" class="mcnTextContentContainer">
                     <tbody><tr>

                         <td valign="top" class="mcnTextContent" style="padding: 0px 18px 9px;font-family: "Noticia Text", Georgia, "Times New Roman", serif;line-height: 150%;mso-line-height-rule: exactly;-ms-text-size-adjust: 100%;-webkit-text-size-adjust: 100%;word-break: break-word;color: #202020;font-size: 16px;text-align: left;">

                             <p dir="ltr" style="font-family: "Noticia Text", Georgia, "Times New Roman", serif;line-height: 150%;margin: 10px 0;padding: 0;mso-line-height-rule: exactly;-ms-text-size-adjust: 100%;-webkit-text-size-adjust: 100%;color: #202020;font-size: 16px;text-align: left;">If the first thing that comes to mind when one mentions Nevada is legalized gambling, then the second and third are spontaneous weddings and Elvis impersonators, in either order and sometimes at the same time. Gambling may be the flashier topic within the crypto and blockchain worlds, but it’s weddings where blockchain is already in use in Nevada today. More specifically, marriage certificates. Birth certificates, too, using the Ethereum protocol. If you’ve ever needed to track down documents like these for financial and personal needs, you understand how an official digital record would make life easier. And if there’s one industry larger than gambling, it’s got to be government records and paperwork.<br>
 <br>
 Read the full report from the AP, and remember that whatever happens in Vegas, is recorded permanently in an immutable ledger: <a href="https://www.apnews.com/8eb100aa9ce9418b9ad34473e732c1e3" target="_blank" style="mso-line-height-rule: exactly;-ms-text-size-adjust: 100%;-webkit-text-size-adjust: 100%;color: #42702f;font-weight: normal;text-decoration: underline;">https://www.apnews.com/8eb100aa9ce9418b9ad34473e732c1e3</a></p>

                         </td>
                     </tr>
                 </tbody></table>
                 <!--[if mso]>
                 </td>
                 <![endif]-->

                 <!--[if mso]>
                 </tr>
                 </table>
                 <![endif]-->
             </td>
         </tr>
     </tbody>
 </table><table border="0" cellpadding="0" cellspacing="0" width="100%" class="mcnDividerBlock" style="min-width: 100%;border-collapse: collapse;mso-table-lspace: 0pt;mso-table-rspace: 0pt;-ms-text-size-adjust: 100%;-webkit-text-size-adjust: 100%;table-layout: fixed !important;">
     <tbody class="mcnDividerBlockOuter">
         <tr>
             <td class="mcnDividerBlockInner" style="min-width: 100%;padding: 18px 18px 24px;mso-line-height-rule: exactly;-ms-text-size-adjust: 100%;-webkit-text-size-adjust: 100%;">
                 <table class="mcnDividerContent" border="0" cellpadding="0" cellspacing="0" width="100%" style="min-width: 100%;border-top: 2px solid #EAEAEA;border-collapse: collapse;mso-table-lspace: 0pt;mso-table-rspace: 0pt;-ms-text-size-adjust: 100%;-webkit-text-size-adjust: 100%;">
                     <tbody><tr>
                         <td style="mso-line-height-rule: exactly;-ms-text-size-adjust: 100%;-webkit-text-size-adjust: 100%;">
                             <span></span>
                         </td>
                     </tr>
                 </tbody></table>
 <!--
                 <td class="mcnDividerBlockInner" style="padding: 18px;">
                 <hr class="mcnDividerContent" style="border-bottom-color:none; border-left-color:none; border-right-color:none; border-bottom-width:0; border-left-width:0; border-right-width:0; margin-top:0; margin-right:0; margin-bottom:0; margin-left:0;" />
 -->
             </td>
         </tr>
     </tbody>
 </table><table border="0" cellpadding="0" cellspacing="0" width="100%" class="mcnTextBlock" style="min-width: 100%;border-collapse: collapse;mso-table-lspace: 0pt;mso-table-rspace: 0pt;-ms-text-size-adjust: 100%;-webkit-text-size-adjust: 100%;">
     <tbody class="mcnTextBlockOuter">
         <tr>
             <td valign="top" class="mcnTextBlockInner" style="padding-top: 9px;mso-line-height-rule: exactly;-ms-text-size-adjust: 100%;-webkit-text-size-adjust: 100%;">
                 <!--[if mso]>
                 <table align="left" border="0" cellspacing="0" cellpadding="0" width="100%" style="width:100%;">
                 <tr>
                 <![endif]-->

                 <!--[if mso]>
                 <td valign="top" width="600" style="width:600px;">
                 <![endif]-->
                 <table align="left" border="0" cellpadding="0" cellspacing="0" style="max-width: 100%;min-width: 100%;border-collapse: collapse;mso-table-lspace: 0pt;mso-table-rspace: 0pt;-ms-text-size-adjust: 100%;-webkit-text-size-adjust: 100%;" width="100%" class="mcnTextContentContainer">
                     <tbody><tr>

                         <td valign="top" class="mcnTextContent" style="padding-top: 0;padding-right: 18px;padding-bottom: 9px;padding-left: 18px;mso-line-height-rule: exactly;-ms-text-size-adjust: 100%;-webkit-text-size-adjust: 100%;word-break: break-word;color: #202020;font-family: 'Noticia Text', Georgia, 'Times New Roman', serif;font-size: 16px;line-height: 150%;text-align: left;">

                             <span style="font-size:18px"><span style="font-family:noticia text,georgia,times new roman,serif"><strong>Airdrops! </strong></span></span><strong id="docs-internal-guid-977df075-7fff-9eb1-e6e5-518295343eba"></strong>
                         </td>
                     </tr>
                 </tbody></table>
                 <!--[if mso]>
                 </td>
                 <![endif]-->

                 <!--[if mso]>
                 </tr>
                 </table>
                 <![endif]-->
             </td>
         </tr>
     </tbody>
 </table><table border="0" cellpadding="0" cellspacing="0" width="100%" class="mcnTextBlock" style="min-width: 100%;border-collapse: collapse;mso-table-lspace: 0pt;mso-table-rspace: 0pt;-ms-text-size-adjust: 100%;-webkit-text-size-adjust: 100%;">
     <tbody class="mcnTextBlockOuter">
         <tr>
             <td valign="top" class="mcnTextBlockInner" style="padding-top: 9px;mso-line-height-rule: exactly;-ms-text-size-adjust: 100%;-webkit-text-size-adjust: 100%;">
                 <!--[if mso]>
                 <table align="left" border="0" cellspacing="0" cellpadding="0" width="100%" style="width:100%;">
                 <tr>
                 <![endif]-->

                 <!--[if mso]>
                 <td valign="top" width="600" style="width:600px;">
                 <![endif]-->
                 <table align="left" border="0" cellpadding="0" cellspacing="0" style="max-width: 100%;min-width: 100%;border-collapse: collapse;mso-table-lspace: 0pt;mso-table-rspace: 0pt;-ms-text-size-adjust: 100%;-webkit-text-size-adjust: 100%;" width="100%" class="mcnTextContentContainer">
                     <tbody><tr>

                         <td valign="top" class="mcnTextContent" style="padding: 0px 18px 9px;font-family: "Noticia Text", Georgia, "Times New Roman", serif;line-height: 150%;mso-line-height-rule: exactly;-ms-text-size-adjust: 100%;-webkit-text-size-adjust: 100%;word-break: break-word;color: #202020;font-size: 16px;text-align: left;">

                             <p dir="ltr" style="font-family: "Noticia Text", Georgia, "Times New Roman", serif;line-height: 150%;margin: 10px 0;padding: 0;mso-line-height-rule: exactly;-ms-text-size-adjust: 100%;-webkit-text-size-adjust: 100%;color: #202020;font-size: 16px;text-align: left;">🤠 Taming the wild, wild west: <a href="https://cointelegraph.com/news/us-state-of-wyoming-passes-two-new-blockchain-crypto-related-bills" target="_blank" style="mso-line-height-rule: exactly;-ms-text-size-adjust: 100%;-webkit-text-size-adjust: 100%;color: #42702f;font-weight: normal;text-decoration: underline;">US State of Wyoming Passes Two New Blockchain, Crypto-Related Bills</a> (Cointelegraph)<br>
 <br>
  The 116th Congress is way headier than the 115th: <a href="https://www.forbes.com/sites/tomangell/2019/01/09/new-congressional-marijuana-bill-is-actually-numbered-h-r-420/#4c2a69912e60" target="_blank" style="mso-line-height-rule: exactly;-ms-text-size-adjust: 100%;-webkit-text-size-adjust: 100%;color: #42702f;font-weight: normal;text-decoration: underline;">New Congressional Marijuana Bill Is Actually Numbered H.R. 420 </a>(Forbes)<br>
 <br>
  For your designer friend that has everything, but not a USB drive with a decorative dead fish inside: <a href="https://japansauce.net/2019/01/06/japanese-person-develops-usb-that-uses-a-small-fish-brain-as-storage/" target="_blank" style="mso-line-height-rule: exactly;-ms-text-size-adjust: 100%;-webkit-text-size-adjust: 100%;color: #42702f;font-weight: normal;text-decoration: underline;">https://japansauce.net/2019/01/06/japanese-person-develops-usb-that-uses-a-small-fish-brain-as-storage</a> (JapanSauce)</p>

                         </td>
                     </tr>
                 </tbody></table>
                 <!--[if mso]>
                 </td>
                 <![endif]-->

                 <!--[if mso]>
                 </tr>
                 </table>
                 <![endif]-->
             </td>
         </tr>
     </tbody>
 </table><table border="0" cellpadding="0" cellspacing="0" width="100%" class="mcnDividerBlock" style="min-width: 100%;border-collapse: collapse;mso-table-lspace: 0pt;mso-table-rspace: 0pt;-ms-text-size-adjust: 100%;-webkit-text-size-adjust: 100%;table-layout: fixed !important;">
     <tbody class="mcnDividerBlockOuter">
         <tr>
             <td class="mcnDividerBlockInner" style="min-width: 100%;padding: 18px 18px 24px;mso-line-height-rule: exactly;-ms-text-size-adjust: 100%;-webkit-text-size-adjust: 100%;">
                 <table class="mcnDividerContent" border="0" cellpadding="0" cellspacing="0" width="100%" style="min-width: 100%;border-top: 2px solid #EAEAEA;border-collapse: collapse;mso-table-lspace: 0pt;mso-table-rspace: 0pt;-ms-text-size-adjust: 100%;-webkit-text-size-adjust: 100%;">
                     <tbody><tr>
                         <td style="mso-line-height-rule: exactly;-ms-text-size-adjust: 100%;-webkit-text-size-adjust: 100%;">
                             <span></span>
                         </td>
                     </tr>
                 </tbody></table>
 <!--
                 <td class="mcnDividerBlockInner" style="padding: 18px;">
                 <hr class="mcnDividerContent" style="border-bottom-color:none; border-left-color:none; border-right-color:none; border-bottom-width:0; border-left-width:0; border-right-width:0; margin-top:0; margin-right:0; margin-bottom:0; margin-left:0;" />
 -->
             </td>
         </tr>
     </tbody>
 </table><table border="0" cellpadding="0" cellspacing="0" width="100%" class="mcnTextBlock" style="min-width: 100%;border-collapse: collapse;mso-table-lspace: 0pt;mso-table-rspace: 0pt;-ms-text-size-adjust: 100%;-webkit-text-size-adjust: 100%;">
     <tbody class="mcnTextBlockOuter">
         <tr>
             <td valign="top" class="mcnTextBlockInner" style="padding-top: 9px;mso-line-height-rule: exactly;-ms-text-size-adjust: 100%;-webkit-text-size-adjust: 100%;">
                 <!--[if mso]>
                 <table align="left" border="0" cellspacing="0" cellpadding="0" width="100%" style="width:100%;">
                 <tr>
                 <![endif]-->

                 <!--[if mso]>
                 <td valign="top" width="600" style="width:600px;">
                 <![endif]-->
                 <table align="left" border="0" cellpadding="0" cellspacing="0" style="max-width: 100%;min-width: 100%;border-collapse: collapse;mso-table-lspace: 0pt;mso-table-rspace: 0pt;-ms-text-size-adjust: 100%;-webkit-text-size-adjust: 100%;" width="100%" class="mcnTextContentContainer">
                     <tbody><tr>

                         <td valign="top" class="mcnTextContent" style="padding-top: 0;padding-right: 18px;padding-bottom: 9px;padding-left: 18px;mso-line-height-rule: exactly;-ms-text-size-adjust: 100%;-webkit-text-size-adjust: 100%;word-break: break-word;color: #202020;font-family: 'Noticia Text', Georgia, 'Times New Roman', serif;font-size: 16px;line-height: 150%;text-align: left;">

                             <span style="font-size:18px"><span style="font-family:noticia text,georgia,times new roman,serif"><strong>Cool $#i®T of the week</strong></span></span>
                         </td>
                     </tr>
                 </tbody></table>
                 <!--[if mso]>
                 </td>
                 <![endif]-->

                 <!--[if mso]>
                 </tr>
                 </table>
                 <![endif]-->
             </td>
         </tr>
     </tbody>
 </table><table border="0" cellpadding="0" cellspacing="0" width="100%" class="mcnImageBlock" style="min-width: 100%;border-collapse: collapse;mso-table-lspace: 0pt;mso-table-rspace: 0pt;-ms-text-size-adjust: 100%;-webkit-text-size-adjust: 100%;">
     <tbody class="mcnImageBlockOuter">
             <tr>
                 <td valign="top" style="padding: 9px;mso-line-height-rule: exactly;-ms-text-size-adjust: 100%;-webkit-text-size-adjust: 100%;" class="mcnImageBlockInner">
                     <table align="left" width="100%" border="0" cellpadding="0" cellspacing="0" class="mcnImageContentContainer" style="min-width: 100%;border-collapse: collapse;mso-table-lspace: 0pt;mso-table-rspace: 0pt;-ms-text-size-adjust: 100%;-webkit-text-size-adjust: 100%;">
                         <tbody><tr>
                             <td class="mcnImageContent" valign="top" style="padding-right: 9px;padding-left: 9px;padding-top: 0;padding-bottom: 0;text-align: center;mso-line-height-rule: exactly;-ms-text-size-adjust: 100%;-webkit-text-size-adjust: 100%;">


                                         <img align="center" alt="" src="https://gallery.mailchimp.com/adee9c5618cae148bdcf8f762/images/577c52dc-dea3-48b0-8fa6-b01ec1de44a3.jpg" width="480" style="max-width: 480px;padding-bottom: 0;display: inline !important;vertical-align: bottom;border: 0;height: auto;outline: none;text-decoration: none;-ms-interpolation-mode: bicubic;" class="mcnImage">


                             </td>
                         </tr>
                     </tbody></table>
                 </td>
             </tr>
     </tbody>
 </table><table border="0" cellpadding="0" cellspacing="0" width="100%" class="mcnTextBlock" style="min-width: 100%;border-collapse: collapse;mso-table-lspace: 0pt;mso-table-rspace: 0pt;-ms-text-size-adjust: 100%;-webkit-text-size-adjust: 100%;">
     <tbody class="mcnTextBlockOuter">
         <tr>
             <td valign="top" class="mcnTextBlockInner" style="padding-top: 9px;mso-line-height-rule: exactly;-ms-text-size-adjust: 100%;-webkit-text-size-adjust: 100%;">
                 <!--[if mso]>
                 <table align="left" border="0" cellspacing="0" cellpadding="0" width="100%" style="width:100%;">
                 <tr>
                 <![endif]-->

                 <!--[if mso]>
                 <td valign="top" width="600" style="width:600px;">
                 <![endif]-->
                 <table align="left" border="0" cellpadding="0" cellspacing="0" style="max-width: 100%;min-width: 100%;border-collapse: collapse;mso-table-lspace: 0pt;mso-table-rspace: 0pt;-ms-text-size-adjust: 100%;-webkit-text-size-adjust: 100%;" width="100%" class="mcnTextContentContainer">
                     <tbody><tr>

                         <td valign="top" class="mcnTextContent" style="padding-top: 0;padding-right: 18px;padding-bottom: 9px;padding-left: 18px;mso-line-height-rule: exactly;-ms-text-size-adjust: 100%;-webkit-text-size-adjust: 100%;word-break: break-word;color: #202020;font-family: 'Noticia Text', Georgia, 'Times New Roman', serif;font-size: 16px;line-height: 150%;text-align: left;">

                             Hat tip to High Snobiety for this one, a <a href="https://www.instagram.com/mattinglypackman/" target="_blank" style="mso-line-height-rule: exactly;-ms-text-size-adjust: 100%;-webkit-text-size-adjust: 100%;color: #42702f;font-weight: normal;text-decoration: underline;">Mattingly Packman</a> mashup of the famed Lithuanian Olympic Grateful Dead tee (yes, <a href="https://www.sportshistoryweekly.com/stories/lithuania-basketball-olympics-nba-grateful-dead,622" target="_blank" style="mso-line-height-rule: exactly;-ms-text-size-adjust: 100%;-webkit-text-size-adjust: 100%;color: #42702f;font-weight: normal;text-decoration: underline;">you read that correctly</a>), and the Ralph Lauren Polo Bear that was huge in 2018. We copped one, did you?<br>
 <br>
 <a href="https://www.highsnobiety.com/p/grateful-dead-polo-bear-mashup-tee/" target="_blank" style="mso-line-height-rule: exactly;-ms-text-size-adjust: 100%;-webkit-text-size-adjust: 100%;color: #42702f;font-weight: normal;text-decoration: underline;">https://www.highsnobiety.com/p/grateful-dead-polo-bear-mashup-tee/</a>
                         </td>
                     </tr>
                 </tbody></table>
                 <!--[if mso]>
                 </td>
                 <![endif]-->

                 <!--[if mso]>
                 </tr>
                 </table>
                 <![endif]-->
             </td>
         </tr>
     </tbody>
 </table><table border="0" cellpadding="0" cellspacing="0" width="100%" class="mcnDividerBlock" style="min-width: 100%;border-collapse: collapse;mso-table-lspace: 0pt;mso-table-rspace: 0pt;-ms-text-size-adjust: 100%;-webkit-text-size-adjust: 100%;table-layout: fixed !important;">
     <tbody class="mcnDividerBlockOuter">
         <tr>
             <td class="mcnDividerBlockInner" style="min-width: 100%;padding: 18px;mso-line-height-rule: exactly;-ms-text-size-adjust: 100%;-webkit-text-size-adjust: 100%;">
                 <table class="mcnDividerContent" border="0" cellpadding="0" cellspacing="0" width="100%" style="min-width: 100%;border-top: 2px solid #EAEAEA;border-collapse: collapse;mso-table-lspace: 0pt;mso-table-rspace: 0pt;-ms-text-size-adjust: 100%;-webkit-text-size-adjust: 100%;">
                     <tbody><tr>
                         <td style="mso-line-height-rule: exactly;-ms-text-size-adjust: 100%;-webkit-text-size-adjust: 100%;">
                             <span></span>
                         </td>
                     </tr>
                 </tbody></table>
 <!--
                 <td class="mcnDividerBlockInner" style="padding: 18px;">
                 <hr class="mcnDividerContent" style="border-bottom-color:none; border-left-color:none; border-right-color:none; border-bottom-width:0; border-left-width:0; border-right-width:0; margin-top:0; margin-right:0; margin-bottom:0; margin-left:0;" />
 -->
             </td>
         </tr>
     </tbody>
 </table><table border="0" cellpadding="0" cellspacing="0" width="100%" class="mcnTextBlock" style="min-width: 100%;border-collapse: collapse;mso-table-lspace: 0pt;mso-table-rspace: 0pt;-ms-text-size-adjust: 100%;-webkit-text-size-adjust: 100%;">
     <tbody class="mcnTextBlockOuter">
         <tr>
             <td valign="top" class="mcnTextBlockInner" style="padding-top: 9px;mso-line-height-rule: exactly;-ms-text-size-adjust: 100%;-webkit-text-size-adjust: 100%;">
                 <!--[if mso]>
                 <table align="left" border="0" cellspacing="0" cellpadding="0" width="100%" style="width:100%;">
                 <tr>
                 <![endif]-->

                 <!--[if mso]>
                 <td valign="top" width="600" style="width:600px;">
                 <![endif]-->
                 <table align="left" border="0" cellpadding="0" cellspacing="0" style="max-width: 100%;min-width: 100%;border-collapse: collapse;mso-table-lspace: 0pt;mso-table-rspace: 0pt;-ms-text-size-adjust: 100%;-webkit-text-size-adjust: 100%;" width="100%" class="mcnTextContentContainer">
                     <tbody><tr>

                         <td valign="top" class="mcnTextContent" style="padding-top: 0;padding-right: 18px;padding-bottom: 9px;padding-left: 18px;mso-line-height-rule: exactly;-ms-text-size-adjust: 100%;-webkit-text-size-adjust: 100%;word-break: break-word;color: #202020;font-family: 'Noticia Text', Georgia, 'Times New Roman', serif;font-size: 16px;line-height: 150%;text-align: left;">

                             <div style="text-align: center;"><strong><span style="font-size:24px">NEXT WEEK WE ARE GIVING AWAY BITCOIN. NO REALLY WE MEAN IT THIS TIME.</span></strong><br>
 <br>
 Yeah, we didn't get our $#^t together in time this week. We'll get it right next time, pinky swear.<br>
 <br>
 <img data-file-id="4352965" height="275" src="https://gallery.mailchimp.com/adee9c5618cae148bdcf8f762/images/3187cd71-aeea-4bd9-9246-02ed24c6d450.gif" style="border: 0px;width: 500px;height: 275px;margin: 0px;outline: none;text-decoration: none;-ms-interpolation-mode: bicubic;" width="500"></div>

 <div style="text-align: center;"><br>
 Tell your friends to sign up so they don't miss the fun: <br>
 <a href="http://eepurl.com/gdbiTD" target="_blank" style="mso-line-height-rule: exactly;-ms-text-size-adjust: 100%;-webkit-text-size-adjust: 100%;color: #42702f;font-weight: normal;text-decoration: underline;">http://eepurl.com/gdbiTD</a><br>
 <br>
 Tips and leads for next week's edition? Email them to us at <a href="mailto:hi@satoshiscloset.com?subject=Newsletter%20tips%20and%20leads" target="_blank" style="mso-line-height-rule: exactly;-ms-text-size-adjust: 100%;-webkit-text-size-adjust: 100%;color: #42702f;font-weight: normal;text-decoration: underline;">hi@satoshiscloset.com</a>.</div>

                         </td>
                     </tr>
                 </tbody></table>
                 <!--[if mso]>
                 </td>
                 <![endif]-->

                 <!--[if mso]>
                 </tr>
                 </table>
                 <![endif]-->
             </td>
         </tr>
     </tbody>
 </table></td>
                             </tr>

     </tbody>
 </table></td>
                             </tr>
                         </table>
                     </td>
                 </tr>
             </table>
         </center>
     </body>
 </html>`
 const POST3 = `<!doctype html>
 <html xmlns="http://www.w3.org/1999/xhtml" xmlns:v="urn:schemas-microsoft-com:vml" xmlns:o="urn:schemas-microsoft-com:office:office">
     <head>
         <!-- NAME: 1 COLUMN -->
         <!--[if gte mso 15]>
         <xml>
             <o:OfficeDocumentSettings>
             <o:AllowPNG/>
             <o:PixelsPerInch>96</o:PixelsPerInch>
             </o:OfficeDocumentSettings>
         </xml>
         <![endif]-->
         <meta charset="UTF-8">
         <meta http-equiv="X-UA-Compatible" content="IE=edge">
         <meta name="viewport" content="width=device-width, initial-scale=1">
         <title>*|MC:SUBJECT|*</title>

     <style type="text/css">
         p{
             margin:10px 0;
             padding:0;
         }
         table{
             border-collapse:collapse;
         }
         h1,h2,h3,h4,h5,h6{
             display:block;
             margin:0;
             padding:0;
         }
         img,a img{
             border:0;
             height:auto;
             outline:none;
             text-decoration:none;
         }
         body,#bodyTable,#bodyCell{
             height:100%;
             margin:0;
             padding:0;
             width:100%;
         }
         .mcnPreviewText{
             display:none !important;
         }
         #outlook a{
             padding:0;
         }
         img{
             -ms-interpolation-mode:bicubic;
         }
         table{
             mso-table-lspace:0pt;
             mso-table-rspace:0pt;
         }
         .ReadMsgBody{
             width:100%;
         }
         .ExternalClass{
             width:100%;
         }
         p,a,li,td,blockquote{
             mso-line-height-rule:exactly;
         }
         a[href^=tel],a[href^=sms]{
             color:inherit;
             cursor:default;
             text-decoration:none;
         }
         p,a,li,td,body,table,blockquote{
             -ms-text-size-adjust:100%;
             -webkit-text-size-adjust:100%;
         }
         .ExternalClass,.ExternalClass p,.ExternalClass td,.ExternalClass div,.ExternalClass span,.ExternalClass font{
             line-height:100%;
         }
         a[x-apple-data-detectors]{
             color:inherit !important;
             text-decoration:none !important;
             font-size:inherit !important;
             font-family:inherit !important;
             font-weight:inherit !important;
             line-height:inherit !important;
         }
         #bodyCell{
             padding:10px;
         }
         .templateContainer{
             max-width:600px !important;
         }
         a.mcnButton{
             display:block;
         }
         .mcnImage,.mcnRetinaImage{
             vertical-align:bottom;
         }
         .mcnTextContent{
             word-break:break-word;
         }
         .mcnTextContent img{
             height:auto !important;
         }
         .mcnDividerBlock{
             table-layout:fixed !important;
         }
         body,#bodyTable{
             background-color:#ffffff;
         }
         #bodyCell{
             border-top:0;
         }
         .templateContainer{
             border:0;
         }
         h1{
             color:#202020;
             font-family:Helvetica;
             font-size:26px;
             font-style:normal;
             font-weight:bold;
             line-height:125%;
             letter-spacing:normal;
             text-align:left;
         }
         h2{
             color:#202020;
             font-family:Helvetica;
             font-size:22px;
             font-style:normal;
             font-weight:bold;
             line-height:125%;
             letter-spacing:normal;
             text-align:left;
         }
         h3{
             color:#202020;
             font-family:Helvetica;
             font-size:20px;
             font-style:normal;
             font-weight:bold;
             line-height:125%;
             letter-spacing:normal;
             text-align:left;
         }
         h4{
             color:#202020;
             font-family:Helvetica;
             font-size:18px;
             font-style:normal;
             font-weight:bold;
             line-height:125%;
             letter-spacing:normal;
             text-align:left;
         }
         #templatePreheader{
             background-color:#FAFAFA;
             background-image:none;
             background-repeat:no-repeat;
             background-position:center;
             background-size:cover;
             border-top:0;
             border-bottom:0;
             padding-top:9px;
             padding-bottom:9px;
         }
         #templatePreheader .mcnTextContent,#templatePreheader .mcnTextContent p{
             color:#656565;
             font-family:Helvetica;
             font-size:12px;
             line-height:150%;
             text-align:left;
         }
         #templatePreheader .mcnTextContent a,#templatePreheader .mcnTextContent p a{
             color:#656565;
             font-weight:normal;
             text-decoration:underline;
         }
         #templateHeader{
             background-color:#ffffff;
             background-image:none;
             background-repeat:no-repeat;
             background-position:center;
             background-size:cover;
             border-top:0;
             border-bottom:0;
             padding-top:18px;
             padding-bottom:0px;
         }
         #templateHeader .mcnTextContent,#templateHeader .mcnTextContent p{
             color:#202020;
             font-family:Helvetica;
             font-size:12px;
             line-height:125%;
             text-align:left;
         }
         #templateHeader .mcnTextContent a,#templateHeader .mcnTextContent p a{
             color:#007C89;
             font-weight:normal;
             text-decoration:underline;
         }
         #templateBody{
             background-color:#ffffff;
             background-image:none;
             background-repeat:no-repeat;
             background-position:center;
             background-size:cover;
             border-top:0;
             border-bottom:2px solid #EAEAEA;
             padding-top:0;
             padding-bottom:9px;
         }
         #templateBody .mcnTextContent,#templateBody .mcnTextContent p{
             color:#202020;
             font-family:'Noticia Text', Georgia, 'Times New Roman', serif;
             font-size:16px;
             line-height:150%;
             text-align:left;
         }
         #templateBody .mcnTextContent a,#templateBody .mcnTextContent p a{
             color:#42702f;
             font-weight:normal;
             text-decoration:underline;
         }
         #templateFooter{
             background-color:#ffffff;
             background-image:none;
             background-repeat:no-repeat;
             background-position:center;
             background-size:cover;
             border-top:0;
             border-bottom:0;
             padding-top:9px;
             padding-bottom:9px;
         }
         #templateFooter .mcnTextContent,#templateFooter .mcnTextContent p{
             color:#656565;
             font-family:Helvetica;
             font-size:12px;
             line-height:150%;
             text-align:center;
         }
         #templateFooter .mcnTextContent a,#templateFooter .mcnTextContent p a{
             color:#656565;
             font-weight:normal;
             text-decoration:underline;
         }
     @media only screen and (min-width:768px){
         .templateContainer{
             width:600px !important;
         }

 }   @media only screen and (max-width: 480px){
         body,table,td,p,a,li,blockquote{
             -webkit-text-size-adjust:none !important;
         }

 }   @media only screen and (max-width: 480px){
         body{
             width:100% !important;
             min-width:100% !important;
         }

 }   @media only screen and (max-width: 480px){
         #bodyCell{
             padding-top:10px !important;
         }

 }   @media only screen and (max-width: 480px){
         .mcnRetinaImage{
             max-width:100% !important;
         }

 }   @media only screen and (max-width: 480px){
         .mcnImage{
             width:100% !important;
         }

 }   @media only screen and (max-width: 480px){
         .mcnCartContainer,.mcnCaptionTopContent,.mcnRecContentContainer,.mcnCaptionBottomContent,.mcnTextContentContainer,.mcnBoxedTextContentContainer,.mcnImageGroupContentContainer,.mcnCaptionLeftTextContentContainer,.mcnCaptionRightTextContentContainer,.mcnCaptionLeftImageContentContainer,.mcnCaptionRightImageContentContainer,.mcnImageCardLeftTextContentContainer,.mcnImageCardRightTextContentContainer,.mcnImageCardLeftImageContentContainer,.mcnImageCardRightImageContentContainer{
             max-width:100% !important;
             width:100% !important;
         }

 }   @media only screen and (max-width: 480px){
         .mcnBoxedTextContentContainer{
             min-width:100% !important;
         }

 }   @media only screen and (max-width: 480px){
         .mcnImageGroupContent{
             padding:9px !important;
         }

 }   @media only screen and (max-width: 480px){
         .mcnCaptionLeftContentOuter .mcnTextContent,.mcnCaptionRightContentOuter .mcnTextContent{
             padding-top:9px !important;
         }

 }   @media only screen and (max-width: 480px){
         .mcnImageCardTopImageContent,.mcnCaptionBottomContent:last-child .mcnCaptionBottomImageContent,.mcnCaptionBlockInner .mcnCaptionTopContent:last-child .mcnTextContent{
             padding-top:18px !important;
         }

 }   @media only screen and (max-width: 480px){
         .mcnImageCardBottomImageContent{
             padding-bottom:9px !important;
         }

 }   @media only screen and (max-width: 480px){
         .mcnImageGroupBlockInner{
             padding-top:0 !important;
             padding-bottom:0 !important;
         }

 }   @media only screen and (max-width: 480px){
         .mcnImageGroupBlockOuter{
             padding-top:9px !important;
             padding-bottom:9px !important;
         }

 }   @media only screen and (max-width: 480px){
         .mcnTextContent,.mcnBoxedTextContentColumn{
             padding-right:18px !important;
             padding-left:18px !important;
         }

 }   @media only screen and (max-width: 480px){
         .mcnImageCardLeftImageContent,.mcnImageCardRightImageContent{
             padding-right:18px !important;
             padding-bottom:0 !important;
             padding-left:18px !important;
         }

 }   @media only screen and (max-width: 480px){
         .mcpreview-image-uploader{
             display:none !important;
             width:100% !important;
         }

 }   @media only screen and (max-width: 480px){
         h1{
             font-size:22px !important;
             line-height:125% !important;
         }

 }   @media only screen and (max-width: 480px){
         h2{
             font-size:20px !important;
             line-height:125% !important;
         }

 }   @media only screen and (max-width: 480px){
         h3{
             font-size:18px !important;
             line-height:125% !important;
         }

 }   @media only screen and (max-width: 480px){
         h4{
             font-size:16px !important;
             line-height:150% !important;
         }

 }   @media only screen and (max-width: 480px){
         .mcnBoxedTextContentContainer .mcnTextContent,.mcnBoxedTextContentContainer .mcnTextContent p{
             font-size:14px !important;
             line-height:150% !important;
         }

 }   @media only screen and (max-width: 480px){
         #templatePreheader{
             display:block !important;
         }

 }   @media only screen and (max-width: 480px){
         #templatePreheader .mcnTextContent,#templatePreheader .mcnTextContent p{
             font-size:14px !important;
             line-height:150% !important;
         }

 }   @media only screen and (max-width: 480px){
         #templateHeader .mcnTextContent,#templateHeader .mcnTextContent p{
             font-size:16px !important;
             line-height:150% !important;
         }

 }   @media only screen and (max-width: 480px){
         #templateBody .mcnTextContent,#templateBody .mcnTextContent p{
             font-size:16px !important;
             line-height:150% !important;
         }

 }   @media only screen and (max-width: 480px){
         #templateFooter .mcnTextContent,#templateFooter .mcnTextContent p{
             font-size:14px !important;
             line-height:150% !important;
         }

 }</style><!--[if !mso]><!--><link href="https://fonts.googleapis.com/css?family=Noticia+Text:400,400i,700,700i" rel="stylesheet"><!--<![endif]--></head>
     <body style="height: 100%;margin: 0;padding: 0;width: 100%;-ms-text-size-adjust: 100%;-webkit-text-size-adjust: 100%;background-color: #ffffff;">
         <!--*|IF:MC_PREVIEW_TEXT|*-->
         <!--[if !gte mso 9]><!----><span class="mcnPreviewText" style="display:none; font-size:0px; line-height:0px; max-height:0px; max-width:0px; opacity:0; overflow:hidden; visibility:hidden; mso-hide:all;">*|MC_PREVIEW_TEXT|*</span><!--<![endif]-->
         <!--*|END:IF|*-->
         <center>
             <table align="center" border="0" cellpadding="0" cellspacing="0" height="100%" width="100%" id="bodyTable" style="border-collapse: collapse;mso-table-lspace: 0pt;mso-table-rspace: 0pt;-ms-text-size-adjust: 100%;-webkit-text-size-adjust: 100%;height: 100%;margin: 0;padding: 0;width: 100%;background-color: #ffffff;">
                 <tr>
                     <td align="center" valign="top" id="bodyCell" style="mso-line-height-rule: exactly;-ms-text-size-adjust: 100%;-webkit-text-size-adjust: 100%;height: 100%;margin: 0;padding: 10px;width: 100%;border-top: 0;">
                         <!-- BEGIN TEMPLATE // -->
                         <!--[if (gte mso 9)|(IE)]>
                         <table align="center" border="0" cellspacing="0" cellpadding="0" width="600" style="width:600px;">
                         <tr>
                         <td align="center" valign="top" width="600" style="width:600px;">
                         <![endif]-->
                         <table border="0" cellpadding="0" cellspacing="0" width="100%" class="templateContainer" style="border-collapse: collapse;mso-table-lspace: 0pt;mso-table-rspace: 0pt;-ms-text-size-adjust: 100%;-webkit-text-size-adjust: 100%;border: 0;max-width: 600px !important;">
                             <tr>
                                 <td valign="top" id="templateHeader" style="background:#ffffff none no-repeat center/cover;mso-line-height-rule: exactly;-ms-text-size-adjust: 100%;-webkit-text-size-adjust: 100%;background-color: #ffffff;background-image: none;background-repeat: no-repeat;background-position: center;background-size: cover;border-top: 0;border-bottom: 0;padding-top: 18px;padding-bottom: 0px;"><table border="0" cellpadding="0" cellspacing="0" width="100%" class="mcnImageBlock" style="min-width: 100%;border-collapse: collapse;mso-table-lspace: 0pt;mso-table-rspace: 0pt;-ms-text-size-adjust: 100%;-webkit-text-size-adjust: 100%;">
     <tbody class="mcnImageBlockOuter">
             <tr>
                 <td valign="top" style="padding: 9px;mso-line-height-rule: exactly;-ms-text-size-adjust: 100%;-webkit-text-size-adjust: 100%;" class="mcnImageBlockInner">
                     <table align="left" width="100%" border="0" cellpadding="0" cellspacing="0" class="mcnImageContentContainer" style="min-width: 100%;border-collapse: collapse;mso-table-lspace: 0pt;mso-table-rspace: 0pt;-ms-text-size-adjust: 100%;-webkit-text-size-adjust: 100%;">
                         <tbody><tr>
                             <td class="mcnImageContent" valign="top" style="padding-right: 9px;padding-left: 9px;padding-top: 0;padding-bottom: 0;text-align: center;mso-line-height-rule: exactly;-ms-text-size-adjust: 100%;-webkit-text-size-adjust: 100%;">


                                         <img align="center" alt="" src="https://gallery.mailchimp.com/adee9c5618cae148bdcf8f762/images/60cd81ab-fa1e-43bc-8036-c28a67f88bc1.jpg" width="564" style="max-width: 960px;padding-bottom: 0;display: inline !important;vertical-align: bottom;border: 0;height: auto;outline: none;text-decoration: none;-ms-interpolation-mode: bicubic;" class="mcnImage">


                             </td>
                         </tr>
                     </tbody></table>
                 </td>
             </tr>
     </tbody>
 </table></td>
                             </tr>
                             <tr>
                                 <td valign="top" id="templateBody" style="background:#ffffff none no-repeat center/cover;mso-line-height-rule: exactly;-ms-text-size-adjust: 100%;-webkit-text-size-adjust: 100%;background-color: #ffffff;background-image: none;background-repeat: no-repeat;background-position: center;background-size: cover;border-top: 0;border-bottom: 2px solid #EAEAEA;padding-top: 0;padding-bottom: 9px;"><table border="0" cellpadding="0" cellspacing="0" width="100%" class="mcnDividerBlock" style="min-width: 100%;border-collapse: collapse;mso-table-lspace: 0pt;mso-table-rspace: 0pt;-ms-text-size-adjust: 100%;-webkit-text-size-adjust: 100%;table-layout: fixed !important;">
     <tbody class="mcnDividerBlockOuter">
         <tr>
             <td class="mcnDividerBlockInner" style="min-width: 100%;padding: 8px 18px 0px;mso-line-height-rule: exactly;-ms-text-size-adjust: 100%;-webkit-text-size-adjust: 100%;">
                 <table class="mcnDividerContent" border="0" cellpadding="0" cellspacing="0" width="100%" style="min-width: 100%;border-top: 2px solid #EAEAEA;border-collapse: collapse;mso-table-lspace: 0pt;mso-table-rspace: 0pt;-ms-text-size-adjust: 100%;-webkit-text-size-adjust: 100%;">
                     <tbody><tr>
                         <td style="mso-line-height-rule: exactly;-ms-text-size-adjust: 100%;-webkit-text-size-adjust: 100%;">
                             <span></span>
                         </td>
                     </tr>
                 </tbody></table>
 <!--
                 <td class="mcnDividerBlockInner" style="padding: 18px;">
                 <hr class="mcnDividerContent" style="border-bottom-color:none; border-left-color:none; border-right-color:none; border-bottom-width:0; border-left-width:0; border-right-width:0; margin-top:0; margin-right:0; margin-bottom:0; margin-left:0;" />
 -->
             </td>
         </tr>
     </tbody>
 </table><table border="0" cellpadding="0" cellspacing="0" width="100%" class="mcnTextBlock" style="min-width: 100%;border-collapse: collapse;mso-table-lspace: 0pt;mso-table-rspace: 0pt;-ms-text-size-adjust: 100%;-webkit-text-size-adjust: 100%;">
     <tbody class="mcnTextBlockOuter">
         <tr>
             <td valign="top" class="mcnTextBlockInner" style="padding-top: 9px;mso-line-height-rule: exactly;-ms-text-size-adjust: 100%;-webkit-text-size-adjust: 100%;">
                 <!--[if mso]>
                 <table align="left" border="0" cellspacing="0" cellpadding="0" width="100%" style="width:100%;">
                 <tr>
                 <![endif]-->

                 <!--[if mso]>
                 <td valign="top" width="600" style="width:600px;">
                 <![endif]-->
                 <table align="left" border="0" cellpadding="0" cellspacing="0" style="max-width: 100%;min-width: 100%;border-collapse: collapse;mso-table-lspace: 0pt;mso-table-rspace: 0pt;-ms-text-size-adjust: 100%;-webkit-text-size-adjust: 100%;" width="100%" class="mcnTextContentContainer">
                     <tbody><tr>

                         <td valign="top" class="mcnTextContent" style="padding-top: 0;padding-right: 18px;padding-bottom: 9px;padding-left: 18px;mso-line-height-rule: exactly;-ms-text-size-adjust: 100%;-webkit-text-size-adjust: 100%;word-break: break-word;color: #202020;font-family: 'Noticia Text', Georgia, 'Times New Roman', serif;font-size: 16px;line-height: 150%;text-align: left;">

                             <div style="text-align: left;"><strong><span style="font-size:12px"><span style="font-family:noticia text,georgia,times new roman,serif">Crypto-puzzle-art, our favorite! • Bitcoin giveaway fo' realz • Rebuilding the 'Net</span></span></strong></div>

                         </td>
                     </tr>
                 </tbody></table>
                 <!--[if mso]>
                 </td>
                 <![endif]-->

                 <!--[if mso]>
                 </tr>
                 </table>
                 <![endif]-->
             </td>
         </tr>
     </tbody>
 </table><table border="0" cellpadding="0" cellspacing="0" width="100%" class="mcnDividerBlock" style="min-width: 100%;border-collapse: collapse;mso-table-lspace: 0pt;mso-table-rspace: 0pt;-ms-text-size-adjust: 100%;-webkit-text-size-adjust: 100%;table-layout: fixed !important;">
     <tbody class="mcnDividerBlockOuter">
         <tr>
             <td class="mcnDividerBlockInner" style="min-width: 100%;padding: 2px 18px 9px;mso-line-height-rule: exactly;-ms-text-size-adjust: 100%;-webkit-text-size-adjust: 100%;">
                 <table class="mcnDividerContent" border="0" cellpadding="0" cellspacing="0" width="100%" style="min-width: 100%;border-top: 2px solid #EAEAEA;border-collapse: collapse;mso-table-lspace: 0pt;mso-table-rspace: 0pt;-ms-text-size-adjust: 100%;-webkit-text-size-adjust: 100%;">
                     <tbody><tr>
                         <td style="mso-line-height-rule: exactly;-ms-text-size-adjust: 100%;-webkit-text-size-adjust: 100%;">
                             <span></span>
                         </td>
                     </tr>
                 </tbody></table>
 <!--
                 <td class="mcnDividerBlockInner" style="padding: 18px;">
                 <hr class="mcnDividerContent" style="border-bottom-color:none; border-left-color:none; border-right-color:none; border-bottom-width:0; border-left-width:0; border-right-width:0; margin-top:0; margin-right:0; margin-bottom:0; margin-left:0;" />
 -->
             </td>
         </tr>
     </tbody>
 </table><table border="0" cellpadding="0" cellspacing="0" width="100%" class="mcnTextBlock" style="min-width: 100%;border-collapse: collapse;mso-table-lspace: 0pt;mso-table-rspace: 0pt;-ms-text-size-adjust: 100%;-webkit-text-size-adjust: 100%;">
     <tbody class="mcnTextBlockOuter">
         <tr>
             <td valign="top" class="mcnTextBlockInner" style="padding-top: 9px;mso-line-height-rule: exactly;-ms-text-size-adjust: 100%;-webkit-text-size-adjust: 100%;">
                 <!--[if mso]>
                 <table align="left" border="0" cellspacing="0" cellpadding="0" width="100%" style="width:100%;">
                 <tr>
                 <![endif]-->

                 <!--[if mso]>
                 <td valign="top" width="600" style="width:600px;">
                 <![endif]-->
                 <table align="left" border="0" cellpadding="0" cellspacing="0" style="max-width: 100%;min-width: 100%;border-collapse: collapse;mso-table-lspace: 0pt;mso-table-rspace: 0pt;-ms-text-size-adjust: 100%;-webkit-text-size-adjust: 100%;" width="100%" class="mcnTextContentContainer">
                     <tbody><tr>

                         <td valign="top" class="mcnTextContent" style="padding: 0px 18px 9px;font-family: "Noticia Text", Georgia, "Times New Roman", serif;line-height: 150%;mso-line-height-rule: exactly;-ms-text-size-adjust: 100%;-webkit-text-size-adjust: 100%;word-break: break-word;color: #202020;font-size: 16px;text-align: left;">

                             <p dir="ltr" style="font-family: "Noticia Text", Georgia, "Times New Roman", serif;line-height: 150%;margin: 10px 0;padding: 0;mso-line-height-rule: exactly;-ms-text-size-adjust: 100%;-webkit-text-size-adjust: 100%;color: #202020;font-size: 16px;text-align: left;"><span style="font-size:18px"><strong>Liberté, égalité, cryptographé</strong></span><br>
 <br>
 <a href="https://mailchi.mp/84e5713f11bb/001-01-2019-debut-issue-2254377" target="_blank" style="mso-line-height-rule: exactly;-ms-text-size-adjust: 100%;-webkit-text-size-adjust: 100%;color: #42702f;font-weight: normal;text-decoration: underline;">Last week</a> we asked if the Crypto Revolution needs rules, viewed through the lens of Gemini’s New York City ad blitz featuring imagery from the American Revolution. We suggested that successful marketplaces do need rules, but revolutions are messy business. Heck, one of the most <a href="https://en.wikipedia.org/wiki/Liberty_Leading_the_People#/media/File:Eug%C3%A8ne_Delacroix_-_Le_28_Juillet._La_Libert%C3%A9_guidant_le_peuple.jpg" target="_blank" style="mso-line-height-rule: exactly;-ms-text-size-adjust: 100%;-webkit-text-size-adjust: 100%;color: #42702f;font-weight: normal;text-decoration: underline;">famous portrayals of the French Revolution</a> depicts a young kid wielding dual pistols, a topless lady Liberté, and a dead guy who for some reason does not have on any pants. </p>

                         </td>
                     </tr>
                 </tbody></table>
                 <!--[if mso]>
                 </td>
                 <![endif]-->

                 <!--[if mso]>
                 </tr>
                 </table>
                 <![endif]-->
             </td>
         </tr>
     </tbody>
 </table><table border="0" cellpadding="0" cellspacing="0" width="100%" class="mcnImageBlock" style="min-width: 100%;border-collapse: collapse;mso-table-lspace: 0pt;mso-table-rspace: 0pt;-ms-text-size-adjust: 100%;-webkit-text-size-adjust: 100%;">
     <tbody class="mcnImageBlockOuter">
             <tr>
                 <td valign="top" style="padding: 0px;mso-line-height-rule: exactly;-ms-text-size-adjust: 100%;-webkit-text-size-adjust: 100%;" class="mcnImageBlockInner">
                     <table align="left" width="100%" border="0" cellpadding="0" cellspacing="0" class="mcnImageContentContainer" style="min-width: 100%;border-collapse: collapse;mso-table-lspace: 0pt;mso-table-rspace: 0pt;-ms-text-size-adjust: 100%;-webkit-text-size-adjust: 100%;">
                         <tbody><tr>
                             <td class="mcnImageContent" valign="top" style="padding-right: 0px;padding-left: 0px;padding-top: 0;padding-bottom: 0;text-align: center;mso-line-height-rule: exactly;-ms-text-size-adjust: 100%;-webkit-text-size-adjust: 100%;">


                                         <img align="center" alt="" src="https://gallery.mailchimp.com/adee9c5618cae148bdcf8f762/images/42e46f34-cd60-4be2-b1bb-c3e55a40a1a7.jpg" width="540" style="max-width: 540px;padding-bottom: 0;display: inline !important;vertical-align: bottom;border: 0;height: auto;outline: none;text-decoration: none;-ms-interpolation-mode: bicubic;" class="mcnImage">


                             </td>
                         </tr>
                     </tbody></table>
                 </td>
             </tr>
     </tbody>
 </table><table border="0" cellpadding="0" cellspacing="0" width="100%" class="mcnTextBlock" style="min-width: 100%;border-collapse: collapse;mso-table-lspace: 0pt;mso-table-rspace: 0pt;-ms-text-size-adjust: 100%;-webkit-text-size-adjust: 100%;">
     <tbody class="mcnTextBlockOuter">
         <tr>
             <td valign="top" class="mcnTextBlockInner" style="padding-top: 9px;mso-line-height-rule: exactly;-ms-text-size-adjust: 100%;-webkit-text-size-adjust: 100%;">
                 <!--[if mso]>
                 <table align="left" border="0" cellspacing="0" cellpadding="0" width="100%" style="width:100%;">
                 <tr>
                 <![endif]-->

                 <!--[if mso]>
                 <td valign="top" width="600" style="width:600px;">
                 <![endif]-->
                 <table align="left" border="0" cellpadding="0" cellspacing="0" style="max-width: 100%;min-width: 100%;border-collapse: collapse;mso-table-lspace: 0pt;mso-table-rspace: 0pt;-ms-text-size-adjust: 100%;-webkit-text-size-adjust: 100%;" width="100%" class="mcnTextContentContainer">
                     <tbody><tr>

                         <td valign="top" class="mcnTextContent" style="padding: 0px 18px 9px;font-family: "Noticia Text", Georgia, "Times New Roman", serif;line-height: 150%;mso-line-height-rule: exactly;-ms-text-size-adjust: 100%;-webkit-text-size-adjust: 100%;word-break: break-word;color: #202020;font-size: 16px;text-align: left;">

                             <p dir="ltr" style="font-family: "Noticia Text", Georgia, "Times New Roman", serif;line-height: 150%;margin: 10px 0;padding: 0;mso-line-height-rule: exactly;-ms-text-size-adjust: 100%;-webkit-text-size-adjust: 100%;color: #202020;font-size: 16px;text-align: left;">It’s that revolutionary artwork that served as inspiration for one of the latest pieces from French artist, <a href="https://www.pboy-art.com/" target="_blank" style="mso-line-height-rule: exactly;-ms-text-size-adjust: 100%;-webkit-text-size-adjust: 100%;color: #42702f;font-weight: normal;text-decoration: underline;">Pascal Boyart aka PBOY</a>, a pioneer in the world of cross-pollinating Bitcoin and cryptocurrency with graffiti, street art, and gallery art. The French mural, <em>La Liberté guidant le peuple 2019</em>, was not only a modern take on the fight for liberty, but also a Bitcoin art puzzle containing a hidden private key granting 0.2891 BTC (around $1,000 USD at the time of its solving) to the first person to deduce its solution. The code was cracked on January 13 by <a href="https://twitter.com/marabrito31" target="_blank" style="mso-line-height-rule: exactly;-ms-text-size-adjust: 100%;-webkit-text-size-adjust: 100%;color: #42702f;font-weight: normal;text-decoration: underline;">@marabrito31</a> and <a href="https://twitter.com/a_ferron" target="_blank" style="mso-line-height-rule: exactly;-ms-text-size-adjust: 100%;-webkit-text-size-adjust: 100%;color: #42702f;font-weight: normal;text-decoration: underline;">Antoine Ferron</a> of Paris. <em>Magnifique! Nous sommes très impressionné!</em><br>
 <br>
 Read how the puzzle was created and solved on PBOY’s website: <a href="https://www.pboy-art.com/single-post/2019/01/13/Solution-de-l%C3%A9nigme-de-la-fresque-La-Libert%C3%A9-guidant-le-peuple-2019" target="_blank" style="mso-line-height-rule: exactly;-ms-text-size-adjust: 100%;-webkit-text-size-adjust: 100%;color: #42702f;font-weight: normal;text-decoration: underline;">https://www.pboy-art.com/single-post/2019/01/13/Solution-de-l%C3%A9nigme-de-la-fresque-La-Libert%C3%A9-guidant-le-peuple-2019</a></p>

                         </td>
                     </tr>
                 </tbody></table>
                 <!--[if mso]>
                 </td>
                 <![endif]-->

                 <!--[if mso]>
                 </tr>
                 </table>
                 <![endif]-->
             </td>
         </tr>
     </tbody>
 </table><table border="0" cellpadding="0" cellspacing="0" width="100%" class="mcnDividerBlock" style="min-width: 100%;border-collapse: collapse;mso-table-lspace: 0pt;mso-table-rspace: 0pt;-ms-text-size-adjust: 100%;-webkit-text-size-adjust: 100%;table-layout: fixed !important;">
     <tbody class="mcnDividerBlockOuter">
         <tr>
             <td class="mcnDividerBlockInner" style="min-width: 100%;padding: 24px 18px;mso-line-height-rule: exactly;-ms-text-size-adjust: 100%;-webkit-text-size-adjust: 100%;">
                 <table class="mcnDividerContent" border="0" cellpadding="0" cellspacing="0" width="100%" style="min-width: 100%;border-top: 2px solid #EAEAEA;border-collapse: collapse;mso-table-lspace: 0pt;mso-table-rspace: 0pt;-ms-text-size-adjust: 100%;-webkit-text-size-adjust: 100%;">
                     <tbody><tr>
                         <td style="mso-line-height-rule: exactly;-ms-text-size-adjust: 100%;-webkit-text-size-adjust: 100%;">
                             <span></span>
                         </td>
                     </tr>
                 </tbody></table>
 <!--
                 <td class="mcnDividerBlockInner" style="padding: 18px;">
                 <hr class="mcnDividerContent" style="border-bottom-color:none; border-left-color:none; border-right-color:none; border-bottom-width:0; border-left-width:0; border-right-width:0; margin-top:0; margin-right:0; margin-bottom:0; margin-left:0;" />
 -->
             </td>
         </tr>
     </tbody>
 </table><table border="0" cellpadding="0" cellspacing="0" width="100%" class="mcnTextBlock" style="min-width: 100%;border-collapse: collapse;mso-table-lspace: 0pt;mso-table-rspace: 0pt;-ms-text-size-adjust: 100%;-webkit-text-size-adjust: 100%;">
     <tbody class="mcnTextBlockOuter">
         <tr>
             <td valign="top" class="mcnTextBlockInner" style="padding-top: 9px;mso-line-height-rule: exactly;-ms-text-size-adjust: 100%;-webkit-text-size-adjust: 100%;">
                 <!--[if mso]>
                 <table align="left" border="0" cellspacing="0" cellpadding="0" width="100%" style="width:100%;">
                 <tr>
                 <![endif]-->

                 <!--[if mso]>
                 <td valign="top" width="600" style="width:600px;">
                 <![endif]-->
                 <table align="left" border="0" cellpadding="0" cellspacing="0" style="max-width: 100%;min-width: 100%;border-collapse: collapse;mso-table-lspace: 0pt;mso-table-rspace: 0pt;-ms-text-size-adjust: 100%;-webkit-text-size-adjust: 100%;" width="100%" class="mcnTextContentContainer">
                     <tbody><tr>

                         <td valign="top" class="mcnTextContent" style="padding-top: 0;padding-right: 18px;padding-bottom: 9px;padding-left: 18px;mso-line-height-rule: exactly;-ms-text-size-adjust: 100%;-webkit-text-size-adjust: 100%;word-break: break-word;color: #202020;font-family: 'Noticia Text', Georgia, 'Times New Roman', serif;font-size: 16px;line-height: 150%;text-align: left;">

                             <span style="font-size:18px"><span style="font-family:noticia text,georgia,times new roman,serif"><strong>Promises Made. Promises Kept. </strong></span></span>
                         </td>
                     </tr>
                 </tbody></table>
                 <!--[if mso]>
                 </td>
                 <![endif]-->

                 <!--[if mso]>
                 </tr>
                 </table>
                 <![endif]-->
             </td>
         </tr>
     </tbody>
 </table><table border="0" cellpadding="0" cellspacing="0" width="100%" class="mcnTextBlock" style="min-width: 100%;border-collapse: collapse;mso-table-lspace: 0pt;mso-table-rspace: 0pt;-ms-text-size-adjust: 100%;-webkit-text-size-adjust: 100%;">
     <tbody class="mcnTextBlockOuter">
         <tr>
             <td valign="top" class="mcnTextBlockInner" style="padding-top: 9px;mso-line-height-rule: exactly;-ms-text-size-adjust: 100%;-webkit-text-size-adjust: 100%;">
                 <!--[if mso]>
                 <table align="left" border="0" cellspacing="0" cellpadding="0" width="100%" style="width:100%;">
                 <tr>
                 <![endif]-->

                 <!--[if mso]>
                 <td valign="top" width="600" style="width:600px;">
                 <![endif]-->
                 <table align="left" border="0" cellpadding="0" cellspacing="0" style="max-width: 100%;min-width: 100%;border-collapse: collapse;mso-table-lspace: 0pt;mso-table-rspace: 0pt;-ms-text-size-adjust: 100%;-webkit-text-size-adjust: 100%;" width="100%" class="mcnTextContentContainer">
                     <tbody><tr>

                         <td valign="top" class="mcnTextContent" style="padding: 0px 18px 9px;font-family: "Noticia Text", Georgia, "Times New Roman", serif;line-height: 200%;mso-line-height-rule: exactly;-ms-text-size-adjust: 100%;-webkit-text-size-adjust: 100%;word-break: break-word;color: #202020;font-size: 16px;text-align: left;">

                             <p dir="ltr" style="font-family: "Noticia Text", Georgia, "Times New Roman", serif;line-height: 200%;margin: 10px 0;padding: 0;mso-line-height-rule: exactly;-ms-text-size-adjust: 100%;-webkit-text-size-adjust: 100%;color: #202020;font-size: 16px;text-align: left;">If PBOY is leading the charge in Bitcoin puzzle prizes like lady Liberté flying the Tricolore, then consider us the pistol kid, and maybe occasionally the dude without any pants.<br>
 <br>
 We are giving away HUNDREDS of THOUSANDS of Satoshi. Over a half million. 535,000 to be exact. Whoa, whoa, whoa there, big spender... Satoshis are the smallest denomination of Bitcoin, basically the equivalent of pennies. But hey, it’s still like $20. And because we like fun and games, we’re going to give one lucky winner half the spoils, and another 10 winners about a buck. We were going to give one really lucky winner a single Satoshi, but crypto apps don’t take too kindly to sending that kinda dough.<br>
 <br>
 We’ve created 11 Bitcoin wallets -- 11 wallet addresses and 11 private keys -- and we’re giving away the private keys, one each to the first 11 people who share this newsletter and show us the proof. We’ve deposited funds into each wallet and placed half of all 11 private keys into the animated GIF below. Grab a screenshot of a half-QR code from the GIF and email it to us at <a href="mailto:hi@satoshiscloset.com?subject=Bitcoin%20giveaway&body=Proof%20of%20shared%20Satoshi's%20Closet%20newsletter%3A%20(Attach%20a%20screengrab%20or%20share%20a%20link)%0A%0AHalf-private%20key%20screengrab%3A" target="_blank" style="mso-line-height-rule: exactly;-ms-text-size-adjust: 100%;-webkit-text-size-adjust: 100%;color: #42702f;font-weight: normal;text-decoration: underline;">hi@satoshiscloset.com</a>. We’ll email you back with the full private key to access your BTC. One entry per person, and the first person to submit each half-private key screengrab gets to claim it.</p>

                         </td>
                     </tr>
                 </tbody></table>
                 <!--[if mso]>
                 </td>
                 <![endif]-->

                 <!--[if mso]>
                 </tr>
                 </table>
                 <![endif]-->
             </td>
         </tr>
     </tbody>
 </table><table border="0" cellpadding="0" cellspacing="0" width="100%" class="mcnImageBlock" style="min-width: 100%;border-collapse: collapse;mso-table-lspace: 0pt;mso-table-rspace: 0pt;-ms-text-size-adjust: 100%;-webkit-text-size-adjust: 100%;">
     <tbody class="mcnImageBlockOuter">
             <tr>
                 <td valign="top" style="padding: 9px;mso-line-height-rule: exactly;-ms-text-size-adjust: 100%;-webkit-text-size-adjust: 100%;" class="mcnImageBlockInner">
                     <table align="left" width="100%" border="0" cellpadding="0" cellspacing="0" class="mcnImageContentContainer" style="min-width: 100%;border-collapse: collapse;mso-table-lspace: 0pt;mso-table-rspace: 0pt;-ms-text-size-adjust: 100%;-webkit-text-size-adjust: 100%;">
                         <tbody><tr>
                             <td class="mcnImageContent" valign="top" style="padding-right: 9px;padding-left: 9px;padding-top: 0;padding-bottom: 0;text-align: center;mso-line-height-rule: exactly;-ms-text-size-adjust: 100%;-webkit-text-size-adjust: 100%;">


                                         <img align="center" alt="" src="https://gallery.mailchimp.com/adee9c5618cae148bdcf8f762/images/aea7f7a1-1d7c-4589-9fd6-d423c53243ec.gif" width="164" style="max-width: 164px;padding-bottom: 0;display: inline !important;vertical-align: bottom;border: 0;height: auto;outline: none;text-decoration: none;-ms-interpolation-mode: bicubic;" class="mcnImage">


                             </td>
                         </tr>
                     </tbody></table>
                 </td>
             </tr>
     </tbody>
 </table><table border="0" cellpadding="0" cellspacing="0" width="100%" class="mcnTextBlock" style="min-width: 100%;border-collapse: collapse;mso-table-lspace: 0pt;mso-table-rspace: 0pt;-ms-text-size-adjust: 100%;-webkit-text-size-adjust: 100%;">
     <tbody class="mcnTextBlockOuter">
         <tr>
             <td valign="top" class="mcnTextBlockInner" style="padding-top: 9px;mso-line-height-rule: exactly;-ms-text-size-adjust: 100%;-webkit-text-size-adjust: 100%;">
                 <!--[if mso]>
                 <table align="left" border="0" cellspacing="0" cellpadding="0" width="100%" style="width:100%;">
                 <tr>
                 <![endif]-->

                 <!--[if mso]>
                 <td valign="top" width="600" style="width:600px;">
                 <![endif]-->
                 <table align="left" border="0" cellpadding="0" cellspacing="0" style="max-width: 100%;min-width: 100%;border-collapse: collapse;mso-table-lspace: 0pt;mso-table-rspace: 0pt;-ms-text-size-adjust: 100%;-webkit-text-size-adjust: 100%;" width="100%" class="mcnTextContentContainer">
                     <tbody><tr>

                         <td valign="top" class="mcnTextContent" style="padding-top: 0;padding-right: 18px;padding-bottom: 9px;padding-left: 18px;mso-line-height-rule: exactly;-ms-text-size-adjust: 100%;-webkit-text-size-adjust: 100%;word-break: break-word;color: #202020;font-family: 'Noticia Text', Georgia, 'Times New Roman', serif;font-size: 16px;line-height: 150%;text-align: left;">

                             <strong>1.</strong> Share this newsletter: <a href="https://twitter.com/home?status=%5BInsert%20custom%20message%20here.%20Please%20say%20nice%20things%20about%20us.%5D%20I'm%20sharing%20this%20so%20I%20can%20win%20some%20%23Bitcoin%20from%20%40satoshiscloset.%20Subscribe%20to%20their%20awesome%20weekly%20email%3A%20http%3A//eepurl.com/gdbiTD%20%23BTC%20%23crypto" target="_blank" style="mso-line-height-rule: exactly;-ms-text-size-adjust: 100%;-webkit-text-size-adjust: 100%;color: #42702f;font-weight: normal;text-decoration: underline;">Share on Twitter</a> and keep the link and our handle in it (have fun with the rest). Or just share our email subscribe link: <a href="http://eepurl.com/gdbiTD" target="_blank" style="mso-line-height-rule: exactly;-ms-text-size-adjust: 100%;-webkit-text-size-adjust: 100%;color: #42702f;font-weight: normal;text-decoration: underline;">http://eepurl.com/gdbiTD</a>. Grab a screenshot of either or copy the link to your share.<br>
 <br>
 <strong>2.</strong> Grab QR code screenshot above.<br>
 <br>
 <strong>3.</strong> Email both to: <a href="mailto:hi@satoshiscloset.com?subject=Bitcoin%20giveaway&body=Proof%20of%20shared%20Satoshi's%20Closet%20newsletter%3A%20(Attach%20a%20screengrab%20or%20share%20a%20link)%0A%0AHalf-private%20key%20screengrab%3A" target="_blank" style="mso-line-height-rule: exactly;-ms-text-size-adjust: 100%;-webkit-text-size-adjust: 100%;color: #42702f;font-weight: normal;text-decoration: underline;">hi@satoshiscloset.com</a>
                         </td>
                     </tr>
                 </tbody></table>
                 <!--[if mso]>
                 </td>
                 <![endif]-->

                 <!--[if mso]>
                 </tr>
                 </table>
                 <![endif]-->
             </td>
         </tr>
     </tbody>
 </table><table border="0" cellpadding="0" cellspacing="0" width="100%" class="mcnDividerBlock" style="min-width: 100%;border-collapse: collapse;mso-table-lspace: 0pt;mso-table-rspace: 0pt;-ms-text-size-adjust: 100%;-webkit-text-size-adjust: 100%;table-layout: fixed !important;">
     <tbody class="mcnDividerBlockOuter">
         <tr>
             <td class="mcnDividerBlockInner" style="min-width: 100%;padding: 18px 18px 24px;mso-line-height-rule: exactly;-ms-text-size-adjust: 100%;-webkit-text-size-adjust: 100%;">
                 <table class="mcnDividerContent" border="0" cellpadding="0" cellspacing="0" width="100%" style="min-width: 100%;border-top: 2px solid #EAEAEA;border-collapse: collapse;mso-table-lspace: 0pt;mso-table-rspace: 0pt;-ms-text-size-adjust: 100%;-webkit-text-size-adjust: 100%;">
                     <tbody><tr>
                         <td style="mso-line-height-rule: exactly;-ms-text-size-adjust: 100%;-webkit-text-size-adjust: 100%;">
                             <span></span>
                         </td>
                     </tr>
                 </tbody></table>
 <!--
                 <td class="mcnDividerBlockInner" style="padding: 18px;">
                 <hr class="mcnDividerContent" style="border-bottom-color:none; border-left-color:none; border-right-color:none; border-bottom-width:0; border-left-width:0; border-right-width:0; margin-top:0; margin-right:0; margin-bottom:0; margin-left:0;" />
 -->
             </td>
         </tr>
     </tbody>
 </table><table border="0" cellpadding="0" cellspacing="0" width="100%" class="mcnTextBlock" style="min-width: 100%;border-collapse: collapse;mso-table-lspace: 0pt;mso-table-rspace: 0pt;-ms-text-size-adjust: 100%;-webkit-text-size-adjust: 100%;">
     <tbody class="mcnTextBlockOuter">
         <tr>
             <td valign="top" class="mcnTextBlockInner" style="padding-top: 9px;mso-line-height-rule: exactly;-ms-text-size-adjust: 100%;-webkit-text-size-adjust: 100%;">
                 <!--[if mso]>
                 <table align="left" border="0" cellspacing="0" cellpadding="0" width="100%" style="width:100%;">
                 <tr>
                 <![endif]-->

                 <!--[if mso]>
                 <td valign="top" width="600" style="width:600px;">
                 <![endif]-->
                 <table align="left" border="0" cellpadding="0" cellspacing="0" style="max-width: 100%;min-width: 100%;border-collapse: collapse;mso-table-lspace: 0pt;mso-table-rspace: 0pt;-ms-text-size-adjust: 100%;-webkit-text-size-adjust: 100%;" width="100%" class="mcnTextContentContainer">
                     <tbody><tr>

                         <td valign="top" class="mcnTextContent" style="padding-top: 0;padding-right: 18px;padding-bottom: 9px;padding-left: 18px;mso-line-height-rule: exactly;-ms-text-size-adjust: 100%;-webkit-text-size-adjust: 100%;word-break: break-word;color: #202020;font-family: 'Noticia Text', Georgia, 'Times New Roman', serif;font-size: 16px;line-height: 150%;text-align: left;">

                             <span style="font-size:18px"><span style="font-family:noticia text,georgia,times new roman,serif"><strong>Returning the Internet to the People</strong></span></span>
                         </td>
                     </tr>
                 </tbody></table>
                 <!--[if mso]>
                 </td>
                 <![endif]-->

                 <!--[if mso]>
                 </tr>
                 </table>
                 <![endif]-->
             </td>
         </tr>
     </tbody>
 </table><table border="0" cellpadding="0" cellspacing="0" width="100%" class="mcnImageBlock" style="min-width: 100%;border-collapse: collapse;mso-table-lspace: 0pt;mso-table-rspace: 0pt;-ms-text-size-adjust: 100%;-webkit-text-size-adjust: 100%;">
     <tbody class="mcnImageBlockOuter">
             <tr>
                 <td valign="top" style="padding: 9px;mso-line-height-rule: exactly;-ms-text-size-adjust: 100%;-webkit-text-size-adjust: 100%;" class="mcnImageBlockInner">
                     <table align="left" width="100%" border="0" cellpadding="0" cellspacing="0" class="mcnImageContentContainer" style="min-width: 100%;border-collapse: collapse;mso-table-lspace: 0pt;mso-table-rspace: 0pt;-ms-text-size-adjust: 100%;-webkit-text-size-adjust: 100%;">
                         <tbody><tr>
                             <td class="mcnImageContent" valign="top" style="padding-right: 9px;padding-left: 9px;padding-top: 0;padding-bottom: 0;text-align: center;mso-line-height-rule: exactly;-ms-text-size-adjust: 100%;-webkit-text-size-adjust: 100%;">


                                         <img align="center" alt="" src="https://gallery.mailchimp.com/adee9c5618cae148bdcf8f762/images/957fe114-512f-4cde-b9a8-b26996daf9e6.gif" width="480" style="max-width: 480px;padding-bottom: 0;display: inline !important;vertical-align: bottom;border: 0;height: auto;outline: none;text-decoration: none;-ms-interpolation-mode: bicubic;" class="mcnImage">


                             </td>
                         </tr>
                     </tbody></table>
                 </td>
             </tr>
     </tbody>
 </table><table border="0" cellpadding="0" cellspacing="0" width="100%" class="mcnTextBlock" style="min-width: 100%;border-collapse: collapse;mso-table-lspace: 0pt;mso-table-rspace: 0pt;-ms-text-size-adjust: 100%;-webkit-text-size-adjust: 100%;">
     <tbody class="mcnTextBlockOuter">
         <tr>
             <td valign="top" class="mcnTextBlockInner" style="padding-top: 9px;mso-line-height-rule: exactly;-ms-text-size-adjust: 100%;-webkit-text-size-adjust: 100%;">
                 <!--[if mso]>
                 <table align="left" border="0" cellspacing="0" cellpadding="0" width="100%" style="width:100%;">
                 <tr>
                 <![endif]-->

                 <!--[if mso]>
                 <td valign="top" width="600" style="width:600px;">
                 <![endif]-->
                 <table align="left" border="0" cellpadding="0" cellspacing="0" style="max-width: 100%;min-width: 100%;border-collapse: collapse;mso-table-lspace: 0pt;mso-table-rspace: 0pt;-ms-text-size-adjust: 100%;-webkit-text-size-adjust: 100%;" width="100%" class="mcnTextContentContainer">
                     <tbody><tr>

                         <td valign="top" class="mcnTextContent" style="padding: 0px 18px 9px;font-family: "Noticia Text", Georgia, "Times New Roman", serif;line-height: 200%;mso-line-height-rule: exactly;-ms-text-size-adjust: 100%;-webkit-text-size-adjust: 100%;word-break: break-word;color: #202020;font-size: 16px;text-align: left;">

                             <p dir="ltr" style="font-family: "Noticia Text", Georgia, "Times New Roman", serif;line-height: 200%;margin: 10px 0;padding: 0;mso-line-height-rule: exactly;-ms-text-size-adjust: 100%;-webkit-text-size-adjust: 100%;color: #202020;font-size: 16px;text-align: left;">Just like cryptocurrency offers the promise to decentralize the monetary world, its underlying blockchain technology can do the same for the Internet. Venture Capitalist Chris Dixon breaks down in short order how the Internet has transformed from a community-driven ecosystem to one dominated by a handful of multi-billion dollar corporations that make, and regularly change, the rules. But blockchain is built on immutable rules, so every participant can rely on constants:<br>
 <br>
 <em>“[Blockchain] can offer strong trust guarantees, rooted in the mathematical and game-theoretic properties of the system. A user or developer can trust that a piece of code running on a blockchain computer will continue to behave as designed, even if individual participants in the network change their motivations or try to subvert the system.”</em><br>
 <br>
 Read Dixon’s full article (it’s not too long) on wired.com: <a href="https://www.wired.com/story/how-blockchain-can-wrest-the-internet-from-corporations/" target="_blank" style="mso-line-height-rule: exactly;-ms-text-size-adjust: 100%;-webkit-text-size-adjust: 100%;color: #42702f;font-weight: normal;text-decoration: underline;">https://www.wired.com/story/how-blockchain-can-wrest-the-internet-from-corporations/</a></p>

                         </td>
                     </tr>
                 </tbody></table>
                 <!--[if mso]>
                 </td>
                 <![endif]-->

                 <!--[if mso]>
                 </tr>
                 </table>
                 <![endif]-->
             </td>
         </tr>
     </tbody>
 </table><table border="0" cellpadding="0" cellspacing="0" width="100%" class="mcnDividerBlock" style="min-width: 100%;border-collapse: collapse;mso-table-lspace: 0pt;mso-table-rspace: 0pt;-ms-text-size-adjust: 100%;-webkit-text-size-adjust: 100%;table-layout: fixed !important;">
     <tbody class="mcnDividerBlockOuter">
         <tr>
             <td class="mcnDividerBlockInner" style="min-width: 100%;padding: 18px 18px 24px;mso-line-height-rule: exactly;-ms-text-size-adjust: 100%;-webkit-text-size-adjust: 100%;">
                 <table class="mcnDividerContent" border="0" cellpadding="0" cellspacing="0" width="100%" style="min-width: 100%;border-top: 2px solid #EAEAEA;border-collapse: collapse;mso-table-lspace: 0pt;mso-table-rspace: 0pt;-ms-text-size-adjust: 100%;-webkit-text-size-adjust: 100%;">
                     <tbody><tr>
                         <td style="mso-line-height-rule: exactly;-ms-text-size-adjust: 100%;-webkit-text-size-adjust: 100%;">
                             <span></span>
                         </td>
                     </tr>
                 </tbody></table>
 <!--
                 <td class="mcnDividerBlockInner" style="padding: 18px;">
                 <hr class="mcnDividerContent" style="border-bottom-color:none; border-left-color:none; border-right-color:none; border-bottom-width:0; border-left-width:0; border-right-width:0; margin-top:0; margin-right:0; margin-bottom:0; margin-left:0;" />
 -->
             </td>
         </tr>
     </tbody>
 </table><table border="0" cellpadding="0" cellspacing="0" width="100%" class="mcnTextBlock" style="min-width: 100%;border-collapse: collapse;mso-table-lspace: 0pt;mso-table-rspace: 0pt;-ms-text-size-adjust: 100%;-webkit-text-size-adjust: 100%;">
     <tbody class="mcnTextBlockOuter">
         <tr>
             <td valign="top" class="mcnTextBlockInner" style="padding-top: 9px;mso-line-height-rule: exactly;-ms-text-size-adjust: 100%;-webkit-text-size-adjust: 100%;">
                 <!--[if mso]>
                 <table align="left" border="0" cellspacing="0" cellpadding="0" width="100%" style="width:100%;">
                 <tr>
                 <![endif]-->

                 <!--[if mso]>
                 <td valign="top" width="600" style="width:600px;">
                 <![endif]-->
                 <table align="left" border="0" cellpadding="0" cellspacing="0" style="max-width: 100%;min-width: 100%;border-collapse: collapse;mso-table-lspace: 0pt;mso-table-rspace: 0pt;-ms-text-size-adjust: 100%;-webkit-text-size-adjust: 100%;" width="100%" class="mcnTextContentContainer">
                     <tbody><tr>

                         <td valign="top" class="mcnTextContent" style="padding-top: 0;padding-right: 18px;padding-bottom: 9px;padding-left: 18px;mso-line-height-rule: exactly;-ms-text-size-adjust: 100%;-webkit-text-size-adjust: 100%;word-break: break-word;color: #202020;font-family: 'Noticia Text', Georgia, 'Times New Roman', serif;font-size: 16px;line-height: 150%;text-align: left;">

                             <span style="font-size:18px"><span style="font-family:noticia text,georgia,times new roman,serif"><strong>Airdrops! </strong></span></span><strong id="docs-internal-guid-977df075-7fff-9eb1-e6e5-518295343eba"></strong>
                         </td>
                     </tr>
                 </tbody></table>
                 <!--[if mso]>
                 </td>
                 <![endif]-->

                 <!--[if mso]>
                 </tr>
                 </table>
                 <![endif]-->
             </td>
         </tr>
     </tbody>
 </table><table border="0" cellpadding="0" cellspacing="0" width="100%" class="mcnTextBlock" style="min-width: 100%;border-collapse: collapse;mso-table-lspace: 0pt;mso-table-rspace: 0pt;-ms-text-size-adjust: 100%;-webkit-text-size-adjust: 100%;">
     <tbody class="mcnTextBlockOuter">
         <tr>
             <td valign="top" class="mcnTextBlockInner" style="padding-top: 9px;mso-line-height-rule: exactly;-ms-text-size-adjust: 100%;-webkit-text-size-adjust: 100%;">
                 <!--[if mso]>
                 <table align="left" border="0" cellspacing="0" cellpadding="0" width="100%" style="width:100%;">
                 <tr>
                 <![endif]-->

                 <!--[if mso]>
                 <td valign="top" width="600" style="width:600px;">
                 <![endif]-->
                 <table align="left" border="0" cellpadding="0" cellspacing="0" style="max-width: 100%;min-width: 100%;border-collapse: collapse;mso-table-lspace: 0pt;mso-table-rspace: 0pt;-ms-text-size-adjust: 100%;-webkit-text-size-adjust: 100%;" width="100%" class="mcnTextContentContainer">
                     <tbody><tr>

                         <td valign="top" class="mcnTextContent" style="padding: 0px 18px 9px;font-family: "Noticia Text", Georgia, "Times New Roman", serif;line-height: 200%;mso-line-height-rule: exactly;-ms-text-size-adjust: 100%;-webkit-text-size-adjust: 100%;word-break: break-word;color: #202020;font-size: 16px;text-align: left;">

                             <p dir="ltr" style="font-family: "Noticia Text", Georgia, "Times New Roman", serif;line-height: 200%;margin: 10px 0;padding: 0;mso-line-height-rule: exactly;-ms-text-size-adjust: 100%;-webkit-text-size-adjust: 100%;color: #202020;font-size: 16px;text-align: left;"> Personalized free samples from Amazon piggy-backing on your orders of other products. Handy, or creep? <a href="https://www.axios.com/amazon-ad-strategy-free-samples-based-on-consumer-data-5ce0fe5b-6112-4d59-8d28-39278f457b6d.html" target="_blank" style="mso-line-height-rule: exactly;-ms-text-size-adjust: 100%;-webkit-text-size-adjust: 100%;color: #42702f;font-weight: normal;text-decoration: underline;">Amazon's new ad strategy: Free samples based on what it knows about you</a> (Axios)<br>
 <br>
 🤑 Did you lose money on Bitcoin in 2018? Join the club! If you sold for a loss (not you, HODL’ers), you can claim a tax deduction: <a href="https://www.creditkarma.com/insights/i/investors-lost-1-7-billion-bitcoin-but-dont-plan-to-deduct/" target="_blank" style="mso-line-height-rule: exactly;-ms-text-size-adjust: 100%;-webkit-text-size-adjust: 100%;color: #42702f;font-weight: normal;text-decoration: underline;">U.S. investors lost $1.7 billion after selling bitcoin, but many don’t plan to deduct it. Here’s why they should</a> (Credit Karma)</p>

                         </td>
                     </tr>
                 </tbody></table>
                 <!--[if mso]>
                 </td>
                 <![endif]-->

                 <!--[if mso]>
                 </tr>
                 </table>
                 <![endif]-->
             </td>
         </tr>
     </tbody>
 </table><table border="0" cellpadding="0" cellspacing="0" width="100%" class="mcnDividerBlock" style="min-width: 100%;border-collapse: collapse;mso-table-lspace: 0pt;mso-table-rspace: 0pt;-ms-text-size-adjust: 100%;-webkit-text-size-adjust: 100%;table-layout: fixed !important;">
     <tbody class="mcnDividerBlockOuter">
         <tr>
             <td class="mcnDividerBlockInner" style="min-width: 100%;padding: 18px;mso-line-height-rule: exactly;-ms-text-size-adjust: 100%;-webkit-text-size-adjust: 100%;">
                 <table class="mcnDividerContent" border="0" cellpadding="0" cellspacing="0" width="100%" style="min-width: 100%;border-top: 2px solid #EAEAEA;border-collapse: collapse;mso-table-lspace: 0pt;mso-table-rspace: 0pt;-ms-text-size-adjust: 100%;-webkit-text-size-adjust: 100%;">
                     <tbody><tr>
                         <td style="mso-line-height-rule: exactly;-ms-text-size-adjust: 100%;-webkit-text-size-adjust: 100%;">
                             <span></span>
                         </td>
                     </tr>
                 </tbody></table>
 <!--
                 <td class="mcnDividerBlockInner" style="padding: 18px;">
                 <hr class="mcnDividerContent" style="border-bottom-color:none; border-left-color:none; border-right-color:none; border-bottom-width:0; border-left-width:0; border-right-width:0; margin-top:0; margin-right:0; margin-bottom:0; margin-left:0;" />
 -->
             </td>
         </tr>
     </tbody>
 </table><table border="0" cellpadding="0" cellspacing="0" width="100%" class="mcnTextBlock" style="min-width: 100%;border-collapse: collapse;mso-table-lspace: 0pt;mso-table-rspace: 0pt;-ms-text-size-adjust: 100%;-webkit-text-size-adjust: 100%;">
     <tbody class="mcnTextBlockOuter">
         <tr>
             <td valign="top" class="mcnTextBlockInner" style="padding-top: 9px;mso-line-height-rule: exactly;-ms-text-size-adjust: 100%;-webkit-text-size-adjust: 100%;">
                 <!--[if mso]>
                 <table align="left" border="0" cellspacing="0" cellpadding="0" width="100%" style="width:100%;">
                 <tr>
                 <![endif]-->

                 <!--[if mso]>
                 <td valign="top" width="600" style="width:600px;">
                 <![endif]-->
                 <table align="left" border="0" cellpadding="0" cellspacing="0" style="max-width: 100%;min-width: 100%;border-collapse: collapse;mso-table-lspace: 0pt;mso-table-rspace: 0pt;-ms-text-size-adjust: 100%;-webkit-text-size-adjust: 100%;" width="100%" class="mcnTextContentContainer">
                     <tbody><tr>

                         <td valign="top" class="mcnTextContent" style="padding-top: 0;padding-right: 18px;padding-bottom: 9px;padding-left: 18px;mso-line-height-rule: exactly;-ms-text-size-adjust: 100%;-webkit-text-size-adjust: 100%;word-break: break-word;color: #202020;font-family: 'Noticia Text', Georgia, 'Times New Roman', serif;font-size: 16px;line-height: 150%;text-align: left;">

                             <div style="text-align: center;"><strong><span style="font-size:24px">MORE FUN TO COME...</span></strong><br>
 <br>
 Tips and leads for next week's edition? Email them to us at <a href="mailto:hi@satoshiscloset.com?subject=Newsletter%20tips%20and%20leads" target="_blank" style="mso-line-height-rule: exactly;-ms-text-size-adjust: 100%;-webkit-text-size-adjust: 100%;color: #42702f;font-weight: normal;text-decoration: underline;">hi@satoshiscloset.com</a>.</div>

                         </td>
                     </tr>
                 </tbody></table>
                 <!--[if mso]>
                 </td>
                 <![endif]-->

                 <!--[if mso]>
                 </tr>
                 </table>
                 <![endif]-->
             </td>
         </tr>
     </tbody>
 </table></td>
                             </tr>
                         </table>
                     </td>
                 </tr>
             </table>
         </center>
     </body>
 </html>`

 const POST4 = `
 <!doctype html>
 <html xmlns="http://www.w3.org/1999/xhtml" xmlns:v="urn:schemas-microsoft-com:vml" xmlns:o="urn:schemas-microsoft-com:office:office">
     <head>
         <!-- NAME: 1 COLUMN -->
         <!--[if gte mso 15]>
         <xml>
             <o:OfficeDocumentSettings>
             <o:AllowPNG/>
             <o:PixelsPerInch>96</o:PixelsPerInch>
             </o:OfficeDocumentSettings>
         </xml>
         <![endif]-->
         <meta charset="UTF-8">
         <meta http-equiv="X-UA-Compatible" content="IE=edge">
         <meta name="viewport" content="width=device-width, initial-scale=1">
         <title>[004-04-2019] Vinny Chase selling kicks for BTC.</title>

     <style type="text/css">
         p{
             margin:10px 0;
             padding:0;
         }
         table{
             border-collapse:collapse;
         }
         h1,h2,h3,h4,h5,h6{
             display:block;
             margin:0;
             padding:0;
         }
         img,a img{
             border:0;
             height:auto;
             outline:none;
             text-decoration:none;
         }
         body,#bodyTable,#bodyCell{
             height:100%;
             margin:0;
             padding:0;
             width:100%;
         }
         .mcnPreviewText{
             display:none !important;
         }
         #outlook a{
             padding:0;
         }
         img{
             -ms-interpolation-mode:bicubic;
         }
         table{
             mso-table-lspace:0pt;
             mso-table-rspace:0pt;
         }
         .ReadMsgBody{
             width:100%;
         }
         .ExternalClass{
             width:100%;
         }
         p,a,li,td,blockquote{
             mso-line-height-rule:exactly;
         }
         a[href^=tel],a[href^=sms]{
             color:inherit;
             cursor:default;
             text-decoration:none;
         }
         p,a,li,td,body,table,blockquote{
             -ms-text-size-adjust:100%;
             -webkit-text-size-adjust:100%;
         }
         .ExternalClass,.ExternalClass p,.ExternalClass td,.ExternalClass div,.ExternalClass span,.ExternalClass font{
             line-height:100%;
         }
         a[x-apple-data-detectors]{
             color:inherit !important;
             text-decoration:none !important;
             font-size:inherit !important;
             font-family:inherit !important;
             font-weight:inherit !important;
             line-height:inherit !important;
         }
         #bodyCell{
             padding:10px;
         }
         .templateContainer{
             max-width:600px !important;
         }
         a.mcnButton{
             display:block;
         }
         .mcnImage,.mcnRetinaImage{
             vertical-align:bottom;
         }
         .mcnTextContent{
             word-break:break-word;
         }
         .mcnTextContent img{
             height:auto !important;
         }
         .mcnDividerBlock{
             table-layout:fixed !important;
         }
         body,#bodyTable{
             background-color:#ffffff;
         }
         #bodyCell{
             border-top:0;
         }
         .templateContainer{
             border:0;
         }
         h1{
             color:#202020;
             font-family:Helvetica;
             font-size:26px;
             font-style:normal;
             font-weight:bold;
             line-height:125%;
             letter-spacing:normal;
             text-align:left;
         }
         h2{
             color:#202020;
             font-family:Helvetica;
             font-size:22px;
             font-style:normal;
             font-weight:bold;
             line-height:125%;
             letter-spacing:normal;
             text-align:left;
         }
         h3{
             color:#202020;
             font-family:Helvetica;
             font-size:20px;
             font-style:normal;
             font-weight:bold;
             line-height:125%;
             letter-spacing:normal;
             text-align:left;
         }
         h4{
             color:#202020;
             font-family:Helvetica;
             font-size:18px;
             font-style:normal;
             font-weight:bold;
             line-height:125%;
             letter-spacing:normal;
             text-align:left;
         }
         #templatePreheader{
             background-color:#FAFAFA;
             background-image:none;
             background-repeat:no-repeat;
             background-position:center;
             background-size:cover;
             border-top:0;
             border-bottom:0;
             padding-top:9px;
             padding-bottom:9px;
         }
         #templatePreheader .mcnTextContent,#templatePreheader .mcnTextContent p{
             color:#656565;
             font-family:Helvetica;
             font-size:12px;
             line-height:150%;
             text-align:left;
         }
         #templatePreheader .mcnTextContent a,#templatePreheader .mcnTextContent p a{
             color:#656565;
             font-weight:normal;
             text-decoration:underline;
         }
         #templateHeader{
             background-color:#ffffff;
             background-image:none;
             background-repeat:no-repeat;
             background-position:center;
             background-size:cover;
             border-top:0;
             border-bottom:0;
             padding-top:18px;
             padding-bottom:0px;
         }
         #templateHeader .mcnTextContent,#templateHeader .mcnTextContent p{
             color:#202020;
             font-family:Helvetica;
             font-size:12px;
             line-height:125%;
             text-align:left;
         }
         #templateHeader .mcnTextContent a,#templateHeader .mcnTextContent p a{
             color:#007C89;
             font-weight:normal;
             text-decoration:underline;
         }
         #templateBody{
             background-color:#ffffff;
             background-image:none;
             background-repeat:no-repeat;
             background-position:center;
             background-size:cover;
             border-top:0;
             border-bottom:2px solid #EAEAEA;
             padding-top:0;
             padding-bottom:9px;
         }
         #templateBody .mcnTextContent,#templateBody .mcnTextContent p{
             color:#202020;
             font-family:'Noticia Text', Georgia, 'Times New Roman', serif;
             font-size:16px;
             line-height:150%;
             text-align:left;
         }
         #templateBody .mcnTextContent a,#templateBody .mcnTextContent p a{
             color:#42702f;
             font-weight:normal;
             text-decoration:underline;
         }
         #templateFooter{
             background-color:#ffffff;
             background-image:none;
             background-repeat:no-repeat;
             background-position:center;
             background-size:cover;
             border-top:0;
             border-bottom:0;
             padding-top:9px;
             padding-bottom:9px;
         }
         #templateFooter .mcnTextContent,#templateFooter .mcnTextContent p{
             color:#656565;
             font-family:Helvetica;
             font-size:12px;
             line-height:150%;
             text-align:center;
         }
         #templateFooter .mcnTextContent a,#templateFooter .mcnTextContent p a{
             color:#656565;
             font-weight:normal;
             text-decoration:underline;
         }
     @media only screen and (min-width:768px){
         .templateContainer{
             width:600px !important;
         }

 }   @media only screen and (max-width: 480px){
         body,table,td,p,a,li,blockquote{
             -webkit-text-size-adjust:none !important;
         }

 }   @media only screen and (max-width: 480px){
         body{
             width:100% !important;
             min-width:100% !important;
         }

 }   @media only screen and (max-width: 480px){
         #bodyCell{
             padding-top:10px !important;
         }

 }   @media only screen and (max-width: 480px){
         .mcnRetinaImage{
             max-width:100% !important;
         }

 }   @media only screen and (max-width: 480px){
         .mcnImage{
             width:100% !important;
         }

 }   @media only screen and (max-width: 480px){
         .mcnCartContainer,.mcnCaptionTopContent,.mcnRecContentContainer,.mcnCaptionBottomContent,.mcnTextContentContainer,.mcnBoxedTextContentContainer,.mcnImageGroupContentContainer,.mcnCaptionLeftTextContentContainer,.mcnCaptionRightTextContentContainer,.mcnCaptionLeftImageContentContainer,.mcnCaptionRightImageContentContainer,.mcnImageCardLeftTextContentContainer,.mcnImageCardRightTextContentContainer,.mcnImageCardLeftImageContentContainer,.mcnImageCardRightImageContentContainer{
             max-width:100% !important;
             width:100% !important;
         }

 }   @media only screen and (max-width: 480px){
         .mcnBoxedTextContentContainer{
             min-width:100% !important;
         }

 }   @media only screen and (max-width: 480px){
         .mcnImageGroupContent{
             padding:9px !important;
         }

 }   @media only screen and (max-width: 480px){
         .mcnCaptionLeftContentOuter .mcnTextContent,.mcnCaptionRightContentOuter .mcnTextContent{
             padding-top:9px !important;
         }

 }   @media only screen and (max-width: 480px){
         .mcnImageCardTopImageContent,.mcnCaptionBottomContent:last-child .mcnCaptionBottomImageContent,.mcnCaptionBlockInner .mcnCaptionTopContent:last-child .mcnTextContent{
             padding-top:18px !important;
         }

 }   @media only screen and (max-width: 480px){
         .mcnImageCardBottomImageContent{
             padding-bottom:9px !important;
         }

 }   @media only screen and (max-width: 480px){
         .mcnImageGroupBlockInner{
             padding-top:0 !important;
             padding-bottom:0 !important;
         }

 }   @media only screen and (max-width: 480px){
         .mcnImageGroupBlockOuter{
             padding-top:9px !important;
             padding-bottom:9px !important;
         }

 }   @media only screen and (max-width: 480px){
         .mcnTextContent,.mcnBoxedTextContentColumn{
             padding-right:18px !important;
             padding-left:18px !important;
         }

 }   @media only screen and (max-width: 480px){
         .mcnImageCardLeftImageContent,.mcnImageCardRightImageContent{
             padding-right:18px !important;
             padding-bottom:0 !important;
             padding-left:18px !important;
         }

 }   @media only screen and (max-width: 480px){
         .mcpreview-image-uploader{
             display:none !important;
             width:100% !important;
         }

 }   @media only screen and (max-width: 480px){
         h1{
             font-size:22px !important;
             line-height:125% !important;
         }

 }   @media only screen and (max-width: 480px){
         h2{
             font-size:20px !important;
             line-height:125% !important;
         }

 }   @media only screen and (max-width: 480px){
         h3{
             font-size:18px !important;
             line-height:125% !important;
         }

 }   @media only screen and (max-width: 480px){
         h4{
             font-size:16px !important;
             line-height:150% !important;
         }

 }   @media only screen and (max-width: 480px){
         .mcnBoxedTextContentContainer .mcnTextContent,.mcnBoxedTextContentContainer .mcnTextContent p{
             font-size:14px !important;
             line-height:150% !important;
         }

 }   @media only screen and (max-width: 480px){
         #templatePreheader{
             display:block !important;
         }

 }   @media only screen and (max-width: 480px){
         #templatePreheader .mcnTextContent,#templatePreheader .mcnTextContent p{
             font-size:14px !important;
             line-height:150% !important;
         }

 }   @media only screen and (max-width: 480px){
         #templateHeader .mcnTextContent,#templateHeader .mcnTextContent p{
             font-size:16px !important;
             line-height:150% !important;
         }

 }   @media only screen and (max-width: 480px){
         #templateBody .mcnTextContent,#templateBody .mcnTextContent p{
             font-size:16px !important;
             line-height:150% !important;
         }

 }   @media only screen and (max-width: 480px){
         #templateFooter .mcnTextContent,#templateFooter .mcnTextContent p{
             font-size:14px !important;
             line-height:150% !important;
         }

 }</style><!--[if !mso]><!--><link href="https://fonts.googleapis.com/css?family=Noticia+Text:400,400i,700,700i" rel="stylesheet"><!--<![endif]--></head>
     <body style="height: 100%;margin: 0;padding: 0;width: 100%;-ms-text-size-adjust: 100%;-webkit-text-size-adjust: 100%;background-color: #ffffff;">
         <!--*|IF:MC_PREVIEW_TEXT|*-->
         <!--[if !gte mso 9]><!----><span class="mcnPreviewText" style="display:none; font-size:0px; line-height:0px; max-height:0px; max-width:0px; opacity:0; overflow:hidden; visibility:hidden; mso-hide:all;">*|MC_PREVIEW_TEXT|*</span><!--<![endif]-->
         <!--*|END:IF|*-->
         <center>
             <table align="center" border="0" cellpadding="0" cellspacing="0" height="100%" width="100%" id="bodyTable" style="border-collapse: collapse;mso-table-lspace: 0pt;mso-table-rspace: 0pt;-ms-text-size-adjust: 100%;-webkit-text-size-adjust: 100%;height: 100%;margin: 0;padding: 0;width: 100%;background-color: #ffffff;">
                 <tr>
                     <td align="center" valign="top" id="bodyCell" style="mso-line-height-rule: exactly;-ms-text-size-adjust: 100%;-webkit-text-size-adjust: 100%;height: 100%;margin: 0;padding: 10px;width: 100%;border-top: 0;">
                         <!-- BEGIN TEMPLATE // -->
                         <!--[if (gte mso 9)|(IE)]>
                         <table align="center" border="0" cellspacing="0" cellpadding="0" width="600" style="width:600px;">
                         <tr>
                         <td align="center" valign="top" width="600" style="width:600px;">
                         <![endif]-->
                         <table border="0" cellpadding="0" cellspacing="0" width="100%" class="templateContainer" style="border-collapse: collapse;mso-table-lspace: 0pt;mso-table-rspace: 0pt;-ms-text-size-adjust: 100%;-webkit-text-size-adjust: 100%;border: 0;max-width: 600px !important;">
                             <tr>
                                 <td valign="top" id="templateHeader" style="background:#ffffff none no-repeat center/cover;mso-line-height-rule: exactly;-ms-text-size-adjust: 100%;-webkit-text-size-adjust: 100%;background-color: #ffffff;background-image: none;background-repeat: no-repeat;background-position: center;background-size: cover;border-top: 0;border-bottom: 0;padding-top: 18px;padding-bottom: 0px;"><table border="0" cellpadding="0" cellspacing="0" width="100%" class="mcnImageBlock" style="min-width: 100%;border-collapse: collapse;mso-table-lspace: 0pt;mso-table-rspace: 0pt;-ms-text-size-adjust: 100%;-webkit-text-size-adjust: 100%;">
     <tbody class="mcnImageBlockOuter">
             <tr>
                 <td valign="top" style="padding: 9px;mso-line-height-rule: exactly;-ms-text-size-adjust: 100%;-webkit-text-size-adjust: 100%;" class="mcnImageBlockInner">
                     <table align="left" width="100%" border="0" cellpadding="0" cellspacing="0" class="mcnImageContentContainer" style="min-width: 100%;border-collapse: collapse;mso-table-lspace: 0pt;mso-table-rspace: 0pt;-ms-text-size-adjust: 100%;-webkit-text-size-adjust: 100%;">
                         <tbody><tr>
                             <td class="mcnImageContent" valign="top" style="padding-right: 9px;padding-left: 9px;padding-top: 0;padding-bottom: 0;text-align: center;mso-line-height-rule: exactly;-ms-text-size-adjust: 100%;-webkit-text-size-adjust: 100%;">


                                         <img align="center" alt="" src="https://gallery.mailchimp.com/adee9c5618cae148bdcf8f762/images/18c896e6-8e61-4a62-9a60-b061f5186408.jpg" width="564" style="max-width: 960px;padding-bottom: 0;display: inline !important;vertical-align: bottom;border: 0;height: auto;outline: none;text-decoration: none;-ms-interpolation-mode: bicubic;" class="mcnImage">


                             </td>
                         </tr>
                     </tbody></table>
                 </td>
             </tr>
     </tbody>
 </table></td>
                             </tr>
                             <tr>
                                 <td valign="top" id="templateBody" style="background:#ffffff none no-repeat center/cover;mso-line-height-rule: exactly;-ms-text-size-adjust: 100%;-webkit-text-size-adjust: 100%;background-color: #ffffff;background-image: none;background-repeat: no-repeat;background-position: center;background-size: cover;border-top: 0;border-bottom: 2px solid #EAEAEA;padding-top: 0;padding-bottom: 9px;"><table border="0" cellpadding="0" cellspacing="0" width="100%" class="mcnDividerBlock" style="min-width: 100%;border-collapse: collapse;mso-table-lspace: 0pt;mso-table-rspace: 0pt;-ms-text-size-adjust: 100%;-webkit-text-size-adjust: 100%;table-layout: fixed !important;">
     <tbody class="mcnDividerBlockOuter">
         <tr>
             <td class="mcnDividerBlockInner" style="min-width: 100%;padding: 8px 18px 0px;mso-line-height-rule: exactly;-ms-text-size-adjust: 100%;-webkit-text-size-adjust: 100%;">
                 <table class="mcnDividerContent" border="0" cellpadding="0" cellspacing="0" width="100%" style="min-width: 100%;border-top: 2px solid #EAEAEA;border-collapse: collapse;mso-table-lspace: 0pt;mso-table-rspace: 0pt;-ms-text-size-adjust: 100%;-webkit-text-size-adjust: 100%;">
                     <tbody><tr>
                         <td style="mso-line-height-rule: exactly;-ms-text-size-adjust: 100%;-webkit-text-size-adjust: 100%;">
                             <span></span>
                         </td>
                     </tr>
                 </tbody></table>
 <!--
                 <td class="mcnDividerBlockInner" style="padding: 18px;">
                 <hr class="mcnDividerContent" style="border-bottom-color:none; border-left-color:none; border-right-color:none; border-bottom-width:0; border-left-width:0; border-right-width:0; margin-top:0; margin-right:0; margin-bottom:0; margin-left:0;" />
 -->
             </td>
         </tr>
     </tbody>
 </table><table border="0" cellpadding="0" cellspacing="0" width="100%" class="mcnTextBlock" style="min-width: 100%;border-collapse: collapse;mso-table-lspace: 0pt;mso-table-rspace: 0pt;-ms-text-size-adjust: 100%;-webkit-text-size-adjust: 100%;">
     <tbody class="mcnTextBlockOuter">
         <tr>
             <td valign="top" class="mcnTextBlockInner" style="padding-top: 9px;mso-line-height-rule: exactly;-ms-text-size-adjust: 100%;-webkit-text-size-adjust: 100%;">
                 <!--[if mso]>
                 <table align="left" border="0" cellspacing="0" cellpadding="0" width="100%" style="width:100%;">
                 <tr>
                 <![endif]-->

                 <!--[if mso]>
                 <td valign="top" width="600" style="width:600px;">
                 <![endif]-->
                 <table align="left" border="0" cellpadding="0" cellspacing="0" style="max-width: 100%;min-width: 100%;border-collapse: collapse;mso-table-lspace: 0pt;mso-table-rspace: 0pt;-ms-text-size-adjust: 100%;-webkit-text-size-adjust: 100%;" width="100%" class="mcnTextContentContainer">
                     <tbody><tr>

                         <td valign="top" class="mcnTextContent" style="padding: 0px 18px 9px;font-family: "Noticia Text", Georgia, "Times New Roman", serif;line-height: 150%;mso-line-height-rule: exactly;-ms-text-size-adjust: 100%;-webkit-text-size-adjust: 100%;word-break: break-word;color: #202020;font-size: 16px;text-align: left;">

                             <div style="text-align: left;"><strong><span style="font-size:12px"><span style="font-family:noticia text,georgia,times new roman,serif">Bienvenue! • ‘E’ is for Ethereum • <em>Cash Cab</em> just got real • Just Do It Again • Airdrops!</span></span></strong></div>

                         </td>
                     </tr>
                 </tbody></table>
                 <!--[if mso]>
                 </td>
                 <![endif]-->

                 <!--[if mso]>
                 </tr>
                 </table>
                 <![endif]-->
             </td>
         </tr>
     </tbody>
 </table><table border="0" cellpadding="0" cellspacing="0" width="100%" class="mcnDividerBlock" style="min-width: 100%;border-collapse: collapse;mso-table-lspace: 0pt;mso-table-rspace: 0pt;-ms-text-size-adjust: 100%;-webkit-text-size-adjust: 100%;table-layout: fixed !important;">
     <tbody class="mcnDividerBlockOuter">
         <tr>
             <td class="mcnDividerBlockInner" style="min-width: 100%;padding: 2px 18px 9px;mso-line-height-rule: exactly;-ms-text-size-adjust: 100%;-webkit-text-size-adjust: 100%;">
                 <table class="mcnDividerContent" border="0" cellpadding="0" cellspacing="0" width="100%" style="min-width: 100%;border-top: 2px solid #EAEAEA;border-collapse: collapse;mso-table-lspace: 0pt;mso-table-rspace: 0pt;-ms-text-size-adjust: 100%;-webkit-text-size-adjust: 100%;">
                     <tbody><tr>
                         <td style="mso-line-height-rule: exactly;-ms-text-size-adjust: 100%;-webkit-text-size-adjust: 100%;">
                             <span></span>
                         </td>
                     </tr>
                 </tbody></table>
 <!--
                 <td class="mcnDividerBlockInner" style="padding: 18px;">
                 <hr class="mcnDividerContent" style="border-bottom-color:none; border-left-color:none; border-right-color:none; border-bottom-width:0; border-left-width:0; border-right-width:0; margin-top:0; margin-right:0; margin-bottom:0; margin-left:0;" />
 -->
             </td>
         </tr>
     </tbody>
 </table><table border="0" cellpadding="0" cellspacing="0" width="100%" class="mcnTextBlock" style="min-width: 100%;border-collapse: collapse;mso-table-lspace: 0pt;mso-table-rspace: 0pt;-ms-text-size-adjust: 100%;-webkit-text-size-adjust: 100%;">
     <tbody class="mcnTextBlockOuter">
         <tr>
             <td valign="top" class="mcnTextBlockInner" style="padding-top: 9px;mso-line-height-rule: exactly;-ms-text-size-adjust: 100%;-webkit-text-size-adjust: 100%;">
                 <!--[if mso]>
                 <table align="left" border="0" cellspacing="0" cellpadding="0" width="100%" style="width:100%;">
                 <tr>
                 <![endif]-->

                 <!--[if mso]>
                 <td valign="top" width="600" style="width:600px;">
                 <![endif]-->
                 <table align="left" border="0" cellpadding="0" cellspacing="0" style="max-width: 100%;min-width: 100%;border-collapse: collapse;mso-table-lspace: 0pt;mso-table-rspace: 0pt;-ms-text-size-adjust: 100%;-webkit-text-size-adjust: 100%;" width="100%" class="mcnTextContentContainer">
                     <tbody><tr>

                         <td valign="top" class="mcnTextContent" style="padding: 0px 18px 9px;font-family: "Noticia Text", Georgia, "Times New Roman", serif;line-height: 150%;mso-line-height-rule: exactly;-ms-text-size-adjust: 100%;-webkit-text-size-adjust: 100%;word-break: break-word;color: #202020;font-size: 16px;text-align: left;">

                             <p dir="ltr" style="font-family: "Noticia Text", Georgia, "Times New Roman", serif;line-height: 150%;margin: 10px 0;padding: 0;mso-line-height-rule: exactly;-ms-text-size-adjust: 100%;-webkit-text-size-adjust: 100%;color: #202020;font-size: 16px;text-align: left;"><span style="font-size:18px"><strong>Welcome to [004-04-2019]!</strong></span><br>
 <br>
 Hello there! We’ve made it to our fourth issue, and so have you! For those who have been with us since [001-01-2019], thank you! And for those who have joined along the way we are truly grateful that you are here 🕴️<br>
 <br>
 Every week we round up some of the latest happenings in crypto, blockchain, tech, fashion, and lifestyle and get them delivered to your inbox. We’re also working on the next generation of streetwear and blockchain tech. Here’s links to our first three editions to get up to speed:<br>
 <br>
 <a href="https://satoshiscloset.com/blog/001-01-2019-debut-issue" target="_blank" style="mso-line-height-rule: exactly;-ms-text-size-adjust: 100%;-webkit-text-size-adjust: 100%;color: #42702f;font-weight: normal;text-decoration: underline;">[001-01-2019] Debut issue.</a><br>
 <a href="https://satoshiscloset.com/blog/002-02-2019-mostly-an-editorial" target="_blank" style="mso-line-height-rule: exactly;-ms-text-size-adjust: 100%;-webkit-text-size-adjust: 100%;color: #42702f;font-weight: normal;text-decoration: underline;">[002-02-2019] Mostly an editorial.</a><br>
 <a href="https://satoshiscloset.com/blog/003-03-2019-puzzles-with-money-in-them" target="_blank" style="mso-line-height-rule: exactly;-ms-text-size-adjust: 100%;-webkit-text-size-adjust: 100%;color: #42702f;font-weight: normal;text-decoration: underline;">[003-03-2019] Puzzles with money in them.</a></p>

                         </td>
                     </tr>
                 </tbody></table>
                 <!--[if mso]>
                 </td>
                 <![endif]-->

                 <!--[if mso]>
                 </tr>
                 </table>
                 <![endif]-->
             </td>
         </tr>
     </tbody>
 </table><table border="0" cellpadding="0" cellspacing="0" width="100%" class="mcnDividerBlock" style="min-width: 100%;border-collapse: collapse;mso-table-lspace: 0pt;mso-table-rspace: 0pt;-ms-text-size-adjust: 100%;-webkit-text-size-adjust: 100%;table-layout: fixed !important;">
     <tbody class="mcnDividerBlockOuter">
         <tr>
             <td class="mcnDividerBlockInner" style="min-width: 100%;padding: 24px 18px;mso-line-height-rule: exactly;-ms-text-size-adjust: 100%;-webkit-text-size-adjust: 100%;">
                 <table class="mcnDividerContent" border="0" cellpadding="0" cellspacing="0" width="100%" style="min-width: 100%;border-top: 2px solid #EAEAEA;border-collapse: collapse;mso-table-lspace: 0pt;mso-table-rspace: 0pt;-ms-text-size-adjust: 100%;-webkit-text-size-adjust: 100%;">
                     <tbody><tr>
                         <td style="mso-line-height-rule: exactly;-ms-text-size-adjust: 100%;-webkit-text-size-adjust: 100%;">
                             <span></span>
                         </td>
                     </tr>
                 </tbody></table>
 <!--
                 <td class="mcnDividerBlockInner" style="padding: 18px;">
                 <hr class="mcnDividerContent" style="border-bottom-color:none; border-left-color:none; border-right-color:none; border-bottom-width:0; border-left-width:0; border-right-width:0; margin-top:0; margin-right:0; margin-bottom:0; margin-left:0;" />
 -->
             </td>
         </tr>
     </tbody>
 </table><table border="0" cellpadding="0" cellspacing="0" width="100%" class="mcnTextBlock" style="min-width: 100%;border-collapse: collapse;mso-table-lspace: 0pt;mso-table-rspace: 0pt;-ms-text-size-adjust: 100%;-webkit-text-size-adjust: 100%;">
     <tbody class="mcnTextBlockOuter">
         <tr>
             <td valign="top" class="mcnTextBlockInner" style="padding-top: 9px;mso-line-height-rule: exactly;-ms-text-size-adjust: 100%;-webkit-text-size-adjust: 100%;">
                 <!--[if mso]>
                 <table align="left" border="0" cellspacing="0" cellpadding="0" width="100%" style="width:100%;">
                 <tr>
                 <![endif]-->

                 <!--[if mso]>
                 <td valign="top" width="600" style="width:600px;">
                 <![endif]-->
                 <table align="left" border="0" cellpadding="0" cellspacing="0" style="max-width: 100%;min-width: 100%;border-collapse: collapse;mso-table-lspace: 0pt;mso-table-rspace: 0pt;-ms-text-size-adjust: 100%;-webkit-text-size-adjust: 100%;" width="100%" class="mcnTextContentContainer">
                     <tbody><tr>

                         <td valign="top" class="mcnTextContent" style="padding: 0px 18px 9px;font-family: "Noticia Text", Georgia, "Times New Roman", serif;line-height: 150%;mso-line-height-rule: exactly;-ms-text-size-adjust: 100%;-webkit-text-size-adjust: 100%;word-break: break-word;color: #202020;font-size: 16px;text-align: left;">

                             <p dir="ltr" style="font-family: "Noticia Text", Georgia, "Times New Roman", serif;line-height: 150%;margin: 10px 0;padding: 0;mso-line-height-rule: exactly;-ms-text-size-adjust: 100%;-webkit-text-size-adjust: 100%;color: #202020;font-size: 16px;text-align: left;"><span style="font-size:18px"><strong>Traded his Ari Gold for Bitcoin</strong></span></p>

                         </td>
                     </tr>
                 </tbody></table>
                 <!--[if mso]>
                 </td>
                 <![endif]-->

                 <!--[if mso]>
                 </tr>
                 </table>
                 <![endif]-->
             </td>
         </tr>
     </tbody>
 </table><table border="0" cellpadding="0" cellspacing="0" width="100%" class="mcnImageCardBlock" style="border-collapse: collapse;mso-table-lspace: 0pt;mso-table-rspace: 0pt;-ms-text-size-adjust: 100%;-webkit-text-size-adjust: 100%;">
     <tbody class="mcnImageCardBlockOuter">
         <tr>
             <td class="mcnImageCardBlockInner" valign="top" style="padding-top: 9px;padding-right: 18px;padding-bottom: 9px;padding-left: 18px;mso-line-height-rule: exactly;-ms-text-size-adjust: 100%;-webkit-text-size-adjust: 100%;">

 <table align="right" border="0" cellpadding="0" cellspacing="0" class="mcnImageCardBottomContent" width="100%" style="border-collapse: collapse;mso-table-lspace: 0pt;mso-table-rspace: 0pt;-ms-text-size-adjust: 100%;-webkit-text-size-adjust: 100%;">
     <tbody><tr>
         <td class="mcnImageCardBottomImageContent" align="center" valign="top" style="padding-top: 0px;padding-right: 0px;padding-bottom: 0;padding-left: 0px;mso-line-height-rule: exactly;-ms-text-size-adjust: 100%;-webkit-text-size-adjust: 100%;">



             <img alt="" src="https://gallery.mailchimp.com/adee9c5618cae148bdcf8f762/images/d550b131-6a58-4097-a01d-0f5dc21850d8.png" width="540" style="max-width: 540px;border: 0;height: auto;outline: none;text-decoration: none;-ms-interpolation-mode: bicubic;vertical-align: bottom;" class="mcnImage">


         </td>
     </tr>
     <tr>
         <td class="mcnTextContent" valign="top" style="padding: 9px 18px;color: #747474;font-family: "Noticia Text", Georgia, "Times New Roman", serif;font-size: 14px;font-weight: normal;text-align: center;mso-line-height-rule: exactly;-ms-text-size-adjust: 100%;-webkit-text-size-adjust: 100%;word-break: break-word;line-height: 150%;" width="546">
             <div dir="ltr" style="text-align: left;"><em>Alt text: "Quick! To the avocado groves and CBD grow farms!"</em></div>

         </td>
     </tr>
 </tbody></table>




             </td>
         </tr>
     </tbody>
 </table><table border="0" cellpadding="0" cellspacing="0" width="100%" class="mcnTextBlock" style="min-width: 100%;border-collapse: collapse;mso-table-lspace: 0pt;mso-table-rspace: 0pt;-ms-text-size-adjust: 100%;-webkit-text-size-adjust: 100%;">
     <tbody class="mcnTextBlockOuter">
         <tr>
             <td valign="top" class="mcnTextBlockInner" style="padding-top: 9px;mso-line-height-rule: exactly;-ms-text-size-adjust: 100%;-webkit-text-size-adjust: 100%;">
                 <!--[if mso]>
                 <table align="left" border="0" cellspacing="0" cellpadding="0" width="100%" style="width:100%;">
                 <tr>
                 <![endif]-->

                 <!--[if mso]>
                 <td valign="top" width="600" style="width:600px;">
                 <![endif]-->
                 <table align="left" border="0" cellpadding="0" cellspacing="0" style="max-width: 100%;min-width: 100%;border-collapse: collapse;mso-table-lspace: 0pt;mso-table-rspace: 0pt;-ms-text-size-adjust: 100%;-webkit-text-size-adjust: 100%;" width="100%" class="mcnTextContentContainer">
                     <tbody><tr>

                         <td valign="top" class="mcnTextContent" style="padding-top: 0;padding-right: 18px;padding-bottom: 9px;padding-left: 18px;mso-line-height-rule: exactly;-ms-text-size-adjust: 100%;-webkit-text-size-adjust: 100%;word-break: break-word;color: #202020;font-family: 'Noticia Text', Georgia, 'Times New Roman', serif;font-size: 16px;line-height: 150%;text-align: left;">

                             Is there a word for being simultaneously hyped and horrified at the prospect of your favorite show or movie putting out a spin-off? While not technically a spin-off-, we certainly experienced a similar sensation when we read that Kevin Connolly, co-star of HBO’s hit 2000’s series, <em>Entourage</em>, is attached to a new TV project called <em><a href="https://cryptostheshow.com/" target="_blank" style="mso-line-height-rule: exactly;-ms-text-size-adjust: 100%;-webkit-text-size-adjust: 100%;color: #42702f;font-weight: normal;text-decoration: underline;">Cryptos</a></em>, currently being shopped around to the major streaming services in hopes of having a 10-episode first season picked up. Says Connolly:<br>
 <br>
 <em>“These guys [producers Jason King and Erik Sords] want to bring cryptocurrency to the mainstream for the better of cryptocurrency. I’m just the filmmaker interested in telling cool stories and exploring cool characters.”</em><br>
 <br>
 Will it toe the line as successfully as <em>Entourage</em> did in glamorizing - to near-absurd levels - the world of a young Hollywood star and his friends? Or is it doomed to a <em>Silicon Valley</em>-like fate in which tech-bro mockery runs its course before it has the chance to spawn off a lame feature-length movie and theatrical release? 🤑<br>
 <br>
 We’re calling a Johnny Drama cameo in the pilot. Read more at BREAKER: <a href="https://breakermag.com/entourages-kevin-connolly-is-developing-a-show-about-crypto-revolutionaries" target="_blank" style="mso-line-height-rule: exactly;-ms-text-size-adjust: 100%;-webkit-text-size-adjust: 100%;color: #42702f;font-weight: normal;text-decoration: underline;">https://breakermag.com/entourages-kevin-connolly-is-developing-a-show-about-crypto-revolutionaries</a>
                         </td>
                     </tr>
                 </tbody></table>
                 <!--[if mso]>
                 </td>
                 <![endif]-->

                 <!--[if mso]>
                 </tr>
                 </table>
                 <![endif]-->
             </td>
         </tr>
     </tbody>
 </table><table border="0" cellpadding="0" cellspacing="0" width="100%" class="mcnDividerBlock" style="min-width: 100%;border-collapse: collapse;mso-table-lspace: 0pt;mso-table-rspace: 0pt;-ms-text-size-adjust: 100%;-webkit-text-size-adjust: 100%;table-layout: fixed !important;">
     <tbody class="mcnDividerBlockOuter">
         <tr>
             <td class="mcnDividerBlockInner" style="min-width: 100%;padding: 24px 18px;mso-line-height-rule: exactly;-ms-text-size-adjust: 100%;-webkit-text-size-adjust: 100%;">
                 <table class="mcnDividerContent" border="0" cellpadding="0" cellspacing="0" width="100%" style="min-width: 100%;border-top: 2px solid #EAEAEA;border-collapse: collapse;mso-table-lspace: 0pt;mso-table-rspace: 0pt;-ms-text-size-adjust: 100%;-webkit-text-size-adjust: 100%;">
                     <tbody><tr>
                         <td style="mso-line-height-rule: exactly;-ms-text-size-adjust: 100%;-webkit-text-size-adjust: 100%;">
                             <span></span>
                         </td>
                     </tr>
                 </tbody></table>
 <!--
                 <td class="mcnDividerBlockInner" style="padding: 18px;">
                 <hr class="mcnDividerContent" style="border-bottom-color:none; border-left-color:none; border-right-color:none; border-bottom-width:0; border-left-width:0; border-right-width:0; margin-top:0; margin-right:0; margin-bottom:0; margin-left:0;" />
 -->
             </td>
         </tr>
     </tbody>
 </table><table border="0" cellpadding="0" cellspacing="0" width="100%" class="mcnTextBlock" style="min-width: 100%;border-collapse: collapse;mso-table-lspace: 0pt;mso-table-rspace: 0pt;-ms-text-size-adjust: 100%;-webkit-text-size-adjust: 100%;">
     <tbody class="mcnTextBlockOuter">
         <tr>
             <td valign="top" class="mcnTextBlockInner" style="padding-top: 9px;mso-line-height-rule: exactly;-ms-text-size-adjust: 100%;-webkit-text-size-adjust: 100%;">
                 <!--[if mso]>
                 <table align="left" border="0" cellspacing="0" cellpadding="0" width="100%" style="width:100%;">
                 <tr>
                 <![endif]-->

                 <!--[if mso]>
                 <td valign="top" width="600" style="width:600px;">
                 <![endif]-->
                 <table align="left" border="0" cellpadding="0" cellspacing="0" style="max-width: 100%;min-width: 100%;border-collapse: collapse;mso-table-lspace: 0pt;mso-table-rspace: 0pt;-ms-text-size-adjust: 100%;-webkit-text-size-adjust: 100%;" width="100%" class="mcnTextContentContainer">
                     <tbody><tr>

                         <td valign="top" class="mcnTextContent" style="padding: 0px 18px 9px;font-family: "Noticia Text", Georgia, "Times New Roman", serif;line-height: 150%;mso-line-height-rule: exactly;-ms-text-size-adjust: 100%;-webkit-text-size-adjust: 100%;word-break: break-word;color: #202020;font-size: 16px;text-align: left;">

                             <span style="font-size:18px"><span style="font-family:noticia text,georgia,times new roman,serif"><strong>A virtual wallet and virtual keys for your actual pocket</strong></span></span>
                         </td>
                     </tr>
                 </tbody></table>
                 <!--[if mso]>
                 </td>
                 <![endif]-->

                 <!--[if mso]>
                 </tr>
                 </table>
                 <![endif]-->
             </td>
         </tr>
     </tbody>
 </table><table border="0" cellpadding="0" cellspacing="0" width="100%" class="mcnTextBlock" style="min-width: 100%;border-collapse: collapse;mso-table-lspace: 0pt;mso-table-rspace: 0pt;-ms-text-size-adjust: 100%;-webkit-text-size-adjust: 100%;">
     <tbody class="mcnTextBlockOuter">
         <tr>
             <td valign="top" class="mcnTextBlockInner" style="padding-top: 9px;mso-line-height-rule: exactly;-ms-text-size-adjust: 100%;-webkit-text-size-adjust: 100%;">
                 <!--[if mso]>
                 <table align="left" border="0" cellspacing="0" cellpadding="0" width="100%" style="width:100%;">
                 <tr>
                 <![endif]-->

                 <!--[if mso]>
                 <td valign="top" width="600" style="width:600px;">
                 <![endif]-->
                 <table align="left" border="0" cellpadding="0" cellspacing="0" style="max-width: 100%;min-width: 100%;border-collapse: collapse;mso-table-lspace: 0pt;mso-table-rspace: 0pt;-ms-text-size-adjust: 100%;-webkit-text-size-adjust: 100%;" width="100%" class="mcnTextContentContainer">
                     <tbody><tr>

                         <td valign="top" class="mcnTextContent" style="padding: 0px 18px 9px;font-family: "Noticia Text", Georgia, "Times New Roman", serif;line-height: 150%;mso-line-height-rule: exactly;-ms-text-size-adjust: 100%;-webkit-text-size-adjust: 100%;word-break: break-word;color: #202020;font-size: 16px;text-align: left;">

                             <p dir="ltr" style="font-family: "Noticia Text", Georgia, "Times New Roman", serif;line-height: 150%;margin: 10px 0;padding: 0;mso-line-height-rule: exactly;-ms-text-size-adjust: 100%;-webkit-text-size-adjust: 100%;color: #202020;font-size: 16px;text-align: left;">If there’s one thing that fascinates us about crypto it’s the symbiotic-yet-paradoxical duality of the virtual and physical worlds. Your virtual money in your virtual wallet, secured by your virtual key, now available in the palm of your actual hand.</p>

                         </td>
                     </tr>
                 </tbody></table>
                 <!--[if mso]>
                 </td>
                 <![endif]-->

                 <!--[if mso]>
                 </tr>
                 </table>
                 <![endif]-->
             </td>
         </tr>
     </tbody>
 </table><table border="0" cellpadding="0" cellspacing="0" width="100%" class="mcnImageCardBlock" style="border-collapse: collapse;mso-table-lspace: 0pt;mso-table-rspace: 0pt;-ms-text-size-adjust: 100%;-webkit-text-size-adjust: 100%;">
     <tbody class="mcnImageCardBlockOuter">
         <tr>
             <td class="mcnImageCardBlockInner" valign="top" style="padding-top: 9px;padding-right: 18px;padding-bottom: 9px;padding-left: 18px;mso-line-height-rule: exactly;-ms-text-size-adjust: 100%;-webkit-text-size-adjust: 100%;">

 <table align="left" border="0" cellpadding="0" cellspacing="0" class="mcnImageCardBottomContent" width="100%" style="border-collapse: collapse;mso-table-lspace: 0pt;mso-table-rspace: 0pt;-ms-text-size-adjust: 100%;-webkit-text-size-adjust: 100%;">
     <tbody><tr>
         <td class="mcnImageCardBottomImageContent" align="center" valign="top" style="padding-top: 0px;padding-right: 0px;padding-bottom: 0;padding-left: 0px;mso-line-height-rule: exactly;-ms-text-size-adjust: 100%;-webkit-text-size-adjust: 100%;">



             <img alt="" src="https://gallery.mailchimp.com/adee9c5618cae148bdcf8f762/images/8c57ed35-7cd4-4773-8099-dbe0f1e325dd.jpg" width="540" style="max-width: 540px;border: 0;height: auto;outline: none;text-decoration: none;-ms-interpolation-mode: bicubic;vertical-align: bottom;" class="mcnImage">


         </td>
     </tr>
     <tr>
         <td class="mcnTextContent" valign="top" style="padding: 9px 18px;color: #747474;font-family: "Noticia Text", Georgia, "Times New Roman", serif;font-size: 14px;font-weight: normal;text-align: center;mso-line-height-rule: exactly;-ms-text-size-adjust: 100%;-webkit-text-size-adjust: 100%;word-break: break-word;line-height: 150%;" width="546">

         </td>
     </tr>
 </tbody></table>




             </td>
         </tr>
     </tbody>
 </table><table border="0" cellpadding="0" cellspacing="0" width="100%" class="mcnTextBlock" style="min-width: 100%;border-collapse: collapse;mso-table-lspace: 0pt;mso-table-rspace: 0pt;-ms-text-size-adjust: 100%;-webkit-text-size-adjust: 100%;">
     <tbody class="mcnTextBlockOuter">
         <tr>
             <td valign="top" class="mcnTextBlockInner" style="padding-top: 9px;mso-line-height-rule: exactly;-ms-text-size-adjust: 100%;-webkit-text-size-adjust: 100%;">
                 <!--[if mso]>
                 <table align="left" border="0" cellspacing="0" cellpadding="0" width="100%" style="width:100%;">
                 <tr>
                 <![endif]-->

                 <!--[if mso]>
                 <td valign="top" width="600" style="width:600px;">
                 <![endif]-->
                 <table align="left" border="0" cellpadding="0" cellspacing="0" style="max-width: 100%;min-width: 100%;border-collapse: collapse;mso-table-lspace: 0pt;mso-table-rspace: 0pt;-ms-text-size-adjust: 100%;-webkit-text-size-adjust: 100%;" width="100%" class="mcnTextContentContainer">
                     <tbody><tr>

                         <td valign="top" class="mcnTextContent" style="padding: 0px 18px 9px;font-family: "Noticia Text", Georgia, "Times New Roman", serif;line-height: 150%;mso-line-height-rule: exactly;-ms-text-size-adjust: 100%;-webkit-text-size-adjust: 100%;word-break: break-word;color: #202020;font-size: 16px;text-align: left;">

                             The Samsung Galaxy S10 would be far from the first physical cryptocurrency wallet on the market -- <a href="https://trezor.io/" target="_blank" style="mso-line-height-rule: exactly;-ms-text-size-adjust: 100%;-webkit-text-size-adjust: 100%;color: #42702f;font-weight: normal;text-decoration: underline;">Trezor</a> and <a href="https://www.ledger.com/" target="_blank" style="mso-line-height-rule: exactly;-ms-text-size-adjust: 100%;-webkit-text-size-adjust: 100%;color: #42702f;font-weight: normal;text-decoration: underline;">Ledger Nano</a> are two of the most well-known -- but placing a crypto wallet inside of a device that millions of non-crypto savvy users are likely to own is potentially a <em>yuge</em> step toward mainstream adoption. <a href="https://twitter.com/VenyaGeskin1/status/1088105968892891136" target="_blank" style="mso-line-height-rule: exactly;-ms-text-size-adjust: 100%;-webkit-text-size-adjust: 100%;color: #42702f;font-weight: normal;text-decoration: underline;">Recently leaked photos</a> certainly point to this feature being included, and we can imagine a day very soon in which one person casually onboards another into our crypto future by showing a few taps on a phone screen to send funds.
                         </td>
                     </tr>
                 </tbody></table>
                 <!--[if mso]>
                 </td>
                 <![endif]-->

                 <!--[if mso]>
                 </tr>
                 </table>
                 <![endif]-->
             </td>
         </tr>
     </tbody>
 </table><table border="0" cellpadding="0" cellspacing="0" width="100%" class="mcnImageBlock" style="min-width: 100%;border-collapse: collapse;mso-table-lspace: 0pt;mso-table-rspace: 0pt;-ms-text-size-adjust: 100%;-webkit-text-size-adjust: 100%;">
     <tbody class="mcnImageBlockOuter">
             <tr>
                 <td valign="top" style="padding: 9px;mso-line-height-rule: exactly;-ms-text-size-adjust: 100%;-webkit-text-size-adjust: 100%;" class="mcnImageBlockInner">
                     <table align="left" width="100%" border="0" cellpadding="0" cellspacing="0" class="mcnImageContentContainer" style="min-width: 100%;border-collapse: collapse;mso-table-lspace: 0pt;mso-table-rspace: 0pt;-ms-text-size-adjust: 100%;-webkit-text-size-adjust: 100%;">
                         <tbody><tr>
                             <td class="mcnImageContent" valign="top" style="padding-right: 9px;padding-left: 9px;padding-top: 0;padding-bottom: 0;text-align: center;mso-line-height-rule: exactly;-ms-text-size-adjust: 100%;-webkit-text-size-adjust: 100%;">


                                         <img align="center" alt="" src="https://gallery.mailchimp.com/adee9c5618cae148bdcf8f762/images/e23d1dfe-55c3-4742-ba71-1cd2e5660b05.gif" width="480" style="max-width: 480px;padding-bottom: 0;display: inline !important;vertical-align: bottom;border: 0;height: auto;outline: none;text-decoration: none;-ms-interpolation-mode: bicubic;" class="mcnImage">


                             </td>
                         </tr>
                     </tbody></table>
                 </td>
             </tr>
     </tbody>
 </table><table border="0" cellpadding="0" cellspacing="0" width="100%" class="mcnTextBlock" style="min-width: 100%;border-collapse: collapse;mso-table-lspace: 0pt;mso-table-rspace: 0pt;-ms-text-size-adjust: 100%;-webkit-text-size-adjust: 100%;">
     <tbody class="mcnTextBlockOuter">
         <tr>
             <td valign="top" class="mcnTextBlockInner" style="padding-top: 9px;mso-line-height-rule: exactly;-ms-text-size-adjust: 100%;-webkit-text-size-adjust: 100%;">
                 <!--[if mso]>
                 <table align="left" border="0" cellspacing="0" cellpadding="0" width="100%" style="width:100%;">
                 <tr>
                 <![endif]-->

                 <!--[if mso]>
                 <td valign="top" width="600" style="width:600px;">
                 <![endif]-->
                 <table align="left" border="0" cellpadding="0" cellspacing="0" style="max-width: 100%;min-width: 100%;border-collapse: collapse;mso-table-lspace: 0pt;mso-table-rspace: 0pt;-ms-text-size-adjust: 100%;-webkit-text-size-adjust: 100%;" width="100%" class="mcnTextContentContainer">
                     <tbody><tr>

                         <td valign="top" class="mcnTextContent" style="padding-top: 0;padding-right: 18px;padding-bottom: 9px;padding-left: 18px;mso-line-height-rule: exactly;-ms-text-size-adjust: 100%;-webkit-text-size-adjust: 100%;word-break: break-word;color: #202020;font-family: 'Noticia Text', Georgia, 'Times New Roman', serif;font-size: 16px;line-height: 150%;text-align: left;">

                             The key - pardon the pun - won’t be the hardware, but instead how seamlessly this all works through the phone’s interface. Will it be as easy as, say, Venmo? Even more critical will be the new user onboarding and wallet creation process. Apps like Coinbase, Gemini, Robinhood, and other crypto exchanges require users to complete some level of ‘<a href="https://en.wikipedia.org/wiki/Know_your_customer" target="_blank" style="mso-line-height-rule: exactly;-ms-text-size-adjust: 100%;-webkit-text-size-adjust: 100%;color: #42702f;font-weight: normal;text-decoration: underline;">Know Your Customer</a>’ and anti-money laundering verification. Will Samsung, from a legal perspective, be able to spin up a new user account faster and easier than these existing alternatives?<br>
 <br>
 That said, the hardware component is not to be ignored, and for a reason completely independent of the underlying technology... what happens when you lose the thing? Get ready for your already frantic phone-call-from-a-friend’s-phone-to-your-Uber-that-just-dropped-you-off-with-your-life-sitting-unlocked-in-the-back-seat to get 10x more frantic because now it’s got stacks of virtual cash sitting in a cold wallet.<br>
 <br>
 Time to finally just replace that gripper thing on the back of your phone with a handcuff.
                         </td>
                     </tr>
                 </tbody></table>
                 <!--[if mso]>
                 </td>
                 <![endif]-->

                 <!--[if mso]>
                 </tr>
                 </table>
                 <![endif]-->
             </td>
         </tr>
     </tbody>
 </table><table border="0" cellpadding="0" cellspacing="0" width="100%" class="mcnImageCardBlock" style="border-collapse: collapse;mso-table-lspace: 0pt;mso-table-rspace: 0pt;-ms-text-size-adjust: 100%;-webkit-text-size-adjust: 100%;">
     <tbody class="mcnImageCardBlockOuter">
         <tr>
             <td class="mcnImageCardBlockInner" valign="top" style="padding-top: 9px;padding-right: 18px;padding-bottom: 9px;padding-left: 18px;mso-line-height-rule: exactly;-ms-text-size-adjust: 100%;-webkit-text-size-adjust: 100%;">

 <table align="left" border="0" cellpadding="0" cellspacing="0" class="mcnImageCardBottomContent" width="100%" style="border-collapse: collapse;mso-table-lspace: 0pt;mso-table-rspace: 0pt;-ms-text-size-adjust: 100%;-webkit-text-size-adjust: 100%;">
     <tbody><tr>
         <td class="mcnImageCardBottomImageContent" align="center" valign="top" style="padding-top: 0px;padding-right: 0px;padding-bottom: 0;padding-left: 0px;mso-line-height-rule: exactly;-ms-text-size-adjust: 100%;-webkit-text-size-adjust: 100%;">



             <img alt="" src="https://gallery.mailchimp.com/adee9c5618cae148bdcf8f762/images/9e2ef90c-6eb0-4309-8dc0-38e9f3fa7b2f.gif" width="532" style="max-width: 532px;border: 0;height: auto;outline: none;text-decoration: none;-ms-interpolation-mode: bicubic;vertical-align: bottom;" class="mcnImage">


         </td>
     </tr>
     <tr>
         <td class="mcnTextContent" valign="top" style="padding: 9px 18px;color: #747474;font-family: "Noticia Text", Georgia, "Times New Roman", serif;font-size: 14px;font-weight: normal;text-align: center;mso-line-height-rule: exactly;-ms-text-size-adjust: 100%;-webkit-text-size-adjust: 100%;word-break: break-word;line-height: 150%;" width="546">
             <div dir="ltr" style="text-align: left;"><em>Like this, except Kramer is your $900 smartphone and Bitcoins.</em></div>

         </td>
     </tr>
 </tbody></table>




             </td>
         </tr>
     </tbody>
 </table><table border="0" cellpadding="0" cellspacing="0" width="100%" class="mcnTextBlock" style="min-width: 100%;border-collapse: collapse;mso-table-lspace: 0pt;mso-table-rspace: 0pt;-ms-text-size-adjust: 100%;-webkit-text-size-adjust: 100%;">
     <tbody class="mcnTextBlockOuter">
         <tr>
             <td valign="top" class="mcnTextBlockInner" style="padding-top: 9px;mso-line-height-rule: exactly;-ms-text-size-adjust: 100%;-webkit-text-size-adjust: 100%;">
                 <!--[if mso]>
                 <table align="left" border="0" cellspacing="0" cellpadding="0" width="100%" style="width:100%;">
                 <tr>
                 <![endif]-->

                 <!--[if mso]>
                 <td valign="top" width="600" style="width:600px;">
                 <![endif]-->
                 <table align="left" border="0" cellpadding="0" cellspacing="0" style="max-width: 100%;min-width: 100%;border-collapse: collapse;mso-table-lspace: 0pt;mso-table-rspace: 0pt;-ms-text-size-adjust: 100%;-webkit-text-size-adjust: 100%;" width="100%" class="mcnTextContentContainer">
                     <tbody><tr>

                         <td valign="top" class="mcnTextContent" style="padding-top: 0;padding-right: 18px;padding-bottom: 9px;padding-left: 18px;mso-line-height-rule: exactly;-ms-text-size-adjust: 100%;-webkit-text-size-adjust: 100%;word-break: break-word;color: #202020;font-family: 'Noticia Text', Georgia, 'Times New Roman', serif;font-size: 16px;line-height: 150%;text-align: left;">

                             Read more at The Block: <a href="https://www.theblockcrypto.com/2019/01/24/samsung-galaxy-s10-images-show-embedded-cryptocurrency-wallet/" target="_blank" style="mso-line-height-rule: exactly;-ms-text-size-adjust: 100%;-webkit-text-size-adjust: 100%;color: #42702f;font-weight: normal;text-decoration: underline;">https://www.theblockcrypto.com/2019/01/24/samsung-galaxy-s10-images-show-embedded-cryptocurrency-wallet/</a>
                         </td>
                     </tr>
                 </tbody></table>
                 <!--[if mso]>
                 </td>
                 <![endif]-->

                 <!--[if mso]>
                 </tr>
                 </table>
                 <![endif]-->
             </td>
         </tr>
     </tbody>
 </table><table border="0" cellpadding="0" cellspacing="0" width="100%" class="mcnDividerBlock" style="min-width: 100%;border-collapse: collapse;mso-table-lspace: 0pt;mso-table-rspace: 0pt;-ms-text-size-adjust: 100%;-webkit-text-size-adjust: 100%;table-layout: fixed !important;">
     <tbody class="mcnDividerBlockOuter">
         <tr>
             <td class="mcnDividerBlockInner" style="min-width: 100%;padding: 18px 18px 24px;mso-line-height-rule: exactly;-ms-text-size-adjust: 100%;-webkit-text-size-adjust: 100%;">
                 <table class="mcnDividerContent" border="0" cellpadding="0" cellspacing="0" width="100%" style="min-width: 100%;border-top: 2px solid #EAEAEA;border-collapse: collapse;mso-table-lspace: 0pt;mso-table-rspace: 0pt;-ms-text-size-adjust: 100%;-webkit-text-size-adjust: 100%;">
                     <tbody><tr>
                         <td style="mso-line-height-rule: exactly;-ms-text-size-adjust: 100%;-webkit-text-size-adjust: 100%;">
                             <span></span>
                         </td>
                     </tr>
                 </tbody></table>
 <!--
                 <td class="mcnDividerBlockInner" style="padding: 18px;">
                 <hr class="mcnDividerContent" style="border-bottom-color:none; border-left-color:none; border-right-color:none; border-bottom-width:0; border-left-width:0; border-right-width:0; margin-top:0; margin-right:0; margin-bottom:0; margin-left:0;" />
 -->
             </td>
         </tr>
     </tbody>
 </table><table border="0" cellpadding="0" cellspacing="0" width="100%" class="mcnTextBlock" style="min-width: 100%;border-collapse: collapse;mso-table-lspace: 0pt;mso-table-rspace: 0pt;-ms-text-size-adjust: 100%;-webkit-text-size-adjust: 100%;">
     <tbody class="mcnTextBlockOuter">
         <tr>
             <td valign="top" class="mcnTextBlockInner" style="padding-top: 9px;mso-line-height-rule: exactly;-ms-text-size-adjust: 100%;-webkit-text-size-adjust: 100%;">
                 <!--[if mso]>
                 <table align="left" border="0" cellspacing="0" cellpadding="0" width="100%" style="width:100%;">
                 <tr>
                 <![endif]-->

                 <!--[if mso]>
                 <td valign="top" width="600" style="width:600px;">
                 <![endif]-->
                 <table align="left" border="0" cellpadding="0" cellspacing="0" style="max-width: 100%;min-width: 100%;border-collapse: collapse;mso-table-lspace: 0pt;mso-table-rspace: 0pt;-ms-text-size-adjust: 100%;-webkit-text-size-adjust: 100%;" width="100%" class="mcnTextContentContainer">
                     <tbody><tr>

                         <td valign="top" class="mcnTextContent" style="padding: 0px 18px 9px;font-family: "Noticia Text", Georgia, "Times New Roman", serif;line-height: 150%;mso-line-height-rule: exactly;-ms-text-size-adjust: 100%;-webkit-text-size-adjust: 100%;word-break: break-word;color: #202020;font-size: 16px;text-align: left;">

                             <span style="font-size:18px"><span style="font-family:noticia text,georgia,times new roman,serif"><strong>Whatever you do, take care of your shoes</strong></span></span>
                         </td>
                     </tr>
                 </tbody></table>
                 <!--[if mso]>
                 </td>
                 <![endif]-->

                 <!--[if mso]>
                 </tr>
                 </table>
                 <![endif]-->
             </td>
         </tr>
     </tbody>
 </table><table border="0" cellpadding="0" cellspacing="0" width="100%" class="mcnTextBlock" style="min-width: 100%;border-collapse: collapse;mso-table-lspace: 0pt;mso-table-rspace: 0pt;-ms-text-size-adjust: 100%;-webkit-text-size-adjust: 100%;">
     <tbody class="mcnTextBlockOuter">
         <tr>
             <td valign="top" class="mcnTextBlockInner" style="padding-top: 9px;mso-line-height-rule: exactly;-ms-text-size-adjust: 100%;-webkit-text-size-adjust: 100%;">
                 <!--[if mso]>
                 <table align="left" border="0" cellspacing="0" cellpadding="0" width="100%" style="width:100%;">
                 <tr>
                 <![endif]-->

                 <!--[if mso]>
                 <td valign="top" width="600" style="width:600px;">
                 <![endif]-->
                 <table align="left" border="0" cellpadding="0" cellspacing="0" style="max-width: 100%;min-width: 100%;border-collapse: collapse;mso-table-lspace: 0pt;mso-table-rspace: 0pt;-ms-text-size-adjust: 100%;-webkit-text-size-adjust: 100%;" width="100%" class="mcnTextContentContainer">
                     <tbody><tr>

                         <td valign="top" class="mcnTextContent" style="padding-top: 0;padding-right: 18px;padding-bottom: 9px;padding-left: 18px;mso-line-height-rule: exactly;-ms-text-size-adjust: 100%;-webkit-text-size-adjust: 100%;word-break: break-word;color: #202020;font-family: 'Noticia Text', Georgia, 'Times New Roman', serif;font-size: 16px;line-height: 150%;text-align: left;">

                             Highsnobiety published an awesome piece this week on the multi-billion dollar secondary sneaker market. In short, the larger economic picture mirrors how it feels out on the streets -- a booming business at the center of massive cultural trends, and the resale market with its more liquid pricing is beginning to subsume the larger retail market. Read a couple snippets below, then check out the entire fascinating article. Big shoutout to Highsnobiety on this one.
                         </td>
                     </tr>
                 </tbody></table>
                 <!--[if mso]>
                 </td>
                 <![endif]-->

                 <!--[if mso]>
                 </tr>
                 </table>
                 <![endif]-->
             </td>
         </tr>
     </tbody>
 </table><table border="0" cellpadding="0" cellspacing="0" width="100%" class="mcnImageBlock" style="min-width: 100%;border-collapse: collapse;mso-table-lspace: 0pt;mso-table-rspace: 0pt;-ms-text-size-adjust: 100%;-webkit-text-size-adjust: 100%;">
     <tbody class="mcnImageBlockOuter">
             <tr>
                 <td valign="top" style="padding: 9px;mso-line-height-rule: exactly;-ms-text-size-adjust: 100%;-webkit-text-size-adjust: 100%;" class="mcnImageBlockInner">
                     <table align="left" width="100%" border="0" cellpadding="0" cellspacing="0" class="mcnImageContentContainer" style="min-width: 100%;border-collapse: collapse;mso-table-lspace: 0pt;mso-table-rspace: 0pt;-ms-text-size-adjust: 100%;-webkit-text-size-adjust: 100%;">
                         <tbody><tr>
                             <td class="mcnImageContent" valign="top" style="padding-right: 9px;padding-left: 9px;padding-top: 0;padding-bottom: 0;text-align: center;mso-line-height-rule: exactly;-ms-text-size-adjust: 100%;-webkit-text-size-adjust: 100%;">


                                         <img align="center" alt="" src="https://gallery.mailchimp.com/adee9c5618cae148bdcf8f762/images/1669c1d8-c8ac-41ac-a8b3-b3a76af21e59.gif" width="564" style="max-width: 960px;padding-bottom: 0;display: inline !important;vertical-align: bottom;border: 0;height: auto;outline: none;text-decoration: none;-ms-interpolation-mode: bicubic;" class="mcnImage">


                             </td>
                         </tr>
                     </tbody></table>
                 </td>
             </tr>
     </tbody>
 </table><table border="0" cellpadding="0" cellspacing="0" width="100%" class="mcnTextBlock" style="min-width: 100%;border-collapse: collapse;mso-table-lspace: 0pt;mso-table-rspace: 0pt;-ms-text-size-adjust: 100%;-webkit-text-size-adjust: 100%;">
     <tbody class="mcnTextBlockOuter">
         <tr>
             <td valign="top" class="mcnTextBlockInner" style="padding-top: 9px;mso-line-height-rule: exactly;-ms-text-size-adjust: 100%;-webkit-text-size-adjust: 100%;">
                 <!--[if mso]>
                 <table align="left" border="0" cellspacing="0" cellpadding="0" width="100%" style="width:100%;">
                 <tr>
                 <![endif]-->

                 <!--[if mso]>
                 <td valign="top" width="600" style="width:600px;">
                 <![endif]-->
                 <table align="left" border="0" cellpadding="0" cellspacing="0" style="max-width: 100%;min-width: 100%;border-collapse: collapse;mso-table-lspace: 0pt;mso-table-rspace: 0pt;-ms-text-size-adjust: 100%;-webkit-text-size-adjust: 100%;" width="100%" class="mcnTextContentContainer">
                     <tbody><tr>

                         <td valign="top" class="mcnTextContent" style="padding: 0px 18px 9px;font-family: "Noticia Text", Georgia, "Times New Roman", serif;line-height: 150%;mso-line-height-rule: exactly;-ms-text-size-adjust: 100%;-webkit-text-size-adjust: 100%;word-break: break-word;color: #202020;font-size: 16px;text-align: left;">

                             <p dir="ltr" style="font-family: "Noticia Text", Georgia, "Times New Roman", serif;line-height: 150%;margin: 10px 0;padding: 0;mso-line-height-rule: exactly;-ms-text-size-adjust: 100%;-webkit-text-size-adjust: 100%;color: #202020;font-size: 16px;text-align: left;"><em>“Fashion is becoming a social lubricant of today, in some ways overtaking music and other forms of creative expression,” says Index Ventures partner Danny Rimer, the guy behind the GOAT and Grailed deals. “[GOAT and Grailed] operate in specific niches, but it turns out these niches are much bigger than we ever expected, have a global appeal, and are growing rapidly. We’re still in the early days of the sneaker phenomenon.”</em><br>
 <br>
 <em>“We do see retail and resell merging,” says GOAT CEO and co-founder Eddy Lu. “It’s not just about flipping shoes anymore. People are coming to us now to buy sneakers, period.” Proof can be found in the 25 percent of the company’s inventory that are general release sneakers offered at or below retail price.</em><br>
 <br>
 Read the full piece: <a href="https://www.highsnobiety.com/p/sneaker-reselling-future/" target="_blank" style="mso-line-height-rule: exactly;-ms-text-size-adjust: 100%;-webkit-text-size-adjust: 100%;color: #42702f;font-weight: normal;text-decoration: underline;">https://www.highsnobiety.com/p/sneaker-reselling-future/</a></p>

                         </td>
                     </tr>
                 </tbody></table>
                 <!--[if mso]>
                 </td>
                 <![endif]-->

                 <!--[if mso]>
                 </tr>
                 </table>
                 <![endif]-->
             </td>
         </tr>
     </tbody>
 </table><table border="0" cellpadding="0" cellspacing="0" width="100%" class="mcnDividerBlock" style="min-width: 100%;border-collapse: collapse;mso-table-lspace: 0pt;mso-table-rspace: 0pt;-ms-text-size-adjust: 100%;-webkit-text-size-adjust: 100%;table-layout: fixed !important;">
     <tbody class="mcnDividerBlockOuter">
         <tr>
             <td class="mcnDividerBlockInner" style="min-width: 100%;padding: 18px 18px 24px;mso-line-height-rule: exactly;-ms-text-size-adjust: 100%;-webkit-text-size-adjust: 100%;">
                 <table class="mcnDividerContent" border="0" cellpadding="0" cellspacing="0" width="100%" style="min-width: 100%;border-top: 2px solid #EAEAEA;border-collapse: collapse;mso-table-lspace: 0pt;mso-table-rspace: 0pt;-ms-text-size-adjust: 100%;-webkit-text-size-adjust: 100%;">
                     <tbody><tr>
                         <td style="mso-line-height-rule: exactly;-ms-text-size-adjust: 100%;-webkit-text-size-adjust: 100%;">
                             <span></span>
                         </td>
                     </tr>
                 </tbody></table>
 <!--
                 <td class="mcnDividerBlockInner" style="padding: 18px;">
                 <hr class="mcnDividerContent" style="border-bottom-color:none; border-left-color:none; border-right-color:none; border-bottom-width:0; border-left-width:0; border-right-width:0; margin-top:0; margin-right:0; margin-bottom:0; margin-left:0;" />
 -->
             </td>
         </tr>
     </tbody>
 </table><table border="0" cellpadding="0" cellspacing="0" width="100%" class="mcnTextBlock" style="min-width: 100%;border-collapse: collapse;mso-table-lspace: 0pt;mso-table-rspace: 0pt;-ms-text-size-adjust: 100%;-webkit-text-size-adjust: 100%;">
     <tbody class="mcnTextBlockOuter">
         <tr>
             <td valign="top" class="mcnTextBlockInner" style="padding-top: 9px;mso-line-height-rule: exactly;-ms-text-size-adjust: 100%;-webkit-text-size-adjust: 100%;">
                 <!--[if mso]>
                 <table align="left" border="0" cellspacing="0" cellpadding="0" width="100%" style="width:100%;">
                 <tr>
                 <![endif]-->

                 <!--[if mso]>
                 <td valign="top" width="600" style="width:600px;">
                 <![endif]-->
                 <table align="left" border="0" cellpadding="0" cellspacing="0" style="max-width: 100%;min-width: 100%;border-collapse: collapse;mso-table-lspace: 0pt;mso-table-rspace: 0pt;-ms-text-size-adjust: 100%;-webkit-text-size-adjust: 100%;" width="100%" class="mcnTextContentContainer">
                     <tbody><tr>

                         <td valign="top" class="mcnTextContent" style="padding: 0px 18px 9px;font-family: "Noticia Text", Georgia, "Times New Roman", serif;line-height: 150%;mso-line-height-rule: exactly;-ms-text-size-adjust: 100%;-webkit-text-size-adjust: 100%;word-break: break-word;color: #202020;font-size: 16px;text-align: left;">

                             <span style="font-size:18px"><span style="font-family:noticia text,georgia,times new roman,serif"><strong>Airdrops! </strong></span></span><strong id="docs-internal-guid-977df075-7fff-9eb1-e6e5-518295343eba"></strong>
                         </td>
                     </tr>
                 </tbody></table>
                 <!--[if mso]>
                 </td>
                 <![endif]-->

                 <!--[if mso]>
                 </tr>
                 </table>
                 <![endif]-->
             </td>
         </tr>
     </tbody>
 </table><table border="0" cellpadding="0" cellspacing="0" width="100%" class="mcnTextBlock" style="min-width: 100%;border-collapse: collapse;mso-table-lspace: 0pt;mso-table-rspace: 0pt;-ms-text-size-adjust: 100%;-webkit-text-size-adjust: 100%;">
     <tbody class="mcnTextBlockOuter">
         <tr>
             <td valign="top" class="mcnTextBlockInner" style="padding-top: 9px;mso-line-height-rule: exactly;-ms-text-size-adjust: 100%;-webkit-text-size-adjust: 100%;">
                 <!--[if mso]>
                 <table align="left" border="0" cellspacing="0" cellpadding="0" width="100%" style="width:100%;">
                 <tr>
                 <![endif]-->

                 <!--[if mso]>
                 <td valign="top" width="600" style="width:600px;">
                 <![endif]-->
                 <table align="left" border="0" cellpadding="0" cellspacing="0" style="max-width: 100%;min-width: 100%;border-collapse: collapse;mso-table-lspace: 0pt;mso-table-rspace: 0pt;-ms-text-size-adjust: 100%;-webkit-text-size-adjust: 100%;" width="100%" class="mcnTextContentContainer">
                     <tbody><tr>

                         <td valign="top" class="mcnTextContent" style="padding: 0px 18px 9px;font-family: "Noticia Text", Georgia, "Times New Roman", serif;line-height: 150%;mso-line-height-rule: exactly;-ms-text-size-adjust: 100%;-webkit-text-size-adjust: 100%;word-break: break-word;color: #202020;font-size: 16px;text-align: left;">

                             <p dir="ltr" style="font-family: "Noticia Text", Georgia, "Times New Roman", serif;line-height: 150%;margin: 10px 0;padding: 0;mso-line-height-rule: exactly;-ms-text-size-adjust: 100%;-webkit-text-size-adjust: 100%;color: #202020;font-size: 16px;text-align: left;"><strong id="docs-internal-guid-ce49d004-7fff-e2f9-7c9f-0218a3be2fe4">🏹 </strong>The number and quality of crypto apps continues to grow, with <a href="https://robinhood.com/" style="mso-line-height-rule: exactly;-ms-text-size-adjust: 100%;-webkit-text-size-adjust: 100%;color: #42702f;font-weight: normal;text-decoration: underline;">Robinhood</a> receiving their ‘BitLicense’ to open up shop in New York. Robinhood has a lot of name recognition in what we’ll call the ‘Millennial FinTech world’ so this could signal another push into the mainstream: <a href="https://blog.robinhood.com/news/2019/1/23/robinhood-crypto-is-coming-to-new-york" style="mso-line-height-rule: exactly;-ms-text-size-adjust: 100%;-webkit-text-size-adjust: 100%;color: #42702f;font-weight: normal;text-decoration: underline;">https://blog.robinhood.com/news/2019/1/23/robinhood-crypto-is-coming-to-new-york</a><br>
 <br>
  <a href="https://en.wikipedia.org/wiki/Infinite_monkey_theorem" target="_blank" style="mso-line-height-rule: exactly;-ms-text-size-adjust: 100%;-webkit-text-size-adjust: 100%;color: #42702f;font-weight: normal;text-decoration: underline;">Infinite monkey theorem</a>: Given an infinite amount of time, every organization will almost surely rebrand with a blue logo. This time it’s Litecoin’s turn: <a href="https://medium.com/@mrilirgashi/litecoin-a-refined-logo-and-vision-ebc2607343e2" target="_blank" style="mso-line-height-rule: exactly;-ms-text-size-adjust: 100%;-webkit-text-size-adjust: 100%;color: #42702f;font-weight: normal;text-decoration: underline;">https://medium.com/@mrilirgashi/litecoin-a-refined-logo-and-vision-ebc2607343e2</a><br>
 <br>
  The music industry is a perfect example of a value chain where blockchain can support creators and rights-holders through immutable attributes of provenance, distribution, and usage. Blockchain platform <a href="https://bitfury.com/" target="_blank" style="mso-line-height-rule: exactly;-ms-text-size-adjust: 100%;-webkit-text-size-adjust: 100%;color: #42702f;font-weight: normal;text-decoration: underline;">Bitfury</a> is getting into the space with the launch of a new open-source music platform, SurroundTM: <a href="https://www.reuters.com/article/us-usa-blockchain-bitfury/global-blockchain-unicorn-bitfury-launches-music-business-idUSKCN1PA0Q9" target="_blank" style="mso-line-height-rule: exactly;-ms-text-size-adjust: 100%;-webkit-text-size-adjust: 100%;color: #42702f;font-weight: normal;text-decoration: underline;">https://www.reuters.com/article/us-usa-blockchain-bitfury/global-blockchain-unicorn-bitfury-launches-music-business-idUSKCN1PA0Q9</a></p>

                         </td>
                     </tr>
                 </tbody></table>
                 <!--[if mso]>
                 </td>
                 <![endif]-->

                 <!--[if mso]>
                 </tr>
                 </table>
                 <![endif]-->
             </td>
         </tr>
     </tbody>
 </table><table border="0" cellpadding="0" cellspacing="0" width="100%" class="mcnDividerBlock" style="min-width: 100%;border-collapse: collapse;mso-table-lspace: 0pt;mso-table-rspace: 0pt;-ms-text-size-adjust: 100%;-webkit-text-size-adjust: 100%;table-layout: fixed !important;">
     <tbody class="mcnDividerBlockOuter">
         <tr>
             <td class="mcnDividerBlockInner" style="min-width: 100%;padding: 18px;mso-line-height-rule: exactly;-ms-text-size-adjust: 100%;-webkit-text-size-adjust: 100%;">
                 <table class="mcnDividerContent" border="0" cellpadding="0" cellspacing="0" width="100%" style="min-width: 100%;border-top: 2px solid #EAEAEA;border-collapse: collapse;mso-table-lspace: 0pt;mso-table-rspace: 0pt;-ms-text-size-adjust: 100%;-webkit-text-size-adjust: 100%;">
                     <tbody><tr>
                         <td style="mso-line-height-rule: exactly;-ms-text-size-adjust: 100%;-webkit-text-size-adjust: 100%;">
                             <span></span>
                         </td>
                     </tr>
                 </tbody></table>
 <!--
                 <td class="mcnDividerBlockInner" style="padding: 18px;">
                 <hr class="mcnDividerContent" style="border-bottom-color:none; border-left-color:none; border-right-color:none; border-bottom-width:0; border-left-width:0; border-right-width:0; margin-top:0; margin-right:0; margin-bottom:0; margin-left:0;" />
 -->
             </td>
         </tr>
     </tbody>
 </table><table border="0" cellpadding="0" cellspacing="0" width="100%" class="mcnTextBlock" style="min-width: 100%;border-collapse: collapse;mso-table-lspace: 0pt;mso-table-rspace: 0pt;-ms-text-size-adjust: 100%;-webkit-text-size-adjust: 100%;">
     <tbody class="mcnTextBlockOuter">
         <tr>
             <td valign="top" class="mcnTextBlockInner" style="padding-top: 9px;mso-line-height-rule: exactly;-ms-text-size-adjust: 100%;-webkit-text-size-adjust: 100%;">
                 <!--[if mso]>
                 <table align="left" border="0" cellspacing="0" cellpadding="0" width="100%" style="width:100%;">
                 <tr>
                 <![endif]-->

                 <!--[if mso]>
                 <td valign="top" width="600" style="width:600px;">
                 <![endif]-->
                 <table align="left" border="0" cellpadding="0" cellspacing="0" style="max-width: 100%;min-width: 100%;border-collapse: collapse;mso-table-lspace: 0pt;mso-table-rspace: 0pt;-ms-text-size-adjust: 100%;-webkit-text-size-adjust: 100%;" width="100%" class="mcnTextContentContainer">
                     <tbody><tr>

                         <td valign="top" class="mcnTextContent" style="padding: 0px 18px 9px;font-family: "Noticia Text", Georgia, "Times New Roman", serif;line-height: 150%;mso-line-height-rule: exactly;-ms-text-size-adjust: 100%;-webkit-text-size-adjust: 100%;word-break: break-word;color: #202020;font-size: 16px;text-align: left;">

                             <div style="text-align: center;"><strong><span style="font-size:24px">SEE YOU NEXT WEEK...</span></strong><br>
 <br>
 Tips and leads for next week's edition? Email them to us at <a href="mailto:hi@satoshiscloset.com?subject=Newsletter%20tips%20and%20leads" target="_blank" style="mso-line-height-rule: exactly;-ms-text-size-adjust: 100%;-webkit-text-size-adjust: 100%;color: #42702f;font-weight: normal;text-decoration: underline;">hi@satoshiscloset.com</a>.</div>

                         </td>
                     </tr>
                 </tbody></table>
             </td>
         </tr>
     </tbody>
 </table></td>
                             </tr>

                         </table>
                     </td>
                 </tr>
             </table>
         </center>
     </body>
 </html>
 `

 const POST5 = `<!doctype html>
 <html xmlns="http://www.w3.org/1999/xhtml" xmlns:v="urn:schemas-microsoft-com:vml" xmlns:o="urn:schemas-microsoft-com:office:office">
     <head>
         <!-- NAME: 1 COLUMN -->
         <!--[if gte mso 15]>
         <xml>
             <o:OfficeDocumentSettings>
             <o:AllowPNG/>
             <o:PixelsPerInch>96</o:PixelsPerInch>
             </o:OfficeDocumentSettings>
         </xml>
         <![endif]-->
         <meta charset="UTF-8">
         <meta http-equiv="X-UA-Compatible" content="IE=edge">
         <meta name="viewport" content="width=device-width, initial-scale=1">
         <title>[005-05-2019] Atomic swaps &amp; Atomic Fireballs</title>

     <style type="text/css">
         p{
             margin:10px 0;
             padding:0;
         }
         table{
             border-collapse:collapse;
         }
         h1,h2,h3,h4,h5,h6{
             display:block;
             margin:0;
             padding:0;
         }
         img,a img{
             border:0;
             height:auto;
             outline:none;
             text-decoration:none;
         }
         body,#bodyTable,#bodyCell{
             height:100%;
             margin:0;
             padding:0;
             width:100%;
         }
         .mcnPreviewText{
             display:none !important;
         }
         #outlook a{
             padding:0;
         }
         img{
             -ms-interpolation-mode:bicubic;
         }
         table{
             mso-table-lspace:0pt;
             mso-table-rspace:0pt;
         }
         .ReadMsgBody{
             width:100%;
         }
         .ExternalClass{
             width:100%;
         }
         p,a,li,td,blockquote{
             mso-line-height-rule:exactly;
         }
         a[href^=tel],a[href^=sms]{
             color:inherit;
             cursor:default;
             text-decoration:none;
         }
         p,a,li,td,body,table,blockquote{
             -ms-text-size-adjust:100%;
             -webkit-text-size-adjust:100%;
         }
         .ExternalClass,.ExternalClass p,.ExternalClass td,.ExternalClass div,.ExternalClass span,.ExternalClass font{
             line-height:100%;
         }
         a[x-apple-data-detectors]{
             color:inherit !important;
             text-decoration:none !important;
             font-size:inherit !important;
             font-family:inherit !important;
             font-weight:inherit !important;
             line-height:inherit !important;
         }
         #bodyCell{
             padding:10px;
         }
         .templateContainer{
             max-width:600px !important;
         }
         a.mcnButton{
             display:block;
         }
         .mcnImage,.mcnRetinaImage{
             vertical-align:bottom;
         }
         .mcnTextContent{
             word-break:break-word;
         }
         .mcnTextContent img{
             height:auto !important;
         }
         .mcnDividerBlock{
             table-layout:fixed !important;
         }
         body,#bodyTable{
             background-color:#ffffff;
         }
         #bodyCell{
             border-top:0;
         }
         .templateContainer{
             border:0;
         }
         h1{
             color:#202020;
             font-family:Helvetica;
             font-size:26px;
             font-style:normal;
             font-weight:bold;
             line-height:125%;
             letter-spacing:normal;
             text-align:left;
         }
         h2{
             color:#202020;
             font-family:Helvetica;
             font-size:22px;
             font-style:normal;
             font-weight:bold;
             line-height:125%;
             letter-spacing:normal;
             text-align:left;
         }
         h3{
             color:#202020;
             font-family:Helvetica;
             font-size:20px;
             font-style:normal;
             font-weight:bold;
             line-height:125%;
             letter-spacing:normal;
             text-align:left;
         }
         h4{
             color:#202020;
             font-family:Helvetica;
             font-size:18px;
             font-style:normal;
             font-weight:bold;
             line-height:125%;
             letter-spacing:normal;
             text-align:left;
         }
         #templatePreheader{
             background-color:#FAFAFA;
             background-image:none;
             background-repeat:no-repeat;
             background-position:center;
             background-size:cover;
             border-top:0;
             border-bottom:0;
             padding-top:9px;
             padding-bottom:9px;
         }
         #templatePreheader .mcnTextContent,#templatePreheader .mcnTextContent p{
             color:#656565;
             font-family:Helvetica;
             font-size:12px;
             line-height:150%;
             text-align:left;
         }
         #templatePreheader .mcnTextContent a,#templatePreheader .mcnTextContent p a{
             color:#656565;
             font-weight:normal;
             text-decoration:underline;
         }
         #templateHeader{
             background-color:#ffffff;
             background-image:none;
             background-repeat:no-repeat;
             background-position:center;
             background-size:cover;
             border-top:0;
             border-bottom:0;
             padding-top:18px;
             padding-bottom:0px;
         }
         #templateHeader .mcnTextContent,#templateHeader .mcnTextContent p{
             color:#202020;
             font-family:Helvetica;
             font-size:12px;
             line-height:125%;
             text-align:left;
         }
         #templateHeader .mcnTextContent a,#templateHeader .mcnTextContent p a{
             color:#007C89;
             font-weight:normal;
             text-decoration:underline;
         }
         #templateBody{
             background-color:#ffffff;
             background-image:none;
             background-repeat:no-repeat;
             background-position:center;
             background-size:cover;
             border-top:0;
             border-bottom:2px solid #EAEAEA;
             padding-top:0;
             padding-bottom:9px;
         }
         #templateBody .mcnTextContent,#templateBody .mcnTextContent p{
             color:#202020;
             font-family:'Noticia Text', Georgia, 'Times New Roman', serif;
             font-size:16px;
             line-height:150%;
             text-align:left;
         }
         #templateBody .mcnTextContent a,#templateBody .mcnTextContent p a{
             color:#42702f;
             font-weight:normal;
             text-decoration:underline;
         }
         #templateFooter{
             background-color:#ffffff;
             background-image:none;
             background-repeat:no-repeat;
             background-position:center;
             background-size:cover;
             border-top:0;
             border-bottom:0;
             padding-top:9px;
             padding-bottom:9px;
         }
         #templateFooter .mcnTextContent,#templateFooter .mcnTextContent p{
             color:#656565;
             font-family:Helvetica;
             font-size:12px;
             line-height:150%;
             text-align:center;
         }
         #templateFooter .mcnTextContent a,#templateFooter .mcnTextContent p a{
             color:#656565;
             font-weight:normal;
             text-decoration:underline;
         }
     @media only screen and (min-width:768px){
         .templateContainer{
             width:600px !important;
         }

 }   @media only screen and (max-width: 480px){
         body,table,td,p,a,li,blockquote{
             -webkit-text-size-adjust:none !important;
         }

 }   @media only screen and (max-width: 480px){
         body{
             width:100% !important;
             min-width:100% !important;
         }

 }   @media only screen and (max-width: 480px){
         #bodyCell{
             padding-top:10px !important;
         }

 }   @media only screen and (max-width: 480px){
         .mcnRetinaImage{
             max-width:100% !important;
         }

 }   @media only screen and (max-width: 480px){
         .mcnImage{
             width:100% !important;
         }

 }   @media only screen and (max-width: 480px){
         .mcnCartContainer,.mcnCaptionTopContent,.mcnRecContentContainer,.mcnCaptionBottomContent,.mcnTextContentContainer,.mcnBoxedTextContentContainer,.mcnImageGroupContentContainer,.mcnCaptionLeftTextContentContainer,.mcnCaptionRightTextContentContainer,.mcnCaptionLeftImageContentContainer,.mcnCaptionRightImageContentContainer,.mcnImageCardLeftTextContentContainer,.mcnImageCardRightTextContentContainer,.mcnImageCardLeftImageContentContainer,.mcnImageCardRightImageContentContainer{
             max-width:100% !important;
             width:100% !important;
         }

 }   @media only screen and (max-width: 480px){
         .mcnBoxedTextContentContainer{
             min-width:100% !important;
         }

 }   @media only screen and (max-width: 480px){
         .mcnImageGroupContent{
             padding:9px !important;
         }

 }   @media only screen and (max-width: 480px){
         .mcnCaptionLeftContentOuter .mcnTextContent,.mcnCaptionRightContentOuter .mcnTextContent{
             padding-top:9px !important;
         }

 }   @media only screen and (max-width: 480px){
         .mcnImageCardTopImageContent,.mcnCaptionBottomContent:last-child .mcnCaptionBottomImageContent,.mcnCaptionBlockInner .mcnCaptionTopContent:last-child .mcnTextContent{
             padding-top:18px !important;
         }

 }   @media only screen and (max-width: 480px){
         .mcnImageCardBottomImageContent{
             padding-bottom:9px !important;
         }

 }   @media only screen and (max-width: 480px){
         .mcnImageGroupBlockInner{
             padding-top:0 !important;
             padding-bottom:0 !important;
         }

 }   @media only screen and (max-width: 480px){
         .mcnImageGroupBlockOuter{
             padding-top:9px !important;
             padding-bottom:9px !important;
         }

 }   @media only screen and (max-width: 480px){
         .mcnTextContent,.mcnBoxedTextContentColumn{
             padding-right:18px !important;
             padding-left:18px !important;
         }

 }   @media only screen and (max-width: 480px){
         .mcnImageCardLeftImageContent,.mcnImageCardRightImageContent{
             padding-right:18px !important;
             padding-bottom:0 !important;
             padding-left:18px !important;
         }

 }   @media only screen and (max-width: 480px){
         .mcpreview-image-uploader{
             display:none !important;
             width:100% !important;
         }

 }   @media only screen and (max-width: 480px){
         h1{
             font-size:22px !important;
             line-height:125% !important;
         }

 }   @media only screen and (max-width: 480px){
         h2{
             font-size:20px !important;
             line-height:125% !important;
         }

 }   @media only screen and (max-width: 480px){
         h3{
             font-size:18px !important;
             line-height:125% !important;
         }

 }   @media only screen and (max-width: 480px){
         h4{
             font-size:16px !important;
             line-height:150% !important;
         }

 }   @media only screen and (max-width: 480px){
         .mcnBoxedTextContentContainer .mcnTextContent,.mcnBoxedTextContentContainer .mcnTextContent p{
             font-size:14px !important;
             line-height:150% !important;
         }

 }   @media only screen and (max-width: 480px){
         #templatePreheader{
             display:block !important;
         }

 }   @media only screen and (max-width: 480px){
         #templatePreheader .mcnTextContent,#templatePreheader .mcnTextContent p{
             font-size:14px !important;
             line-height:150% !important;
         }

 }   @media only screen and (max-width: 480px){
         #templateHeader .mcnTextContent,#templateHeader .mcnTextContent p{
             font-size:16px !important;
             line-height:150% !important;
         }

 }   @media only screen and (max-width: 480px){
         #templateBody .mcnTextContent,#templateBody .mcnTextContent p{
             font-size:16px !important;
             line-height:150% !important;
         }

 }   @media only screen and (max-width: 480px){
         #templateFooter .mcnTextContent,#templateFooter .mcnTextContent p{
             font-size:14px !important;
             line-height:150% !important;
         }

 }</style><!--[if !mso]><!--><link href="https://fonts.googleapis.com/css?family=Noticia+Text:400,400i,700,700i" rel="stylesheet"><!--<![endif]--></head>
     <body style="height: 100%;margin: 0;padding: 0;width: 100%;-ms-text-size-adjust: 100%;-webkit-text-size-adjust: 100%;background-color: #ffffff;">
         <!--*|IF:MC_PREVIEW_TEXT|*-->
         <!--[if !gte mso 9]><!----><span class="mcnPreviewText" style="display:none; font-size:0px; line-height:0px; max-height:0px; max-width:0px; opacity:0; overflow:hidden; visibility:hidden; mso-hide:all;">*|MC_PREVIEW_TEXT|*</span><!--<![endif]-->
         <!--*|END:IF|*-->
         <center>
             <table align="center" border="0" cellpadding="0" cellspacing="0" height="100%" width="100%" id="bodyTable" style="border-collapse: collapse;mso-table-lspace: 0pt;mso-table-rspace: 0pt;-ms-text-size-adjust: 100%;-webkit-text-size-adjust: 100%;height: 100%;margin: 0;padding: 0;width: 100%;background-color: #ffffff;">
                 <tr>
                     <td align="center" valign="top" id="bodyCell" style="mso-line-height-rule: exactly;-ms-text-size-adjust: 100%;-webkit-text-size-adjust: 100%;height: 100%;margin: 0;padding: 10px;width: 100%;border-top: 0;">
                         <!-- BEGIN TEMPLATE // -->
                         <!--[if (gte mso 9)|(IE)]>
                         <table align="center" border="0" cellspacing="0" cellpadding="0" width="600" style="width:600px;">
                         <tr>
                         <td align="center" valign="top" width="600" style="width:600px;">
                         <![endif]-->
                         <table border="0" cellpadding="0" cellspacing="0" width="100%" class="templateContainer" style="border-collapse: collapse;mso-table-lspace: 0pt;mso-table-rspace: 0pt;-ms-text-size-adjust: 100%;-webkit-text-size-adjust: 100%;border: 0;max-width: 600px !important;">

                             <tr>
                                 <td valign="top" id="templateHeader" style="background:#ffffff none no-repeat center/cover;mso-line-height-rule: exactly;-ms-text-size-adjust: 100%;-webkit-text-size-adjust: 100%;background-color: #ffffff;background-image: none;background-repeat: no-repeat;background-position: center;background-size: cover;border-top: 0;border-bottom: 0;padding-top: 18px;padding-bottom: 0px;"><table border="0" cellpadding="0" cellspacing="0" width="100%" class="mcnImageBlock" style="min-width: 100%;border-collapse: collapse;mso-table-lspace: 0pt;mso-table-rspace: 0pt;-ms-text-size-adjust: 100%;-webkit-text-size-adjust: 100%;">
     <tbody class="mcnImageBlockOuter">
             <tr>
                 <td valign="top" style="padding: 9px;mso-line-height-rule: exactly;-ms-text-size-adjust: 100%;-webkit-text-size-adjust: 100%;" class="mcnImageBlockInner">
                     <table align="left" width="100%" border="0" cellpadding="0" cellspacing="0" class="mcnImageContentContainer" style="min-width: 100%;border-collapse: collapse;mso-table-lspace: 0pt;mso-table-rspace: 0pt;-ms-text-size-adjust: 100%;-webkit-text-size-adjust: 100%;">
                         <tbody><tr>
                             <td class="mcnImageContent" valign="top" style="padding-right: 9px;padding-left: 9px;padding-top: 0;padding-bottom: 0;text-align: center;mso-line-height-rule: exactly;-ms-text-size-adjust: 100%;-webkit-text-size-adjust: 100%;">


                                         <img align="center" alt="" src="https://gallery.mailchimp.com/adee9c5618cae148bdcf8f762/images/1a087f93-229e-4a53-80a4-bbc26757e872.jpg" width="564" style="max-width: 960px;padding-bottom: 0;display: inline !important;vertical-align: bottom;border: 0;height: auto;outline: none;text-decoration: none;-ms-interpolation-mode: bicubic;" class="mcnImage">


                             </td>
                         </tr>
                     </tbody></table>
                 </td>
             </tr>
     </tbody>
 </table></td>
                             </tr>
                             <tr>
                                 <td valign="top" id="templateBody" style="background:#ffffff none no-repeat center/cover;mso-line-height-rule: exactly;-ms-text-size-adjust: 100%;-webkit-text-size-adjust: 100%;background-color: #ffffff;background-image: none;background-repeat: no-repeat;background-position: center;background-size: cover;border-top: 0;border-bottom: 2px solid #EAEAEA;padding-top: 0;padding-bottom: 9px;"><table border="0" cellpadding="0" cellspacing="0" width="100%" class="mcnDividerBlock" style="min-width: 100%;border-collapse: collapse;mso-table-lspace: 0pt;mso-table-rspace: 0pt;-ms-text-size-adjust: 100%;-webkit-text-size-adjust: 100%;table-layout: fixed !important;">
     <tbody class="mcnDividerBlockOuter">
         <tr>
             <td class="mcnDividerBlockInner" style="min-width: 100%;padding: 8px 18px 0px;mso-line-height-rule: exactly;-ms-text-size-adjust: 100%;-webkit-text-size-adjust: 100%;">
                 <table class="mcnDividerContent" border="0" cellpadding="0" cellspacing="0" width="100%" style="min-width: 100%;border-top: 2px solid #EAEAEA;border-collapse: collapse;mso-table-lspace: 0pt;mso-table-rspace: 0pt;-ms-text-size-adjust: 100%;-webkit-text-size-adjust: 100%;">
                     <tbody><tr>
                         <td style="mso-line-height-rule: exactly;-ms-text-size-adjust: 100%;-webkit-text-size-adjust: 100%;">
                             <span></span>
                         </td>
                     </tr>
                 </tbody></table>
 <!--
                 <td class="mcnDividerBlockInner" style="padding: 18px;">
                 <hr class="mcnDividerContent" style="border-bottom-color:none; border-left-color:none; border-right-color:none; border-bottom-width:0; border-left-width:0; border-right-width:0; margin-top:0; margin-right:0; margin-bottom:0; margin-left:0;" />
 -->
             </td>
         </tr>
     </tbody>
 </table><table border="0" cellpadding="0" cellspacing="0" width="100%" class="mcnTextBlock" style="min-width: 100%;border-collapse: collapse;mso-table-lspace: 0pt;mso-table-rspace: 0pt;-ms-text-size-adjust: 100%;-webkit-text-size-adjust: 100%;">
     <tbody class="mcnTextBlockOuter">
         <tr>
             <td valign="top" class="mcnTextBlockInner" style="padding-top: 9px;mso-line-height-rule: exactly;-ms-text-size-adjust: 100%;-webkit-text-size-adjust: 100%;">
                 <!--[if mso]>
                 <table align="left" border="0" cellspacing="0" cellpadding="0" width="100%" style="width:100%;">
                 <tr>
                 <![endif]-->

                 <!--[if mso]>
                 <td valign="top" width="600" style="width:600px;">
                 <![endif]-->
                 <table align="left" border="0" cellpadding="0" cellspacing="0" style="max-width: 100%;min-width: 100%;border-collapse: collapse;mso-table-lspace: 0pt;mso-table-rspace: 0pt;-ms-text-size-adjust: 100%;-webkit-text-size-adjust: 100%;" width="100%" class="mcnTextContentContainer">
                     <tbody><tr>

                         <td valign="top" class="mcnTextContent" style="padding: 0px 18px 9px;font-family: "Noticia Text", Georgia, "Times New Roman", serif;line-height: 150%;mso-line-height-rule: exactly;-ms-text-size-adjust: 100%;-webkit-text-size-adjust: 100%;word-break: break-word;color: #202020;font-size: 16px;text-align: left;">

                             <div style="text-align: left;"><strong><span style="font-size:12px"><span style="font-family:noticia text,georgia,times new roman,serif">Congressional Bitcoin demo • A crypto skeptic’s view • Clowning the commish</span></span></strong></div>

                         </td>
                     </tr>
                 </tbody></table>
                 <!--[if mso]>
                 </td>
                 <![endif]-->

                 <!--[if mso]>
                 </tr>
                 </table>
                 <![endif]-->
             </td>
         </tr>
     </tbody>
 </table><table border="0" cellpadding="0" cellspacing="0" width="100%" class="mcnDividerBlock" style="min-width: 100%;border-collapse: collapse;mso-table-lspace: 0pt;mso-table-rspace: 0pt;-ms-text-size-adjust: 100%;-webkit-text-size-adjust: 100%;table-layout: fixed !important;">
     <tbody class="mcnDividerBlockOuter">
         <tr>
             <td class="mcnDividerBlockInner" style="min-width: 100%;padding: 2px 18px 9px;mso-line-height-rule: exactly;-ms-text-size-adjust: 100%;-webkit-text-size-adjust: 100%;">
                 <table class="mcnDividerContent" border="0" cellpadding="0" cellspacing="0" width="100%" style="min-width: 100%;border-top: 2px solid #EAEAEA;border-collapse: collapse;mso-table-lspace: 0pt;mso-table-rspace: 0pt;-ms-text-size-adjust: 100%;-webkit-text-size-adjust: 100%;">
                     <tbody><tr>
                         <td style="mso-line-height-rule: exactly;-ms-text-size-adjust: 100%;-webkit-text-size-adjust: 100%;">
                             <span></span>
                         </td>
                     </tr>
                 </tbody></table>
 <!--
                 <td class="mcnDividerBlockInner" style="padding: 18px;">
                 <hr class="mcnDividerContent" style="border-bottom-color:none; border-left-color:none; border-right-color:none; border-bottom-width:0; border-left-width:0; border-right-width:0; margin-top:0; margin-right:0; margin-bottom:0; margin-left:0;" />
 -->
             </td>
         </tr>
     </tbody>
 </table><table border="0" cellpadding="0" cellspacing="0" width="100%" class="mcnTextBlock" style="min-width: 100%;border-collapse: collapse;mso-table-lspace: 0pt;mso-table-rspace: 0pt;-ms-text-size-adjust: 100%;-webkit-text-size-adjust: 100%;">
     <tbody class="mcnTextBlockOuter">
         <tr>
             <td valign="top" class="mcnTextBlockInner" style="padding-top: 9px;mso-line-height-rule: exactly;-ms-text-size-adjust: 100%;-webkit-text-size-adjust: 100%;">
                 <!--[if mso]>
                 <table align="left" border="0" cellspacing="0" cellpadding="0" width="100%" style="width:100%;">
                 <tr>
                 <![endif]-->

                 <!--[if mso]>
                 <td valign="top" width="600" style="width:600px;">
                 <![endif]-->
                 <table align="left" border="0" cellpadding="0" cellspacing="0" style="max-width: 100%;min-width: 100%;border-collapse: collapse;mso-table-lspace: 0pt;mso-table-rspace: 0pt;-ms-text-size-adjust: 100%;-webkit-text-size-adjust: 100%;" width="100%" class="mcnTextContentContainer">
                     <tbody><tr>

                         <td valign="top" class="mcnTextContent" style="padding: 0px 18px 9px;font-family: "Noticia Text", Georgia, "Times New Roman", serif;line-height: 150%;mso-line-height-rule: exactly;-ms-text-size-adjust: 100%;-webkit-text-size-adjust: 100%;word-break: break-word;color: #202020;font-size: 16px;text-align: left;">

                             <p dir="ltr" style="font-family: "Noticia Text", Georgia, "Times New Roman", serif;line-height: 150%;margin: 10px 0;padding: 0;mso-line-height-rule: exactly;-ms-text-size-adjust: 100%;-webkit-text-size-adjust: 100%;color: #202020;font-size: 16px;text-align: left;"><span style="font-size:18px"><strong>Nailed it! Here’s your Skittles, Congressperson.</strong></span><br>
 <br>
 Remember that really cool science project you had to stand up and show the entire class back in 5th grade? Well now imagine that instead of 5th grade it’s the U.S. Congress and that your science project is Bitcoin. The team from <a href="https://coincenter.org/" target="_blank" style="mso-line-height-rule: exactly;-ms-text-size-adjust: 100%;-webkit-text-size-adjust: 100%;color: #42702f;font-weight: normal;text-decoration: underline;">Coin Center</a> must have been feeling the pressure given the stature of the audience, and thankfully their demonstration of a live Bitcoin transaction on the Lightning Network was successful. Click below to watch the short clip (it’s only 14 seconds long, you can do it) and catch a glimpse of our crypto future. </p>

                         </td>
                     </tr>
                 </tbody></table>
                 <!--[if mso]>
                 </td>
                 <![endif]-->

                 <!--[if mso]>
                 </tr>
                 </table>
                 <![endif]-->
             </td>
         </tr>
     </tbody>
 </table><table border="0" cellpadding="0" cellspacing="0" width="100%" class="mcnImageBlock" style="min-width: 100%;border-collapse: collapse;mso-table-lspace: 0pt;mso-table-rspace: 0pt;-ms-text-size-adjust: 100%;-webkit-text-size-adjust: 100%;">
     <tbody class="mcnImageBlockOuter">
             <tr>
                 <td valign="top" style="padding: 9px;mso-line-height-rule: exactly;-ms-text-size-adjust: 100%;-webkit-text-size-adjust: 100%;" class="mcnImageBlockInner">
                     <table align="left" width="100%" border="0" cellpadding="0" cellspacing="0" class="mcnImageContentContainer" style="min-width: 100%;border-collapse: collapse;mso-table-lspace: 0pt;mso-table-rspace: 0pt;-ms-text-size-adjust: 100%;-webkit-text-size-adjust: 100%;">
                         <tbody><tr>
                             <td class="mcnImageContent" valign="top" style="padding-right: 9px;padding-left: 9px;padding-top: 0;padding-bottom: 0;text-align: center;mso-line-height-rule: exactly;-ms-text-size-adjust: 100%;-webkit-text-size-adjust: 100%;">

                                     <a href="https://twitter.com/coincenter/status/1086357640958148608" title="" class="" target="_blank" style="mso-line-height-rule: exactly;-ms-text-size-adjust: 100%;-webkit-text-size-adjust: 100%;">
                                         <img align="center" alt="" src="https://gallery.mailchimp.com/adee9c5618cae148bdcf8f762/images/3720ad0d-2c18-46c4-bd18-ca0f3f230ab3.jpg" width="564" style="max-width: 984px;padding-bottom: 0;display: inline !important;vertical-align: bottom;border: 0;height: auto;outline: none;text-decoration: none;-ms-interpolation-mode: bicubic;" class="mcnImage">
                                     </a>

                             </td>
                         </tr>
                     </tbody></table>
                 </td>
             </tr>
     </tbody>
 </table><table border="0" cellpadding="0" cellspacing="0" width="100%" class="mcnTextBlock" style="min-width: 100%;border-collapse: collapse;mso-table-lspace: 0pt;mso-table-rspace: 0pt;-ms-text-size-adjust: 100%;-webkit-text-size-adjust: 100%;">
     <tbody class="mcnTextBlockOuter">
         <tr>
             <td valign="top" class="mcnTextBlockInner" style="padding-top: 9px;mso-line-height-rule: exactly;-ms-text-size-adjust: 100%;-webkit-text-size-adjust: 100%;">
                 <!--[if mso]>
                 <table align="left" border="0" cellspacing="0" cellpadding="0" width="100%" style="width:100%;">
                 <tr>
                 <![endif]-->

                 <!--[if mso]>
                 <td valign="top" width="600" style="width:600px;">
                 <![endif]-->
                 <table align="left" border="0" cellpadding="0" cellspacing="0" style="max-width: 100%;min-width: 100%;border-collapse: collapse;mso-table-lspace: 0pt;mso-table-rspace: 0pt;-ms-text-size-adjust: 100%;-webkit-text-size-adjust: 100%;" width="100%" class="mcnTextContentContainer">
                     <tbody><tr>

                         <td valign="top" class="mcnTextContent" style="padding-top: 0;padding-right: 18px;padding-bottom: 9px;padding-left: 18px;mso-line-height-rule: exactly;-ms-text-size-adjust: 100%;-webkit-text-size-adjust: 100%;word-break: break-word;color: #202020;font-family: 'Noticia Text', Georgia, 'Times New Roman', serif;font-size: 16px;line-height: 150%;text-align: left;">

                             Read a bit more on the Coin Center blog: <a href="https://coincenter.org/link/we-demonstrated-the-bitcoin-lightning-network-in-congress" target="_blank" style="mso-line-height-rule: exactly;-ms-text-size-adjust: 100%;-webkit-text-size-adjust: 100%;color: #42702f;font-weight: normal;text-decoration: underline;">https://coincenter.org/link/we-demonstrated-the-bitcoin-lightning-network-in-congress</a><br>
 <br>
 And did you know there is a Congressional Blockchain Caucus? <a href="https://www.congressionalblockchaincaucus.com" target="_blank" style="mso-line-height-rule: exactly;-ms-text-size-adjust: 100%;-webkit-text-size-adjust: 100%;color: #42702f;font-weight: normal;text-decoration: underline;">https://www.congressionalblockchaincaucus.com</a>
                         </td>
                     </tr>
                 </tbody></table>
                 <!--[if mso]>
                 </td>
                 <![endif]-->

                 <!--[if mso]>
                 </tr>
                 </table>
                 <![endif]-->
             </td>
         </tr>
     </tbody>
 </table><table border="0" cellpadding="0" cellspacing="0" width="100%" class="mcnDividerBlock" style="min-width: 100%;border-collapse: collapse;mso-table-lspace: 0pt;mso-table-rspace: 0pt;-ms-text-size-adjust: 100%;-webkit-text-size-adjust: 100%;table-layout: fixed !important;">
     <tbody class="mcnDividerBlockOuter">
         <tr>
             <td class="mcnDividerBlockInner" style="min-width: 100%;padding: 24px 18px;mso-line-height-rule: exactly;-ms-text-size-adjust: 100%;-webkit-text-size-adjust: 100%;">
                 <table class="mcnDividerContent" border="0" cellpadding="0" cellspacing="0" width="100%" style="min-width: 100%;border-top: 2px solid #EAEAEA;border-collapse: collapse;mso-table-lspace: 0pt;mso-table-rspace: 0pt;-ms-text-size-adjust: 100%;-webkit-text-size-adjust: 100%;">
                     <tbody><tr>
                         <td style="mso-line-height-rule: exactly;-ms-text-size-adjust: 100%;-webkit-text-size-adjust: 100%;">
                             <span></span>
                         </td>
                     </tr>
                 </tbody></table>
 <!--
                 <td class="mcnDividerBlockInner" style="padding: 18px;">
                 <hr class="mcnDividerContent" style="border-bottom-color:none; border-left-color:none; border-right-color:none; border-bottom-width:0; border-left-width:0; border-right-width:0; margin-top:0; margin-right:0; margin-bottom:0; margin-left:0;" />
 -->
             </td>
         </tr>
     </tbody>
 </table><table border="0" cellpadding="0" cellspacing="0" width="100%" class="mcnTextBlock" style="min-width: 100%;border-collapse: collapse;mso-table-lspace: 0pt;mso-table-rspace: 0pt;-ms-text-size-adjust: 100%;-webkit-text-size-adjust: 100%;">
     <tbody class="mcnTextBlockOuter">
         <tr>
             <td valign="top" class="mcnTextBlockInner" style="padding-top: 9px;mso-line-height-rule: exactly;-ms-text-size-adjust: 100%;-webkit-text-size-adjust: 100%;">
                 <!--[if mso]>
                 <table align="left" border="0" cellspacing="0" cellpadding="0" width="100%" style="width:100%;">
                 <tr>
                 <![endif]-->

                 <!--[if mso]>
                 <td valign="top" width="600" style="width:600px;">
                 <![endif]-->
                 <table align="left" border="0" cellpadding="0" cellspacing="0" style="max-width: 100%;min-width: 100%;border-collapse: collapse;mso-table-lspace: 0pt;mso-table-rspace: 0pt;-ms-text-size-adjust: 100%;-webkit-text-size-adjust: 100%;" width="100%" class="mcnTextContentContainer">
                     <tbody><tr>

                         <td valign="top" class="mcnTextContent" style="padding: 0px 18px 9px;font-family: "Noticia Text", Georgia, "Times New Roman", serif;line-height: 150%;mso-line-height-rule: exactly;-ms-text-size-adjust: 100%;-webkit-text-size-adjust: 100%;word-break: break-word;color: #202020;font-size: 16px;text-align: left;">

                             <p dir="ltr" style="font-family: "Noticia Text", Georgia, "Times New Roman", serif;line-height: 150%;margin: 10px 0;padding: 0;mso-line-height-rule: exactly;-ms-text-size-adjust: 100%;-webkit-text-size-adjust: 100%;color: #202020;font-size: 16px;text-align: left;"><span style="font-size:18px"><strong>Yep, he actually called it Buttcoin</strong></span></p>

                         </td>
                     </tr>
                 </tbody></table>
                 <!--[if mso]>
                 </td>
                 <![endif]-->

                 <!--[if mso]>
                 </tr>
                 </table>
                 <![endif]-->
             </td>
         </tr>
     </tbody>
 </table><table border="0" cellpadding="0" cellspacing="0" width="100%" class="mcnTextBlock" style="min-width: 100%;border-collapse: collapse;mso-table-lspace: 0pt;mso-table-rspace: 0pt;-ms-text-size-adjust: 100%;-webkit-text-size-adjust: 100%;">
     <tbody class="mcnTextBlockOuter">
         <tr>
             <td valign="top" class="mcnTextBlockInner" style="padding-top: 9px;mso-line-height-rule: exactly;-ms-text-size-adjust: 100%;-webkit-text-size-adjust: 100%;">
                 <!--[if mso]>
                 <table align="left" border="0" cellspacing="0" cellpadding="0" width="100%" style="width:100%;">
                 <tr>
                 <![endif]-->

                 <!--[if mso]>
                 <td valign="top" width="600" style="width:600px;">
                 <![endif]-->
                 <table align="left" border="0" cellpadding="0" cellspacing="0" style="max-width: 100%;min-width: 100%;border-collapse: collapse;mso-table-lspace: 0pt;mso-table-rspace: 0pt;-ms-text-size-adjust: 100%;-webkit-text-size-adjust: 100%;" width="100%" class="mcnTextContentContainer">
                     <tbody><tr>

                         <td valign="top" class="mcnTextContent" style="padding-top: 0;padding-right: 18px;padding-bottom: 9px;padding-left: 18px;mso-line-height-rule: exactly;-ms-text-size-adjust: 100%;-webkit-text-size-adjust: 100%;word-break: break-word;color: #202020;font-family: 'Noticia Text', Georgia, 'Times New Roman', serif;font-size: 16px;line-height: 150%;text-align: left;">

                             Somewhere between rational crypto skepticism and ‘get off my lawn!’ is the latest piece making the rounds by author, <a href="https://twitter.com/davidgerard" target="_blank" style="mso-line-height-rule: exactly;-ms-text-size-adjust: 100%;-webkit-text-size-adjust: 100%;color: #42702f;font-weight: normal;text-decoration: underline;">David Gerard</a>. The usual critiques are there: energy consumption, efficiency (or lack thereof), and the centralization of network control by powerful mining operators. These areas are worthy of examination and improvement. Yet a few of Gerard’s assertions raise an eyebrow:<br>
 <br>
 <em>“If you believe Bitcoin is a legitimate alternative to the current monetary system – set out how that would happen. Make a plan that doesn’t have really obvious huge glaring holes in it – and that shows comprehension of how the existing system works and the stuff it does. Bitcoiners have never put forward a reality-based plan to replace the present financial system, that accounts for what the existing system does.”</em>
                         </td>
                     </tr>
                 </tbody></table>
                 <!--[if mso]>
                 </td>
                 <![endif]-->

                 <!--[if mso]>
                 </tr>
                 </table>
                 <![endif]-->
             </td>
         </tr>
     </tbody>
 </table><table border="0" cellpadding="0" cellspacing="0" width="100%" class="mcnImageCardBlock" style="border-collapse: collapse;mso-table-lspace: 0pt;mso-table-rspace: 0pt;-ms-text-size-adjust: 100%;-webkit-text-size-adjust: 100%;">
     <tbody class="mcnImageCardBlockOuter">
         <tr>
             <td class="mcnImageCardBlockInner" valign="top" style="padding-top: 9px;padding-right: 18px;padding-bottom: 9px;padding-left: 18px;mso-line-height-rule: exactly;-ms-text-size-adjust: 100%;-webkit-text-size-adjust: 100%;">

 <table align="right" border="0" cellpadding="0" cellspacing="0" class="mcnImageCardBottomContent" width="100%" style="border-collapse: collapse;mso-table-lspace: 0pt;mso-table-rspace: 0pt;-ms-text-size-adjust: 100%;-webkit-text-size-adjust: 100%;">
     <tbody><tr>
         <td class="mcnImageCardBottomImageContent" align="center" valign="top" style="padding-top: 0px;padding-right: 0px;padding-bottom: 0;padding-left: 0px;mso-line-height-rule: exactly;-ms-text-size-adjust: 100%;-webkit-text-size-adjust: 100%;">



             <img alt="" src="https://gallery.mailchimp.com/adee9c5618cae148bdcf8f762/images/fa00defc-d3a2-4e6a-867a-213b8ab27294.jpeg" width="564" style="max-width: 640px;border: 0;height: auto;outline: none;text-decoration: none;-ms-interpolation-mode: bicubic;vertical-align: bottom;" class="mcnImage">


         </td>
     </tr>
     <tr>
         <td class="mcnTextContent" valign="top" style="padding: 9px 18px;color: #747474;font-family: "Noticia Text", Georgia, "Times New Roman", serif;font-size: 14px;font-weight: normal;text-align: center;mso-line-height-rule: exactly;-ms-text-size-adjust: 100%;-webkit-text-size-adjust: 100%;word-break: break-word;line-height: 150%;" width="546">
             <div dir="ltr" style="text-align: center;"><em>“Just wait until we invent the credit default swap!”</em></div>

         </td>
     </tr>
 </tbody></table>




             </td>
         </tr>
     </tbody>
 </table><table border="0" cellpadding="0" cellspacing="0" width="100%" class="mcnTextBlock" style="min-width: 100%;border-collapse: collapse;mso-table-lspace: 0pt;mso-table-rspace: 0pt;-ms-text-size-adjust: 100%;-webkit-text-size-adjust: 100%;">
     <tbody class="mcnTextBlockOuter">
         <tr>
             <td valign="top" class="mcnTextBlockInner" style="padding-top: 9px;mso-line-height-rule: exactly;-ms-text-size-adjust: 100%;-webkit-text-size-adjust: 100%;">
                 <!--[if mso]>
                 <table align="left" border="0" cellspacing="0" cellpadding="0" width="100%" style="width:100%;">
                 <tr>
                 <![endif]-->

                 <!--[if mso]>
                 <td valign="top" width="600" style="width:600px;">
                 <![endif]-->
                 <table align="left" border="0" cellpadding="0" cellspacing="0" style="max-width: 100%;min-width: 100%;border-collapse: collapse;mso-table-lspace: 0pt;mso-table-rspace: 0pt;-ms-text-size-adjust: 100%;-webkit-text-size-adjust: 100%;" width="100%" class="mcnTextContentContainer">
                     <tbody><tr>

                         <td valign="top" class="mcnTextContent" style="padding-top: 0;padding-right: 18px;padding-bottom: 9px;padding-left: 18px;mso-line-height-rule: exactly;-ms-text-size-adjust: 100%;-webkit-text-size-adjust: 100%;word-break: break-word;color: #202020;font-family: 'Noticia Text', Georgia, 'Times New Roman', serif;font-size: 16px;line-height: 150%;text-align: left;">

                             Gerard treats designing a new monetary system from the ground up like some homework assignment that can be ginned up in its entirety over the course of a semester sitting in front of a chalkboard. The current monetary system wasn’t presented in a white paper and adopted at the snap of Adam Smith’s fingers. We’re living with the result of centuries of social and economic/monetary evolution. Expecting a new monetary system to be fully baked on day one is absurd. We’d further challenge Gerard’s underlying assumption that tomorrow’s monetary system should even look like today’s to begin with. If certain functions of the current system could be made obsolete through better systems, and new functions created that better serve all people, wouldn’t that be a wiser choice?<br>
 <br>
 <em>"Nobody attacks a cryptographic system at the strongest point – they attack at the weak points. The Bitcoin system is much more than a blockchain. And so we see an endless string of exchange and user hacks – because the Bitcoin blockchain’s cryptographic security is a six-inch steel plate door in a cardboard frame."</em>
                         </td>
                     </tr>
                 </tbody></table>
                 <!--[if mso]>
                 </td>
                 <![endif]-->

                 <!--[if mso]>
                 </tr>
                 </table>
                 <![endif]-->
             </td>
         </tr>
     </tbody>
 </table><table border="0" cellpadding="0" cellspacing="0" width="100%" class="mcnImageCardBlock" style="border-collapse: collapse;mso-table-lspace: 0pt;mso-table-rspace: 0pt;-ms-text-size-adjust: 100%;-webkit-text-size-adjust: 100%;">
     <tbody class="mcnImageCardBlockOuter">
         <tr>
             <td class="mcnImageCardBlockInner" valign="top" style="padding-top: 9px;padding-right: 18px;padding-bottom: 9px;padding-left: 18px;mso-line-height-rule: exactly;-ms-text-size-adjust: 100%;-webkit-text-size-adjust: 100%;">

 <table align="left" border="0" cellpadding="0" cellspacing="0" class="mcnImageCardBottomContent" width="100%" style="border-collapse: collapse;mso-table-lspace: 0pt;mso-table-rspace: 0pt;-ms-text-size-adjust: 100%;-webkit-text-size-adjust: 100%;">
     <tbody><tr>
         <td class="mcnImageCardBottomImageContent" align="center" valign="top" style="padding-top: 0px;padding-right: 0px;padding-bottom: 0;padding-left: 0px;mso-line-height-rule: exactly;-ms-text-size-adjust: 100%;-webkit-text-size-adjust: 100%;">



             <img alt="" src="https://gallery.mailchimp.com/adee9c5618cae148bdcf8f762/images/bbdc6e9f-3102-43a0-9df5-638e1f54a290.gif" width="564" style="max-width: 600px;border: 0;height: auto;outline: none;text-decoration: none;-ms-interpolation-mode: bicubic;vertical-align: bottom;" class="mcnImage">


         </td>
     </tr>
     <tr>
         <td class="mcnTextContent" valign="top" style="padding: 9px 18px;color: #747474;font-family: "Noticia Text", Georgia, "Times New Roman", serif;font-size: 14px;font-weight: normal;text-align: center;mso-line-height-rule: exactly;-ms-text-size-adjust: 100%;-webkit-text-size-adjust: 100%;word-break: break-word;line-height: 150%;" width="546">
             <div dir="ltr" style="text-align: left;"><em>This is way more secure than all of my passwords.</em></div>

         </td>
     </tr>
 </tbody></table>




             </td>
         </tr>
     </tbody>
 </table><table border="0" cellpadding="0" cellspacing="0" width="100%" class="mcnTextBlock" style="min-width: 100%;border-collapse: collapse;mso-table-lspace: 0pt;mso-table-rspace: 0pt;-ms-text-size-adjust: 100%;-webkit-text-size-adjust: 100%;">
     <tbody class="mcnTextBlockOuter">
         <tr>
             <td valign="top" class="mcnTextBlockInner" style="padding-top: 9px;mso-line-height-rule: exactly;-ms-text-size-adjust: 100%;-webkit-text-size-adjust: 100%;">
                 <!--[if mso]>
                 <table align="left" border="0" cellspacing="0" cellpadding="0" width="100%" style="width:100%;">
                 <tr>
                 <![endif]-->

                 <!--[if mso]>
                 <td valign="top" width="600" style="width:600px;">
                 <![endif]-->
                 <table align="left" border="0" cellpadding="0" cellspacing="0" style="max-width: 100%;min-width: 100%;border-collapse: collapse;mso-table-lspace: 0pt;mso-table-rspace: 0pt;-ms-text-size-adjust: 100%;-webkit-text-size-adjust: 100%;" width="100%" class="mcnTextContentContainer">
                     <tbody><tr>

                         <td valign="top" class="mcnTextContent" style="padding-top: 0;padding-right: 18px;padding-bottom: 9px;padding-left: 18px;mso-line-height-rule: exactly;-ms-text-size-adjust: 100%;-webkit-text-size-adjust: 100%;word-break: break-word;color: #202020;font-family: 'Noticia Text', Georgia, 'Times New Roman', serif;font-size: 16px;line-height: 150%;text-align: left;">

                             Okay, this made us LOL. We agree that design and usability issues within the larger blockchain and crypto ecosystem must improve for its greatest potential benefits to be achieved, including solving non-crypto specific nuisances of our day like login and password management.<br>
 <br>
 <em>"Bitcoiners seem to have given up even trying to convince normal people about Bitcoin. All I see is them trying to convince each other. Hodl!"</em><br>
 <br>
 It’s definitely true that the froth of 2017 has subsided, but that’s been the case for a year now (it’s 2019 now, bro). And there’s definitely a new sense of internal solidarity that emerged within the crypto community throughout 2018. But isn’t that a good thing? No great victory was ever earned without a team huddling through adversity to re-strategize and re-energize. And <em>Hodl</em>, bro, really? We’re all about the <em>Buidl</em> now, baby. You know, since an entire new monetary system never dropped overnight.<br>
 <br>
 There’s more great food for thought in David Gerard’s piece on The Block, we recommend you check it out: <a href="https://www.theblockcrypto.com/2019/01/31/the-buttcoin-standard-the-problem-with-bitcoin/" target="_blank" style="mso-line-height-rule: exactly;-ms-text-size-adjust: 100%;-webkit-text-size-adjust: 100%;color: #42702f;font-weight: normal;text-decoration: underline;">https://www.theblockcrypto.com/2019/01/31/the-buttcoin-standard-the-problem-with-bitcoin/</a>
                         </td>
                     </tr>
                 </tbody></table>
                 <!--[if mso]>
                 </td>
                 <![endif]-->

                 <!--[if mso]>
                 </tr>
                 </table>
                 <![endif]-->
             </td>
         </tr>
     </tbody>
 </table><table border="0" cellpadding="0" cellspacing="0" width="100%" class="mcnDividerBlock" style="min-width: 100%;border-collapse: collapse;mso-table-lspace: 0pt;mso-table-rspace: 0pt;-ms-text-size-adjust: 100%;-webkit-text-size-adjust: 100%;table-layout: fixed !important;">
     <tbody class="mcnDividerBlockOuter">
         <tr>
             <td class="mcnDividerBlockInner" style="min-width: 100%;padding: 24px 18px;mso-line-height-rule: exactly;-ms-text-size-adjust: 100%;-webkit-text-size-adjust: 100%;">
                 <table class="mcnDividerContent" border="0" cellpadding="0" cellspacing="0" width="100%" style="min-width: 100%;border-top: 2px solid #EAEAEA;border-collapse: collapse;mso-table-lspace: 0pt;mso-table-rspace: 0pt;-ms-text-size-adjust: 100%;-webkit-text-size-adjust: 100%;">
                     <tbody><tr>
                         <td style="mso-line-height-rule: exactly;-ms-text-size-adjust: 100%;-webkit-text-size-adjust: 100%;">
                             <span></span>
                         </td>
                     </tr>
                 </tbody></table>
 <!--
                 <td class="mcnDividerBlockInner" style="padding: 18px;">
                 <hr class="mcnDividerContent" style="border-bottom-color:none; border-left-color:none; border-right-color:none; border-bottom-width:0; border-left-width:0; border-right-width:0; margin-top:0; margin-right:0; margin-bottom:0; margin-left:0;" />
 -->
             </td>
         </tr>
     </tbody>
 </table><table border="0" cellpadding="0" cellspacing="0" width="100%" class="mcnTextBlock" style="min-width: 100%;border-collapse: collapse;mso-table-lspace: 0pt;mso-table-rspace: 0pt;-ms-text-size-adjust: 100%;-webkit-text-size-adjust: 100%;">
     <tbody class="mcnTextBlockOuter">
         <tr>
             <td valign="top" class="mcnTextBlockInner" style="padding-top: 9px;mso-line-height-rule: exactly;-ms-text-size-adjust: 100%;-webkit-text-size-adjust: 100%;">
                 <!--[if mso]>
                 <table align="left" border="0" cellspacing="0" cellpadding="0" width="100%" style="width:100%;">
                 <tr>
                 <![endif]-->

                 <!--[if mso]>
                 <td valign="top" width="600" style="width:600px;">
                 <![endif]-->
                 <table align="left" border="0" cellpadding="0" cellspacing="0" style="max-width: 100%;min-width: 100%;border-collapse: collapse;mso-table-lspace: 0pt;mso-table-rspace: 0pt;-ms-text-size-adjust: 100%;-webkit-text-size-adjust: 100%;" width="100%" class="mcnTextContentContainer">
                     <tbody><tr>

                         <td valign="top" class="mcnTextContent" style="padding: 0px 18px 9px;font-family: "Noticia Text", Georgia, "Times New Roman", serif;line-height: 150%;mso-line-height-rule: exactly;-ms-text-size-adjust: 100%;-webkit-text-size-adjust: 100%;word-break: break-word;color: #202020;font-size: 16px;text-align: left;">

                             <span style="font-size:18px"><span style="font-family:noticia text,georgia,times new roman,serif"><strong>Cool $#i®T of the week</strong></span></span>
                         </td>
                     </tr>
                 </tbody></table>
                 <!--[if mso]>
                 </td>
                 <![endif]-->

                 <!--[if mso]>
                 </tr>
                 </table>
                 <![endif]-->
             </td>
         </tr>
     </tbody>
 </table><table border="0" cellpadding="0" cellspacing="0" width="100%" class="mcnTextBlock" style="min-width: 100%;border-collapse: collapse;mso-table-lspace: 0pt;mso-table-rspace: 0pt;-ms-text-size-adjust: 100%;-webkit-text-size-adjust: 100%;">
     <tbody class="mcnTextBlockOuter">
         <tr>
             <td valign="top" class="mcnTextBlockInner" style="padding-top: 9px;mso-line-height-rule: exactly;-ms-text-size-adjust: 100%;-webkit-text-size-adjust: 100%;">
                 <!--[if mso]>
                 <table align="left" border="0" cellspacing="0" cellpadding="0" width="100%" style="width:100%;">
                 <tr>
                 <![endif]-->

                 <!--[if mso]>
                 <td valign="top" width="600" style="width:600px;">
                 <![endif]-->
                 <table align="left" border="0" cellpadding="0" cellspacing="0" style="max-width: 100%;min-width: 100%;border-collapse: collapse;mso-table-lspace: 0pt;mso-table-rspace: 0pt;-ms-text-size-adjust: 100%;-webkit-text-size-adjust: 100%;" width="100%" class="mcnTextContentContainer">
                     <tbody><tr>

                         <td valign="top" class="mcnTextContent" style="padding: 0px 18px 9px;font-family: "Noticia Text", Georgia, "Times New Roman", serif;line-height: 150%;mso-line-height-rule: exactly;-ms-text-size-adjust: 100%;-webkit-text-size-adjust: 100%;word-break: break-word;color: #202020;font-size: 16px;text-align: left;">

                             <p dir="ltr" style="font-family: "Noticia Text", Georgia, "Times New Roman", serif;line-height: 150%;margin: 10px 0;padding: 0;mso-line-height-rule: exactly;-ms-text-size-adjust: 100%;-webkit-text-size-adjust: 100%;color: #202020;font-size: 16px;text-align: left;">New Orleans Saints head coach, Sean Payton, subteley deployed this graphic tee to call NFL Commissioner, Roger Goodell, a clown on national television.</p>

                         </td>
                     </tr>
                 </tbody></table>
                 <!--[if mso]>
                 </td>
                 <![endif]-->

                 <!--[if mso]>
                 </tr>
                 </table>
                 <![endif]-->
             </td>
         </tr>
     </tbody>
 </table><table border="0" cellpadding="0" cellspacing="0" width="100%" class="mcnImageCardBlock" style="border-collapse: collapse;mso-table-lspace: 0pt;mso-table-rspace: 0pt;-ms-text-size-adjust: 100%;-webkit-text-size-adjust: 100%;">
     <tbody class="mcnImageCardBlockOuter">
         <tr>
             <td class="mcnImageCardBlockInner" valign="top" style="padding-top: 9px;padding-right: 18px;padding-bottom: 9px;padding-left: 18px;mso-line-height-rule: exactly;-ms-text-size-adjust: 100%;-webkit-text-size-adjust: 100%;">

 <table align="right" border="0" cellpadding="0" cellspacing="0" class="mcnImageCardBottomContent" width="100%" style="border-collapse: collapse;mso-table-lspace: 0pt;mso-table-rspace: 0pt;-ms-text-size-adjust: 100%;-webkit-text-size-adjust: 100%;">
     <tbody><tr>
         <td class="mcnImageCardBottomImageContent" align="center" valign="top" style="padding-top: 0px;padding-right: 0px;padding-bottom: 0;padding-left: 0px;mso-line-height-rule: exactly;-ms-text-size-adjust: 100%;-webkit-text-size-adjust: 100%;">



             <img alt="" src="https://gallery.mailchimp.com/adee9c5618cae148bdcf8f762/images/8364e957-564e-4e77-bdd5-f399279b3e5e.jpg" width="564" style="max-width: 567px;border: 0;height: auto;outline: none;text-decoration: none;-ms-interpolation-mode: bicubic;vertical-align: bottom;" class="mcnImage">


         </td>
     </tr>
     <tr>
         <td class="mcnTextContent" valign="top" style="padding: 9px 18px;color: #747474;font-family: "Noticia Text", Georgia, "Times New Roman", serif;font-size: 14px;font-weight: normal;text-align: center;mso-line-height-rule: exactly;-ms-text-size-adjust: 100%;-webkit-text-size-adjust: 100%;word-break: break-word;line-height: 150%;" width="546">

         </td>
     </tr>
 </tbody></table>




             </td>
         </tr>
     </tbody>
 </table><table border="0" cellpadding="0" cellspacing="0" width="100%" class="mcnImageBlock" style="min-width: 100%;border-collapse: collapse;mso-table-lspace: 0pt;mso-table-rspace: 0pt;-ms-text-size-adjust: 100%;-webkit-text-size-adjust: 100%;">
     <tbody class="mcnImageBlockOuter">
             <tr>
                 <td valign="top" style="padding: 9px;mso-line-height-rule: exactly;-ms-text-size-adjust: 100%;-webkit-text-size-adjust: 100%;" class="mcnImageBlockInner">
                     <table align="left" width="100%" border="0" cellpadding="0" cellspacing="0" class="mcnImageContentContainer" style="min-width: 100%;border-collapse: collapse;mso-table-lspace: 0pt;mso-table-rspace: 0pt;-ms-text-size-adjust: 100%;-webkit-text-size-adjust: 100%;">
                         <tbody><tr>
                             <td class="mcnImageContent" valign="top" style="padding-right: 9px;padding-left: 9px;padding-top: 0;padding-bottom: 0;text-align: center;mso-line-height-rule: exactly;-ms-text-size-adjust: 100%;-webkit-text-size-adjust: 100%;">


                                         <img align="center" alt="" src="https://gallery.mailchimp.com/adee9c5618cae148bdcf8f762/images/3a9aae95-a919-484d-9434-ee5de342b1ef.jpg" width="564" style="max-width: 800px;padding-bottom: 0;display: inline !important;vertical-align: bottom;border: 0;height: auto;outline: none;text-decoration: none;-ms-interpolation-mode: bicubic;" class="mcnImage">


                             </td>
                         </tr>
                     </tbody></table>
                 </td>
             </tr>
     </tbody>
 </table><table border="0" cellpadding="0" cellspacing="0" width="100%" class="mcnTextBlock" style="min-width: 100%;border-collapse: collapse;mso-table-lspace: 0pt;mso-table-rspace: 0pt;-ms-text-size-adjust: 100%;-webkit-text-size-adjust: 100%;">
     <tbody class="mcnTextBlockOuter">
         <tr>
             <td valign="top" class="mcnTextBlockInner" style="padding-top: 9px;mso-line-height-rule: exactly;-ms-text-size-adjust: 100%;-webkit-text-size-adjust: 100%;">
                 <!--[if mso]>
                 <table align="left" border="0" cellspacing="0" cellpadding="0" width="100%" style="width:100%;">
                 <tr>
                 <![endif]-->

                 <!--[if mso]>
                 <td valign="top" width="600" style="width:600px;">
                 <![endif]-->
                 <table align="left" border="0" cellpadding="0" cellspacing="0" style="max-width: 100%;min-width: 100%;border-collapse: collapse;mso-table-lspace: 0pt;mso-table-rspace: 0pt;-ms-text-size-adjust: 100%;-webkit-text-size-adjust: 100%;" width="100%" class="mcnTextContentContainer">
                     <tbody><tr>

                         <td valign="top" class="mcnTextContent" style="padding-top: 0;padding-right: 18px;padding-bottom: 9px;padding-left: 18px;mso-line-height-rule: exactly;-ms-text-size-adjust: 100%;-webkit-text-size-adjust: 100%;word-break: break-word;color: #202020;font-family: 'Noticia Text', Georgia, 'Times New Roman', serif;font-size: 16px;line-height: 150%;text-align: left;">

                             Has your team been hosed by the commish? Get the tee from Barstool Sports: <a href="https://store.barstoolsports.com/products/clown-turquoise?variant=12427776360545" target="_blank" style="mso-line-height-rule: exactly;-ms-text-size-adjust: 100%;-webkit-text-size-adjust: 100%;color: #42702f;font-weight: normal;text-decoration: underline;">https://store.barstoolsports.com/products/clown-turquoise?variant=12427776360545</a>
                         </td>
                     </tr>
                 </tbody></table>
                 <!--[if mso]>
                 </td>
                 <![endif]-->

                 <!--[if mso]>
                 </tr>
                 </table>
                 <![endif]-->
             </td>
         </tr>
     </tbody>
 </table><table border="0" cellpadding="0" cellspacing="0" width="100%" class="mcnDividerBlock" style="min-width: 100%;border-collapse: collapse;mso-table-lspace: 0pt;mso-table-rspace: 0pt;-ms-text-size-adjust: 100%;-webkit-text-size-adjust: 100%;table-layout: fixed !important;">
     <tbody class="mcnDividerBlockOuter">
         <tr>
             <td class="mcnDividerBlockInner" style="min-width: 100%;padding: 18px;mso-line-height-rule: exactly;-ms-text-size-adjust: 100%;-webkit-text-size-adjust: 100%;">
                 <table class="mcnDividerContent" border="0" cellpadding="0" cellspacing="0" width="100%" style="min-width: 100%;border-top: 2px solid #EAEAEA;border-collapse: collapse;mso-table-lspace: 0pt;mso-table-rspace: 0pt;-ms-text-size-adjust: 100%;-webkit-text-size-adjust: 100%;">
                     <tbody><tr>
                         <td style="mso-line-height-rule: exactly;-ms-text-size-adjust: 100%;-webkit-text-size-adjust: 100%;">
                             <span></span>
                         </td>
                     </tr>
                 </tbody></table>
 <!--
                 <td class="mcnDividerBlockInner" style="padding: 18px;">
                 <hr class="mcnDividerContent" style="border-bottom-color:none; border-left-color:none; border-right-color:none; border-bottom-width:0; border-left-width:0; border-right-width:0; margin-top:0; margin-right:0; margin-bottom:0; margin-left:0;" />
 -->
             </td>
         </tr>
     </tbody>
 </table><table border="0" cellpadding="0" cellspacing="0" width="100%" class="mcnTextBlock" style="min-width: 100%;border-collapse: collapse;mso-table-lspace: 0pt;mso-table-rspace: 0pt;-ms-text-size-adjust: 100%;-webkit-text-size-adjust: 100%;">
     <tbody class="mcnTextBlockOuter">
         <tr>
             <td valign="top" class="mcnTextBlockInner" style="padding-top: 9px;mso-line-height-rule: exactly;-ms-text-size-adjust: 100%;-webkit-text-size-adjust: 100%;">
                 <!--[if mso]>
                 <table align="left" border="0" cellspacing="0" cellpadding="0" width="100%" style="width:100%;">
                 <tr>
                 <![endif]-->

                 <!--[if mso]>
                 <td valign="top" width="600" style="width:600px;">
                 <![endif]-->
                 <table align="left" border="0" cellpadding="0" cellspacing="0" style="max-width: 100%;min-width: 100%;border-collapse: collapse;mso-table-lspace: 0pt;mso-table-rspace: 0pt;-ms-text-size-adjust: 100%;-webkit-text-size-adjust: 100%;" width="100%" class="mcnTextContentContainer">
                     <tbody><tr>

                         <td valign="top" class="mcnTextContent" style="padding: 0px 18px 9px;font-family: "Noticia Text", Georgia, "Times New Roman", serif;line-height: 150%;mso-line-height-rule: exactly;-ms-text-size-adjust: 100%;-webkit-text-size-adjust: 100%;word-break: break-word;color: #202020;font-size: 16px;text-align: left;">

                             <div style="text-align: center;"><strong><span style="font-size:24px">SEE YOU NEXT WEEK...</span></strong><br>
 <br>
 Tips and leads for next week's edition? Email them to us at <a href="mailto:hi@satoshiscloset.com?subject=Newsletter%20tips%20and%20leads" target="_blank" style="mso-line-height-rule: exactly;-ms-text-size-adjust: 100%;-webkit-text-size-adjust: 100%;color: #42702f;font-weight: normal;text-decoration: underline;">hi@satoshiscloset.com</a>.<br>
 <br>
 <img data-file-id="4326517" height="128" src="https://gallery.mailchimp.com/adee9c5618cae148bdcf8f762/images/98c6e2b1-a7da-4b2a-8718-799bda1c4658.gif" style="border: 0px;width: 128px;height: 128px;margin: 0px;outline: none;text-decoration: none;-ms-interpolation-mode: bicubic;" width="128"><br>
 <strong>Check out previous issues:</strong><br>
 <a href="https://satoshiscloset.com/blog" target="_blank" style="mso-line-height-rule: exactly;-ms-text-size-adjust: 100%;-webkit-text-size-adjust: 100%;color: #42702f;font-weight: normal;text-decoration: underline;">https://satoshiscloset.com/blog</a></div>
 </td>
                     </tr>
                 </tbody></table>
             </td>
         </tr>
     </tbody>
 </table></td>
                             </tr>

                         </table>
                     </td>
                 </tr>
             </table>
         </center>
     </body>
 </html>`

 const POST6 = `
 <!doctype html>
 <html xmlns="http://www.w3.org/1999/xhtml" xmlns:v="urn:schemas-microsoft-com:vml" xmlns:o="urn:schemas-microsoft-com:office:office">
     <head>
         <!-- NAME: 1 COLUMN -->
         <!--[if gte mso 15]>
         <xml>
             <o:OfficeDocumentSettings>
             <o:AllowPNG/>
             <o:PixelsPerInch>96</o:PixelsPerInch>
             </o:OfficeDocumentSettings>
         </xml>
         <![endif]-->
         <meta charset="UTF-8">
         <meta http-equiv="X-UA-Compatible" content="IE=edge">
         <meta name="viewport" content="width=device-width, initial-scale=1">
         <title>[006-06-2019] Silky threads and The Dead.</title>

     <style type="text/css">
         p{
             margin:10px 0;
             padding:0;
         }
         table{
             border-collapse:collapse;
         }
         h1,h2,h3,h4,h5,h6{
             display:block;
             margin:0;
             padding:0;
         }
         img,a img{
             border:0;
             height:auto;
             outline:none;
             text-decoration:none;
         }
         body,#bodyTable,#bodyCell{
             height:100%;
             margin:0;
             padding:0;
             width:100%;
         }
         .mcnPreviewText{
             display:none !important;
         }
         #outlook a{
             padding:0;
         }
         img{
             -ms-interpolation-mode:bicubic;
         }
         table{
             mso-table-lspace:0pt;
             mso-table-rspace:0pt;
         }
         .ReadMsgBody{
             width:100%;
         }
         .ExternalClass{
             width:100%;
         }
         p,a,li,td,blockquote{
             mso-line-height-rule:exactly;
         }
         a[href^=tel],a[href^=sms]{
             color:inherit;
             cursor:default;
             text-decoration:none;
         }
         p,a,li,td,body,table,blockquote{
             -ms-text-size-adjust:100%;
             -webkit-text-size-adjust:100%;
         }
         .ExternalClass,.ExternalClass p,.ExternalClass td,.ExternalClass div,.ExternalClass span,.ExternalClass font{
             line-height:100%;
         }
         a[x-apple-data-detectors]{
             color:inherit !important;
             text-decoration:none !important;
             font-size:inherit !important;
             font-family:inherit !important;
             font-weight:inherit !important;
             line-height:inherit !important;
         }
         #bodyCell{
             padding:10px;
         }
         .templateContainer{
             max-width:600px !important;
         }
         a.mcnButton{
             display:block;
         }
         .mcnImage,.mcnRetinaImage{
             vertical-align:bottom;
         }
         .mcnTextContent{
             word-break:break-word;
         }
         .mcnTextContent img{
             height:auto !important;
         }
         .mcnDividerBlock{
             table-layout:fixed !important;
         }
         body,#bodyTable{
             background-color:#ffffff;
         }
         #bodyCell{
             border-top:0;
         }
         .templateContainer{
             border:0;
         }
         h1{
             color:#202020;
             font-family:Helvetica;
             font-size:26px;
             font-style:normal;
             font-weight:bold;
             line-height:125%;
             letter-spacing:normal;
             text-align:left;
         }
         h2{
             color:#202020;
             font-family:Helvetica;
             font-size:22px;
             font-style:normal;
             font-weight:bold;
             line-height:125%;
             letter-spacing:normal;
             text-align:left;
         }
         h3{
             color:#202020;
             font-family:Helvetica;
             font-size:20px;
             font-style:normal;
             font-weight:bold;
             line-height:125%;
             letter-spacing:normal;
             text-align:left;
         }
         h4{
             color:#202020;
             font-family:Helvetica;
             font-size:18px;
             font-style:normal;
             font-weight:bold;
             line-height:125%;
             letter-spacing:normal;
             text-align:left;
         }
         #templatePreheader{
             background-color:#FAFAFA;
             background-image:none;
             background-repeat:no-repeat;
             background-position:center;
             background-size:cover;
             border-top:0;
             border-bottom:0;
             padding-top:9px;
             padding-bottom:9px;
         }
         #templatePreheader .mcnTextContent,#templatePreheader .mcnTextContent p{
             color:#656565;
             font-family:Helvetica;
             font-size:12px;
             line-height:150%;
             text-align:left;
         }
         #templatePreheader .mcnTextContent a,#templatePreheader .mcnTextContent p a{
             color:#656565;
             font-weight:normal;
             text-decoration:underline;
         }
         #templateHeader{
             background-color:#ffffff;
             background-image:none;
             background-repeat:no-repeat;
             background-position:center;
             background-size:cover;
             border-top:0;
             border-bottom:0;
             padding-top:18px;
             padding-bottom:0px;
         }
         #templateHeader .mcnTextContent,#templateHeader .mcnTextContent p{
             color:#202020;
             font-family:Helvetica;
             font-size:12px;
             line-height:125%;
             text-align:left;
         }
         #templateHeader .mcnTextContent a,#templateHeader .mcnTextContent p a{
             color:#007C89;
             font-weight:normal;
             text-decoration:underline;
         }
         #templateBody{
             background-color:#ffffff;
             background-image:none;
             background-repeat:no-repeat;
             background-position:center;
             background-size:cover;
             border-top:0;
             border-bottom:2px solid #EAEAEA;
             padding-top:0;
             padding-bottom:9px;
         }
         #templateBody .mcnTextContent,#templateBody .mcnTextContent p{
             color:#202020;
             font-family:'Noticia Text', Georgia, 'Times New Roman', serif;
             font-size:16px;
             line-height:150%;
             text-align:left;
         }
         #templateBody .mcnTextContent a,#templateBody .mcnTextContent p a{
             color:#42702f;
             font-weight:normal;
             text-decoration:underline;
         }
         #templateFooter{
             background-color:#ffffff;
             background-image:none;
             background-repeat:no-repeat;
             background-position:center;
             background-size:cover;
             border-top:0;
             border-bottom:0;
             padding-top:9px;
             padding-bottom:9px;
         }
         #templateFooter .mcnTextContent,#templateFooter .mcnTextContent p{
             color:#656565;
             font-family:Helvetica;
             font-size:12px;
             line-height:150%;
             text-align:center;
         }
         #templateFooter .mcnTextContent a,#templateFooter .mcnTextContent p a{
             color:#656565;
             font-weight:normal;
             text-decoration:underline;
         }
     @media only screen and (min-width:768px){
         .templateContainer{
             width:600px !important;
         }

 }   @media only screen and (max-width: 480px){
         body,table,td,p,a,li,blockquote{
             -webkit-text-size-adjust:none !important;
         }

 }   @media only screen and (max-width: 480px){
         body{
             width:100% !important;
             min-width:100% !important;
         }

 }   @media only screen and (max-width: 480px){
         #bodyCell{
             padding-top:10px !important;
         }

 }   @media only screen and (max-width: 480px){
         .mcnRetinaImage{
             max-width:100% !important;
         }

 }   @media only screen and (max-width: 480px){
         .mcnImage{
             width:100% !important;
         }

 }   @media only screen and (max-width: 480px){
         .mcnCartContainer,.mcnCaptionTopContent,.mcnRecContentContainer,.mcnCaptionBottomContent,.mcnTextContentContainer,.mcnBoxedTextContentContainer,.mcnImageGroupContentContainer,.mcnCaptionLeftTextContentContainer,.mcnCaptionRightTextContentContainer,.mcnCaptionLeftImageContentContainer,.mcnCaptionRightImageContentContainer,.mcnImageCardLeftTextContentContainer,.mcnImageCardRightTextContentContainer,.mcnImageCardLeftImageContentContainer,.mcnImageCardRightImageContentContainer{
             max-width:100% !important;
             width:100% !important;
         }

 }   @media only screen and (max-width: 480px){
         .mcnBoxedTextContentContainer{
             min-width:100% !important;
         }

 }   @media only screen and (max-width: 480px){
         .mcnImageGroupContent{
             padding:9px !important;
         }

 }   @media only screen and (max-width: 480px){
         .mcnCaptionLeftContentOuter .mcnTextContent,.mcnCaptionRightContentOuter .mcnTextContent{
             padding-top:9px !important;
         }

 }   @media only screen and (max-width: 480px){
         .mcnImageCardTopImageContent,.mcnCaptionBottomContent:last-child .mcnCaptionBottomImageContent,.mcnCaptionBlockInner .mcnCaptionTopContent:last-child .mcnTextContent{
             padding-top:18px !important;
         }

 }   @media only screen and (max-width: 480px){
         .mcnImageCardBottomImageContent{
             padding-bottom:9px !important;
         }

 }   @media only screen and (max-width: 480px){
         .mcnImageGroupBlockInner{
             padding-top:0 !important;
             padding-bottom:0 !important;
         }

 }   @media only screen and (max-width: 480px){
         .mcnImageGroupBlockOuter{
             padding-top:9px !important;
             padding-bottom:9px !important;
         }

 }   @media only screen and (max-width: 480px){
         .mcnTextContent,.mcnBoxedTextContentColumn{
             padding-right:18px !important;
             padding-left:18px !important;
         }

 }   @media only screen and (max-width: 480px){
         .mcnImageCardLeftImageContent,.mcnImageCardRightImageContent{
             padding-right:18px !important;
             padding-bottom:0 !important;
             padding-left:18px !important;
         }

 }   @media only screen and (max-width: 480px){
         .mcpreview-image-uploader{
             display:none !important;
             width:100% !important;
         }

 }   @media only screen and (max-width: 480px){
         h1{
             font-size:22px !important;
             line-height:125% !important;
         }

 }   @media only screen and (max-width: 480px){
         h2{
             font-size:20px !important;
             line-height:125% !important;
         }

 }   @media only screen and (max-width: 480px){
         h3{
             font-size:18px !important;
             line-height:125% !important;
         }

 }   @media only screen and (max-width: 480px){
         h4{
             font-size:16px !important;
             line-height:150% !important;
         }

 }   @media only screen and (max-width: 480px){
         .mcnBoxedTextContentContainer .mcnTextContent,.mcnBoxedTextContentContainer .mcnTextContent p{
             font-size:14px !important;
             line-height:150% !important;
         }

 }   @media only screen and (max-width: 480px){
         #templatePreheader{
             display:block !important;
         }

 }   @media only screen and (max-width: 480px){
         #templatePreheader .mcnTextContent,#templatePreheader .mcnTextContent p{
             font-size:14px !important;
             line-height:150% !important;
         }

 }   @media only screen and (max-width: 480px){
         #templateHeader .mcnTextContent,#templateHeader .mcnTextContent p{
             font-size:16px !important;
             line-height:150% !important;
         }

 }   @media only screen and (max-width: 480px){
         #templateBody .mcnTextContent,#templateBody .mcnTextContent p{
             font-size:16px !important;
             line-height:150% !important;
         }

 }   @media only screen and (max-width: 480px){
         #templateFooter .mcnTextContent,#templateFooter .mcnTextContent p{
             font-size:14px !important;
             line-height:150% !important;
         }

 }</style><!--[if !mso]><!--><link href="https://fonts.googleapis.com/css?family=Noticia+Text:400,400i,700,700i" rel="stylesheet"><!--<![endif]--></head>
     <body style="height: 100%;margin: 0;padding: 0;width: 100%;-ms-text-size-adjust: 100%;-webkit-text-size-adjust: 100%;background-color: #ffffff;">
         <!--*|IF:MC_PREVIEW_TEXT|*-->
         <!--[if !gte mso 9]><!----><span class="mcnPreviewText" style="display:none; font-size:0px; line-height:0px; max-height:0px; max-width:0px; opacity:0; overflow:hidden; visibility:hidden; mso-hide:all;">*|MC_PREVIEW_TEXT|*</span><!--<![endif]-->
         <!--*|END:IF|*-->
         <center>
             <table align="center" border="0" cellpadding="0" cellspacing="0" height="100%" width="100%" id="bodyTable" style="border-collapse: collapse;mso-table-lspace: 0pt;mso-table-rspace: 0pt;-ms-text-size-adjust: 100%;-webkit-text-size-adjust: 100%;height: 100%;margin: 0;padding: 0;width: 100%;background-color: #ffffff;">
                 <tr>
                     <td align="center" valign="top" id="bodyCell" style="mso-line-height-rule: exactly;-ms-text-size-adjust: 100%;-webkit-text-size-adjust: 100%;height: 100%;margin: 0;padding: 10px;width: 100%;border-top: 0;">
                         <!-- BEGIN TEMPLATE // -->
                         <!--[if (gte mso 9)|(IE)]>
                         <table align="center" border="0" cellspacing="0" cellpadding="0" width="600" style="width:600px;">
                         <tr>
                         <td align="center" valign="top" width="600" style="width:600px;">
                         <![endif]-->
                         <table border="0" cellpadding="0" cellspacing="0" width="100%" class="templateContainer" style="border-collapse: collapse;mso-table-lspace: 0pt;mso-table-rspace: 0pt;-ms-text-size-adjust: 100%;-webkit-text-size-adjust: 100%;border: 0;max-width: 600px !important;">
                             <tr>
                                 <td valign="top" id="templateHeader" style="background:#ffffff none no-repeat center/cover;mso-line-height-rule: exactly;-ms-text-size-adjust: 100%;-webkit-text-size-adjust: 100%;background-color: #ffffff;background-image: none;background-repeat: no-repeat;background-position: center;background-size: cover;border-top: 0;border-bottom: 0;padding-top: 18px;padding-bottom: 0px;"><table border="0" cellpadding="0" cellspacing="0" width="100%" class="mcnImageBlock" style="min-width: 100%;border-collapse: collapse;mso-table-lspace: 0pt;mso-table-rspace: 0pt;-ms-text-size-adjust: 100%;-webkit-text-size-adjust: 100%;">
     <tbody class="mcnImageBlockOuter">
             <tr>
                 <td valign="top" style="padding: 9px;mso-line-height-rule: exactly;-ms-text-size-adjust: 100%;-webkit-text-size-adjust: 100%;" class="mcnImageBlockInner">
                     <table align="left" width="100%" border="0" cellpadding="0" cellspacing="0" class="mcnImageContentContainer" style="min-width: 100%;border-collapse: collapse;mso-table-lspace: 0pt;mso-table-rspace: 0pt;-ms-text-size-adjust: 100%;-webkit-text-size-adjust: 100%;">
                         <tbody><tr>
                             <td class="mcnImageContent" valign="top" style="padding-right: 9px;padding-left: 9px;padding-top: 0;padding-bottom: 0;text-align: center;mso-line-height-rule: exactly;-ms-text-size-adjust: 100%;-webkit-text-size-adjust: 100%;">


                                         <img align="center" alt="" src="https://gallery.mailchimp.com/adee9c5618cae148bdcf8f762/images/3c29cfbc-6e8d-4048-8529-49f22f01dc2f.jpg" width="564" style="max-width: 960px;padding-bottom: 0;display: inline !important;vertical-align: bottom;border: 0;height: auto;outline: none;text-decoration: none;-ms-interpolation-mode: bicubic;" class="mcnImage">


                             </td>
                         </tr>
                     </tbody></table>
                 </td>
             </tr>
     </tbody>
 </table></td>
                             </tr>
                             <tr>
                                 <td valign="top" id="templateBody" style="background:#ffffff none no-repeat center/cover;mso-line-height-rule: exactly;-ms-text-size-adjust: 100%;-webkit-text-size-adjust: 100%;background-color: #ffffff;background-image: none;background-repeat: no-repeat;background-position: center;background-size: cover;border-top: 0;border-bottom: 2px solid #EAEAEA;padding-top: 0;padding-bottom: 9px;"><table border="0" cellpadding="0" cellspacing="0" width="100%" class="mcnDividerBlock" style="min-width: 100%;border-collapse: collapse;mso-table-lspace: 0pt;mso-table-rspace: 0pt;-ms-text-size-adjust: 100%;-webkit-text-size-adjust: 100%;table-layout: fixed !important;">
     <tbody class="mcnDividerBlockOuter">
         <tr>
             <td class="mcnDividerBlockInner" style="min-width: 100%;padding: 8px 18px 0px;mso-line-height-rule: exactly;-ms-text-size-adjust: 100%;-webkit-text-size-adjust: 100%;">
                 <table class="mcnDividerContent" border="0" cellpadding="0" cellspacing="0" width="100%" style="min-width: 100%;border-top: 2px solid #EAEAEA;border-collapse: collapse;mso-table-lspace: 0pt;mso-table-rspace: 0pt;-ms-text-size-adjust: 100%;-webkit-text-size-adjust: 100%;">
                     <tbody><tr>
                         <td style="mso-line-height-rule: exactly;-ms-text-size-adjust: 100%;-webkit-text-size-adjust: 100%;">
                             <span></span>
                         </td>
                     </tr>
                 </tbody></table>
 <!--
                 <td class="mcnDividerBlockInner" style="padding: 18px;">
                 <hr class="mcnDividerContent" style="border-bottom-color:none; border-left-color:none; border-right-color:none; border-bottom-width:0; border-left-width:0; border-right-width:0; margin-top:0; margin-right:0; margin-bottom:0; margin-left:0;" />
 -->
             </td>
         </tr>
     </tbody>
 </table><table border="0" cellpadding="0" cellspacing="0" width="100%" class="mcnTextBlock" style="min-width: 100%;border-collapse: collapse;mso-table-lspace: 0pt;mso-table-rspace: 0pt;-ms-text-size-adjust: 100%;-webkit-text-size-adjust: 100%;">
     <tbody class="mcnTextBlockOuter">
         <tr>
             <td valign="top" class="mcnTextBlockInner" style="padding-top: 9px;mso-line-height-rule: exactly;-ms-text-size-adjust: 100%;-webkit-text-size-adjust: 100%;">
                 <!--[if mso]>
                 <table align="left" border="0" cellspacing="0" cellpadding="0" width="100%" style="width:100%;">
                 <tr>
                 <![endif]-->

                 <!--[if mso]>
                 <td valign="top" width="600" style="width:600px;">
                 <![endif]-->
                 <table align="left" border="0" cellpadding="0" cellspacing="0" style="max-width: 100%;min-width: 100%;border-collapse: collapse;mso-table-lspace: 0pt;mso-table-rspace: 0pt;-ms-text-size-adjust: 100%;-webkit-text-size-adjust: 100%;" width="100%" class="mcnTextContentContainer">
                     <tbody><tr>

                         <td valign="top" class="mcnTextContent" style="padding: 0px 18px 9px;font-family: "Noticia Text", Georgia, "Times New Roman", serif;line-height: 150%;mso-line-height-rule: exactly;-ms-text-size-adjust: 100%;-webkit-text-size-adjust: 100%;word-break: break-word;color: #202020;font-size: 16px;text-align: left;">

                             <div style="text-align: left;"><strong><span style="font-size:12px"><span style="font-family:noticia text,georgia,times new roman,serif">Aaaaaand it’s gone • A Valentine’s Day Treat • Cool $#i®T • Airdrops!</span></span></strong></div>

                         </td>
                     </tr>
                 </tbody></table>
                 <!--[if mso]>
                 </td>
                 <![endif]-->

                 <!--[if mso]>
                 </tr>
                 </table>
                 <![endif]-->
             </td>
         </tr>
     </tbody>
 </table><table border="0" cellpadding="0" cellspacing="0" width="100%" class="mcnDividerBlock" style="min-width: 100%;border-collapse: collapse;mso-table-lspace: 0pt;mso-table-rspace: 0pt;-ms-text-size-adjust: 100%;-webkit-text-size-adjust: 100%;table-layout: fixed !important;">
     <tbody class="mcnDividerBlockOuter">
         <tr>
             <td class="mcnDividerBlockInner" style="min-width: 100%;padding: 2px 18px 9px;mso-line-height-rule: exactly;-ms-text-size-adjust: 100%;-webkit-text-size-adjust: 100%;">
                 <table class="mcnDividerContent" border="0" cellpadding="0" cellspacing="0" width="100%" style="min-width: 100%;border-top: 2px solid #EAEAEA;border-collapse: collapse;mso-table-lspace: 0pt;mso-table-rspace: 0pt;-ms-text-size-adjust: 100%;-webkit-text-size-adjust: 100%;">
                     <tbody><tr>
                         <td style="mso-line-height-rule: exactly;-ms-text-size-adjust: 100%;-webkit-text-size-adjust: 100%;">
                             <span></span>
                         </td>
                     </tr>
                 </tbody></table>
 <!--
                 <td class="mcnDividerBlockInner" style="padding: 18px;">
                 <hr class="mcnDividerContent" style="border-bottom-color:none; border-left-color:none; border-right-color:none; border-bottom-width:0; border-left-width:0; border-right-width:0; margin-top:0; margin-right:0; margin-bottom:0; margin-left:0;" />
 -->
             </td>
         </tr>
     </tbody>
 </table><table border="0" cellpadding="0" cellspacing="0" width="100%" class="mcnTextBlock" style="min-width: 100%;border-collapse: collapse;mso-table-lspace: 0pt;mso-table-rspace: 0pt;-ms-text-size-adjust: 100%;-webkit-text-size-adjust: 100%;">
     <tbody class="mcnTextBlockOuter">
         <tr>
             <td valign="top" class="mcnTextBlockInner" style="padding-top: 9px;mso-line-height-rule: exactly;-ms-text-size-adjust: 100%;-webkit-text-size-adjust: 100%;">
                 <!--[if mso]>
                 <table align="left" border="0" cellspacing="0" cellpadding="0" width="100%" style="width:100%;">
                 <tr>
                 <![endif]-->

                 <!--[if mso]>
                 <td valign="top" width="600" style="width:600px;">
                 <![endif]-->
                 <table align="left" border="0" cellpadding="0" cellspacing="0" style="max-width: 100%;min-width: 100%;border-collapse: collapse;mso-table-lspace: 0pt;mso-table-rspace: 0pt;-ms-text-size-adjust: 100%;-webkit-text-size-adjust: 100%;" width="100%" class="mcnTextContentContainer">
                     <tbody><tr>

                         <td valign="top" class="mcnTextContent" style="padding: 0px 18px 9px;font-family: "Noticia Text", Georgia, "Times New Roman", serif;line-height: 150%;mso-line-height-rule: exactly;-ms-text-size-adjust: 100%;-webkit-text-size-adjust: 100%;word-break: break-word;color: #202020;font-size: 16px;text-align: left;">

                             <p dir="ltr" style="font-family: "Noticia Text", Georgia, "Times New Roman", serif;line-height: 150%;margin: 10px 0;padding: 0;mso-line-height-rule: exactly;-ms-text-size-adjust: 100%;-webkit-text-size-adjust: 100%;color: #202020;font-size: 16px;text-align: left;"><span style="font-size:18px"><strong>Out Cold</strong></span><br>
 <br>
 The biggest story in crypto this week is the death(??) of Gerald Cotten, the founder of Canadian crypto exchange QuadrigaCX. His death in December of last year is making the rounds in today’s news since it was discovered that Cotten is the only holder of the exchange’s “cold storage” password - the contents of which include customers’ crypto to the tune of $190 million, funds that likely will never be recovered.</p>

                         </td>
                     </tr>
                 </tbody></table>
                 <!--[if mso]>
                 </td>
                 <![endif]-->

                 <!--[if mso]>
                 </tr>
                 </table>
                 <![endif]-->
             </td>
         </tr>
     </tbody>
 </table><table border="0" cellpadding="0" cellspacing="0" width="100%" class="mcnImageBlock" style="min-width: 100%;border-collapse: collapse;mso-table-lspace: 0pt;mso-table-rspace: 0pt;-ms-text-size-adjust: 100%;-webkit-text-size-adjust: 100%;">
     <tbody class="mcnImageBlockOuter">
             <tr>
                 <td valign="top" style="padding: 9px;mso-line-height-rule: exactly;-ms-text-size-adjust: 100%;-webkit-text-size-adjust: 100%;" class="mcnImageBlockInner">
                     <table align="left" width="100%" border="0" cellpadding="0" cellspacing="0" class="mcnImageContentContainer" style="min-width: 100%;border-collapse: collapse;mso-table-lspace: 0pt;mso-table-rspace: 0pt;-ms-text-size-adjust: 100%;-webkit-text-size-adjust: 100%;">
                         <tbody><tr>
                             <td class="mcnImageContent" valign="top" style="padding-right: 9px;padding-left: 9px;padding-top: 0;padding-bottom: 0;text-align: center;mso-line-height-rule: exactly;-ms-text-size-adjust: 100%;-webkit-text-size-adjust: 100%;">

                                     <a href="https://twitter.com/coincenter/status/1086357640958148608" title="" class="" target="_blank" style="mso-line-height-rule: exactly;-ms-text-size-adjust: 100%;-webkit-text-size-adjust: 100%;">
                                         <img align="center" alt="" src="https://gallery.mailchimp.com/adee9c5618cae148bdcf8f762/images/80d67a4c-571b-4235-bd71-224f29d7d9de.gif" width="480" style="max-width: 480px;padding-bottom: 0;display: inline !important;vertical-align: bottom;border: 0;height: auto;outline: none;text-decoration: none;-ms-interpolation-mode: bicubic;" class="mcnImage">
                                     </a>

                             </td>
                         </tr>
                     </tbody></table>
                 </td>
             </tr>
     </tbody>
 </table><table border="0" cellpadding="0" cellspacing="0" width="100%" class="mcnTextBlock" style="min-width: 100%;border-collapse: collapse;mso-table-lspace: 0pt;mso-table-rspace: 0pt;-ms-text-size-adjust: 100%;-webkit-text-size-adjust: 100%;">
     <tbody class="mcnTextBlockOuter">
         <tr>
             <td valign="top" class="mcnTextBlockInner" style="padding-top: 9px;mso-line-height-rule: exactly;-ms-text-size-adjust: 100%;-webkit-text-size-adjust: 100%;">
                 <!--[if mso]>
                 <table align="left" border="0" cellspacing="0" cellpadding="0" width="100%" style="width:100%;">
                 <tr>
                 <![endif]-->

                 <!--[if mso]>
                 <td valign="top" width="600" style="width:600px;">
                 <![endif]-->
                 <table align="left" border="0" cellpadding="0" cellspacing="0" style="max-width: 100%;min-width: 100%;border-collapse: collapse;mso-table-lspace: 0pt;mso-table-rspace: 0pt;-ms-text-size-adjust: 100%;-webkit-text-size-adjust: 100%;" width="100%" class="mcnTextContentContainer">
                     <tbody><tr>

                         <td valign="top" class="mcnTextContent" style="padding-top: 0;padding-right: 18px;padding-bottom: 9px;padding-left: 18px;mso-line-height-rule: exactly;-ms-text-size-adjust: 100%;-webkit-text-size-adjust: 100%;word-break: break-word;color: #202020;font-family: 'Noticia Text', Georgia, 'Times New Roman', serif;font-size: 16px;line-height: 150%;text-align: left;">

                             Cold storage, essentially, is how crypto is stored offline. The intent is to keep your cryptocurrency safe - kind of like putting your money in the bank, so that if you lost your wallet or got robbed you wouldn’t lose everything. What happened in this case is the wallet is still safely in your pocket, but you lost your entire bank. 🤷 More accurately, the QuadrigaCX cold storage “bank” is still residing in the same place, but Cotten died knowing the bank’s location and account access codes, and never shared it with anyone before he died. 🤐⚰️<br>
 <br>
 This alone would be newsworthy and highlight some of the major shortcomings of today’s nascent crypto landscape from both a regulatory and usability standpoint. After all, it’s one thing when a little bit of cash is lost and a central bank can print more. But when Bitcoin disappears, it’s gone forever.<br>
 <br>
 Then the plot thickens. The circumstances around Cotten’s death are suspect. Company in financial troubles, traveling to work on an orphanage in India, newly established insurance policies set up to benefit his wife… and dogs. And the region where Cotten died is known for its <a href="https://www.newsbtc.com/2019/02/06/ceo-who-held-150m-in-crypto-died-in-a-region-known-for-having-a-fake-death-mafia/" target="_blank" style="mso-line-height-rule: exactly;-ms-text-size-adjust: 100%;-webkit-text-size-adjust: 100%;color: #42702f;font-weight: normal;text-decoration: underline;">fake death industry</a>. Taking a page from <a href="http://www.simpsonsworld.com/video/320744003865" target="_blank" style="mso-line-height-rule: exactly;-ms-text-size-adjust: 100%;-webkit-text-size-adjust: 100%;color: #42702f;font-weight: normal;text-decoration: underline;">Rory B. Bellows (aka Krusty the Clown)</a> perhaps?
                         </td>
                     </tr>
                 </tbody></table>
                 <!--[if mso]>
                 </td>
                 <![endif]-->

                 <!--[if mso]>
                 </tr>
                 </table>
                 <![endif]-->
             </td>
         </tr>
     </tbody>
 </table><table border="0" cellpadding="0" cellspacing="0" width="100%" class="mcnImageBlock" style="min-width: 100%;border-collapse: collapse;mso-table-lspace: 0pt;mso-table-rspace: 0pt;-ms-text-size-adjust: 100%;-webkit-text-size-adjust: 100%;">
     <tbody class="mcnImageBlockOuter">
             <tr>
                 <td valign="top" style="padding: 9px;mso-line-height-rule: exactly;-ms-text-size-adjust: 100%;-webkit-text-size-adjust: 100%;" class="mcnImageBlockInner">
                     <table align="left" width="100%" border="0" cellpadding="0" cellspacing="0" class="mcnImageContentContainer" style="min-width: 100%;border-collapse: collapse;mso-table-lspace: 0pt;mso-table-rspace: 0pt;-ms-text-size-adjust: 100%;-webkit-text-size-adjust: 100%;">
                         <tbody><tr>
                             <td class="mcnImageContent" valign="top" style="padding-right: 9px;padding-left: 9px;padding-top: 0;padding-bottom: 0;text-align: center;mso-line-height-rule: exactly;-ms-text-size-adjust: 100%;-webkit-text-size-adjust: 100%;">

                                     <a href="https://twitter.com/coincenter/status/1086357640958148608" title="" class="" target="_blank" style="mso-line-height-rule: exactly;-ms-text-size-adjust: 100%;-webkit-text-size-adjust: 100%;">
                                         <img align="center" alt="" src="https://gallery.mailchimp.com/adee9c5618cae148bdcf8f762/images/e9c7ff55-0ecd-4110-aaaa-0b8e6fae8b26.jpg" width="474" style="max-width: 474px;padding-bottom: 0;display: inline !important;vertical-align: bottom;border: 0;height: auto;outline: none;text-decoration: none;-ms-interpolation-mode: bicubic;" class="mcnImage">
                                     </a>

                             </td>
                         </tr>
                     </tbody></table>
                 </td>
             </tr>
     </tbody>
 </table><table border="0" cellpadding="0" cellspacing="0" width="100%" class="mcnTextBlock" style="min-width: 100%;border-collapse: collapse;mso-table-lspace: 0pt;mso-table-rspace: 0pt;-ms-text-size-adjust: 100%;-webkit-text-size-adjust: 100%;">
     <tbody class="mcnTextBlockOuter">
         <tr>
             <td valign="top" class="mcnTextBlockInner" style="padding-top: 9px;mso-line-height-rule: exactly;-ms-text-size-adjust: 100%;-webkit-text-size-adjust: 100%;">
                 <!--[if mso]>
                 <table align="left" border="0" cellspacing="0" cellpadding="0" width="100%" style="width:100%;">
                 <tr>
                 <![endif]-->

                 <!--[if mso]>
                 <td valign="top" width="600" style="width:600px;">
                 <![endif]-->
                 <table align="left" border="0" cellpadding="0" cellspacing="0" style="max-width: 100%;min-width: 100%;border-collapse: collapse;mso-table-lspace: 0pt;mso-table-rspace: 0pt;-ms-text-size-adjust: 100%;-webkit-text-size-adjust: 100%;" width="100%" class="mcnTextContentContainer">
                     <tbody><tr>

                         <td valign="top" class="mcnTextContent" style="padding-top: 0;padding-right: 18px;padding-bottom: 9px;padding-left: 18px;mso-line-height-rule: exactly;-ms-text-size-adjust: 100%;-webkit-text-size-adjust: 100%;word-break: break-word;color: #202020;font-family: 'Noticia Text', Georgia, 'Times New Roman', serif;font-size: 16px;line-height: 150%;text-align: left;">

                             Read more on Gizmodo: <a href="https://gizmodo.com/crypto-exchange-says-it-cant-repay-190-million-to-clie-1832309454" target="_blank" style="mso-line-height-rule: exactly;-ms-text-size-adjust: 100%;-webkit-text-size-adjust: 100%;color: #42702f;font-weight: normal;text-decoration: underline;">https://gizmodo.com/crypto-exchange-says-it-cant-repay-190-million-to-clie-1832309454</a><br>
 <br>
 Or the latest news and speculation on Twitter:<br>
 <a href="https://twitter.com/search?q=QuadrigaCX&src=typd" target="_blank" style="mso-line-height-rule: exactly;-ms-text-size-adjust: 100%;-webkit-text-size-adjust: 100%;color: #42702f;font-weight: normal;text-decoration: underline;">https://twitter.com/search?q=QuadrigaCX&src=typd</a>
                         </td>
                     </tr>
                 </tbody></table>
                 <!--[if mso]>
                 </td>
                 <![endif]-->

                 <!--[if mso]>
                 </tr>
                 </table>
                 <![endif]-->
             </td>
         </tr>
     </tbody>
 </table><table border="0" cellpadding="0" cellspacing="0" width="100%" class="mcnDividerBlock" style="min-width: 100%;border-collapse: collapse;mso-table-lspace: 0pt;mso-table-rspace: 0pt;-ms-text-size-adjust: 100%;-webkit-text-size-adjust: 100%;table-layout: fixed !important;">
     <tbody class="mcnDividerBlockOuter">
         <tr>
             <td class="mcnDividerBlockInner" style="min-width: 100%;padding: 24px 18px;mso-line-height-rule: exactly;-ms-text-size-adjust: 100%;-webkit-text-size-adjust: 100%;">
                 <table class="mcnDividerContent" border="0" cellpadding="0" cellspacing="0" width="100%" style="min-width: 100%;border-top: 2px solid #EAEAEA;border-collapse: collapse;mso-table-lspace: 0pt;mso-table-rspace: 0pt;-ms-text-size-adjust: 100%;-webkit-text-size-adjust: 100%;">
                     <tbody><tr>
                         <td style="mso-line-height-rule: exactly;-ms-text-size-adjust: 100%;-webkit-text-size-adjust: 100%;">
                             <span></span>
                         </td>
                     </tr>
                 </tbody></table>
 <!--
                 <td class="mcnDividerBlockInner" style="padding: 18px;">
                 <hr class="mcnDividerContent" style="border-bottom-color:none; border-left-color:none; border-right-color:none; border-bottom-width:0; border-left-width:0; border-right-width:0; margin-top:0; margin-right:0; margin-bottom:0; margin-left:0;" />
 -->
             </td>
         </tr>
     </tbody>
 </table><table border="0" cellpadding="0" cellspacing="0" width="100%" class="mcnTextBlock" style="min-width: 100%;border-collapse: collapse;mso-table-lspace: 0pt;mso-table-rspace: 0pt;-ms-text-size-adjust: 100%;-webkit-text-size-adjust: 100%;">
     <tbody class="mcnTextBlockOuter">
         <tr>
             <td valign="top" class="mcnTextBlockInner" style="padding-top: 9px;mso-line-height-rule: exactly;-ms-text-size-adjust: 100%;-webkit-text-size-adjust: 100%;">
                 <!--[if mso]>
                 <table align="left" border="0" cellspacing="0" cellpadding="0" width="100%" style="width:100%;">
                 <tr>
                 <![endif]-->

                 <!--[if mso]>
                 <td valign="top" width="600" style="width:600px;">
                 <![endif]-->
                 <table align="left" border="0" cellpadding="0" cellspacing="0" style="max-width: 100%;min-width: 100%;border-collapse: collapse;mso-table-lspace: 0pt;mso-table-rspace: 0pt;-ms-text-size-adjust: 100%;-webkit-text-size-adjust: 100%;" width="100%" class="mcnTextContentContainer">
                     <tbody><tr>

                         <td valign="top" class="mcnTextContent" style="padding: 0px 18px 9px;font-family: "Noticia Text", Georgia, "Times New Roman", serif;line-height: 150%;mso-line-height-rule: exactly;-ms-text-size-adjust: 100%;-webkit-text-size-adjust: 100%;word-break: break-word;color: #202020;font-size: 16px;text-align: left;">

                             <p dir="ltr" style="font-family: "Noticia Text", Georgia, "Times New Roman", serif;line-height: 150%;margin: 10px 0;padding: 0;mso-line-height-rule: exactly;-ms-text-size-adjust: 100%;-webkit-text-size-adjust: 100%;color: #202020;font-size: 16px;text-align: left;"><span style="font-size:18px"><strong>Take the last 15% off </strong></span></p>

                         </td>
                     </tr>
                 </tbody></table>
                 <!--[if mso]>
                 </td>
                 <![endif]-->

                 <!--[if mso]>
                 </tr>
                 </table>
                 <![endif]-->
             </td>
         </tr>
     </tbody>
 </table><table border="0" cellpadding="0" cellspacing="0" width="100%" class="mcnTextBlock" style="min-width: 100%;border-collapse: collapse;mso-table-lspace: 0pt;mso-table-rspace: 0pt;-ms-text-size-adjust: 100%;-webkit-text-size-adjust: 100%;">
     <tbody class="mcnTextBlockOuter">
         <tr>
             <td valign="top" class="mcnTextBlockInner" style="padding-top: 9px;mso-line-height-rule: exactly;-ms-text-size-adjust: 100%;-webkit-text-size-adjust: 100%;">
                 <!--[if mso]>
                 <table align="left" border="0" cellspacing="0" cellpadding="0" width="100%" style="width:100%;">
                 <tr>
                 <![endif]-->

                 <!--[if mso]>
                 <td valign="top" width="600" style="width:600px;">
                 <![endif]-->
                 <table align="left" border="0" cellpadding="0" cellspacing="0" style="max-width: 100%;min-width: 100%;border-collapse: collapse;mso-table-lspace: 0pt;mso-table-rspace: 0pt;-ms-text-size-adjust: 100%;-webkit-text-size-adjust: 100%;" width="100%" class="mcnTextContentContainer">
                     <tbody><tr>

                         <td valign="top" class="mcnTextContent" style="padding-top: 0;padding-right: 18px;padding-bottom: 9px;padding-left: 18px;mso-line-height-rule: exactly;-ms-text-size-adjust: 100%;-webkit-text-size-adjust: 100%;word-break: break-word;color: #202020;font-family: 'Noticia Text', Georgia, 'Times New Roman', serif;font-size: 16px;line-height: 150%;text-align: left;">

                             This Valentine’s Day, show that special someone how you really feel with the gift of high-end lingerie purchased with Bitcoin. That’s the haps this Valentine’s Day season at <a href="http://www.panties.com" target="_blank" style="mso-line-height-rule: exactly;-ms-text-size-adjust: 100%;-webkit-text-size-adjust: 100%;color: #42702f;font-weight: normal;text-decoration: underline;">Panties.com</a>, where purchases made with BTC enjoy 15% off and a pair of free lace panties.
                         </td>
                     </tr>
                 </tbody></table>
                 <!--[if mso]>
                 </td>
                 <![endif]-->

                 <!--[if mso]>
                 </tr>
                 </table>
                 <![endif]-->
             </td>
         </tr>
     </tbody>
 </table><table border="0" cellpadding="0" cellspacing="0" width="100%" class="mcnImageCardBlock" style="border-collapse: collapse;mso-table-lspace: 0pt;mso-table-rspace: 0pt;-ms-text-size-adjust: 100%;-webkit-text-size-adjust: 100%;">
     <tbody class="mcnImageCardBlockOuter">
         <tr>
             <td class="mcnImageCardBlockInner" valign="top" style="padding-top: 9px;padding-right: 18px;padding-bottom: 9px;padding-left: 18px;mso-line-height-rule: exactly;-ms-text-size-adjust: 100%;-webkit-text-size-adjust: 100%;">

 <table align="right" border="0" cellpadding="0" cellspacing="0" class="mcnImageCardBottomContent" width="100%" style="border-collapse: collapse;mso-table-lspace: 0pt;mso-table-rspace: 0pt;-ms-text-size-adjust: 100%;-webkit-text-size-adjust: 100%;">
     <tbody><tr>
         <td class="mcnImageCardBottomImageContent" align="center" valign="top" style="padding-top: 0px;padding-right: 0px;padding-bottom: 0;padding-left: 0px;mso-line-height-rule: exactly;-ms-text-size-adjust: 100%;-webkit-text-size-adjust: 100%;">



             <img alt="" src="https://gallery.mailchimp.com/adee9c5618cae148bdcf8f762/images/27ce3a2d-6a2f-4ac7-8f43-b64606552b75.jpeg" width="564" style="max-width: 736px;border: 0;height: auto;outline: none;text-decoration: none;-ms-interpolation-mode: bicubic;vertical-align: bottom;" class="mcnImage">


         </td>
     </tr>
     <tr>
         <td class="mcnTextContent" valign="top" style="padding: 9px 18px;color: #747474;font-family: "Noticia Text", Georgia, "Times New Roman", serif;font-size: 14px;font-weight: normal;text-align: center;mso-line-height-rule: exactly;-ms-text-size-adjust: 100%;-webkit-text-size-adjust: 100%;word-break: break-word;line-height: 150%;" width="546">
             <div dir="ltr" style="text-align: center;"><em>Still single.</em></div>

         </td>
     </tr>
 </tbody></table>




             </td>
         </tr>
     </tbody>
 </table><table border="0" cellpadding="0" cellspacing="0" width="100%" class="mcnTextBlock" style="min-width: 100%;border-collapse: collapse;mso-table-lspace: 0pt;mso-table-rspace: 0pt;-ms-text-size-adjust: 100%;-webkit-text-size-adjust: 100%;">
     <tbody class="mcnTextBlockOuter">
         <tr>
             <td valign="top" class="mcnTextBlockInner" style="padding-top: 9px;mso-line-height-rule: exactly;-ms-text-size-adjust: 100%;-webkit-text-size-adjust: 100%;">
                 <!--[if mso]>
                 <table align="left" border="0" cellspacing="0" cellpadding="0" width="100%" style="width:100%;">
                 <tr>
                 <![endif]-->

                 <!--[if mso]>
                 <td valign="top" width="600" style="width:600px;">
                 <![endif]-->
                 <table align="left" border="0" cellpadding="0" cellspacing="0" style="max-width: 100%;min-width: 100%;border-collapse: collapse;mso-table-lspace: 0pt;mso-table-rspace: 0pt;-ms-text-size-adjust: 100%;-webkit-text-size-adjust: 100%;" width="100%" class="mcnTextContentContainer">
                     <tbody><tr>

                         <td valign="top" class="mcnTextContent" style="padding-top: 0;padding-right: 18px;padding-bottom: 9px;padding-left: 18px;mso-line-height-rule: exactly;-ms-text-size-adjust: 100%;-webkit-text-size-adjust: 100%;word-break: break-word;color: #202020;font-family: 'Noticia Text', Georgia, 'Times New Roman', serif;font-size: 16px;line-height: 150%;text-align: left;">

                             We love this story, not just because we appreciate a hot deal, but because of the power of crypto to empower women-owned businesses in the face of censorship in mainstream digital channels like Google and Instagram. As crypto enthusiasts tend to resist censorship, introducing crypto into ecommerce and advertising platforms is likely to pierce through these veils. It also just makes plain business sense - Panties.com founder Lila Williams notes the lower transaction fees of Bitpay compared to credit card processing.<br>
 <br>
 Continued innovations in crypto payment processing and increased utilization by pioneering merchants like Williams is surely one key to our crypto future. <br>
 <br>
 More on Coindesk: <a href="https://www.coindesk.com/lingerie-valentine-bitcoin-crypto" target="_blank" style="mso-line-height-rule: exactly;-ms-text-size-adjust: 100%;-webkit-text-size-adjust: 100%;color: #42702f;font-weight: normal;text-decoration: underline;">https://www.coindesk.com/lingerie-valentine-bitcoin-crypto</a>
                         </td>
                     </tr>
                 </tbody></table>
                 <!--[if mso]>
                 </td>
                 <![endif]-->

                 <!--[if mso]>
                 </tr>
                 </table>
                 <![endif]-->
             </td>
         </tr>
     </tbody>
 </table><table border="0" cellpadding="0" cellspacing="0" width="100%" class="mcnDividerBlock" style="min-width: 100%;border-collapse: collapse;mso-table-lspace: 0pt;mso-table-rspace: 0pt;-ms-text-size-adjust: 100%;-webkit-text-size-adjust: 100%;table-layout: fixed !important;">
     <tbody class="mcnDividerBlockOuter">
         <tr>
             <td class="mcnDividerBlockInner" style="min-width: 100%;padding: 24px 18px;mso-line-height-rule: exactly;-ms-text-size-adjust: 100%;-webkit-text-size-adjust: 100%;">
                 <table class="mcnDividerContent" border="0" cellpadding="0" cellspacing="0" width="100%" style="min-width: 100%;border-top: 2px solid #EAEAEA;border-collapse: collapse;mso-table-lspace: 0pt;mso-table-rspace: 0pt;-ms-text-size-adjust: 100%;-webkit-text-size-adjust: 100%;">
                     <tbody><tr>
                         <td style="mso-line-height-rule: exactly;-ms-text-size-adjust: 100%;-webkit-text-size-adjust: 100%;">
                             <span></span>
                         </td>
                     </tr>
                 </tbody></table>
 <!--
                 <td class="mcnDividerBlockInner" style="padding: 18px;">
                 <hr class="mcnDividerContent" style="border-bottom-color:none; border-left-color:none; border-right-color:none; border-bottom-width:0; border-left-width:0; border-right-width:0; margin-top:0; margin-right:0; margin-bottom:0; margin-left:0;" />
 -->
             </td>
         </tr>
     </tbody>
 </table><table border="0" cellpadding="0" cellspacing="0" width="100%" class="mcnTextBlock" style="min-width: 100%;border-collapse: collapse;mso-table-lspace: 0pt;mso-table-rspace: 0pt;-ms-text-size-adjust: 100%;-webkit-text-size-adjust: 100%;">
     <tbody class="mcnTextBlockOuter">
         <tr>
             <td valign="top" class="mcnTextBlockInner" style="padding-top: 9px;mso-line-height-rule: exactly;-ms-text-size-adjust: 100%;-webkit-text-size-adjust: 100%;">
                 <!--[if mso]>
                 <table align="left" border="0" cellspacing="0" cellpadding="0" width="100%" style="width:100%;">
                 <tr>
                 <![endif]-->

                 <!--[if mso]>
                 <td valign="top" width="600" style="width:600px;">
                 <![endif]-->
                 <table align="left" border="0" cellpadding="0" cellspacing="0" style="max-width: 100%;min-width: 100%;border-collapse: collapse;mso-table-lspace: 0pt;mso-table-rspace: 0pt;-ms-text-size-adjust: 100%;-webkit-text-size-adjust: 100%;" width="100%" class="mcnTextContentContainer">
                     <tbody><tr>

                         <td valign="top" class="mcnTextContent" style="padding: 0px 18px 9px;font-family: "Noticia Text", Georgia, "Times New Roman", serif;line-height: 150%;mso-line-height-rule: exactly;-ms-text-size-adjust: 100%;-webkit-text-size-adjust: 100%;word-break: break-word;color: #202020;font-size: 16px;text-align: left;">

                             <span style="font-size:18px"><span style="font-family:noticia text,georgia,times new roman,serif"><strong>Cool $#i®T of the week</strong></span></span>
                         </td>
                     </tr>
                 </tbody></table>
                 <!--[if mso]>
                 </td>
                 <![endif]-->

                 <!--[if mso]>
                 </tr>
                 </table>
                 <![endif]-->
             </td>
         </tr>
     </tbody>
 </table><table border="0" cellpadding="0" cellspacing="0" width="100%" class="mcnTextBlock" style="min-width: 100%;border-collapse: collapse;mso-table-lspace: 0pt;mso-table-rspace: 0pt;-ms-text-size-adjust: 100%;-webkit-text-size-adjust: 100%;">
     <tbody class="mcnTextBlockOuter">
         <tr>
             <td valign="top" class="mcnTextBlockInner" style="padding-top: 9px;mso-line-height-rule: exactly;-ms-text-size-adjust: 100%;-webkit-text-size-adjust: 100%;">
                 <!--[if mso]>
                 <table align="left" border="0" cellspacing="0" cellpadding="0" width="100%" style="width:100%;">
                 <tr>
                 <![endif]-->

                 <!--[if mso]>
                 <td valign="top" width="600" style="width:600px;">
                 <![endif]-->
                 <table align="left" border="0" cellpadding="0" cellspacing="0" style="max-width: 100%;min-width: 100%;border-collapse: collapse;mso-table-lspace: 0pt;mso-table-rspace: 0pt;-ms-text-size-adjust: 100%;-webkit-text-size-adjust: 100%;" width="100%" class="mcnTextContentContainer">
                     <tbody><tr>

                         <td valign="top" class="mcnTextContent" style="padding: 0px 18px 9px;font-family: "Noticia Text", Georgia, "Times New Roman", serif;line-height: 150%;mso-line-height-rule: exactly;-ms-text-size-adjust: 100%;-webkit-text-size-adjust: 100%;word-break: break-word;color: #202020;font-size: 16px;text-align: left;">

                             <p dir="ltr" style="font-family: "Noticia Text", Georgia, "Times New Roman", serif;line-height: 150%;margin: 10px 0;padding: 0;mso-line-height-rule: exactly;-ms-text-size-adjust: 100%;-webkit-text-size-adjust: 100%;color: #202020;font-size: 16px;text-align: left;">Last night we copped this DEADTONE Cream Puff War long sleeve. Pantone smoothness front and rear, and QR code on the sleeve hyperlinks to 1967 Grateful Dead. This one is right up our alley.</p>

                         </td>
                     </tr>
                 </tbody></table>
                 <!--[if mso]>
                 </td>
                 <![endif]-->

                 <!--[if mso]>
                 </tr>
                 </table>
                 <![endif]-->
             </td>
         </tr>
     </tbody>
 </table><table border="0" cellpadding="0" cellspacing="0" width="100%" class="mcnImageCardBlock" style="border-collapse: collapse;mso-table-lspace: 0pt;mso-table-rspace: 0pt;-ms-text-size-adjust: 100%;-webkit-text-size-adjust: 100%;">
     <tbody class="mcnImageCardBlockOuter">
         <tr>
             <td class="mcnImageCardBlockInner" valign="top" style="padding-top: 9px;padding-right: 18px;padding-bottom: 9px;padding-left: 18px;mso-line-height-rule: exactly;-ms-text-size-adjust: 100%;-webkit-text-size-adjust: 100%;">

 <table align="left" border="0" cellpadding="0" cellspacing="0" class="mcnImageCardBottomContent" width="100%" style="border-collapse: collapse;mso-table-lspace: 0pt;mso-table-rspace: 0pt;-ms-text-size-adjust: 100%;-webkit-text-size-adjust: 100%;">
     <tbody><tr>
         <td class="mcnImageCardBottomImageContent" align="center" valign="top" style="padding-top: 0px;padding-right: 0px;padding-bottom: 0;padding-left: 0px;mso-line-height-rule: exactly;-ms-text-size-adjust: 100%;-webkit-text-size-adjust: 100%;">



             <img alt="" src="https://gallery.mailchimp.com/adee9c5618cae148bdcf8f762/images/09f55cc7-f8d0-4220-81c3-c91859ab92a5.jpg" width="564" style="max-width: 800px;border: 0;height: auto;outline: none;text-decoration: none;-ms-interpolation-mode: bicubic;vertical-align: bottom;" class="mcnImage">


         </td>
     </tr>
     <tr>
         <td class="mcnTextContent" valign="top" style="padding: 9px 18px;color: #747474;font-family: "Noticia Text", Georgia, "Times New Roman", serif;font-size: 14px;font-weight: normal;text-align: center;mso-line-height-rule: exactly;-ms-text-size-adjust: 100%;-webkit-text-size-adjust: 100%;word-break: break-word;line-height: 150%;" width="546">

         </td>
     </tr>
 </tbody></table>




             </td>
         </tr>
     </tbody>
 </table><table border="0" cellpadding="0" cellspacing="0" width="100%" class="mcnTextBlock" style="min-width: 100%;border-collapse: collapse;mso-table-lspace: 0pt;mso-table-rspace: 0pt;-ms-text-size-adjust: 100%;-webkit-text-size-adjust: 100%;">
     <tbody class="mcnTextBlockOuter">
         <tr>
             <td valign="top" class="mcnTextBlockInner" style="padding-top: 9px;mso-line-height-rule: exactly;-ms-text-size-adjust: 100%;-webkit-text-size-adjust: 100%;">
                 <!--[if mso]>
                 <table align="left" border="0" cellspacing="0" cellpadding="0" width="100%" style="width:100%;">
                 <tr>
                 <![endif]-->

                 <!--[if mso]>
                 <td valign="top" width="600" style="width:600px;">
                 <![endif]-->
                 <table align="left" border="0" cellpadding="0" cellspacing="0" style="max-width: 100%;min-width: 100%;border-collapse: collapse;mso-table-lspace: 0pt;mso-table-rspace: 0pt;-ms-text-size-adjust: 100%;-webkit-text-size-adjust: 100%;" width="100%" class="mcnTextContentContainer">
                     <tbody><tr>

                         <td valign="top" class="mcnTextContent" style="padding-top: 0;padding-right: 18px;padding-bottom: 9px;padding-left: 18px;mso-line-height-rule: exactly;-ms-text-size-adjust: 100%;-webkit-text-size-adjust: 100%;word-break: break-word;color: #202020;font-family: 'Noticia Text', Georgia, 'Times New Roman', serif;font-size: 16px;line-height: 150%;text-align: left;">

                             Get it while it lasts: <a href="https://www.bigfootcountycountrycraftstore.com/product/cream-puff-war" target="_blank" style="mso-line-height-rule: exactly;-ms-text-size-adjust: 100%;-webkit-text-size-adjust: 100%;color: #42702f;font-weight: normal;text-decoration: underline;">https://www.bigfootcountycountrycraftstore.com/product/cream-puff-war</a>
                         </td>
                     </tr>
                 </tbody></table>
                 <!--[if mso]>
                 </td>
                 <![endif]-->

                 <!--[if mso]>
                 </tr>
                 </table>
                 <![endif]-->
             </td>
         </tr>
     </tbody>
 </table><table border="0" cellpadding="0" cellspacing="0" width="100%" class="mcnDividerBlock" style="min-width: 100%;border-collapse: collapse;mso-table-lspace: 0pt;mso-table-rspace: 0pt;-ms-text-size-adjust: 100%;-webkit-text-size-adjust: 100%;table-layout: fixed !important;">
     <tbody class="mcnDividerBlockOuter">
         <tr>
             <td class="mcnDividerBlockInner" style="min-width: 100%;padding: 18px;mso-line-height-rule: exactly;-ms-text-size-adjust: 100%;-webkit-text-size-adjust: 100%;">
                 <table class="mcnDividerContent" border="0" cellpadding="0" cellspacing="0" width="100%" style="min-width: 100%;border-top: 2px solid #EAEAEA;border-collapse: collapse;mso-table-lspace: 0pt;mso-table-rspace: 0pt;-ms-text-size-adjust: 100%;-webkit-text-size-adjust: 100%;">
                     <tbody><tr>
                         <td style="mso-line-height-rule: exactly;-ms-text-size-adjust: 100%;-webkit-text-size-adjust: 100%;">
                             <span></span>
                         </td>
                     </tr>
                 </tbody></table>
 <!--
                 <td class="mcnDividerBlockInner" style="padding: 18px;">
                 <hr class="mcnDividerContent" style="border-bottom-color:none; border-left-color:none; border-right-color:none; border-bottom-width:0; border-left-width:0; border-right-width:0; margin-top:0; margin-right:0; margin-bottom:0; margin-left:0;" />
 -->
             </td>
         </tr>
     </tbody>
 </table><table border="0" cellpadding="0" cellspacing="0" width="100%" class="mcnTextBlock" style="min-width: 100%;border-collapse: collapse;mso-table-lspace: 0pt;mso-table-rspace: 0pt;-ms-text-size-adjust: 100%;-webkit-text-size-adjust: 100%;">
     <tbody class="mcnTextBlockOuter">
         <tr>
             <td valign="top" class="mcnTextBlockInner" style="padding-top: 9px;mso-line-height-rule: exactly;-ms-text-size-adjust: 100%;-webkit-text-size-adjust: 100%;">
                 <!--[if mso]>
                 <table align="left" border="0" cellspacing="0" cellpadding="0" width="100%" style="width:100%;">
                 <tr>
                 <![endif]-->

                 <!--[if mso]>
                 <td valign="top" width="600" style="width:600px;">
                 <![endif]-->
                 <table align="left" border="0" cellpadding="0" cellspacing="0" style="max-width: 100%;min-width: 100%;border-collapse: collapse;mso-table-lspace: 0pt;mso-table-rspace: 0pt;-ms-text-size-adjust: 100%;-webkit-text-size-adjust: 100%;" width="100%" class="mcnTextContentContainer">
                     <tbody><tr>

                         <td valign="top" class="mcnTextContent" style="padding: 0px 18px 9px;font-family: "Noticia Text", Georgia, "Times New Roman", serif;line-height: 150%;mso-line-height-rule: exactly;-ms-text-size-adjust: 100%;-webkit-text-size-adjust: 100%;word-break: break-word;color: #202020;font-size: 16px;text-align: left;">

                             <div><strong><span style="font-size:24px">Airdrops! </span></strong></div>

 <div> </div>

 <div>‍♂️‍♂️ Are the Winklevoss Twins engaging in skullduggery? Or are they simply enforcing terms that parties agreed upon? In either case we’d say any time you start selling 1 dollar for 99 cents that you’re asking for trouble.<br>
 <a href="https://www.coindesk.com/winklevoss-crypto-gemini-gusd-stablecoin-redemption" target="_blank" style="mso-line-height-rule: exactly;-ms-text-size-adjust: 100%;-webkit-text-size-adjust: 100%;color: #42702f;font-weight: normal;text-decoration: underline;">Winklevoss Exchange Gemini Shuts Down Accounts Over Stablecoin Redemptions</a> (Coindesk)<br>
 <br>
  Greatest investment of all time? <a href="https://www.highsnobiety.com/p/foot-locker-goat-investment/" target="_blank" style="mso-line-height-rule: exactly;-ms-text-size-adjust: 100%;-webkit-text-size-adjust: 100%;color: #42702f;font-weight: normal;text-decoration: underline;">Sneaker Resale Battle Heats Up With Foot Locker’s $100 Million Investment in GOAT</a> (Highsnobiety)<br>
 <br>
  The future of live virtual entertainment had the curtain pulled back a little further on February 2nd: <a href="https://www.theverge.com/2019/2/2/18208223/fortnite-epic-games-marshmello-concert-exciting-bizarre-future-music" target="_blank" style="mso-line-height-rule: exactly;-ms-text-size-adjust: 100%;-webkit-text-size-adjust: 100%;color: #42702f;font-weight: normal;text-decoration: underline;">Fortnite’s Marshmello concert was a bizarre and exciting glimpse of the future</a> (The Verge)</div>

                         </td>
                     </tr>
                 </tbody></table>
                 <!--[if mso]>
                 </td>
                 <![endif]-->

                 <!--[if mso]>
                 </tr>
                 </table>
                 <![endif]-->
             </td>
         </tr>
     </tbody>
 </table><table border="0" cellpadding="0" cellspacing="0" width="100%" class="mcnDividerBlock" style="min-width: 100%;border-collapse: collapse;mso-table-lspace: 0pt;mso-table-rspace: 0pt;-ms-text-size-adjust: 100%;-webkit-text-size-adjust: 100%;table-layout: fixed !important;">
     <tbody class="mcnDividerBlockOuter">
         <tr>
             <td class="mcnDividerBlockInner" style="min-width: 100%;padding: 18px;mso-line-height-rule: exactly;-ms-text-size-adjust: 100%;-webkit-text-size-adjust: 100%;">
                 <table class="mcnDividerContent" border="0" cellpadding="0" cellspacing="0" width="100%" style="min-width: 100%;border-top: 2px solid #EAEAEA;border-collapse: collapse;mso-table-lspace: 0pt;mso-table-rspace: 0pt;-ms-text-size-adjust: 100%;-webkit-text-size-adjust: 100%;">
                     <tbody><tr>
                         <td style="mso-line-height-rule: exactly;-ms-text-size-adjust: 100%;-webkit-text-size-adjust: 100%;">
                             <span></span>
                         </td>
                     </tr>
                 </tbody></table>
 <!--
                 <td class="mcnDividerBlockInner" style="padding: 18px;">
                 <hr class="mcnDividerContent" style="border-bottom-color:none; border-left-color:none; border-right-color:none; border-bottom-width:0; border-left-width:0; border-right-width:0; margin-top:0; margin-right:0; margin-bottom:0; margin-left:0;" />
 -->
             </td>
         </tr>
     </tbody>
 </table><table border="0" cellpadding="0" cellspacing="0" width="100%" class="mcnTextBlock" style="min-width: 100%;border-collapse: collapse;mso-table-lspace: 0pt;mso-table-rspace: 0pt;-ms-text-size-adjust: 100%;-webkit-text-size-adjust: 100%;">
     <tbody class="mcnTextBlockOuter">
         <tr>
             <td valign="top" class="mcnTextBlockInner" style="padding-top: 9px;mso-line-height-rule: exactly;-ms-text-size-adjust: 100%;-webkit-text-size-adjust: 100%;">
                 <!--[if mso]>
                 <table align="left" border="0" cellspacing="0" cellpadding="0" width="100%" style="width:100%;">
                 <tr>
                 <![endif]-->

                 <!--[if mso]>
                 <td valign="top" width="600" style="width:600px;">
                 <![endif]-->
                 <table align="left" border="0" cellpadding="0" cellspacing="0" style="max-width: 100%;min-width: 100%;border-collapse: collapse;mso-table-lspace: 0pt;mso-table-rspace: 0pt;-ms-text-size-adjust: 100%;-webkit-text-size-adjust: 100%;" width="100%" class="mcnTextContentContainer">
                     <tbody><tr>

                         <td valign="top" class="mcnTextContent" style="padding: 0px 18px 9px;font-family: "Noticia Text", Georgia, "Times New Roman", serif;line-height: 150%;mso-line-height-rule: exactly;-ms-text-size-adjust: 100%;-webkit-text-size-adjust: 100%;word-break: break-word;color: #202020;font-size: 16px;text-align: left;">

                             <div style="text-align: center;"><strong><span style="font-size:24px">SEE YOU NEXT WEEK...</span></strong><br>
 <br>
 Tips and leads for next week's edition? Email them to us at <a href="mailto:hi@satoshiscloset.com?subject=Newsletter%20tips%20and%20leads" target="_blank" style="mso-line-height-rule: exactly;-ms-text-size-adjust: 100%;-webkit-text-size-adjust: 100%;color: #42702f;font-weight: normal;text-decoration: underline;">hi@satoshiscloset.com</a>.<br>
 <br>
 <img data-file-id="4326517" height="128" src="https://gallery.mailchimp.com/adee9c5618cae148bdcf8f762/images/98c6e2b1-a7da-4b2a-8718-799bda1c4658.gif" style="border: 0px;width: 128px;height: 128px;margin: 0px;outline: none;text-decoration: none;-ms-interpolation-mode: bicubic;" width="128"><br>
 <strong>Check out previous issues:</strong><br>
 <a href="https://satoshiscloset.com/blog" target="_blank" style="mso-line-height-rule: exactly;-ms-text-size-adjust: 100%;-webkit-text-size-adjust: 100%;color: #42702f;font-weight: normal;text-decoration: underline;">https://satoshiscloset.com/blog</a></div>
 </td>
                     </tr>
                 </tbody></table>
             </td>
         </tr>
     </tbody>
 </table></td>
                             </tr>

                         </table>
                     </td>
                 </tr>
             </table>
         </center>
     </body>
 </html>`

 const POST7 = `<!doctype html>
 <html xmlns="http://www.w3.org/1999/xhtml" xmlns:v="urn:schemas-microsoft-com:vml" xmlns:o="urn:schemas-microsoft-com:office:office">
     <head>
         <!-- NAME: 1 COLUMN -->
         <!--[if gte mso 15]>
         <xml>
             <o:OfficeDocumentSettings>
             <o:AllowPNG/>
             <o:PixelsPerInch>96</o:PixelsPerInch>
             </o:OfficeDocumentSettings>
         </xml>
         <![endif]-->
         <meta charset="UTF-8">
         <meta http-equiv="X-UA-Compatible" content="IE=edge">
         <meta name="viewport" content="width=device-width, initial-scale=1">
         <title>[007-07-2019] Little ditty about Jack and Dimon</title>

     <style type="text/css">
         p{
             margin:10px 0;
             padding:0;
         }
         table{
             border-collapse:collapse;
         }
         h1,h2,h3,h4,h5,h6{
             display:block;
             margin:0;
             padding:0;
         }
         img,a img{
             border:0;
             height:auto;
             outline:none;
             text-decoration:none;
         }
         body,#bodyTable,#bodyCell{
             height:100%;
             margin:0;
             padding:0;
             width:100%;
         }
         .mcnPreviewText{
             display:none !important;
         }
         #outlook a{
             padding:0;
         }
         img{
             -ms-interpolation-mode:bicubic;
         }
         table{
             mso-table-lspace:0pt;
             mso-table-rspace:0pt;
         }
         .ReadMsgBody{
             width:100%;
         }
         .ExternalClass{
             width:100%;
         }
         p,a,li,td,blockquote{
             mso-line-height-rule:exactly;
         }
         a[href^=tel],a[href^=sms]{
             color:inherit;
             cursor:default;
             text-decoration:none;
         }
         p,a,li,td,body,table,blockquote{
             -ms-text-size-adjust:100%;
             -webkit-text-size-adjust:100%;
         }
         .ExternalClass,.ExternalClass p,.ExternalClass td,.ExternalClass div,.ExternalClass span,.ExternalClass font{
             line-height:100%;
         }
         a[x-apple-data-detectors]{
             color:inherit !important;
             text-decoration:none !important;
             font-size:inherit !important;
             font-family:inherit !important;
             font-weight:inherit !important;
             line-height:inherit !important;
         }
         #bodyCell{
             padding:10px;
         }
         .templateContainer{
             max-width:600px !important;
         }
         a.mcnButton{
             display:block;
         }
         .mcnImage,.mcnRetinaImage{
             vertical-align:bottom;
         }
         .mcnTextContent{
             word-break:break-word;
         }
         .mcnTextContent img{
             height:auto !important;
         }
         .mcnDividerBlock{
             table-layout:fixed !important;
         }
         body,#bodyTable{
             background-color:#ffffff;
         }
         #bodyCell{
             border-top:0;
         }
         .templateContainer{
             border:0;
         }
         h1{
             color:#202020;
             font-family:Helvetica;
             font-size:26px;
             font-style:normal;
             font-weight:bold;
             line-height:125%;
             letter-spacing:normal;
             text-align:left;
         }
         h2{
             color:#202020;
             font-family:Helvetica;
             font-size:22px;
             font-style:normal;
             font-weight:bold;
             line-height:125%;
             letter-spacing:normal;
             text-align:left;
         }
         h3{
             color:#202020;
             font-family:Helvetica;
             font-size:20px;
             font-style:normal;
             font-weight:bold;
             line-height:125%;
             letter-spacing:normal;
             text-align:left;
         }
         h4{
             color:#202020;
             font-family:Helvetica;
             font-size:18px;
             font-style:normal;
             font-weight:bold;
             line-height:125%;
             letter-spacing:normal;
             text-align:left;
         }
         #templatePreheader{
             background-color:#FAFAFA;
             background-image:none;
             background-repeat:no-repeat;
             background-position:center;
             background-size:cover;
             border-top:0;
             border-bottom:0;
             padding-top:9px;
             padding-bottom:9px;
         }
         #templatePreheader .mcnTextContent,#templatePreheader .mcnTextContent p{
             color:#656565;
             font-family:Helvetica;
             font-size:12px;
             line-height:150%;
             text-align:left;
         }
         #templatePreheader .mcnTextContent a,#templatePreheader .mcnTextContent p a{
             color:#656565;
             font-weight:normal;
             text-decoration:underline;
         }
         #templateHeader{
             background-color:#ffffff;
             background-image:none;
             background-repeat:no-repeat;
             background-position:center;
             background-size:cover;
             border-top:0;
             border-bottom:0;
             padding-top:18px;
             padding-bottom:0px;
         }
         #templateHeader .mcnTextContent,#templateHeader .mcnTextContent p{
             color:#202020;
             font-family:Helvetica;
             font-size:12px;
             line-height:125%;
             text-align:left;
         }
         #templateHeader .mcnTextContent a,#templateHeader .mcnTextContent p a{
             color:#007C89;
             font-weight:normal;
             text-decoration:underline;
         }
         #templateBody{
             background-color:#ffffff;
             background-image:none;
             background-repeat:no-repeat;
             background-position:center;
             background-size:cover;
             border-top:0;
             border-bottom:2px solid #EAEAEA;
             padding-top:0;
             padding-bottom:9px;
         }
         #templateBody .mcnTextContent,#templateBody .mcnTextContent p{
             color:#202020;
             font-family:'Noticia Text', Georgia, 'Times New Roman', serif;
             font-size:16px;
             line-height:150%;
             text-align:left;
         }
         #templateBody .mcnTextContent a,#templateBody .mcnTextContent p a{
             color:#42702f;
             font-weight:normal;
             text-decoration:underline;
         }
         #templateFooter{
             background-color:#ffffff;
             background-image:none;
             background-repeat:no-repeat;
             background-position:center;
             background-size:cover;
             border-top:0;
             border-bottom:0;
             padding-top:9px;
             padding-bottom:9px;
         }
         #templateFooter .mcnTextContent,#templateFooter .mcnTextContent p{
             color:#656565;
             font-family:Helvetica;
             font-size:12px;
             line-height:150%;
             text-align:center;
         }
         #templateFooter .mcnTextContent a,#templateFooter .mcnTextContent p a{
             color:#656565;
             font-weight:normal;
             text-decoration:underline;
         }
     @media only screen and (min-width:768px){
         .templateContainer{
             width:600px !important;
         }

 }   @media only screen and (max-width: 480px){
         body,table,td,p,a,li,blockquote{
             -webkit-text-size-adjust:none !important;
         }

 }   @media only screen and (max-width: 480px){
         body{
             width:100% !important;
             min-width:100% !important;
         }

 }   @media only screen and (max-width: 480px){
         #bodyCell{
             padding-top:10px !important;
         }

 }   @media only screen and (max-width: 480px){
         .mcnRetinaImage{
             max-width:100% !important;
         }

 }   @media only screen and (max-width: 480px){
         .mcnImage{
             width:100% !important;
         }

 }   @media only screen and (max-width: 480px){
         .mcnCartContainer,.mcnCaptionTopContent,.mcnRecContentContainer,.mcnCaptionBottomContent,.mcnTextContentContainer,.mcnBoxedTextContentContainer,.mcnImageGroupContentContainer,.mcnCaptionLeftTextContentContainer,.mcnCaptionRightTextContentContainer,.mcnCaptionLeftImageContentContainer,.mcnCaptionRightImageContentContainer,.mcnImageCardLeftTextContentContainer,.mcnImageCardRightTextContentContainer,.mcnImageCardLeftImageContentContainer,.mcnImageCardRightImageContentContainer{
             max-width:100% !important;
             width:100% !important;
         }

 }   @media only screen and (max-width: 480px){
         .mcnBoxedTextContentContainer{
             min-width:100% !important;
         }

 }   @media only screen and (max-width: 480px){
         .mcnImageGroupContent{
             padding:9px !important;
         }

 }   @media only screen and (max-width: 480px){
         .mcnCaptionLeftContentOuter .mcnTextContent,.mcnCaptionRightContentOuter .mcnTextContent{
             padding-top:9px !important;
         }

 }   @media only screen and (max-width: 480px){
         .mcnImageCardTopImageContent,.mcnCaptionBottomContent:last-child .mcnCaptionBottomImageContent,.mcnCaptionBlockInner .mcnCaptionTopContent:last-child .mcnTextContent{
             padding-top:18px !important;
         }

 }   @media only screen and (max-width: 480px){
         .mcnImageCardBottomImageContent{
             padding-bottom:9px !important;
         }

 }   @media only screen and (max-width: 480px){
         .mcnImageGroupBlockInner{
             padding-top:0 !important;
             padding-bottom:0 !important;
         }

 }   @media only screen and (max-width: 480px){
         .mcnImageGroupBlockOuter{
             padding-top:9px !important;
             padding-bottom:9px !important;
         }

 }   @media only screen and (max-width: 480px){
         .mcnTextContent,.mcnBoxedTextContentColumn{
             padding-right:18px !important;
             padding-left:18px !important;
         }

 }   @media only screen and (max-width: 480px){
         .mcnImageCardLeftImageContent,.mcnImageCardRightImageContent{
             padding-right:18px !important;
             padding-bottom:0 !important;
             padding-left:18px !important;
         }

 }   @media only screen and (max-width: 480px){
         .mcpreview-image-uploader{
             display:none !important;
             width:100% !important;
         }

 }   @media only screen and (max-width: 480px){
         h1{
             font-size:22px !important;
             line-height:125% !important;
         }

 }   @media only screen and (max-width: 480px){
         h2{
             font-size:20px !important;
             line-height:125% !important;
         }

 }   @media only screen and (max-width: 480px){
         h3{
             font-size:18px !important;
             line-height:125% !important;
         }

 }   @media only screen and (max-width: 480px){
         h4{
             font-size:16px !important;
             line-height:150% !important;
         }

 }   @media only screen and (max-width: 480px){
         .mcnBoxedTextContentContainer .mcnTextContent,.mcnBoxedTextContentContainer .mcnTextContent p{
             font-size:14px !important;
             line-height:150% !important;
         }

 }   @media only screen and (max-width: 480px){
         #templatePreheader{
             display:block !important;
         }

 }   @media only screen and (max-width: 480px){
         #templatePreheader .mcnTextContent,#templatePreheader .mcnTextContent p{
             font-size:14px !important;
             line-height:150% !important;
         }

 }   @media only screen and (max-width: 480px){
         #templateHeader .mcnTextContent,#templateHeader .mcnTextContent p{
             font-size:16px !important;
             line-height:150% !important;
         }

 }   @media only screen and (max-width: 480px){
         #templateBody .mcnTextContent,#templateBody .mcnTextContent p{
             font-size:16px !important;
             line-height:150% !important;
         }

 }   @media only screen and (max-width: 480px){
         #templateFooter .mcnTextContent,#templateFooter .mcnTextContent p{
             font-size:14px !important;
             line-height:150% !important;
         }

 }</style><!--[if !mso]><!--><link href="https://fonts.googleapis.com/css?family=Noticia+Text:400,400i,700,700i" rel="stylesheet"><!--<![endif]--></head>
     <body style="height: 100%;margin: 0;padding: 0;width: 100%;-ms-text-size-adjust: 100%;-webkit-text-size-adjust: 100%;background-color: #ffffff;">
         <!--*|IF:MC_PREVIEW_TEXT|*-->
         <!--[if !gte mso 9]><!----><span class="mcnPreviewText" style="display:none; font-size:0px; line-height:0px; max-height:0px; max-width:0px; opacity:0; overflow:hidden; visibility:hidden; mso-hide:all;">*|MC_PREVIEW_TEXT|*</span><!--<![endif]-->
         <!--*|END:IF|*-->
         <center>
             <table align="center" border="0" cellpadding="0" cellspacing="0" height="100%" width="100%" id="bodyTable" style="border-collapse: collapse;mso-table-lspace: 0pt;mso-table-rspace: 0pt;-ms-text-size-adjust: 100%;-webkit-text-size-adjust: 100%;height: 100%;margin: 0;padding: 0;width: 100%;background-color: #ffffff;">
                 <tr>
                     <td align="center" valign="top" id="bodyCell" style="mso-line-height-rule: exactly;-ms-text-size-adjust: 100%;-webkit-text-size-adjust: 100%;height: 100%;margin: 0;padding: 10px;width: 100%;border-top: 0;">
                         <!-- BEGIN TEMPLATE // -->
                         <!--[if (gte mso 9)|(IE)]>
                         <table align="center" border="0" cellspacing="0" cellpadding="0" width="600" style="width:600px;">
                         <tr>
                         <td align="center" valign="top" width="600" style="width:600px;">
                         <![endif]-->
                         <table border="0" cellpadding="0" cellspacing="0" width="100%" class="templateContainer" style="border-collapse: collapse;mso-table-lspace: 0pt;mso-table-rspace: 0pt;-ms-text-size-adjust: 100%;-webkit-text-size-adjust: 100%;border: 0;max-width: 600px !important;">
                             <tr>
                                 <td valign="top" id="templateHeader" style="background:#ffffff none no-repeat center/cover;mso-line-height-rule: exactly;-ms-text-size-adjust: 100%;-webkit-text-size-adjust: 100%;background-color: #ffffff;background-image: none;background-repeat: no-repeat;background-position: center;background-size: cover;border-top: 0;border-bottom: 0;padding-top: 18px;padding-bottom: 0px;"><table border="0" cellpadding="0" cellspacing="0" width="100%" class="mcnImageBlock" style="min-width: 100%;border-collapse: collapse;mso-table-lspace: 0pt;mso-table-rspace: 0pt;-ms-text-size-adjust: 100%;-webkit-text-size-adjust: 100%;">
     <tbody class="mcnImageBlockOuter">
             <tr>
                 <td valign="top" style="padding: 9px;mso-line-height-rule: exactly;-ms-text-size-adjust: 100%;-webkit-text-size-adjust: 100%;" class="mcnImageBlockInner">
                     <table align="left" width="100%" border="0" cellpadding="0" cellspacing="0" class="mcnImageContentContainer" style="min-width: 100%;border-collapse: collapse;mso-table-lspace: 0pt;mso-table-rspace: 0pt;-ms-text-size-adjust: 100%;-webkit-text-size-adjust: 100%;">
                         <tbody><tr>
                             <td class="mcnImageContent" valign="top" style="padding-right: 9px;padding-left: 9px;padding-top: 0;padding-bottom: 0;text-align: center;mso-line-height-rule: exactly;-ms-text-size-adjust: 100%;-webkit-text-size-adjust: 100%;">


                                         <img align="center" alt="" src="https://gallery.mailchimp.com/adee9c5618cae148bdcf8f762/images/7bdb1e7c-f16f-4898-bb36-a4760bd19990.jpg" width="564" style="max-width: 960px;padding-bottom: 0;display: inline !important;vertical-align: bottom;border: 0;height: auto;outline: none;text-decoration: none;-ms-interpolation-mode: bicubic;" class="mcnImage">


                             </td>
                         </tr>
                     </tbody></table>
                 </td>
             </tr>
     </tbody>
 </table></td>
                             </tr>
                             <tr>
                                 <td valign="top" id="templateBody" style="background:#ffffff none no-repeat center/cover;mso-line-height-rule: exactly;-ms-text-size-adjust: 100%;-webkit-text-size-adjust: 100%;background-color: #ffffff;background-image: none;background-repeat: no-repeat;background-position: center;background-size: cover;border-top: 0;border-bottom: 2px solid #EAEAEA;padding-top: 0;padding-bottom: 9px;"><table border="0" cellpadding="0" cellspacing="0" width="100%" class="mcnDividerBlock" style="min-width: 100%;border-collapse: collapse;mso-table-lspace: 0pt;mso-table-rspace: 0pt;-ms-text-size-adjust: 100%;-webkit-text-size-adjust: 100%;table-layout: fixed !important;">
     <tbody class="mcnDividerBlockOuter">
         <tr>
             <td class="mcnDividerBlockInner" style="min-width: 100%;padding: 8px 18px 0px;mso-line-height-rule: exactly;-ms-text-size-adjust: 100%;-webkit-text-size-adjust: 100%;">
                 <table class="mcnDividerContent" border="0" cellpadding="0" cellspacing="0" width="100%" style="min-width: 100%;border-top: 2px solid #EAEAEA;border-collapse: collapse;mso-table-lspace: 0pt;mso-table-rspace: 0pt;-ms-text-size-adjust: 100%;-webkit-text-size-adjust: 100%;">
                     <tbody><tr>
                         <td style="mso-line-height-rule: exactly;-ms-text-size-adjust: 100%;-webkit-text-size-adjust: 100%;">
                             <span></span>
                         </td>
                     </tr>
                 </tbody></table>
 <!--
                 <td class="mcnDividerBlockInner" style="padding: 18px;">
                 <hr class="mcnDividerContent" style="border-bottom-color:none; border-left-color:none; border-right-color:none; border-bottom-width:0; border-left-width:0; border-right-width:0; margin-top:0; margin-right:0; margin-bottom:0; margin-left:0;" />
 -->
             </td>
         </tr>
     </tbody>
 </table><table border="0" cellpadding="0" cellspacing="0" width="100%" class="mcnTextBlock" style="min-width: 100%;border-collapse: collapse;mso-table-lspace: 0pt;mso-table-rspace: 0pt;-ms-text-size-adjust: 100%;-webkit-text-size-adjust: 100%;">
     <tbody class="mcnTextBlockOuter">
         <tr>
             <td valign="top" class="mcnTextBlockInner" style="padding-top: 9px;mso-line-height-rule: exactly;-ms-text-size-adjust: 100%;-webkit-text-size-adjust: 100%;">
                 <!--[if mso]>
                 <table align="left" border="0" cellspacing="0" cellpadding="0" width="100%" style="width:100%;">
                 <tr>
                 <![endif]-->

                 <!--[if mso]>
                 <td valign="top" width="600" style="width:600px;">
                 <![endif]-->
                 <table align="left" border="0" cellpadding="0" cellspacing="0" style="max-width: 100%;min-width: 100%;border-collapse: collapse;mso-table-lspace: 0pt;mso-table-rspace: 0pt;-ms-text-size-adjust: 100%;-webkit-text-size-adjust: 100%;" width="100%" class="mcnTextContentContainer">
                     <tbody><tr>

                         <td valign="top" class="mcnTextContent" style="padding: 0px 18px 9px;font-family: "Noticia Text", Georgia, "Times New Roman", serif;line-height: 150%;mso-line-height-rule: exactly;-ms-text-size-adjust: 100%;-webkit-text-size-adjust: 100%;word-break: break-word;color: #202020;font-size: 16px;text-align: left;">

                             <div style="text-align: left;"><strong><span style="font-size:12px"><span style="font-family:noticia text,georgia,times new roman,serif">Dorsey brings the thunder • Crypto goes corporate • Cool $#i®T • Airdrops!</span></span></strong></div>

                         </td>
                     </tr>
                 </tbody></table>
                 <!--[if mso]>
                 </td>
                 <![endif]-->

                 <!--[if mso]>
                 </tr>
                 </table>
                 <![endif]-->
             </td>
         </tr>
     </tbody>
 </table><table border="0" cellpadding="0" cellspacing="0" width="100%" class="mcnDividerBlock" style="min-width: 100%;border-collapse: collapse;mso-table-lspace: 0pt;mso-table-rspace: 0pt;-ms-text-size-adjust: 100%;-webkit-text-size-adjust: 100%;table-layout: fixed !important;">
     <tbody class="mcnDividerBlockOuter">
         <tr>
             <td class="mcnDividerBlockInner" style="min-width: 100%;padding: 2px 18px 9px;mso-line-height-rule: exactly;-ms-text-size-adjust: 100%;-webkit-text-size-adjust: 100%;">
                 <table class="mcnDividerContent" border="0" cellpadding="0" cellspacing="0" width="100%" style="min-width: 100%;border-top: 2px solid #EAEAEA;border-collapse: collapse;mso-table-lspace: 0pt;mso-table-rspace: 0pt;-ms-text-size-adjust: 100%;-webkit-text-size-adjust: 100%;">
                     <tbody><tr>
                         <td style="mso-line-height-rule: exactly;-ms-text-size-adjust: 100%;-webkit-text-size-adjust: 100%;">
                             <span></span>
                         </td>
                     </tr>
                 </tbody></table>
 <!--
                 <td class="mcnDividerBlockInner" style="padding: 18px;">
                 <hr class="mcnDividerContent" style="border-bottom-color:none; border-left-color:none; border-right-color:none; border-bottom-width:0; border-left-width:0; border-right-width:0; margin-top:0; margin-right:0; margin-bottom:0; margin-left:0;" />
 -->
             </td>
         </tr>
     </tbody>
 </table><table border="0" cellpadding="0" cellspacing="0" width="100%" class="mcnTextBlock" style="min-width: 100%;border-collapse: collapse;mso-table-lspace: 0pt;mso-table-rspace: 0pt;-ms-text-size-adjust: 100%;-webkit-text-size-adjust: 100%;">
     <tbody class="mcnTextBlockOuter">
         <tr>
             <td valign="top" class="mcnTextBlockInner" style="padding-top: 9px;mso-line-height-rule: exactly;-ms-text-size-adjust: 100%;-webkit-text-size-adjust: 100%;">
                 <!--[if mso]>
                 <table align="left" border="0" cellspacing="0" cellpadding="0" width="100%" style="width:100%;">
                 <tr>
                 <![endif]-->

                 <!--[if mso]>
                 <td valign="top" width="600" style="width:600px;">
                 <![endif]-->
                 <table align="left" border="0" cellpadding="0" cellspacing="0" style="max-width: 100%;min-width: 100%;border-collapse: collapse;mso-table-lspace: 0pt;mso-table-rspace: 0pt;-ms-text-size-adjust: 100%;-webkit-text-size-adjust: 100%;" width="100%" class="mcnTextContentContainer">
                     <tbody><tr>

                         <td valign="top" class="mcnTextContent" style="padding: 0px 18px 9px;font-family: "Noticia Text", Georgia, "Times New Roman", serif;line-height: 150%;mso-line-height-rule: exactly;-ms-text-size-adjust: 100%;-webkit-text-size-adjust: 100%;word-break: break-word;color: #202020;font-size: 16px;text-align: left;">

                             <p dir="ltr" style="font-family: "Noticia Text", Georgia, "Times New Roman", serif;line-height: 150%;margin: 10px 0;padding: 0;mso-line-height-rule: exactly;-ms-text-size-adjust: 100%;-webkit-text-size-adjust: 100%;color: #202020;font-size: 16px;text-align: left;"><span style="font-size:18px"><strong>Jack and the BTCstalk</strong></span><br>
 <br>
 The fairy tale of <em>Jack and the Beanstalk</em> can be neatly summarized like this: Jack sells the aging family cash cow to acquire a handful of magic beans that his mother dismisses as worthless, only to see them grow overnight into a giant beanstalk high into the sky. Jack climbs the beanstalk where he finds a sleeping giant whom he ultimately slays while stealing his golden coins. It’s a more macabre tale than we recall from our childhood, but we’re just going to assume the giant was evil or something.</p>

                         </td>
                     </tr>
                 </tbody></table>
                 <!--[if mso]>
                 </td>
                 <![endif]-->

                 <!--[if mso]>
                 </tr>
                 </table>
                 <![endif]-->
             </td>
         </tr>
     </tbody>
 </table><table border="0" cellpadding="0" cellspacing="0" width="100%" class="mcnImageCardBlock" style="border-collapse: collapse;mso-table-lspace: 0pt;mso-table-rspace: 0pt;-ms-text-size-adjust: 100%;-webkit-text-size-adjust: 100%;">
     <tbody class="mcnImageCardBlockOuter">
         <tr>
             <td class="mcnImageCardBlockInner" valign="top" style="padding-top: 9px;padding-right: 18px;padding-bottom: 9px;padding-left: 18px;mso-line-height-rule: exactly;-ms-text-size-adjust: 100%;-webkit-text-size-adjust: 100%;">

 <table align="right" border="0" cellpadding="0" cellspacing="0" class="mcnImageCardBottomContent" width="100%" style="border-collapse: collapse;mso-table-lspace: 0pt;mso-table-rspace: 0pt;-ms-text-size-adjust: 100%;-webkit-text-size-adjust: 100%;">
     <tbody><tr>
         <td class="mcnImageCardBottomImageContent" align="left" valign="top" style="padding-top: 0px;padding-right: 0px;padding-bottom: 0;padding-left: 0px;mso-line-height-rule: exactly;-ms-text-size-adjust: 100%;-webkit-text-size-adjust: 100%;">



             <img alt="" src="https://gallery.mailchimp.com/adee9c5618cae148bdcf8f762/images/b5451bfb-1456-47c0-a66b-a9cddc62418b.jpg" width="540" style="max-width: 540px;border: 0;height: auto;outline: none;text-decoration: none;-ms-interpolation-mode: bicubic;vertical-align: bottom;" class="mcnImage">


         </td>
     </tr>
     <tr>
         <td class="mcnTextContent" valign="top" style="padding: 9px 18px;color: #747474;font-family: "Noticia Text", Georgia, "Times New Roman", serif;font-size: 14px;font-style: italic;font-weight: normal;text-align: left;mso-line-height-rule: exactly;-ms-text-size-adjust: 100%;-webkit-text-size-adjust: 100%;word-break: break-word;line-height: 150%;" width="546">
             To the moon...
         </td>
     </tr>
 </tbody></table>




             </td>
         </tr>
     </tbody>
 </table><table border="0" cellpadding="0" cellspacing="0" width="100%" class="mcnTextBlock" style="min-width: 100%;border-collapse: collapse;mso-table-lspace: 0pt;mso-table-rspace: 0pt;-ms-text-size-adjust: 100%;-webkit-text-size-adjust: 100%;">
     <tbody class="mcnTextBlockOuter">
         <tr>
             <td valign="top" class="mcnTextBlockInner" style="padding-top: 9px;mso-line-height-rule: exactly;-ms-text-size-adjust: 100%;-webkit-text-size-adjust: 100%;">
                 <!--[if mso]>
                 <table align="left" border="0" cellspacing="0" cellpadding="0" width="100%" style="width:100%;">
                 <tr>
                 <![endif]-->

                 <!--[if mso]>
                 <td valign="top" width="600" style="width:600px;">
                 <![endif]-->
                 <table align="left" border="0" cellpadding="0" cellspacing="0" style="max-width: 100%;min-width: 100%;border-collapse: collapse;mso-table-lspace: 0pt;mso-table-rspace: 0pt;-ms-text-size-adjust: 100%;-webkit-text-size-adjust: 100%;" width="100%" class="mcnTextContentContainer">
                     <tbody><tr>

                         <td valign="top" class="mcnTextContent" style="padding-top: 0;padding-right: 18px;padding-bottom: 9px;padding-left: 18px;mso-line-height-rule: exactly;-ms-text-size-adjust: 100%;-webkit-text-size-adjust: 100%;word-break: break-word;color: #202020;font-family: 'Noticia Text', Georgia, 'Times New Roman', serif;font-size: 16px;line-height: 150%;text-align: left;">

                             Maybe it’s because we view the world through crypto-colored glasses, but that sounds a little bit like the story of Bitcoin to us. Cast aside as worthless, it shoots up to reveal access to previously unimaginable wealth, guarded by giants. We also have our very own Jack - Jack Dorsey of Twitter-founding fame, and also the founder and CEO of <a href="https://squareup.com/" target="_blank" style="mso-line-height-rule: exactly;-ms-text-size-adjust: 100%;-webkit-text-size-adjust: 100%;color: #42702f;font-weight: normal;text-decoration: underline;">Square</a>. If you’re not familiar with Square, it’s the tablet-based checkout register you’ve seen in many of your favorite stores.<br>
 <br>
 Jack is very bullish on Bitcoin, and with the weight of his $30 billion Square payments platform and its install base at 30,000 merchants, we see huge potential for a tipping point of Bitcoin acceptance. Not only does Square have a significant foothold with merchants, but the <a href="https://cash.app/" target="_blank" style="mso-line-height-rule: exactly;-ms-text-size-adjust: 100%;-webkit-text-size-adjust: 100%;color: #42702f;font-weight: normal;text-decoration: underline;">Square Cash app</a> places peer-to-peer payment capabilities in the hands of smartphone owners anywhere. And the latest news is Jack’s declaration that implementation of the Lightning Network (which makes BTC transactions process faster) on the Cash App is a matter of “when, not if.” Oh yeah, Visa and Mastercard are raising their fees on merchants, too (see our Airdrops section below).<br>
 <br>
 Sounds like Jack has all the ingredients necessary to make these magic beans do their thing… 🤑<br>
 <br>
 Get a fuller take on Jack’s bullishness on BTC: <a href="https://thrillercrypto.com/2019/02/11/jack-dorsey-says-cash-app-support-for-lightning-network-a-question-of-when-not-if/" target="_blank" style="mso-line-height-rule: exactly;-ms-text-size-adjust: 100%;-webkit-text-size-adjust: 100%;color: #42702f;font-weight: normal;text-decoration: underline;">https://thrillercrypto.com/2019/02/11/jack-dorsey-says-cash-app-support-for-lightning-network-a-question-of-when-not-if/</a>
                         </td>
                     </tr>
                 </tbody></table>
                 <!--[if mso]>
                 </td>
                 <![endif]-->

                 <!--[if mso]>
                 </tr>
                 </table>
                 <![endif]-->
             </td>
         </tr>
     </tbody>
 </table><table border="0" cellpadding="0" cellspacing="0" width="100%" class="mcnDividerBlock" style="min-width: 100%;border-collapse: collapse;mso-table-lspace: 0pt;mso-table-rspace: 0pt;-ms-text-size-adjust: 100%;-webkit-text-size-adjust: 100%;table-layout: fixed !important;">
     <tbody class="mcnDividerBlockOuter">
         <tr>
             <td class="mcnDividerBlockInner" style="min-width: 100%;padding: 24px 18px;mso-line-height-rule: exactly;-ms-text-size-adjust: 100%;-webkit-text-size-adjust: 100%;">
                 <table class="mcnDividerContent" border="0" cellpadding="0" cellspacing="0" width="100%" style="min-width: 100%;border-top: 2px solid #EAEAEA;border-collapse: collapse;mso-table-lspace: 0pt;mso-table-rspace: 0pt;-ms-text-size-adjust: 100%;-webkit-text-size-adjust: 100%;">
                     <tbody><tr>
                         <td style="mso-line-height-rule: exactly;-ms-text-size-adjust: 100%;-webkit-text-size-adjust: 100%;">
                             <span></span>
                         </td>
                     </tr>
                 </tbody></table>
 <!--
                 <td class="mcnDividerBlockInner" style="padding: 18px;">
                 <hr class="mcnDividerContent" style="border-bottom-color:none; border-left-color:none; border-right-color:none; border-bottom-width:0; border-left-width:0; border-right-width:0; margin-top:0; margin-right:0; margin-bottom:0; margin-left:0;" />
 -->
             </td>
         </tr>
     </tbody>
 </table><table border="0" cellpadding="0" cellspacing="0" width="100%" class="mcnTextBlock" style="min-width: 100%;border-collapse: collapse;mso-table-lspace: 0pt;mso-table-rspace: 0pt;-ms-text-size-adjust: 100%;-webkit-text-size-adjust: 100%;">
     <tbody class="mcnTextBlockOuter">
         <tr>
             <td valign="top" class="mcnTextBlockInner" style="padding-top: 9px;mso-line-height-rule: exactly;-ms-text-size-adjust: 100%;-webkit-text-size-adjust: 100%;">
                 <!--[if mso]>
                 <table align="left" border="0" cellspacing="0" cellpadding="0" width="100%" style="width:100%;">
                 <tr>
                 <![endif]-->

                 <!--[if mso]>
                 <td valign="top" width="600" style="width:600px;">
                 <![endif]-->
                 <table align="left" border="0" cellpadding="0" cellspacing="0" style="max-width: 100%;min-width: 100%;border-collapse: collapse;mso-table-lspace: 0pt;mso-table-rspace: 0pt;-ms-text-size-adjust: 100%;-webkit-text-size-adjust: 100%;" width="100%" class="mcnTextContentContainer">
                     <tbody><tr>

                         <td valign="top" class="mcnTextContent" style="padding: 0px 18px 9px;font-family: "Noticia Text", Georgia, "Times New Roman", serif;line-height: 150%;mso-line-height-rule: exactly;-ms-text-size-adjust: 100%;-webkit-text-size-adjust: 100%;word-break: break-word;color: #202020;font-size: 16px;text-align: left;">

                             <p dir="ltr" style="font-family: "Noticia Text", Georgia, "Times New Roman", serif;line-height: 150%;margin: 10px 0;padding: 0;mso-line-height-rule: exactly;-ms-text-size-adjust: 100%;-webkit-text-size-adjust: 100%;color: #202020;font-size: 16px;text-align: left;"><span style="font-size:18px"><strong>Banksters gonna bankster </strong></span></p>

                         </td>
                     </tr>
                 </tbody></table>
                 <!--[if mso]>
                 </td>
                 <![endif]-->

                 <!--[if mso]>
                 </tr>
                 </table>
                 <![endif]-->
             </td>
         </tr>
     </tbody>
 </table><table border="0" cellpadding="0" cellspacing="0" width="100%" class="mcnTextBlock" style="min-width: 100%;border-collapse: collapse;mso-table-lspace: 0pt;mso-table-rspace: 0pt;-ms-text-size-adjust: 100%;-webkit-text-size-adjust: 100%;">
     <tbody class="mcnTextBlockOuter">
         <tr>
             <td valign="top" class="mcnTextBlockInner" style="padding-top: 9px;mso-line-height-rule: exactly;-ms-text-size-adjust: 100%;-webkit-text-size-adjust: 100%;">
                 <!--[if mso]>
                 <table align="left" border="0" cellspacing="0" cellpadding="0" width="100%" style="width:100%;">
                 <tr>
                 <![endif]-->

                 <!--[if mso]>
                 <td valign="top" width="600" style="width:600px;">
                 <![endif]-->
                 <table align="left" border="0" cellpadding="0" cellspacing="0" style="max-width: 100%;min-width: 100%;border-collapse: collapse;mso-table-lspace: 0pt;mso-table-rspace: 0pt;-ms-text-size-adjust: 100%;-webkit-text-size-adjust: 100%;" width="100%" class="mcnTextContentContainer">
                     <tbody><tr>

                         <td valign="top" class="mcnTextContent" style="padding-top: 0;padding-right: 18px;padding-bottom: 9px;padding-left: 18px;mso-line-height-rule: exactly;-ms-text-size-adjust: 100%;-webkit-text-size-adjust: 100%;word-break: break-word;color: #202020;font-family: 'Noticia Text', Georgia, 'Times New Roman', serif;font-size: 16px;line-height: 150%;text-align: left;">

                             "When I sell liquor, they call it bootlegging. When my patrons serve it on a silver tray on Lakeshore Drive, they call it hospitality." <br>
 - Al Capone<br>
 <br>
 Earlier this week JP Morgan Chase came out behind cryptocurrency and blockchain in a big way with the announcement of JPM Coin, a token they plan to use to settle financial transactions globally with greater speed and efficiency than current mechanisms. It will function similarly to a stablecoin, meaning it’s pretty much a proxy for US Dollars as opposed to a cryptocurrency that could rise and fall in value with its adoption and perceived value.<br>
 <br>
 This announcement comes with added interest since Chase CEO Jamie Dimon has made headlines with past remarks such as “Bitcoin is a fraud," and, "if you're stupid enough to buy it, you'll pay the price for it one day." Or perhaps our favorite, "I don’t really give a shit [about Bitcoin]." You could have fooled us, Jamie.
                         </td>
                     </tr>
                 </tbody></table>
                 <!--[if mso]>
                 </td>
                 <![endif]-->

                 <!--[if mso]>
                 </tr>
                 </table>
                 <![endif]-->
             </td>
         </tr>
     </tbody>
 </table><table border="0" cellpadding="0" cellspacing="0" width="100%" class="mcnImageCardBlock" style="border-collapse: collapse;mso-table-lspace: 0pt;mso-table-rspace: 0pt;-ms-text-size-adjust: 100%;-webkit-text-size-adjust: 100%;">
     <tbody class="mcnImageCardBlockOuter">
         <tr>
             <td class="mcnImageCardBlockInner" valign="top" style="padding-top: 9px;padding-right: 18px;padding-bottom: 9px;padding-left: 18px;mso-line-height-rule: exactly;-ms-text-size-adjust: 100%;-webkit-text-size-adjust: 100%;">

 <table align="right" border="0" cellpadding="0" cellspacing="0" class="mcnImageCardBottomContent" width="100%" style="border-collapse: collapse;mso-table-lspace: 0pt;mso-table-rspace: 0pt;-ms-text-size-adjust: 100%;-webkit-text-size-adjust: 100%;">
     <tbody><tr>
         <td class="mcnImageCardBottomImageContent" align="center" valign="top" style="padding-top: 0px;padding-right: 0px;padding-bottom: 0;padding-left: 0px;mso-line-height-rule: exactly;-ms-text-size-adjust: 100%;-webkit-text-size-adjust: 100%;">



             <img alt="" src="https://gallery.mailchimp.com/adee9c5618cae148bdcf8f762/images/3ed1d4e8-f222-47bd-be7d-6cd305fc8879.jpg" width="564" style="max-width: 1107px;border: 0;height: auto;outline: none;text-decoration: none;-ms-interpolation-mode: bicubic;vertical-align: bottom;" class="mcnImage">


         </td>
     </tr>
     <tr>
         <td class="mcnTextContent" valign="top" style="padding: 9px 18px;color: #747474;font-family: "Noticia Text", Georgia, "Times New Roman", serif;font-size: 14px;font-weight: normal;text-align: center;mso-line-height-rule: exactly;-ms-text-size-adjust: 100%;-webkit-text-size-adjust: 100%;word-break: break-word;line-height: 150%;" width="546">
             <div dir="ltr" style="text-align: center;"><em>Except at one point, Harvey “Two-Face” Dent was a good guy.</em></div>

         </td>
     </tr>
 </tbody></table>




             </td>
         </tr>
     </tbody>
 </table><table border="0" cellpadding="0" cellspacing="0" width="100%" class="mcnTextBlock" style="min-width: 100%;border-collapse: collapse;mso-table-lspace: 0pt;mso-table-rspace: 0pt;-ms-text-size-adjust: 100%;-webkit-text-size-adjust: 100%;">
     <tbody class="mcnTextBlockOuter">
         <tr>
             <td valign="top" class="mcnTextBlockInner" style="padding-top: 9px;mso-line-height-rule: exactly;-ms-text-size-adjust: 100%;-webkit-text-size-adjust: 100%;">
                 <!--[if mso]>
                 <table align="left" border="0" cellspacing="0" cellpadding="0" width="100%" style="width:100%;">
                 <tr>
                 <![endif]-->

                 <!--[if mso]>
                 <td valign="top" width="600" style="width:600px;">
                 <![endif]-->
                 <table align="left" border="0" cellpadding="0" cellspacing="0" style="max-width: 100%;min-width: 100%;border-collapse: collapse;mso-table-lspace: 0pt;mso-table-rspace: 0pt;-ms-text-size-adjust: 100%;-webkit-text-size-adjust: 100%;" width="100%" class="mcnTextContentContainer">
                     <tbody><tr>

                         <td valign="top" class="mcnTextContent" style="padding-top: 0;padding-right: 18px;padding-bottom: 9px;padding-left: 18px;mso-line-height-rule: exactly;-ms-text-size-adjust: 100%;-webkit-text-size-adjust: 100%;word-break: break-word;color: #202020;font-family: 'Noticia Text', Georgia, 'Times New Roman', serif;font-size: 16px;line-height: 150%;text-align: left;">

                             Now, we understand with the alternative being their obsolescence, institutional buy-in is likely inevitable on a path toward our crypto future, so ultimately a player like Chase diving in is a positive sign for crypto. The question is whether upstarts and proponents of decentralization will win the day before the movement is co-opted by incumbent behemoths. It’s also possible that multiple winners will emerge into a pluralistic world of digital global currencies.<br>
 <br>
 In the case of Jamie Dimon and JP Morgan Chase, it’s also true they’ve been consistent in touting the potential of blockchain, as well as crypto versions of government-sanctioned currency. Still, we can’t help but chuckle when one of Bitcoin’s most prominent detractors goes ahead and slaps the company name on their shiny new crypto project.
                         </td>
                     </tr>
                 </tbody></table>
                 <!--[if mso]>
                 </td>
                 <![endif]-->

                 <!--[if mso]>
                 </tr>
                 </table>
                 <![endif]-->
             </td>
         </tr>
     </tbody>
 </table><table border="0" cellpadding="0" cellspacing="0" width="100%" class="mcnImageCardBlock" style="border-collapse: collapse;mso-table-lspace: 0pt;mso-table-rspace: 0pt;-ms-text-size-adjust: 100%;-webkit-text-size-adjust: 100%;">
     <tbody class="mcnImageCardBlockOuter">
         <tr>
             <td class="mcnImageCardBlockInner" valign="top" style="padding-top: 9px;padding-right: 18px;padding-bottom: 9px;padding-left: 18px;mso-line-height-rule: exactly;-ms-text-size-adjust: 100%;-webkit-text-size-adjust: 100%;">

 <table align="left" border="0" cellpadding="0" cellspacing="0" class="mcnImageCardBottomContent" width="100%" style="border-collapse: collapse;mso-table-lspace: 0pt;mso-table-rspace: 0pt;-ms-text-size-adjust: 100%;-webkit-text-size-adjust: 100%;">
     <tbody><tr>
         <td class="mcnImageCardBottomImageContent" align="center" valign="top" style="padding-top: 0px;padding-right: 0px;padding-bottom: 0;padding-left: 0px;mso-line-height-rule: exactly;-ms-text-size-adjust: 100%;-webkit-text-size-adjust: 100%;">



             <img alt="" src="https://gallery.mailchimp.com/adee9c5618cae148bdcf8f762/images/1767c64c-088d-41fd-a66a-2ce2a1c333c5.gif" width="400" style="max-width: 400px;border: 0;height: auto;outline: none;text-decoration: none;-ms-interpolation-mode: bicubic;vertical-align: bottom;" class="mcnImage">


         </td>
     </tr>
     <tr>
         <td class="mcnTextContent" valign="top" style="padding: 9px 18px;color: #747474;font-family: "Noticia Text", Georgia, "Times New Roman", serif;font-size: 14px;font-weight: normal;text-align: center;mso-line-height-rule: exactly;-ms-text-size-adjust: 100%;-webkit-text-size-adjust: 100%;word-break: break-word;line-height: 150%;" width="546">
             <div style="text-align: center;"><em>We liked Music Band before they were cool.</em></div>

         </td>
     </tr>
 </tbody></table>




             </td>
         </tr>
     </tbody>
 </table><table border="0" cellpadding="0" cellspacing="0" width="100%" class="mcnTextBlock" style="min-width: 100%;border-collapse: collapse;mso-table-lspace: 0pt;mso-table-rspace: 0pt;-ms-text-size-adjust: 100%;-webkit-text-size-adjust: 100%;">
     <tbody class="mcnTextBlockOuter">
         <tr>
             <td valign="top" class="mcnTextBlockInner" style="padding-top: 9px;mso-line-height-rule: exactly;-ms-text-size-adjust: 100%;-webkit-text-size-adjust: 100%;">
                 <!--[if mso]>
                 <table align="left" border="0" cellspacing="0" cellpadding="0" width="100%" style="width:100%;">
                 <tr>
                 <![endif]-->

                 <!--[if mso]>
                 <td valign="top" width="600" style="width:600px;">
                 <![endif]-->
                 <table align="left" border="0" cellpadding="0" cellspacing="0" style="max-width: 100%;min-width: 100%;border-collapse: collapse;mso-table-lspace: 0pt;mso-table-rspace: 0pt;-ms-text-size-adjust: 100%;-webkit-text-size-adjust: 100%;" width="100%" class="mcnTextContentContainer">
                     <tbody><tr>

                         <td valign="top" class="mcnTextContent" style="padding-top: 0;padding-right: 18px;padding-bottom: 9px;padding-left: 18px;mso-line-height-rule: exactly;-ms-text-size-adjust: 100%;-webkit-text-size-adjust: 100%;word-break: break-word;color: #202020;font-family: 'Noticia Text', Georgia, 'Times New Roman', serif;font-size: 16px;line-height: 150%;text-align: left;">

                             Get more details from CNBC: <a href="https://www.cnbc.com/2019/02/13/jp-morgan-is-rolling-out-the-first-us-bank-backed-cryptocurrency-to-transform-payments--.html" target="_blank" style="mso-line-height-rule: exactly;-ms-text-size-adjust: 100%;-webkit-text-size-adjust: 100%;color: #42702f;font-weight: normal;text-decoration: underline;">https://www.cnbc.com/2019/02/13/jp-morgan-is-rolling-out-the-first-us-bank-backed-cryptocurrency-to-transform-payments--.html</a><br>
 <br>
 P.S. We left the two hearts emoji on the headline from last week’s issue because it was just too darn cute, and because Dimon  Crypto now.
                         </td>
                     </tr>
                 </tbody></table>
                 <!--[if mso]>
                 </td>
                 <![endif]-->

                 <!--[if mso]>
                 </tr>
                 </table>
                 <![endif]-->
             </td>
         </tr>
     </tbody>
 </table><table border="0" cellpadding="0" cellspacing="0" width="100%" class="mcnDividerBlock" style="min-width: 100%;border-collapse: collapse;mso-table-lspace: 0pt;mso-table-rspace: 0pt;-ms-text-size-adjust: 100%;-webkit-text-size-adjust: 100%;table-layout: fixed !important;">
     <tbody class="mcnDividerBlockOuter">
         <tr>
             <td class="mcnDividerBlockInner" style="min-width: 100%;padding: 24px 18px;mso-line-height-rule: exactly;-ms-text-size-adjust: 100%;-webkit-text-size-adjust: 100%;">
                 <table class="mcnDividerContent" border="0" cellpadding="0" cellspacing="0" width="100%" style="min-width: 100%;border-top: 2px solid #EAEAEA;border-collapse: collapse;mso-table-lspace: 0pt;mso-table-rspace: 0pt;-ms-text-size-adjust: 100%;-webkit-text-size-adjust: 100%;">
                     <tbody><tr>
                         <td style="mso-line-height-rule: exactly;-ms-text-size-adjust: 100%;-webkit-text-size-adjust: 100%;">
                             <span></span>
                         </td>
                     </tr>
                 </tbody></table>
 <!--
                 <td class="mcnDividerBlockInner" style="padding: 18px;">
                 <hr class="mcnDividerContent" style="border-bottom-color:none; border-left-color:none; border-right-color:none; border-bottom-width:0; border-left-width:0; border-right-width:0; margin-top:0; margin-right:0; margin-bottom:0; margin-left:0;" />
 -->
             </td>
         </tr>
     </tbody>
 </table><table border="0" cellpadding="0" cellspacing="0" width="100%" class="mcnTextBlock" style="min-width: 100%;border-collapse: collapse;mso-table-lspace: 0pt;mso-table-rspace: 0pt;-ms-text-size-adjust: 100%;-webkit-text-size-adjust: 100%;">
     <tbody class="mcnTextBlockOuter">
         <tr>
             <td valign="top" class="mcnTextBlockInner" style="padding-top: 9px;mso-line-height-rule: exactly;-ms-text-size-adjust: 100%;-webkit-text-size-adjust: 100%;">
                 <!--[if mso]>
                 <table align="left" border="0" cellspacing="0" cellpadding="0" width="100%" style="width:100%;">
                 <tr>
                 <![endif]-->

                 <!--[if mso]>
                 <td valign="top" width="600" style="width:600px;">
                 <![endif]-->
                 <table align="left" border="0" cellpadding="0" cellspacing="0" style="max-width: 100%;min-width: 100%;border-collapse: collapse;mso-table-lspace: 0pt;mso-table-rspace: 0pt;-ms-text-size-adjust: 100%;-webkit-text-size-adjust: 100%;" width="100%" class="mcnTextContentContainer">
                     <tbody><tr>

                         <td valign="top" class="mcnTextContent" style="padding: 0px 18px 9px;font-family: "Noticia Text", Georgia, "Times New Roman", serif;line-height: 150%;mso-line-height-rule: exactly;-ms-text-size-adjust: 100%;-webkit-text-size-adjust: 100%;word-break: break-word;color: #202020;font-size: 16px;text-align: left;">

                             <span style="font-size:18px"><span style="font-family:noticia text,georgia,times new roman,serif"><strong>Cool $#i®T of the week</strong></span></span>
                         </td>
                     </tr>
                 </tbody></table>
                 <!--[if mso]>
                 </td>
                 <![endif]-->

                 <!--[if mso]>
                 </tr>
                 </table>
                 <![endif]-->
             </td>
         </tr>
     </tbody>
 </table><table border="0" cellpadding="0" cellspacing="0" width="100%" class="mcnTextBlock" style="min-width: 100%;border-collapse: collapse;mso-table-lspace: 0pt;mso-table-rspace: 0pt;-ms-text-size-adjust: 100%;-webkit-text-size-adjust: 100%;">
     <tbody class="mcnTextBlockOuter">
         <tr>
             <td valign="top" class="mcnTextBlockInner" style="padding-top: 9px;mso-line-height-rule: exactly;-ms-text-size-adjust: 100%;-webkit-text-size-adjust: 100%;">
                 <!--[if mso]>
                 <table align="left" border="0" cellspacing="0" cellpadding="0" width="100%" style="width:100%;">
                 <tr>
                 <![endif]-->

                 <!--[if mso]>
                 <td valign="top" width="600" style="width:600px;">
                 <![endif]-->
                 <table align="left" border="0" cellpadding="0" cellspacing="0" style="max-width: 100%;min-width: 100%;border-collapse: collapse;mso-table-lspace: 0pt;mso-table-rspace: 0pt;-ms-text-size-adjust: 100%;-webkit-text-size-adjust: 100%;" width="100%" class="mcnTextContentContainer">
                     <tbody><tr>

                         <td valign="top" class="mcnTextContent" style="padding: 0px 18px 9px;font-family: "Noticia Text", Georgia, "Times New Roman", serif;line-height: 150%;mso-line-height-rule: exactly;-ms-text-size-adjust: 100%;-webkit-text-size-adjust: 100%;word-break: break-word;color: #202020;font-size: 16px;text-align: left;">

                             <p dir="ltr" style="font-family: "Noticia Text", Georgia, "Times New Roman", serif;line-height: 150%;margin: 10px 0;padding: 0;mso-line-height-rule: exactly;-ms-text-size-adjust: 100%;-webkit-text-size-adjust: 100%;color: #202020;font-size: 16px;text-align: left;">Your favorite player is crushing it on both ends of the court, putting up MVP-caliber numbers, so you plunk down a few bills to rock their jersey on gameday. And then they get traded or demand more money and leave in free agency <br>
 <br>
 Sellers like Fanatics have experimented with trade-in programs to bail out the woebegone fan and alloy jersey trade-ins. Now the NBA is exploring technology that we can only describe as a digital jersey, with changeable numbers and names 🤯<br>
 <br>
 You really have to see the video for yourself to grok it:<br>
 <a href="https://www.complex.com/sports/2019/02/adam-silver-showcases-nba-jerseys-of-the-future-at-tech-summit" target="_blank" style="mso-line-height-rule: exactly;-ms-text-size-adjust: 100%;-webkit-text-size-adjust: 100%;color: #42702f;font-weight: normal;text-decoration: underline;">https://www.complex.com/sports/2019/02/adam-silver-showcases-nba-jerseys-of-the-future-at-tech-summit</a></p>

                         </td>
                     </tr>
                 </tbody></table>
                 <!--[if mso]>
                 </td>
                 <![endif]-->

                 <!--[if mso]>
                 </tr>
                 </table>
                 <![endif]-->
             </td>
         </tr>
     </tbody>
 </table><table border="0" cellpadding="0" cellspacing="0" width="100%" class="mcnImageCardBlock" style="border-collapse: collapse;mso-table-lspace: 0pt;mso-table-rspace: 0pt;-ms-text-size-adjust: 100%;-webkit-text-size-adjust: 100%;">
     <tbody class="mcnImageCardBlockOuter">
         <tr>
             <td class="mcnImageCardBlockInner" valign="top" style="padding-top: 9px;padding-right: 18px;padding-bottom: 9px;padding-left: 18px;mso-line-height-rule: exactly;-ms-text-size-adjust: 100%;-webkit-text-size-adjust: 100%;">

 <table align="left" border="0" cellpadding="0" cellspacing="0" class="mcnImageCardBottomContent" width="100%" style="border-collapse: collapse;mso-table-lspace: 0pt;mso-table-rspace: 0pt;-ms-text-size-adjust: 100%;-webkit-text-size-adjust: 100%;">
     <tbody><tr>
         <td class="mcnImageCardBottomImageContent" align="center" valign="top" style="padding-top: 0px;padding-right: 0px;padding-bottom: 0;padding-left: 0px;mso-line-height-rule: exactly;-ms-text-size-adjust: 100%;-webkit-text-size-adjust: 100%;">


             <a href="https://www.complex.com/sports/2019/02/adam-silver-showcases-nba-jerseys-of-the-future-at-tech-summit" title="" class="" target="_blank" style="mso-line-height-rule: exactly;-ms-text-size-adjust: 100%;-webkit-text-size-adjust: 100%;">


             <img alt="" src="https://gallery.mailchimp.com/adee9c5618cae148bdcf8f762/images/0da6ddd3-b5fe-4ea9-b9d1-4f8ca142bb10.jpg" width="516" style="max-width: 516px;border: 0;height: auto;outline: none;text-decoration: none;-ms-interpolation-mode: bicubic;vertical-align: bottom;" class="mcnImage">
             </a>

         </td>
     </tr>
     <tr>
         <td class="mcnTextContent" valign="top" style="padding: 9px 18px;color: #747474;font-family: "Noticia Text", Georgia, "Times New Roman", serif;font-size: 14px;font-weight: normal;text-align: center;mso-line-height-rule: exactly;-ms-text-size-adjust: 100%;-webkit-text-size-adjust: 100%;word-break: break-word;line-height: 150%;" width="546">

         </td>
     </tr>
 </tbody></table>




             </td>
         </tr>
     </tbody>
 </table><table border="0" cellpadding="0" cellspacing="0" width="100%" class="mcnDividerBlock" style="min-width: 100%;border-collapse: collapse;mso-table-lspace: 0pt;mso-table-rspace: 0pt;-ms-text-size-adjust: 100%;-webkit-text-size-adjust: 100%;table-layout: fixed !important;">
     <tbody class="mcnDividerBlockOuter">
         <tr>
             <td class="mcnDividerBlockInner" style="min-width: 100%;padding: 18px;mso-line-height-rule: exactly;-ms-text-size-adjust: 100%;-webkit-text-size-adjust: 100%;">
                 <table class="mcnDividerContent" border="0" cellpadding="0" cellspacing="0" width="100%" style="min-width: 100%;border-top: 2px solid #EAEAEA;border-collapse: collapse;mso-table-lspace: 0pt;mso-table-rspace: 0pt;-ms-text-size-adjust: 100%;-webkit-text-size-adjust: 100%;">
                     <tbody><tr>
                         <td style="mso-line-height-rule: exactly;-ms-text-size-adjust: 100%;-webkit-text-size-adjust: 100%;">
                             <span></span>
                         </td>
                     </tr>
                 </tbody></table>
 <!--
                 <td class="mcnDividerBlockInner" style="padding: 18px;">
                 <hr class="mcnDividerContent" style="border-bottom-color:none; border-left-color:none; border-right-color:none; border-bottom-width:0; border-left-width:0; border-right-width:0; margin-top:0; margin-right:0; margin-bottom:0; margin-left:0;" />
 -->
             </td>
         </tr>
     </tbody>
 </table><table border="0" cellpadding="0" cellspacing="0" width="100%" class="mcnTextBlock" style="min-width: 100%;border-collapse: collapse;mso-table-lspace: 0pt;mso-table-rspace: 0pt;-ms-text-size-adjust: 100%;-webkit-text-size-adjust: 100%;">
     <tbody class="mcnTextBlockOuter">
         <tr>
             <td valign="top" class="mcnTextBlockInner" style="padding-top: 9px;mso-line-height-rule: exactly;-ms-text-size-adjust: 100%;-webkit-text-size-adjust: 100%;">
                 <!--[if mso]>
                 <table align="left" border="0" cellspacing="0" cellpadding="0" width="100%" style="width:100%;">
                 <tr>
                 <![endif]-->

                 <!--[if mso]>
                 <td valign="top" width="600" style="width:600px;">
                 <![endif]-->
                 <table align="left" border="0" cellpadding="0" cellspacing="0" style="max-width: 100%;min-width: 100%;border-collapse: collapse;mso-table-lspace: 0pt;mso-table-rspace: 0pt;-ms-text-size-adjust: 100%;-webkit-text-size-adjust: 100%;" width="100%" class="mcnTextContentContainer">
                     <tbody><tr>

                         <td valign="top" class="mcnTextContent" style="padding: 0px 18px 9px;font-family: "Noticia Text", Georgia, "Times New Roman", serif;line-height: 150%;mso-line-height-rule: exactly;-ms-text-size-adjust: 100%;-webkit-text-size-adjust: 100%;word-break: break-word;color: #202020;font-size: 16px;text-align: left;">

                             <div><strong><span style="font-size:24px">Airdrops! </span></strong></div>

 <div> </div>

 <div> Vintage. Thrift. Second-hand. Upcycling. The $20 billion secondary clothing and accessory market continues to grow: <a href="https://techcrunch.com/2019/02/08/luxury-handbag-marketplace-rebag-raises-25m-to-expand-to-30-more-stores/" target="_blank" style="mso-line-height-rule: exactly;-ms-text-size-adjust: 100%;-webkit-text-size-adjust: 100%;color: #42702f;font-weight: normal;text-decoration: underline;">Luxury handbag marketplace Rebag raises $25M to expand to 30 more stores</a> (Techcrunch)<br>
 <br>
 ‍ The fashion market continues to transform through technology behind the scenes, as well, such as with this application that connects fashion brands and retailers for wholesale purchasing: <a href="https://venturebeat.com/2019/02/13/joor-raises-16-million-to-expand-its-online-fashion-marketplace/" target="_blank" style="mso-line-height-rule: exactly;-ms-text-size-adjust: 100%;-webkit-text-size-adjust: 100%;color: #42702f;font-weight: normal;text-decoration: underline;">Joor raises $16 million to expand its online fashion marketplace for wholesalers</a> (VentureBeat)<br>
 <br>
  Credit card processing fees are going up, likely to ultimately hit consumers in the wallet with every swipe. (Accelerating their eventual demise in our crypto future?) <a href="https://www.wsj.com/articles/purchases-with-plastic-get-costlier-for-merchantsand-consumers-11550226601" target="_blank" style="mso-line-height-rule: exactly;-ms-text-size-adjust: 100%;-webkit-text-size-adjust: 100%;color: #42702f;font-weight: normal;text-decoration: underline;">Purchases With Plastic Get Costlier for Merchants—and Consumers</a> (Wall Street Journal)</div>

                         </td>
                     </tr>
                 </tbody></table>
                 <!--[if mso]>
                 </td>
                 <![endif]-->

                 <!--[if mso]>
                 </tr>
                 </table>
                 <![endif]-->
             </td>
         </tr>
     </tbody>
 </table><table border="0" cellpadding="0" cellspacing="0" width="100%" class="mcnDividerBlock" style="min-width: 100%;border-collapse: collapse;mso-table-lspace: 0pt;mso-table-rspace: 0pt;-ms-text-size-adjust: 100%;-webkit-text-size-adjust: 100%;table-layout: fixed !important;">
     <tbody class="mcnDividerBlockOuter">
         <tr>
             <td class="mcnDividerBlockInner" style="min-width: 100%;padding: 18px;mso-line-height-rule: exactly;-ms-text-size-adjust: 100%;-webkit-text-size-adjust: 100%;">
                 <table class="mcnDividerContent" border="0" cellpadding="0" cellspacing="0" width="100%" style="min-width: 100%;border-top: 2px solid #EAEAEA;border-collapse: collapse;mso-table-lspace: 0pt;mso-table-rspace: 0pt;-ms-text-size-adjust: 100%;-webkit-text-size-adjust: 100%;">
                     <tbody><tr>
                         <td style="mso-line-height-rule: exactly;-ms-text-size-adjust: 100%;-webkit-text-size-adjust: 100%;">
                             <span></span>
                         </td>
                     </tr>
                 </tbody></table>
 <!--
                 <td class="mcnDividerBlockInner" style="padding: 18px;">
                 <hr class="mcnDividerContent" style="border-bottom-color:none; border-left-color:none; border-right-color:none; border-bottom-width:0; border-left-width:0; border-right-width:0; margin-top:0; margin-right:0; margin-bottom:0; margin-left:0;" />
 -->
             </td>
         </tr>
     </tbody>
 </table><table border="0" cellpadding="0" cellspacing="0" width="100%" class="mcnTextBlock" style="min-width: 100%;border-collapse: collapse;mso-table-lspace: 0pt;mso-table-rspace: 0pt;-ms-text-size-adjust: 100%;-webkit-text-size-adjust: 100%;">
     <tbody class="mcnTextBlockOuter">
         <tr>
             <td valign="top" class="mcnTextBlockInner" style="padding-top: 9px;mso-line-height-rule: exactly;-ms-text-size-adjust: 100%;-webkit-text-size-adjust: 100%;">
                 <!--[if mso]>
                 <table align="left" border="0" cellspacing="0" cellpadding="0" width="100%" style="width:100%;">
                 <tr>
                 <![endif]-->

                 <!--[if mso]>
                 <td valign="top" width="600" style="width:600px;">
                 <![endif]-->
                 <table align="left" border="0" cellpadding="0" cellspacing="0" style="max-width: 100%;min-width: 100%;border-collapse: collapse;mso-table-lspace: 0pt;mso-table-rspace: 0pt;-ms-text-size-adjust: 100%;-webkit-text-size-adjust: 100%;" width="100%" class="mcnTextContentContainer">
                     <tbody><tr>

                         <td valign="top" class="mcnTextContent" style="padding: 0px 18px 9px;font-family: "Noticia Text", Georgia, "Times New Roman", serif;line-height: 150%;mso-line-height-rule: exactly;-ms-text-size-adjust: 100%;-webkit-text-size-adjust: 100%;word-break: break-word;color: #202020;font-size: 16px;text-align: left;">

                             <div style="text-align: center;"><strong><span style="font-size:24px">SEE YOU NEXT WEEK...</span></strong><br>
 <br>
 Tips and leads for next week's edition? Email them to us at <a href="mailto:hi@satoshiscloset.com?subject=Newsletter%20tips%20and%20leads" target="_blank" style="mso-line-height-rule: exactly;-ms-text-size-adjust: 100%;-webkit-text-size-adjust: 100%;color: #42702f;font-weight: normal;text-decoration: underline;">hi@satoshiscloset.com</a>.<br>
 <br>
 <img data-file-id="4326517" height="128" src="https://gallery.mailchimp.com/adee9c5618cae148bdcf8f762/images/98c6e2b1-a7da-4b2a-8718-799bda1c4658.gif" style="border: 0px;width: 128px;height: 128px;margin: 0px;outline: none;text-decoration: none;-ms-interpolation-mode: bicubic;" width="128"><br>
 <strong>Check out previous issues:</strong><br>
 <a href="https://satoshiscloset.com/blog" target="_blank" style="mso-line-height-rule: exactly;-ms-text-size-adjust: 100%;-webkit-text-size-adjust: 100%;color: #42702f;font-weight: normal;text-decoration: underline;">https://satoshiscloset.com/blog</a></div>
 </td>
                     </tr>
                 </tbody></table>
             </td>
         </tr>
     </tbody>
 </table></td>
                             </tr>

                         </table>
                     </td>
                 </tr>
             </table>
         </center>
     </body>
 </html>`

 const POST8 = `<!doctype html>
 <html xmlns="http://www.w3.org/1999/xhtml" xmlns:v="urn:schemas-microsoft-com:vml" xmlns:o="urn:schemas-microsoft-com:office:office">
     <head>
         <!-- NAME: 1 COLUMN -->
         <!--[if gte mso 15]>
         <xml>
             <o:OfficeDocumentSettings>
             <o:AllowPNG/>
             <o:PixelsPerInch>96</o:PixelsPerInch>
             </o:OfficeDocumentSettings>
         </xml>
         <![endif]-->
         <meta charset="UTF-8">
         <meta http-equiv="X-UA-Compatible" content="IE=edge">
         <meta name="viewport" content="width=device-width, initial-scale=1">
         <title>[008-08-2019] 1.21 Gigawatts</title>

     <style type="text/css">
         p{
             margin:10px 0;
             padding:0;
         }
         table{
             border-collapse:collapse;
         }
         h1,h2,h3,h4,h5,h6{
             display:block;
             margin:0;
             padding:0;
         }
         img,a img{
             border:0;
             height:auto;
             outline:none;
             text-decoration:none;
         }
         body,#bodyTable,#bodyCell{
             height:100%;
             margin:0;
             padding:0;
             width:100%;
         }
         .mcnPreviewText{
             display:none !important;
         }
         #outlook a{
             padding:0;
         }
         img{
             -ms-interpolation-mode:bicubic;
         }
         table{
             mso-table-lspace:0pt;
             mso-table-rspace:0pt;
         }
         .ReadMsgBody{
             width:100%;
         }
         .ExternalClass{
             width:100%;
         }
         p,a,li,td,blockquote{
             mso-line-height-rule:exactly;
         }
         a[href^=tel],a[href^=sms]{
             color:inherit;
             cursor:default;
             text-decoration:none;
         }
         p,a,li,td,body,table,blockquote{
             -ms-text-size-adjust:100%;
             -webkit-text-size-adjust:100%;
         }
         .ExternalClass,.ExternalClass p,.ExternalClass td,.ExternalClass div,.ExternalClass span,.ExternalClass font{
             line-height:100%;
         }
         a[x-apple-data-detectors]{
             color:inherit !important;
             text-decoration:none !important;
             font-size:inherit !important;
             font-family:inherit !important;
             font-weight:inherit !important;
             line-height:inherit !important;
         }
         #bodyCell{
             padding:10px;
         }
         .templateContainer{
             max-width:600px !important;
         }
         a.mcnButton{
             display:block;
         }
         .mcnImage,.mcnRetinaImage{
             vertical-align:bottom;
         }
         .mcnTextContent{
             word-break:break-word;
         }
         .mcnTextContent img{
             height:auto !important;
         }
         .mcnDividerBlock{
             table-layout:fixed !important;
         }
         body,#bodyTable{
             background-color:#ffffff;
         }
         #bodyCell{
             border-top:0;
         }
         .templateContainer{
             border:0;
         }
         h1{
             color:#202020;
             font-family:Helvetica;
             font-size:26px;
             font-style:normal;
             font-weight:bold;
             line-height:125%;
             letter-spacing:normal;
             text-align:left;
         }
         h2{
             color:#202020;
             font-family:Helvetica;
             font-size:22px;
             font-style:normal;
             font-weight:bold;
             line-height:125%;
             letter-spacing:normal;
             text-align:left;
         }
         h3{
             color:#202020;
             font-family:Helvetica;
             font-size:20px;
             font-style:normal;
             font-weight:bold;
             line-height:125%;
             letter-spacing:normal;
             text-align:left;
         }
         h4{
             color:#202020;
             font-family:Helvetica;
             font-size:18px;
             font-style:normal;
             font-weight:bold;
             line-height:125%;
             letter-spacing:normal;
             text-align:left;
         }
         #templatePreheader{
             background-color:#FAFAFA;
             background-image:none;
             background-repeat:no-repeat;
             background-position:center;
             background-size:cover;
             border-top:0;
             border-bottom:0;
             padding-top:9px;
             padding-bottom:9px;
         }
         #templatePreheader .mcnTextContent,#templatePreheader .mcnTextContent p{
             color:#656565;
             font-family:Helvetica;
             font-size:12px;
             line-height:150%;
             text-align:left;
         }
         #templatePreheader .mcnTextContent a,#templatePreheader .mcnTextContent p a{
             color:#656565;
             font-weight:normal;
             text-decoration:underline;
         }
         #templateHeader{
             background-color:#ffffff;
             background-image:none;
             background-repeat:no-repeat;
             background-position:center;
             background-size:cover;
             border-top:0;
             border-bottom:0;
             padding-top:18px;
             padding-bottom:0px;
         }
         #templateHeader .mcnTextContent,#templateHeader .mcnTextContent p{
             color:#202020;
             font-family:Helvetica;
             font-size:12px;
             line-height:125%;
             text-align:left;
         }
         #templateHeader .mcnTextContent a,#templateHeader .mcnTextContent p a{
             color:#007C89;
             font-weight:normal;
             text-decoration:underline;
         }
         #templateBody{
             background-color:#ffffff;
             background-image:none;
             background-repeat:no-repeat;
             background-position:center;
             background-size:cover;
             border-top:0;
             border-bottom:2px solid #EAEAEA;
             padding-top:0;
             padding-bottom:9px;
         }
         #templateBody .mcnTextContent,#templateBody .mcnTextContent p{
             color:#202020;
             font-family:'Noticia Text', Georgia, 'Times New Roman', serif;
             font-size:16px;
             line-height:150%;
             text-align:left;
         }
         #templateBody .mcnTextContent a,#templateBody .mcnTextContent p a{
             color:#42702f;
             font-weight:normal;
             text-decoration:underline;
         }
         #templateFooter{
             background-color:#ffffff;
             background-image:none;
             background-repeat:no-repeat;
             background-position:center;
             background-size:cover;
             border-top:0;
             border-bottom:0;
             padding-top:9px;
             padding-bottom:9px;
         }
         #templateFooter .mcnTextContent,#templateFooter .mcnTextContent p{
             color:#656565;
             font-family:Helvetica;
             font-size:12px;
             line-height:150%;
             text-align:center;
         }
         #templateFooter .mcnTextContent a,#templateFooter .mcnTextContent p a{
             color:#656565;
             font-weight:normal;
             text-decoration:underline;
         }
     @media only screen and (min-width:768px){
         .templateContainer{
             width:600px !important;
         }

 }   @media only screen and (max-width: 480px){
         body,table,td,p,a,li,blockquote{
             -webkit-text-size-adjust:none !important;
         }

 }   @media only screen and (max-width: 480px){
         body{
             width:100% !important;
             min-width:100% !important;
         }

 }   @media only screen and (max-width: 480px){
         #bodyCell{
             padding-top:10px !important;
         }

 }   @media only screen and (max-width: 480px){
         .mcnRetinaImage{
             max-width:100% !important;
         }

 }   @media only screen and (max-width: 480px){
         .mcnImage{
             width:100% !important;
         }

 }   @media only screen and (max-width: 480px){
         .mcnCartContainer,.mcnCaptionTopContent,.mcnRecContentContainer,.mcnCaptionBottomContent,.mcnTextContentContainer,.mcnBoxedTextContentContainer,.mcnImageGroupContentContainer,.mcnCaptionLeftTextContentContainer,.mcnCaptionRightTextContentContainer,.mcnCaptionLeftImageContentContainer,.mcnCaptionRightImageContentContainer,.mcnImageCardLeftTextContentContainer,.mcnImageCardRightTextContentContainer,.mcnImageCardLeftImageContentContainer,.mcnImageCardRightImageContentContainer{
             max-width:100% !important;
             width:100% !important;
         }

 }   @media only screen and (max-width: 480px){
         .mcnBoxedTextContentContainer{
             min-width:100% !important;
         }

 }   @media only screen and (max-width: 480px){
         .mcnImageGroupContent{
             padding:9px !important;
         }

 }   @media only screen and (max-width: 480px){
         .mcnCaptionLeftContentOuter .mcnTextContent,.mcnCaptionRightContentOuter .mcnTextContent{
             padding-top:9px !important;
         }

 }   @media only screen and (max-width: 480px){
         .mcnImageCardTopImageContent,.mcnCaptionBottomContent:last-child .mcnCaptionBottomImageContent,.mcnCaptionBlockInner .mcnCaptionTopContent:last-child .mcnTextContent{
             padding-top:18px !important;
         }

 }   @media only screen and (max-width: 480px){
         .mcnImageCardBottomImageContent{
             padding-bottom:9px !important;
         }

 }   @media only screen and (max-width: 480px){
         .mcnImageGroupBlockInner{
             padding-top:0 !important;
             padding-bottom:0 !important;
         }

 }   @media only screen and (max-width: 480px){
         .mcnImageGroupBlockOuter{
             padding-top:9px !important;
             padding-bottom:9px !important;
         }

 }   @media only screen and (max-width: 480px){
         .mcnTextContent,.mcnBoxedTextContentColumn{
             padding-right:18px !important;
             padding-left:18px !important;
         }

 }   @media only screen and (max-width: 480px){
         .mcnImageCardLeftImageContent,.mcnImageCardRightImageContent{
             padding-right:18px !important;
             padding-bottom:0 !important;
             padding-left:18px !important;
         }

 }   @media only screen and (max-width: 480px){
         .mcpreview-image-uploader{
             display:none !important;
             width:100% !important;
         }

 }   @media only screen and (max-width: 480px){
         h1{
             font-size:22px !important;
             line-height:125% !important;
         }

 }   @media only screen and (max-width: 480px){
         h2{
             font-size:20px !important;
             line-height:125% !important;
         }

 }   @media only screen and (max-width: 480px){
         h3{
             font-size:18px !important;
             line-height:125% !important;
         }

 }   @media only screen and (max-width: 480px){
         h4{
             font-size:16px !important;
             line-height:150% !important;
         }

 }   @media only screen and (max-width: 480px){
         .mcnBoxedTextContentContainer .mcnTextContent,.mcnBoxedTextContentContainer .mcnTextContent p{
             font-size:14px !important;
             line-height:150% !important;
         }

 }   @media only screen and (max-width: 480px){
         #templatePreheader{
             display:block !important;
         }

 }   @media only screen and (max-width: 480px){
         #templatePreheader .mcnTextContent,#templatePreheader .mcnTextContent p{
             font-size:14px !important;
             line-height:150% !important;
         }

 }   @media only screen and (max-width: 480px){
         #templateHeader .mcnTextContent,#templateHeader .mcnTextContent p{
             font-size:16px !important;
             line-height:150% !important;
         }

 }   @media only screen and (max-width: 480px){
         #templateBody .mcnTextContent,#templateBody .mcnTextContent p{
             font-size:16px !important;
             line-height:150% !important;
         }

 }   @media only screen and (max-width: 480px){
         #templateFooter .mcnTextContent,#templateFooter .mcnTextContent p{
             font-size:14px !important;
             line-height:150% !important;
         }

 }</style><!--[if !mso]><!--><link href="https://fonts.googleapis.com/css?family=Noticia+Text:400,400i,700,700i" rel="stylesheet"><!--<![endif]--></head>
     <body style="height: 100%;margin: 0;padding: 0;width: 100%;-ms-text-size-adjust: 100%;-webkit-text-size-adjust: 100%;background-color: #ffffff;">
         <!--*|IF:MC_PREVIEW_TEXT|*-->
         <!--[if !gte mso 9]><!----><span class="mcnPreviewText" style="display:none; font-size:0px; line-height:0px; max-height:0px; max-width:0px; opacity:0; overflow:hidden; visibility:hidden; mso-hide:all;">*|MC_PREVIEW_TEXT|*</span><!--<![endif]-->
         <!--*|END:IF|*-->
         <center>
             <table align="center" border="0" cellpadding="0" cellspacing="0" height="100%" width="100%" id="bodyTable" style="border-collapse: collapse;mso-table-lspace: 0pt;mso-table-rspace: 0pt;-ms-text-size-adjust: 100%;-webkit-text-size-adjust: 100%;height: 100%;margin: 0;padding: 0;width: 100%;background-color: #ffffff;">
                 <tr>
                     <td align="center" valign="top" id="bodyCell" style="mso-line-height-rule: exactly;-ms-text-size-adjust: 100%;-webkit-text-size-adjust: 100%;height: 100%;margin: 0;padding: 10px;width: 100%;border-top: 0;">
                         <!-- BEGIN TEMPLATE // -->
                         <!--[if (gte mso 9)|(IE)]>
                         <table align="center" border="0" cellspacing="0" cellpadding="0" width="600" style="width:600px;">
                         <tr>
                         <td align="center" valign="top" width="600" style="width:600px;">
                         <![endif]-->
                         <table border="0" cellpadding="0" cellspacing="0" width="100%" class="templateContainer" style="border-collapse: collapse;mso-table-lspace: 0pt;mso-table-rspace: 0pt;-ms-text-size-adjust: 100%;-webkit-text-size-adjust: 100%;border: 0;max-width: 600px !important;">
                             <tr>
                                 <td valign="top" id="templateHeader" style="background:#ffffff none no-repeat center/cover;mso-line-height-rule: exactly;-ms-text-size-adjust: 100%;-webkit-text-size-adjust: 100%;background-color: #ffffff;background-image: none;background-repeat: no-repeat;background-position: center;background-size: cover;border-top: 0;border-bottom: 0;padding-top: 18px;padding-bottom: 0px;"><table border="0" cellpadding="0" cellspacing="0" width="100%" class="mcnImageBlock" style="min-width: 100%;border-collapse: collapse;mso-table-lspace: 0pt;mso-table-rspace: 0pt;-ms-text-size-adjust: 100%;-webkit-text-size-adjust: 100%;">
     <tbody class="mcnImageBlockOuter">
             <tr>
                 <td valign="top" style="padding: 9px;mso-line-height-rule: exactly;-ms-text-size-adjust: 100%;-webkit-text-size-adjust: 100%;" class="mcnImageBlockInner">
                     <table align="left" width="100%" border="0" cellpadding="0" cellspacing="0" class="mcnImageContentContainer" style="min-width: 100%;border-collapse: collapse;mso-table-lspace: 0pt;mso-table-rspace: 0pt;-ms-text-size-adjust: 100%;-webkit-text-size-adjust: 100%;">
                         <tbody><tr>
                             <td class="mcnImageContent" valign="top" style="padding-right: 9px;padding-left: 9px;padding-top: 0;padding-bottom: 0;text-align: center;mso-line-height-rule: exactly;-ms-text-size-adjust: 100%;-webkit-text-size-adjust: 100%;">


                                         <img align="center" alt="" src="https://gallery.mailchimp.com/adee9c5618cae148bdcf8f762/images/f73271eb-5084-4140-95c5-c54797c2c39b.jpg" width="564" style="max-width: 960px;padding-bottom: 0;display: inline !important;vertical-align: bottom;border: 0;height: auto;outline: none;text-decoration: none;-ms-interpolation-mode: bicubic;" class="mcnImage">


                             </td>
                         </tr>
                     </tbody></table>
                 </td>
             </tr>
     </tbody>
 </table></td>
                             </tr>
                             <tr>
                                 <td valign="top" id="templateBody" style="background:#ffffff none no-repeat center/cover;mso-line-height-rule: exactly;-ms-text-size-adjust: 100%;-webkit-text-size-adjust: 100%;background-color: #ffffff;background-image: none;background-repeat: no-repeat;background-position: center;background-size: cover;border-top: 0;border-bottom: 2px solid #EAEAEA;padding-top: 0;padding-bottom: 9px;"><table border="0" cellpadding="0" cellspacing="0" width="100%" class="mcnDividerBlock" style="min-width: 100%;border-collapse: collapse;mso-table-lspace: 0pt;mso-table-rspace: 0pt;-ms-text-size-adjust: 100%;-webkit-text-size-adjust: 100%;table-layout: fixed !important;">
     <tbody class="mcnDividerBlockOuter">
         <tr>
             <td class="mcnDividerBlockInner" style="min-width: 100%;padding: 8px 18px 0px;mso-line-height-rule: exactly;-ms-text-size-adjust: 100%;-webkit-text-size-adjust: 100%;">
                 <table class="mcnDividerContent" border="0" cellpadding="0" cellspacing="0" width="100%" style="min-width: 100%;border-top: 2px solid #EAEAEA;border-collapse: collapse;mso-table-lspace: 0pt;mso-table-rspace: 0pt;-ms-text-size-adjust: 100%;-webkit-text-size-adjust: 100%;">
                     <tbody><tr>
                         <td style="mso-line-height-rule: exactly;-ms-text-size-adjust: 100%;-webkit-text-size-adjust: 100%;">
                             <span></span>
                         </td>
                     </tr>
                 </tbody></table>
 <!--
                 <td class="mcnDividerBlockInner" style="padding: 18px;">
                 <hr class="mcnDividerContent" style="border-bottom-color:none; border-left-color:none; border-right-color:none; border-bottom-width:0; border-left-width:0; border-right-width:0; margin-top:0; margin-right:0; margin-bottom:0; margin-left:0;" />
 -->
             </td>
         </tr>
     </tbody>
 </table><table border="0" cellpadding="0" cellspacing="0" width="100%" class="mcnTextBlock" style="min-width: 100%;border-collapse: collapse;mso-table-lspace: 0pt;mso-table-rspace: 0pt;-ms-text-size-adjust: 100%;-webkit-text-size-adjust: 100%;">
     <tbody class="mcnTextBlockOuter">
         <tr>
             <td valign="top" class="mcnTextBlockInner" style="padding-top: 9px;mso-line-height-rule: exactly;-ms-text-size-adjust: 100%;-webkit-text-size-adjust: 100%;">
                 <!--[if mso]>
                 <table align="left" border="0" cellspacing="0" cellpadding="0" width="100%" style="width:100%;">
                 <tr>
                 <![endif]-->

                 <!--[if mso]>
                 <td valign="top" width="600" style="width:600px;">
                 <![endif]-->
                 <table align="left" border="0" cellpadding="0" cellspacing="0" style="max-width: 100%;min-width: 100%;border-collapse: collapse;mso-table-lspace: 0pt;mso-table-rspace: 0pt;-ms-text-size-adjust: 100%;-webkit-text-size-adjust: 100%;" width="100%" class="mcnTextContentContainer">
                     <tbody><tr>

                         <td valign="top" class="mcnTextContent" style="padding: 0px 18px 9px;font-family: "Noticia Text", Georgia, "Times New Roman", serif;line-height: 150%;mso-line-height-rule: exactly;-ms-text-size-adjust: 100%;-webkit-text-size-adjust: 100%;word-break: break-word;color: #202020;font-size: 16px;text-align: left;">

                             <div style="text-align: left;"><strong><span style="font-size:12px"><span style="font-family:noticia text,georgia,times new roman,serif">Get Pizza, Give Props • Passing the Torch • Cool $#i®T • Airdrops!</span></span></strong></div>

                         </td>
                     </tr>
                 </tbody></table>
                 <!--[if mso]>
                 </td>
                 <![endif]-->

                 <!--[if mso]>
                 </tr>
                 </table>
                 <![endif]-->
             </td>
         </tr>
     </tbody>
 </table><table border="0" cellpadding="0" cellspacing="0" width="100%" class="mcnDividerBlock" style="min-width: 100%;border-collapse: collapse;mso-table-lspace: 0pt;mso-table-rspace: 0pt;-ms-text-size-adjust: 100%;-webkit-text-size-adjust: 100%;table-layout: fixed !important;">
     <tbody class="mcnDividerBlockOuter">
         <tr>
             <td class="mcnDividerBlockInner" style="min-width: 100%;padding: 2px 18px 9px;mso-line-height-rule: exactly;-ms-text-size-adjust: 100%;-webkit-text-size-adjust: 100%;">
                 <table class="mcnDividerContent" border="0" cellpadding="0" cellspacing="0" width="100%" style="min-width: 100%;border-top: 2px solid #EAEAEA;border-collapse: collapse;mso-table-lspace: 0pt;mso-table-rspace: 0pt;-ms-text-size-adjust: 100%;-webkit-text-size-adjust: 100%;">
                     <tbody><tr>
                         <td style="mso-line-height-rule: exactly;-ms-text-size-adjust: 100%;-webkit-text-size-adjust: 100%;">
                             <span></span>
                         </td>
                     </tr>
                 </tbody></table>
 <!--
                 <td class="mcnDividerBlockInner" style="padding: 18px;">
                 <hr class="mcnDividerContent" style="border-bottom-color:none; border-left-color:none; border-right-color:none; border-bottom-width:0; border-left-width:0; border-right-width:0; margin-top:0; margin-right:0; margin-bottom:0; margin-left:0;" />
 -->
             </td>
         </tr>
     </tbody>
 </table><table border="0" cellpadding="0" cellspacing="0" width="100%" class="mcnTextBlock" style="min-width: 100%;border-collapse: collapse;mso-table-lspace: 0pt;mso-table-rspace: 0pt;-ms-text-size-adjust: 100%;-webkit-text-size-adjust: 100%;">
     <tbody class="mcnTextBlockOuter">
         <tr>
             <td valign="top" class="mcnTextBlockInner" style="padding-top: 9px;mso-line-height-rule: exactly;-ms-text-size-adjust: 100%;-webkit-text-size-adjust: 100%;">
                 <!--[if mso]>
                 <table align="left" border="0" cellspacing="0" cellpadding="0" width="100%" style="width:100%;">
                 <tr>
                 <![endif]-->

                 <!--[if mso]>
                 <td valign="top" width="600" style="width:600px;">
                 <![endif]-->
                 <table align="left" border="0" cellpadding="0" cellspacing="0" style="max-width: 100%;min-width: 100%;border-collapse: collapse;mso-table-lspace: 0pt;mso-table-rspace: 0pt;-ms-text-size-adjust: 100%;-webkit-text-size-adjust: 100%;" width="100%" class="mcnTextContentContainer">
                     <tbody><tr>

                         <td valign="top" class="mcnTextContent" style="padding: 0px 18px 9px;font-family: "Noticia Text", Georgia, "Times New Roman", serif;line-height: 150%;mso-line-height-rule: exactly;-ms-text-size-adjust: 100%;-webkit-text-size-adjust: 100%;word-break: break-word;color: #202020;font-size: 16px;text-align: left;">

                             <p dir="ltr" style="font-family: "Noticia Text", Georgia, "Times New Roman", serif;line-height: 150%;margin: 10px 0;padding: 0;mso-line-height-rule: exactly;-ms-text-size-adjust: 100%;-webkit-text-size-adjust: 100%;color: #202020;font-size: 16px;text-align: left;"><span style="font-size:18px"><strong>A Tale of Two Apps...</strong></span><br>
 <br>
 A common refrain in the crypto community is that when practical real-world applications built on crypto and blockchain arrive, that the next wave of mainstream adoption will begin. We’re predicting a flurry of activity in 2019, and we see evidence of this already in February with two applications built on Bitcoin’s Lightning Network (remember, <a href="https://lightning.network/" target="_blank" style="mso-line-height-rule: exactly;-ms-text-size-adjust: 100%;-webkit-text-size-adjust: 100%;color: #42702f;font-weight: normal;text-decoration: underline;">Lightning Network</a> is a ‘Layer 2’ technology that makes Bitcoin transactions faster and cheaper).<br>
 <br>
 First up we have Lightning Pizza. <a href="http://LN.PIZZA" target="_blank" style="mso-line-height-rule: exactly;-ms-text-size-adjust: 100%;-webkit-text-size-adjust: 100%;color: #42702f;font-weight: normal;text-decoration: underline;">LN.PIZZA</a> allows users anywhere in the U.S. to order Domino’s Pizza over the Lightning Network. Now, don’t get us started on the merits of Domino’s pies (or the New York-style / Chicago-style debate), because we applaud the tipping of the first domino(🥁), and we especially believe in the power of pizza to move mountains. Read more at <a href="https://ln.pizza/about" target="_blank" style="mso-line-height-rule: exactly;-ms-text-size-adjust: 100%;-webkit-text-size-adjust: 100%;color: #42702f;font-weight: normal;text-decoration: underline;">https://ln.pizza/about</a>.</p>

                         </td>
                     </tr>
                 </tbody></table>
                 <!--[if mso]>
                 </td>
                 <![endif]-->

                 <!--[if mso]>
                 </tr>
                 </table>
                 <![endif]-->
             </td>
         </tr>
     </tbody>
 </table><table border="0" cellpadding="0" cellspacing="0" width="100%" class="mcnImageCardBlock" style="border-collapse: collapse;mso-table-lspace: 0pt;mso-table-rspace: 0pt;-ms-text-size-adjust: 100%;-webkit-text-size-adjust: 100%;">
     <tbody class="mcnImageCardBlockOuter">
         <tr>
             <td class="mcnImageCardBlockInner" valign="top" style="padding-top: 9px;padding-right: 18px;padding-bottom: 9px;padding-left: 18px;mso-line-height-rule: exactly;-ms-text-size-adjust: 100%;-webkit-text-size-adjust: 100%;">

 <table align="left" border="0" cellpadding="0" cellspacing="0" class="mcnImageCardBottomContent" width="100%" style="border-collapse: collapse;mso-table-lspace: 0pt;mso-table-rspace: 0pt;-ms-text-size-adjust: 100%;-webkit-text-size-adjust: 100%;">
     <tbody><tr>
         <td class="mcnImageCardBottomImageContent" align="left" valign="top" style="padding-top: 0px;padding-right: 0px;padding-bottom: 0;padding-left: 0px;mso-line-height-rule: exactly;-ms-text-size-adjust: 100%;-webkit-text-size-adjust: 100%;">



             <img alt="" src="https://gallery.mailchimp.com/adee9c5618cae148bdcf8f762/images/337bf5b8-67cc-4a7e-9ab2-4f55da064178.gif" width="500" style="max-width: 500px;border: 0;height: auto;outline: none;text-decoration: none;-ms-interpolation-mode: bicubic;vertical-align: bottom;" class="mcnImage">


         </td>
     </tr>
     <tr>
         <td class="mcnTextContent" valign="top" style="padding: 9px 18px;color: #747474;font-family: "Noticia Text", Georgia, "Times New Roman", serif;font-size: 14px;font-style: italic;font-weight: normal;text-align: left;mso-line-height-rule: exactly;-ms-text-size-adjust: 100%;-webkit-text-size-adjust: 100%;word-break: break-word;line-height: 150%;" width="546">
             Pizza rat sold at the top...
         </td>
     </tr>
 </tbody></table>




             </td>
         </tr>
     </tbody>
 </table><table border="0" cellpadding="0" cellspacing="0" width="100%" class="mcnTextBlock" style="min-width: 100%;border-collapse: collapse;mso-table-lspace: 0pt;mso-table-rspace: 0pt;-ms-text-size-adjust: 100%;-webkit-text-size-adjust: 100%;">
     <tbody class="mcnTextBlockOuter">
         <tr>
             <td valign="top" class="mcnTextBlockInner" style="padding-top: 9px;mso-line-height-rule: exactly;-ms-text-size-adjust: 100%;-webkit-text-size-adjust: 100%;">
                 <!--[if mso]>
                 <table align="left" border="0" cellspacing="0" cellpadding="0" width="100%" style="width:100%;">
                 <tr>
                 <![endif]-->

                 <!--[if mso]>
                 <td valign="top" width="600" style="width:600px;">
                 <![endif]-->
                 <table align="left" border="0" cellpadding="0" cellspacing="0" style="max-width: 100%;min-width: 100%;border-collapse: collapse;mso-table-lspace: 0pt;mso-table-rspace: 0pt;-ms-text-size-adjust: 100%;-webkit-text-size-adjust: 100%;" width="100%" class="mcnTextContentContainer">
                     <tbody><tr>

                         <td valign="top" class="mcnTextContent" style="padding-top: 0;padding-right: 18px;padding-bottom: 9px;padding-left: 18px;mso-line-height-rule: exactly;-ms-text-size-adjust: 100%;-webkit-text-size-adjust: 100%;word-break: break-word;color: #202020;font-family: 'Noticia Text', Georgia, 'Times New Roman', serif;font-size: 16px;line-height: 150%;text-align: left;">

                             The next app we want to highlight is <a href="https://tippin.me/" target="_blank" style="mso-line-height-rule: exactly;-ms-text-size-adjust: 100%;-webkit-text-size-adjust: 100%;color: #42702f;font-weight: normal;text-decoration: underline;">Tippin.me</a>. This app is a Chrome and Firefox browser extension that enables Twitter users to tip one another in small amounts of BTC via the Lightning Network. We see enormous potential here in the news and journalism industries, as they’ve been degraded by legacy monetization models that prioritize sensationalism over quality investigation and writing for the sake of maximizing eyeballs for advertising revenue.
                         </td>
                     </tr>
                 </tbody></table>
                 <!--[if mso]>
                 </td>
                 <![endif]-->

                 <!--[if mso]>
                 </tr>
                 </table>
                 <![endif]-->
             </td>
         </tr>
     </tbody>
 </table><table border="0" cellpadding="0" cellspacing="0" width="100%" class="mcnImageCardBlock" style="border-collapse: collapse;mso-table-lspace: 0pt;mso-table-rspace: 0pt;-ms-text-size-adjust: 100%;-webkit-text-size-adjust: 100%;">
     <tbody class="mcnImageCardBlockOuter">
         <tr>
             <td class="mcnImageCardBlockInner" valign="top" style="padding-top: 9px;padding-right: 18px;padding-bottom: 9px;padding-left: 18px;mso-line-height-rule: exactly;-ms-text-size-adjust: 100%;-webkit-text-size-adjust: 100%;">

 <table align="right" border="0" cellpadding="0" cellspacing="0" class="mcnImageCardBottomContent" width="100%" style="border-collapse: collapse;mso-table-lspace: 0pt;mso-table-rspace: 0pt;-ms-text-size-adjust: 100%;-webkit-text-size-adjust: 100%;">
     <tbody><tr>
         <td class="mcnImageCardBottomImageContent" align="center" valign="top" style="padding-top: 0px;padding-right: 0px;padding-bottom: 0;padding-left: 0px;mso-line-height-rule: exactly;-ms-text-size-adjust: 100%;-webkit-text-size-adjust: 100%;">


             <a href="https://www.complex.com/sports/2019/02/adam-silver-showcases-nba-jerseys-of-the-future-at-tech-summit" title="" class="" target="_blank" style="mso-line-height-rule: exactly;-ms-text-size-adjust: 100%;-webkit-text-size-adjust: 100%;">


             <img alt="" src="https://gallery.mailchimp.com/adee9c5618cae148bdcf8f762/images/35422da0-e2db-486c-b8ed-f19de796c2f6.gif" width="357" style="max-width: 357px;border: 0;height: auto;outline: none;text-decoration: none;-ms-interpolation-mode: bicubic;vertical-align: bottom;" class="mcnImage">
             </a>

         </td>
     </tr>
     <tr>
         <td class="mcnTextContent" valign="top" style="padding: 9px 18px;color: #747474;font-family: "Noticia Text", Georgia, "Times New Roman", serif;font-size: 14px;font-weight: normal;text-align: center;mso-line-height-rule: exactly;-ms-text-size-adjust: 100%;-webkit-text-size-adjust: 100%;word-break: break-word;line-height: 150%;" width="546">

         </td>
     </tr>
 </tbody></table>




             </td>
         </tr>
     </tbody>
 </table><table border="0" cellpadding="0" cellspacing="0" width="100%" class="mcnTextBlock" style="min-width: 100%;border-collapse: collapse;mso-table-lspace: 0pt;mso-table-rspace: 0pt;-ms-text-size-adjust: 100%;-webkit-text-size-adjust: 100%;">
     <tbody class="mcnTextBlockOuter">
         <tr>
             <td valign="top" class="mcnTextBlockInner" style="padding-top: 9px;mso-line-height-rule: exactly;-ms-text-size-adjust: 100%;-webkit-text-size-adjust: 100%;">
                 <!--[if mso]>
                 <table align="left" border="0" cellspacing="0" cellpadding="0" width="100%" style="width:100%;">
                 <tr>
                 <![endif]-->

                 <!--[if mso]>
                 <td valign="top" width="600" style="width:600px;">
                 <![endif]-->
                 <table align="left" border="0" cellpadding="0" cellspacing="0" style="max-width: 100%;min-width: 100%;border-collapse: collapse;mso-table-lspace: 0pt;mso-table-rspace: 0pt;-ms-text-size-adjust: 100%;-webkit-text-size-adjust: 100%;" width="100%" class="mcnTextContentContainer">
                     <tbody><tr>

                         <td valign="top" class="mcnTextContent" style="padding-top: 0;padding-right: 18px;padding-bottom: 9px;padding-left: 18px;mso-line-height-rule: exactly;-ms-text-size-adjust: 100%;-webkit-text-size-adjust: 100%;word-break: break-word;color: #202020;font-family: 'Noticia Text', Georgia, 'Times New Roman', serif;font-size: 16px;line-height: 150%;text-align: left;">

                             As journalism crowdfunding models are still taking root in this era of the web, the hypothetical requirements for one to reach the tipping point are ease of use, speed, and really small denominations for payments such that it feels nearly free to the reader (similar to a single impression in a traditional ad-driven model). Lightning Network inherently offers the latter two. It’s incumbent upon product teams to drive the former.<br>
 <br>
 We highly recommend reading Anthony Pompliano’s take, as he effectively covers both the philosophical and practical possibilities offered by Tippin.me: <a href="https://offthechain.substack.com/p/the-new-crypto-product-sweeping-twitter" target="_blank" style="mso-line-height-rule: exactly;-ms-text-size-adjust: 100%;-webkit-text-size-adjust: 100%;color: #42702f;font-weight: normal;text-decoration: underline;">The new crypto product sweeping Twitter.</a>
                         </td>
                     </tr>
                 </tbody></table>
                 <!--[if mso]>
                 </td>
                 <![endif]-->

                 <!--[if mso]>
                 </tr>
                 </table>
                 <![endif]-->
             </td>
         </tr>
     </tbody>
 </table><table border="0" cellpadding="0" cellspacing="0" width="100%" class="mcnDividerBlock" style="min-width: 100%;border-collapse: collapse;mso-table-lspace: 0pt;mso-table-rspace: 0pt;-ms-text-size-adjust: 100%;-webkit-text-size-adjust: 100%;table-layout: fixed !important;">
     <tbody class="mcnDividerBlockOuter">
         <tr>
             <td class="mcnDividerBlockInner" style="min-width: 100%;padding: 24px 18px;mso-line-height-rule: exactly;-ms-text-size-adjust: 100%;-webkit-text-size-adjust: 100%;">
                 <table class="mcnDividerContent" border="0" cellpadding="0" cellspacing="0" width="100%" style="min-width: 100%;border-top: 2px solid #EAEAEA;border-collapse: collapse;mso-table-lspace: 0pt;mso-table-rspace: 0pt;-ms-text-size-adjust: 100%;-webkit-text-size-adjust: 100%;">
                     <tbody><tr>
                         <td style="mso-line-height-rule: exactly;-ms-text-size-adjust: 100%;-webkit-text-size-adjust: 100%;">
                             <span></span>
                         </td>
                     </tr>
                 </tbody></table>
 <!--
                 <td class="mcnDividerBlockInner" style="padding: 18px;">
                 <hr class="mcnDividerContent" style="border-bottom-color:none; border-left-color:none; border-right-color:none; border-bottom-width:0; border-left-width:0; border-right-width:0; margin-top:0; margin-right:0; margin-bottom:0; margin-left:0;" />
 -->
             </td>
         </tr>
     </tbody>
 </table><table border="0" cellpadding="0" cellspacing="0" width="100%" class="mcnTextBlock" style="min-width: 100%;border-collapse: collapse;mso-table-lspace: 0pt;mso-table-rspace: 0pt;-ms-text-size-adjust: 100%;-webkit-text-size-adjust: 100%;">
     <tbody class="mcnTextBlockOuter">
         <tr>
             <td valign="top" class="mcnTextBlockInner" style="padding-top: 9px;mso-line-height-rule: exactly;-ms-text-size-adjust: 100%;-webkit-text-size-adjust: 100%;">
                 <!--[if mso]>
                 <table align="left" border="0" cellspacing="0" cellpadding="0" width="100%" style="width:100%;">
                 <tr>
                 <![endif]-->

                 <!--[if mso]>
                 <td valign="top" width="600" style="width:600px;">
                 <![endif]-->
                 <table align="left" border="0" cellpadding="0" cellspacing="0" style="max-width: 100%;min-width: 100%;border-collapse: collapse;mso-table-lspace: 0pt;mso-table-rspace: 0pt;-ms-text-size-adjust: 100%;-webkit-text-size-adjust: 100%;" width="100%" class="mcnTextContentContainer">
                     <tbody><tr>

                         <td valign="top" class="mcnTextContent" style="padding: 0px 18px 9px;font-family: "Noticia Text", Georgia, "Times New Roman", serif;line-height: 150%;mso-line-height-rule: exactly;-ms-text-size-adjust: 100%;-webkit-text-size-adjust: 100%;word-break: break-word;color: #202020;font-size: 16px;text-align: left;">

                             <p dir="ltr" style="font-family: "Noticia Text", Georgia, "Times New Roman", serif;line-height: 150%;margin: 10px 0;padding: 0;mso-line-height-rule: exactly;-ms-text-size-adjust: 100%;-webkit-text-size-adjust: 100%;color: #202020;font-size: 16px;text-align: left;"><span style="font-size:18px"><strong>… and a Proof of Concept</strong></span></p>

                         </td>
                     </tr>
                 </tbody></table>
                 <!--[if mso]>
                 </td>
                 <![endif]-->

                 <!--[if mso]>
                 </tr>
                 </table>
                 <![endif]-->
             </td>
         </tr>
     </tbody>
 </table><table border="0" cellpadding="0" cellspacing="0" width="100%" class="mcnTextBlock" style="min-width: 100%;border-collapse: collapse;mso-table-lspace: 0pt;mso-table-rspace: 0pt;-ms-text-size-adjust: 100%;-webkit-text-size-adjust: 100%;">
     <tbody class="mcnTextBlockOuter">
         <tr>
             <td valign="top" class="mcnTextBlockInner" style="padding-top: 9px;mso-line-height-rule: exactly;-ms-text-size-adjust: 100%;-webkit-text-size-adjust: 100%;">
                 <!--[if mso]>
                 <table align="left" border="0" cellspacing="0" cellpadding="0" width="100%" style="width:100%;">
                 <tr>
                 <![endif]-->

                 <!--[if mso]>
                 <td valign="top" width="600" style="width:600px;">
                 <![endif]-->
                 <table align="left" border="0" cellpadding="0" cellspacing="0" style="max-width: 100%;min-width: 100%;border-collapse: collapse;mso-table-lspace: 0pt;mso-table-rspace: 0pt;-ms-text-size-adjust: 100%;-webkit-text-size-adjust: 100%;" width="100%" class="mcnTextContentContainer">
                     <tbody><tr>

                         <td valign="top" class="mcnTextContent" style="padding-top: 0;padding-right: 18px;padding-bottom: 9px;padding-left: 18px;mso-line-height-rule: exactly;-ms-text-size-adjust: 100%;-webkit-text-size-adjust: 100%;word-break: break-word;color: #202020;font-family: 'Noticia Text', Georgia, 'Times New Roman', serif;font-size: 16px;line-height: 150%;text-align: left;">

                             For a little over a month, a virtual Bitcoin torch has been passed around the crypto community on Twitter (aka ‘cryptotwitter’), using Tippin.me and Lightning Network. The premise behind the torch is that each successive bearer of the torch adds 10,000 Satoshis to it - roughly $0.38 USD at the moment - before passing it on to the next trusted party. Anyone could hijack the torch when it’s their turn and take the money, now worth about $127 USD. Perhaps the project’s other name, Lightning Network Trust Chain, is more appropriate. Here’s the genesis Tweet from @hodlonaut: <a href="https://twitter.com/hodlonaut/status/1086703428791865345" target="_blank" style="mso-line-height-rule: exactly;-ms-text-size-adjust: 100%;-webkit-text-size-adjust: 100%;color: #42702f;font-weight: normal;text-decoration: underline;">https://twitter.com/hodlonaut/status/1086703428791865345</a>
                         </td>
                     </tr>
                 </tbody></table>
                 <!--[if mso]>
                 </td>
                 <![endif]-->

                 <!--[if mso]>
                 </tr>
                 </table>
                 <![endif]-->
             </td>
         </tr>
     </tbody>
 </table><table border="0" cellpadding="0" cellspacing="0" width="100%" class="mcnImageCardBlock" style="border-collapse: collapse;mso-table-lspace: 0pt;mso-table-rspace: 0pt;-ms-text-size-adjust: 100%;-webkit-text-size-adjust: 100%;">
     <tbody class="mcnImageCardBlockOuter">
         <tr>
             <td class="mcnImageCardBlockInner" valign="top" style="padding-top: 9px;padding-right: 18px;padding-bottom: 9px;padding-left: 18px;mso-line-height-rule: exactly;-ms-text-size-adjust: 100%;-webkit-text-size-adjust: 100%;">

 <table align="left" border="0" cellpadding="0" cellspacing="0" class="mcnImageCardBottomContent" width="100%" style="border-collapse: collapse;mso-table-lspace: 0pt;mso-table-rspace: 0pt;-ms-text-size-adjust: 100%;-webkit-text-size-adjust: 100%;">
     <tbody><tr>
         <td class="mcnImageCardBottomImageContent" align="center" valign="top" style="padding-top: 0px;padding-right: 0px;padding-bottom: 0;padding-left: 0px;mso-line-height-rule: exactly;-ms-text-size-adjust: 100%;-webkit-text-size-adjust: 100%;">



             <img alt="" src="https://gallery.mailchimp.com/adee9c5618cae148bdcf8f762/images/10387c12-1e58-4e79-8f03-17b48fffbebc.gif" width="300" style="max-width: 300px;border: 0;height: auto;outline: none;text-decoration: none;-ms-interpolation-mode: bicubic;vertical-align: bottom;" class="mcnImage">


         </td>
     </tr>
     <tr>
         <td class="mcnTextContent" valign="top" style="padding: 9px 18px;color: #747474;font-family: "Noticia Text", Georgia, "Times New Roman", serif;font-size: 14px;font-weight: normal;text-align: center;mso-line-height-rule: exactly;-ms-text-size-adjust: 100%;-webkit-text-size-adjust: 100%;word-break: break-word;line-height: 150%;" width="546">
             <div dir="ltr" style="text-align: center;"><em>Dat shit cray</em></div>

         </td>
     </tr>
 </tbody></table>




             </td>
         </tr>
     </tbody>
 </table><table border="0" cellpadding="0" cellspacing="0" width="100%" class="mcnTextBlock" style="min-width: 100%;border-collapse: collapse;mso-table-lspace: 0pt;mso-table-rspace: 0pt;-ms-text-size-adjust: 100%;-webkit-text-size-adjust: 100%;">
     <tbody class="mcnTextBlockOuter">
         <tr>
             <td valign="top" class="mcnTextBlockInner" style="padding-top: 9px;mso-line-height-rule: exactly;-ms-text-size-adjust: 100%;-webkit-text-size-adjust: 100%;">
                 <!--[if mso]>
                 <table align="left" border="0" cellspacing="0" cellpadding="0" width="100%" style="width:100%;">
                 <tr>
                 <![endif]-->

                 <!--[if mso]>
                 <td valign="top" width="600" style="width:600px;">
                 <![endif]-->
                 <table align="left" border="0" cellpadding="0" cellspacing="0" style="max-width: 100%;min-width: 100%;border-collapse: collapse;mso-table-lspace: 0pt;mso-table-rspace: 0pt;-ms-text-size-adjust: 100%;-webkit-text-size-adjust: 100%;" width="100%" class="mcnTextContentContainer">
                     <tbody><tr>

                         <td valign="top" class="mcnTextContent" style="padding-top: 0;padding-right: 18px;padding-bottom: 9px;padding-left: 18px;mso-line-height-rule: exactly;-ms-text-size-adjust: 100%;-webkit-text-size-adjust: 100%;word-break: break-word;color: #202020;font-family: 'Noticia Text', Georgia, 'Times New Roman', serif;font-size: 16px;line-height: 150%;text-align: left;">

                             Most recently the torch was held by a group within Fidelity Investments, the $7.2 trillion asset manager, <a href="https://bitcoinist.com/fidelity-investments-bitcoin-lightning-torch/" target="_blank" style="mso-line-height-rule: exactly;-ms-text-size-adjust: 100%;-webkit-text-size-adjust: 100%;color: #42702f;font-weight: normal;text-decoration: underline;">according to Bitcoinist</a>. They are the 229th holder of the torch and first financial institution anything near their magnitude to do so. Somehow this excursion feels a bit more genuine than JPMCoin...<br>
 <br>
 Get the latest on Twitter at <a href="https://twitter.com/hashtag/LNTorch" target="_blank" style="mso-line-height-rule: exactly;-ms-text-size-adjust: 100%;-webkit-text-size-adjust: 100%;color: #42702f;font-weight: normal;text-decoration: underline;">#LNTorch</a> and <a href="https://twitter.com/hashtag/LNTrustChain" target="_blank" style="mso-line-height-rule: exactly;-ms-text-size-adjust: 100%;-webkit-text-size-adjust: 100%;color: #42702f;font-weight: normal;text-decoration: underline;">#LNTrustChain</a>
                         </td>
                     </tr>
                 </tbody></table>
                 <!--[if mso]>
                 </td>
                 <![endif]-->

                 <!--[if mso]>
                 </tr>
                 </table>
                 <![endif]-->
             </td>
         </tr>
     </tbody>
 </table><table border="0" cellpadding="0" cellspacing="0" width="100%" class="mcnDividerBlock" style="min-width: 100%;border-collapse: collapse;mso-table-lspace: 0pt;mso-table-rspace: 0pt;-ms-text-size-adjust: 100%;-webkit-text-size-adjust: 100%;table-layout: fixed !important;">
     <tbody class="mcnDividerBlockOuter">
         <tr>
             <td class="mcnDividerBlockInner" style="min-width: 100%;padding: 24px 18px;mso-line-height-rule: exactly;-ms-text-size-adjust: 100%;-webkit-text-size-adjust: 100%;">
                 <table class="mcnDividerContent" border="0" cellpadding="0" cellspacing="0" width="100%" style="min-width: 100%;border-top: 2px solid #EAEAEA;border-collapse: collapse;mso-table-lspace: 0pt;mso-table-rspace: 0pt;-ms-text-size-adjust: 100%;-webkit-text-size-adjust: 100%;">
                     <tbody><tr>
                         <td style="mso-line-height-rule: exactly;-ms-text-size-adjust: 100%;-webkit-text-size-adjust: 100%;">
                             <span></span>
                         </td>
                     </tr>
                 </tbody></table>
 <!--
                 <td class="mcnDividerBlockInner" style="padding: 18px;">
                 <hr class="mcnDividerContent" style="border-bottom-color:none; border-left-color:none; border-right-color:none; border-bottom-width:0; border-left-width:0; border-right-width:0; margin-top:0; margin-right:0; margin-bottom:0; margin-left:0;" />
 -->
             </td>
         </tr>
     </tbody>
 </table><table border="0" cellpadding="0" cellspacing="0" width="100%" class="mcnTextBlock" style="min-width: 100%;border-collapse: collapse;mso-table-lspace: 0pt;mso-table-rspace: 0pt;-ms-text-size-adjust: 100%;-webkit-text-size-adjust: 100%;">
     <tbody class="mcnTextBlockOuter">
         <tr>
             <td valign="top" class="mcnTextBlockInner" style="padding-top: 9px;mso-line-height-rule: exactly;-ms-text-size-adjust: 100%;-webkit-text-size-adjust: 100%;">
                 <!--[if mso]>
                 <table align="left" border="0" cellspacing="0" cellpadding="0" width="100%" style="width:100%;">
                 <tr>
                 <![endif]-->

                 <!--[if mso]>
                 <td valign="top" width="600" style="width:600px;">
                 <![endif]-->
                 <table align="left" border="0" cellpadding="0" cellspacing="0" style="max-width: 100%;min-width: 100%;border-collapse: collapse;mso-table-lspace: 0pt;mso-table-rspace: 0pt;-ms-text-size-adjust: 100%;-webkit-text-size-adjust: 100%;" width="100%" class="mcnTextContentContainer">
                     <tbody><tr>

                         <td valign="top" class="mcnTextContent" style="padding: 0px 18px 9px;font-family: "Noticia Text", Georgia, "Times New Roman", serif;line-height: 150%;mso-line-height-rule: exactly;-ms-text-size-adjust: 100%;-webkit-text-size-adjust: 100%;word-break: break-word;color: #202020;font-size: 16px;text-align: left;">

                             <span style="font-size:18px"><span style="font-family:noticia text,georgia,times new roman,serif"><strong>Cool $#i®T of the week</strong></span></span>
                         </td>
                     </tr>
                 </tbody></table>
                 <!--[if mso]>
                 </td>
                 <![endif]-->

                 <!--[if mso]>
                 </tr>
                 </table>
                 <![endif]-->
             </td>
         </tr>
     </tbody>
 </table><table border="0" cellpadding="0" cellspacing="0" width="100%" class="mcnTextBlock" style="min-width: 100%;border-collapse: collapse;mso-table-lspace: 0pt;mso-table-rspace: 0pt;-ms-text-size-adjust: 100%;-webkit-text-size-adjust: 100%;">
     <tbody class="mcnTextBlockOuter">
         <tr>
             <td valign="top" class="mcnTextBlockInner" style="padding-top: 9px;mso-line-height-rule: exactly;-ms-text-size-adjust: 100%;-webkit-text-size-adjust: 100%;">
                 <!--[if mso]>
                 <table align="left" border="0" cellspacing="0" cellpadding="0" width="100%" style="width:100%;">
                 <tr>
                 <![endif]-->

                 <!--[if mso]>
                 <td valign="top" width="600" style="width:600px;">
                 <![endif]-->
                 <table align="left" border="0" cellpadding="0" cellspacing="0" style="max-width: 100%;min-width: 100%;border-collapse: collapse;mso-table-lspace: 0pt;mso-table-rspace: 0pt;-ms-text-size-adjust: 100%;-webkit-text-size-adjust: 100%;" width="100%" class="mcnTextContentContainer">
                     <tbody><tr>

                         <td valign="top" class="mcnTextContent" style="padding: 0px 18px 9px;font-family: "Noticia Text", Georgia, "Times New Roman", serif;line-height: 150%;mso-line-height-rule: exactly;-ms-text-size-adjust: 100%;-webkit-text-size-adjust: 100%;word-break: break-word;color: #202020;font-size: 16px;text-align: left;">

                             <p dir="ltr" style="font-family: "Noticia Text", Georgia, "Times New Roman", serif;line-height: 150%;margin: 10px 0;padding: 0;mso-line-height-rule: exactly;-ms-text-size-adjust: 100%;-webkit-text-size-adjust: 100%;color: #202020;font-size: 16px;text-align: left;">Dig these t-shirts like the one below showcasing the artwork of famed New York City street artists, Keith Haring and Jean-Michel Basquiat, and pop art icon, Andy Warhol. Only $15!</p>

                         </td>
                     </tr>
                 </tbody></table>
                 <!--[if mso]>
                 </td>
                 <![endif]-->

                 <!--[if mso]>
                 </tr>
                 </table>
                 <![endif]-->
             </td>
         </tr>
     </tbody>
 </table><table border="0" cellpadding="0" cellspacing="0" width="100%" class="mcnImageCardBlock" style="border-collapse: collapse;mso-table-lspace: 0pt;mso-table-rspace: 0pt;-ms-text-size-adjust: 100%;-webkit-text-size-adjust: 100%;">
     <tbody class="mcnImageCardBlockOuter">
         <tr>
             <td class="mcnImageCardBlockInner" valign="top" style="padding-top: 9px;padding-right: 18px;padding-bottom: 9px;padding-left: 18px;mso-line-height-rule: exactly;-ms-text-size-adjust: 100%;-webkit-text-size-adjust: 100%;">

 <table align="left" border="0" cellpadding="0" cellspacing="0" class="mcnImageCardBottomContent" width="100%" style="border-collapse: collapse;mso-table-lspace: 0pt;mso-table-rspace: 0pt;-ms-text-size-adjust: 100%;-webkit-text-size-adjust: 100%;">
     <tbody><tr>
         <td class="mcnImageCardBottomImageContent" align="center" valign="top" style="padding-top: 0px;padding-right: 0px;padding-bottom: 0;padding-left: 0px;mso-line-height-rule: exactly;-ms-text-size-adjust: 100%;-webkit-text-size-adjust: 100%;">


             <a href="https://www.complex.com/sports/2019/02/adam-silver-showcases-nba-jerseys-of-the-future-at-tech-summit" title="" class="" target="_blank" style="mso-line-height-rule: exactly;-ms-text-size-adjust: 100%;-webkit-text-size-adjust: 100%;">


             <img alt="" src="https://gallery.mailchimp.com/adee9c5618cae148bdcf8f762/images/267c1e49-b3ad-41f2-b254-b733dc8fd9ef.jpg" width="520" style="max-width: 520px;border: 0;height: auto;outline: none;text-decoration: none;-ms-interpolation-mode: bicubic;vertical-align: bottom;" class="mcnImage">
             </a>

         </td>
     </tr>
     <tr>
         <td class="mcnTextContent" valign="top" style="padding: 9px 18px;color: #747474;font-family: "Noticia Text", Georgia, "Times New Roman", serif;font-size: 14px;font-weight: normal;text-align: center;mso-line-height-rule: exactly;-ms-text-size-adjust: 100%;-webkit-text-size-adjust: 100%;word-break: break-word;line-height: 150%;" width="546">

         </td>
     </tr>
 </tbody></table>




             </td>
         </tr>
     </tbody>
 </table><table border="0" cellpadding="0" cellspacing="0" width="100%" class="mcnTextBlock" style="min-width: 100%;border-collapse: collapse;mso-table-lspace: 0pt;mso-table-rspace: 0pt;-ms-text-size-adjust: 100%;-webkit-text-size-adjust: 100%;">
     <tbody class="mcnTextBlockOuter">
         <tr>
             <td valign="top" class="mcnTextBlockInner" style="padding-top: 9px;mso-line-height-rule: exactly;-ms-text-size-adjust: 100%;-webkit-text-size-adjust: 100%;">
                 <!--[if mso]>
                 <table align="left" border="0" cellspacing="0" cellpadding="0" width="100%" style="width:100%;">
                 <tr>
                 <![endif]-->

                 <!--[if mso]>
                 <td valign="top" width="600" style="width:600px;">
                 <![endif]-->
                 <table align="left" border="0" cellpadding="0" cellspacing="0" style="max-width: 100%;min-width: 100%;border-collapse: collapse;mso-table-lspace: 0pt;mso-table-rspace: 0pt;-ms-text-size-adjust: 100%;-webkit-text-size-adjust: 100%;" width="100%" class="mcnTextContentContainer">
                     <tbody><tr>

                         <td valign="top" class="mcnTextContent" style="padding: 0px 18px 9px;font-family: "Noticia Text", Georgia, "Times New Roman", serif;line-height: 150%;mso-line-height-rule: exactly;-ms-text-size-adjust: 100%;-webkit-text-size-adjust: 100%;word-break: break-word;color: #202020;font-size: 16px;text-align: left;">

                             <p dir="ltr" style="font-family: "Noticia Text", Georgia, "Times New Roman", serif;line-height: 150%;margin: 10px 0;padding: 0;mso-line-height-rule: exactly;-ms-text-size-adjust: 100%;-webkit-text-size-adjust: 100%;color: #202020;font-size: 16px;text-align: left;">Read more at Highsnobiety before hopping over to Uniqlo to buy:<br>
 <a href="https://www.highsnobiety.com/p/uniqlo-sprz-ny-basquiat-haring-warhol-tees-buy/" target="_blank" style="mso-line-height-rule: exactly;-ms-text-size-adjust: 100%;-webkit-text-size-adjust: 100%;color: #42702f;font-weight: normal;text-decoration: underline;">https://www.highsnobiety.com/p/uniqlo-sprz-ny-basquiat-haring-warhol-tees-buy/</a><br>
 <a href="https://www.uniqlo.com/us/en/ut-graphic-tees/shop-all-sprz-ny" target="_blank" style="mso-line-height-rule: exactly;-ms-text-size-adjust: 100%;-webkit-text-size-adjust: 100%;color: #42702f;font-weight: normal;text-decoration: underline;">https://www.uniqlo.com/us/en/ut-graphic-tees/shop-all-sprz-ny</a></p>

                         </td>
                     </tr>
                 </tbody></table>
                 <!--[if mso]>
                 </td>
                 <![endif]-->

                 <!--[if mso]>
                 </tr>
                 </table>
                 <![endif]-->
             </td>
         </tr>
     </tbody>
 </table><table border="0" cellpadding="0" cellspacing="0" width="100%" class="mcnDividerBlock" style="min-width: 100%;border-collapse: collapse;mso-table-lspace: 0pt;mso-table-rspace: 0pt;-ms-text-size-adjust: 100%;-webkit-text-size-adjust: 100%;table-layout: fixed !important;">
     <tbody class="mcnDividerBlockOuter">
         <tr>
             <td class="mcnDividerBlockInner" style="min-width: 100%;padding: 18px;mso-line-height-rule: exactly;-ms-text-size-adjust: 100%;-webkit-text-size-adjust: 100%;">
                 <table class="mcnDividerContent" border="0" cellpadding="0" cellspacing="0" width="100%" style="min-width: 100%;border-top: 2px solid #EAEAEA;border-collapse: collapse;mso-table-lspace: 0pt;mso-table-rspace: 0pt;-ms-text-size-adjust: 100%;-webkit-text-size-adjust: 100%;">
                     <tbody><tr>
                         <td style="mso-line-height-rule: exactly;-ms-text-size-adjust: 100%;-webkit-text-size-adjust: 100%;">
                             <span></span>
                         </td>
                     </tr>
                 </tbody></table>
 <!--
                 <td class="mcnDividerBlockInner" style="padding: 18px;">
                 <hr class="mcnDividerContent" style="border-bottom-color:none; border-left-color:none; border-right-color:none; border-bottom-width:0; border-left-width:0; border-right-width:0; margin-top:0; margin-right:0; margin-bottom:0; margin-left:0;" />
 -->
             </td>
         </tr>
     </tbody>
 </table><table border="0" cellpadding="0" cellspacing="0" width="100%" class="mcnTextBlock" style="min-width: 100%;border-collapse: collapse;mso-table-lspace: 0pt;mso-table-rspace: 0pt;-ms-text-size-adjust: 100%;-webkit-text-size-adjust: 100%;">
     <tbody class="mcnTextBlockOuter">
         <tr>
             <td valign="top" class="mcnTextBlockInner" style="padding-top: 9px;mso-line-height-rule: exactly;-ms-text-size-adjust: 100%;-webkit-text-size-adjust: 100%;">
                 <!--[if mso]>
                 <table align="left" border="0" cellspacing="0" cellpadding="0" width="100%" style="width:100%;">
                 <tr>
                 <![endif]-->

                 <!--[if mso]>
                 <td valign="top" width="600" style="width:600px;">
                 <![endif]-->
                 <table align="left" border="0" cellpadding="0" cellspacing="0" style="max-width: 100%;min-width: 100%;border-collapse: collapse;mso-table-lspace: 0pt;mso-table-rspace: 0pt;-ms-text-size-adjust: 100%;-webkit-text-size-adjust: 100%;" width="100%" class="mcnTextContentContainer">
                     <tbody><tr>

                         <td valign="top" class="mcnTextContent" style="padding: 0px 18px 9px;font-family: "Noticia Text", Georgia, "Times New Roman", serif;line-height: 150%;mso-line-height-rule: exactly;-ms-text-size-adjust: 100%;-webkit-text-size-adjust: 100%;word-break: break-word;color: #202020;font-size: 16px;text-align: left;">

                             <div><strong><span style="font-size:24px">Airdrops! </span></strong></div>

 <div> </div>

 <div> Brian Armstrong, CEO of Coinbase, talks about some of the differences between ‘hot’ and ‘cold’ crypto storage, a topic in the news especially since the QuadrigaCX mishap: <a href="http://fortune.com/2019/02/21/cryptocurrency-custody-misconceptions-coinbase-ceo/" target="_blank" style="mso-line-height-rule: exactly;-ms-text-size-adjust: 100%;-webkit-text-size-adjust: 100%;color: #42702f;font-weight: normal;text-decoration: underline;">Busting Myths About Cryptocurrency Custody</a> (Fortune)<br>
 <br>
  The state of Wyoming continues to take the lead in cryptocurrency legislation that aims to foster a strong market: <a href="https://www.coindesk.com/wyoming-lawmakers-pass-three-bills-in-boost-for-states-crypto-industry" target="_blank" style="mso-line-height-rule: exactly;-ms-text-size-adjust: 100%;-webkit-text-size-adjust: 100%;color: #42702f;font-weight: normal;text-decoration: underline;">Wyoming Lawmakers Pass Three Bills in Boost for State’s Crypto Industry</a> (Coindesk)<br>
 <br>
  Samsung confirms rumors that their new flagship device will feature a Bitcoin hardware wallet: <a href="https://bitcoinist.com/samsung-galaxy-s10-bitcoin-wallet/" target="_blank" style="mso-line-height-rule: exactly;-ms-text-size-adjust: 100%;-webkit-text-size-adjust: 100%;color: #42702f;font-weight: normal;text-decoration: underline;">Samsung Galaxy S10 has a Built-in Bitcoin Hardware Wallet</a> (Bitcoinist)<br>
 <br>
 🤑 This article needs no preface, as the title says it all: <a href="https://cointelegraph.com/news/bitcoin-will-hit-1-million-says-ibms-vp-of-blockchain-jesse-lund" target="_blank" style="mso-line-height-rule: exactly;-ms-text-size-adjust: 100%;-webkit-text-size-adjust: 100%;color: #42702f;font-weight: normal;text-decoration: underline;">Bitcoin Will Hit $1 Million, Says IBM’s VP of Blockchain Jesse Lund </a>(Cointelgraph)</div>

                         </td>
                     </tr>
                 </tbody></table>
                 <!--[if mso]>
                 </td>
                 <![endif]-->

                 <!--[if mso]>
                 </tr>
                 </table>
                 <![endif]-->
             </td>
         </tr>
     </tbody>
 </table><table border="0" cellpadding="0" cellspacing="0" width="100%" class="mcnDividerBlock" style="min-width: 100%;border-collapse: collapse;mso-table-lspace: 0pt;mso-table-rspace: 0pt;-ms-text-size-adjust: 100%;-webkit-text-size-adjust: 100%;table-layout: fixed !important;">
     <tbody class="mcnDividerBlockOuter">
         <tr>
             <td class="mcnDividerBlockInner" style="min-width: 100%;padding: 18px;mso-line-height-rule: exactly;-ms-text-size-adjust: 100%;-webkit-text-size-adjust: 100%;">
                 <table class="mcnDividerContent" border="0" cellpadding="0" cellspacing="0" width="100%" style="min-width: 100%;border-top: 2px solid #EAEAEA;border-collapse: collapse;mso-table-lspace: 0pt;mso-table-rspace: 0pt;-ms-text-size-adjust: 100%;-webkit-text-size-adjust: 100%;">
                     <tbody><tr>
                         <td style="mso-line-height-rule: exactly;-ms-text-size-adjust: 100%;-webkit-text-size-adjust: 100%;">
                             <span></span>
                         </td>
                     </tr>
                 </tbody></table>
 <!--
                 <td class="mcnDividerBlockInner" style="padding: 18px;">
                 <hr class="mcnDividerContent" style="border-bottom-color:none; border-left-color:none; border-right-color:none; border-bottom-width:0; border-left-width:0; border-right-width:0; margin-top:0; margin-right:0; margin-bottom:0; margin-left:0;" />
 -->
             </td>
         </tr>
     </tbody>
 </table><table border="0" cellpadding="0" cellspacing="0" width="100%" class="mcnTextBlock" style="min-width: 100%;border-collapse: collapse;mso-table-lspace: 0pt;mso-table-rspace: 0pt;-ms-text-size-adjust: 100%;-webkit-text-size-adjust: 100%;">
     <tbody class="mcnTextBlockOuter">
         <tr>
             <td valign="top" class="mcnTextBlockInner" style="padding-top: 9px;mso-line-height-rule: exactly;-ms-text-size-adjust: 100%;-webkit-text-size-adjust: 100%;">
                 <!--[if mso]>
                 <table align="left" border="0" cellspacing="0" cellpadding="0" width="100%" style="width:100%;">
                 <tr>
                 <![endif]-->

                 <!--[if mso]>
                 <td valign="top" width="600" style="width:600px;">
                 <![endif]-->
                 <table align="left" border="0" cellpadding="0" cellspacing="0" style="max-width: 100%;min-width: 100%;border-collapse: collapse;mso-table-lspace: 0pt;mso-table-rspace: 0pt;-ms-text-size-adjust: 100%;-webkit-text-size-adjust: 100%;" width="100%" class="mcnTextContentContainer">
                     <tbody><tr>

                         <td valign="top" class="mcnTextContent" style="padding: 0px 18px 9px;font-family: "Noticia Text", Georgia, "Times New Roman", serif;line-height: 150%;mso-line-height-rule: exactly;-ms-text-size-adjust: 100%;-webkit-text-size-adjust: 100%;word-break: break-word;color: #202020;font-size: 16px;text-align: left;">

                             <div style="text-align: center;"><strong><span style="font-size:24px">SEE YOU NEXT WEEK...</span></strong><br>
 <br>
 Tips and leads for next week's edition? Email them to us at <a href="mailto:hi@satoshiscloset.com?subject=Newsletter%20tips%20and%20leads" target="_blank" style="mso-line-height-rule: exactly;-ms-text-size-adjust: 100%;-webkit-text-size-adjust: 100%;color: #42702f;font-weight: normal;text-decoration: underline;">hi@satoshiscloset.com</a>.<br>
 <br>
 <img data-file-id="4326517" height="128" src="https://gallery.mailchimp.com/adee9c5618cae148bdcf8f762/images/98c6e2b1-a7da-4b2a-8718-799bda1c4658.gif" style="border: 0px;width: 128px;height: 128px;margin: 0px;outline: none;text-decoration: none;-ms-interpolation-mode: bicubic;" width="128"><br>
 <strong>Check out previous issues:</strong><br>
 <a href="https://satoshiscloset.com/blog" target="_blank" style="mso-line-height-rule: exactly;-ms-text-size-adjust: 100%;-webkit-text-size-adjust: 100%;color: #42702f;font-weight: normal;text-decoration: underline;">https://satoshiscloset.com/blog</a></div>
 </td>
                     </tr>
                 </tbody></table>
             </td>
         </tr>
     </tbody>
 </table></td>
                             </tr>

                         </table>
                     </td>
                 </tr>
             </table>
         </center>
     </body>
 </html>`

const initialState = {
    posts: [
        {
            id: '001-01-2019-debut-issue',
            title: '[001-01-2019] Debut issue.',
            html: POST1
        },
        {
            id: '002-02-2019-mostly-an-editorial',
            title: '[002-02-2019] Mostly an editorial.',
            html: POST2
        },
        {
            id: '003-03-2019-puzzles-with-money-in-them',
            title: '[003-03-2019] Puzzles with money in them.',
            html: POST3
        },
        {
            id: '004-04-2019-vinny-chase-selling-kicks-for-btc',
            title: '[004-04-2019] Vinny Chase selling kicks for BTC.',
            html: POST4
        },
        {
            id: '005-05-2019-atomic-swaps-atomic-fireballs',
            title: '[005-05-2019] Atomic swaps & Atomic Fireballs.',
            html: POST5
        },
        {
            id: '006-06-2019-silky-threads-and-the-dead',
            title: '[006-06-2019] Silky threads and The Dead.',
            html: POST6
        },
        {
            id: '007-07-2019-little-ditty-about-jack-dimon',
            title: '[007-07-2019] Little ditty about Jack and Dimon',
            html: POST7
        },
        {
            id: '008-08-2019-1-21-gigawatts',
            title: '[008-08-2019] 1.21 Gigawatts',
            html: POST8
        },
        {
            id: '009-09-2019-stepping-out',
            title: '[009-09-2019] Stepping Out',
            html: POST9
        },
        {
            id: 'iso-lookbook',
            title: 'The Initial Shirt Offering Lookbook',
            html: ''
        },
        {
            id: 'iso-launchparty',
            title: 'ISO Launch Party @ Mr. Throwback',
            html: ''
        },
        {
            id: 'putting-apparel-on-blockchain',
            title: 'Putting apparel on blockchain / How the ISO works',
            html: POST10
        },
        {
            id: 'our-crypto-future-pt1',
            title: 'Our Crypto Future: Pt. 1 — Block time',
            html: ''
        },
        {
            id: 'nft-buying-guide',
            title: 'NFT Buying Guide',
            html: ''
        }
    ]
}

export default function appReducer(state = initialState, action) {
    switch (action.type) {
        default:
            return {posts: initialState.posts.reverse()}
    }
}
