import React, { Component } from 'react';

import '../App/index.scss';

import Header from '../shared/Header';
import Footer from '../shared/Footer';
import ItemTray from '../App/ItemTray'

class Onboard extends Component {
  state = {}

  render() {
    return (
      <div>
        <Header />
          <div className="App">
            <ItemTray onboard={true} />
          </div>
        <Footer />
      </div>
    );
  }
}

export default Onboard;
