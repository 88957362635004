import React from 'react';
import moment from 'moment';

import './index.scss';

import Header from '../shared/Header';
import Footer from '../shared/Footer';

const ABOUT_TEXT = [
  {text: 'About Satoshi\'s Closet', type: 'header'},
  {text: 'Satoshi’s Closet is the world’s first streetwear brand built on blockchain.', type: 'text'},
  {text: 'We believe that blockchain technology and crypto can radically transform how apparel is sold, resold, and integrated into our digital future — changing both the apparel game and how we think about and transact value.', type: 'text'},
  {text: 'read our manifesto / white paper', type: 'link', to: '/manifesto.pdf'},
  {text: 'Our company exists for two reasons. The first is to provide a vehicle for the future of streetwear and culture through technology and artistic expression. The second is to educate and advocate for the adoption of cryptocurrency as a basis for global economic exchange.', type: 'text'},
  {text: 'We want this to be fun, and informative.', type: 'text'},
  {text: require('../../assets/images/website-about-image.jpg'), alt: 'about page image', type: 'image'},
  {text: 'What is blockchain?', type: 'subheader'},
  {text: 'Blockchain is an Internet technology that enables direct party-to-party transactions without the need for an intermediary (such as a bank) to verify the transaction.', type: 'text'},
  {text: 'Instead, transactions are validated by a distributed network of computers that use code to collectively determine transactions are legitimate. A global ledger is maintained and cannot be erased or tampered with, since each computer on the network can validate the entire blockchain of history. This makes it virtually unhackable.', type: 'text'},
  {text: 'The implications for this technology are immense, from financial applications (cryptocurrency) to supply chain, device-to-device communications, and authentication of physical goods.', type: 'text'},
  {text: 'What is cryptocurrency?', type: 'subheader'},
  {text: 'Cryptocurrency - often called “crypto” for short - is the application of blockchain as it applies to digital money. It is advantagous to fiat currency since it cannot be devalued by central banking authorities, and it cannot be confiscated because only the owner of the “keys” to the account can access it. Its peer-to-peer nature also eliminates the need for “middlemen” in transactions, thus promoting privacy between transacting parties and reducing the need for transcation fees.', type: 'text'},
  {text: 'What are non-fungible tokens?', type: 'subheader'},
  {text: 'Non-fungible tokens (NFT’s) are an application of blockchain technology that allows for the creation of unique digital objects that are individually identifiable. Think of them like a barcode or fingerprint of which there are no two alike. Okay, so think of them like snowflakes :)', type: 'text'},
  {text: 'NFT’s are especially useful when it is necessary to track items that are either one-of-a-kind or part of a finite set, such as a painting, a limited edition pair of sneakers, or other collectibles.', type: 'text'},
  {text: 'terms of service', type: 'link', to: '/terms'}

]

function About() {
  const divs = ABOUT_TEXT.map((item, index) => {
    let className = 'about__' + item.type;
    let div = (<div key={'text_'+index} className={className}>{item.text}</div>);
    if (item.type === 'link') {
      if (index === ABOUT_TEXT.length - 1) {
        className += ' last';
      }
      div = (<div key={'link_'+index}  className={className}><a href={item.to}>{item.text}</a></div>)
    } else if (item.type === 'image') {
      const partyTime = moment.utc('2019-10-05T00:00:00') // 8pm
      const now = moment.utc()
      div = now > partyTime ? (<img src={item.text} alt={item.alt} />) : null;
    }
    return (div)
  })
  return (
    <div>
      <Header />
      <div className="about">
        <div className='about__inner'>{divs}</div>
      </div>
      <Footer />
    </div>
  );
}

export default About;
