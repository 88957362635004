import React, {Component} from 'react';
import { Link } from 'react-router-dom'

import './index.scss';

import Header from '../shared/Header';
import Footer from '../shared/Footer';

class IsoLaunchParty extends Component{
  render() {
    return (
      <div>
        <Header />
        <div className='blog'>
          <div className='blog__inner launchparty'>
            <div className='blog__link post'>
              <Link to='/blog'><span>&#x3c;</span>Back to Blog</Link>
            </div>
            <div>
              <div className='blog__header'>
                ISO Launch Party @ Mr. Throwback
              </div>
              <div className='blog__subheader'>
                <div>Huge thanks to <a rel="noopener noreferrer" target='_blank' href='https://www.instagram.com/mrthrowbacknyc/'>@MrThrowbackNYC</a> for sharing your space for The Initial Shirt Offering launch party on 10-04-19.</div>
                <div>Many thanks as to <a rel="noopener noreferrer" target='_blank' href='https://www.instagram.com/eyeofasaad/'>@eyeofasaad</a> for photography for the evening!</div>
              </div>
              <div className='blog__images'>
                <div>
                  <img alt='Launch 1' src='/images/launchparty/launch-1.jpg' />
                  <img alt='Launch 2' src='/images/launchparty/launch-2.jpg' />
                  <img alt='Launch 3' src='/images/launchparty/launch-3.jpg' />
                </div>
                <div>
                  <img alt='Launch 4' src='/images/launchparty/launch-4.jpg' />
                  <img alt='Launch 5' src='/images/launchparty/launch-5.jpg' />
                  <img alt='Launch 6' src='/images/launchparty/launch-6.jpg' />
                </div>
                <div>
                  <img alt='Launch 7' src='/images/launchparty/launch-7.jpg' />
                  <img alt='Launch 8' src='/images/launchparty/launch-8.jpg' />
                  <img alt='Launch 9' src='/images/launchparty/launch-9.jpg' />
                </div>
              </div>
            </div>
          </div>
        </div>
        <Footer />
      </div>
    );
  }
}

export default IsoLaunchParty;
