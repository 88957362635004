import React, { Component } from 'react';
import * as EmailValidator from 'email-validator';

import './index.scss';

import Header from '../shared/Header';
import Footer from '../shared/Footer';

import { sendEmail } from '../../services/aws';

class Contact extends Component {
  state = {}

  onChange(key, event) {
    const value = event.target.value
    this.setState({[key]: value});
  }

  onClick() {
    const {email, message, submitting, success} = this.state;
    if (!email) {
      this.setState({error: 'Please enter an email address'});
      return;
    }
    if (!EmailValidator.validate(email)) {
      this.setState({error: 'Please enter a valid email address'});
      return;
    }
    if (!message) {
      this.setState({error: 'Please enter a message'});
      return;
    }
    if (submitting || success) {
      return;
    }
    const data = `message from ${email}: ${message}`;
    this.setState({submitting: true, error: null}, () => this.sendEmail(data));
  }

  async sendEmail(email) {
    try {
      await sendEmail('contact us', email, this.onSuccess.bind(this));
    } catch (err) {
      console.error(err);
    }
  }

  onSuccess() {
      this.setState({success: true, submitting: false});
  }

  render() {
    const {success, submitting, error} = this.state;
    const buttonText = success ? 'Success!' : submitting ? 'sending...' : 'submit';
    return (
      <div>
        <Header />
        <div className="contact">
          <div className='contact__inner'>
            <div className='contact__header'>Get in touch</div>
            <div className='contact__input'>
              <div className='label'>message</div>
              <input type='text' placeholder='What would you like to tell us?' onChange={this.onChange.bind(this, 'message')} />
            </div>
            <div className='contact__input'>
              <div className='label'>email address</div>
              <input type='email' placeholder='Enter email address' onChange={this.onChange.bind(this, 'email')} />
            </div>
            <div className='contact__button-container'>
                <div  className='contact__button' onClick={this.onClick.bind(this)}>{buttonText}</div>
                {success &&
                    <div style={{marginTop: 10, fontSize: 12}}>Thanks! We've received your email and will be in touch with you soon.</div>
                }
                {error &&
                    <div style={{marginTop: 10, fontSize: 12, color: 'red'}}>{error}</div>
                }
                <div className='contact__button-footer'>Or just send an email to <a href='mailto:hi@satoshiscloset.com'>hi@satoshiscloset.com</a>, we're going to get the email from the form above at the same spot, anyway.</div>
            </div>
          </div>
          <div className='contact__send-eth'>
            <img alt="Satoshi's Closet Send ETH!" src={require('../../assets/images/send_eth.svg')} />
          </div>
        </div>
        <Footer />
      </div>
    );
  }
}

export default Contact;
