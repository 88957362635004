import React, {Component} from 'react';
import './index.scss';

import Header from '../shared/Header';
import Footer from '../shared/Footer';
import Launch from './Launch';
import GeneralInfo from './GeneralInfo';

class App extends Component{
  render() {
    return (
      <div>
        <Header />
        <div className="App">
          <Launch />
          <GeneralInfo partyTime={true} />
        </div>
        <Footer />
      </div>
    );
  }
}

export default App;
