import React, {Component} from 'react';
import { Link } from 'react-router-dom'

import './index.scss';

import Footer from '../shared/Footer';

class WLT extends Component{
  scrollToTop() {
    document.body.scrollTop = 0; // For Safari
    document.documentElement.scrollTop = 0; // For Chrome, Firefox, IE and Opera
  }

  render() {
    return (
      <div className='wlt'>
        <img src='wlt-logo-cycle.gif' width='360' height='240' />
        <div className='wlt__main'>
          <div className='wlt__main__header'>The best crypto wallet for NFT's.</div>
          <div className='wlt__main__subheader'>Sign up for early access, secret NFT drops, and more.</div>
          <Footer landing={true} />
        </div>
        <div className='wlt__new'>
          <img src='WLT-carousel.gif' className='carousel' />
          <div>
            <div className='web-text'><a target="_blank" href="https://wlt.satoshiscloset.com/web">Try the WEB WLT gallery alpha ></a></div>
            <a className='web' target="_blank" href="https://wlt.satoshiscloset.com/web"><img src='WLT-WEB.jpg' /></a>
          </div>
        </div>
        <div className='wlt__story'>
          <div className='wlt__story__header'>WLT by Satoshi’s Closet</div>
          <div>NEW YORK CITY, April 2022 — You find a table outside and sit down to wait for your companion’s arrival. You set your phone on the table face-up and open your WLT app to subtly show off your new limited edition “Technobabble” NFT wallet backdrop designed by artist Paul Michael Graves, a piece you were lucky to acquire in a surprise Satoshi’s Closet release last week.</div>
          <div>Your friend catches sight of the low-key flex as he takes his seat. “You picked up another new WLT NFT? It seems like you have a new one every time we meet up.” What can you say? It’s not an addiction, but you consider yourself a fashionable person, and you find yourself interacting with your wallet on the daily – whether it’s checking your funds, making a quick transaction, or managing your crypto assets and NFT’s. You might as well be staring at artwork you enjoy and supporting creators and brands that you like. Besides, you just re-sold a different WLT NFT for a half-ETH the other day from a different artist you discovered before their following picked up.</div>
          <div>After you and your friend put in your order – it’s still a wonder to be sitting outside enjoying each other’s company after the pandemic receded – you want to show off your latest acquisition, one of the earliest audiovisual NFT’s from musician/artist Rare Scrilla. With a single tap you select your NFT’s, then deftly trace your finger over a keypad to open your #2 wallet – you don’t want your companion to see some rarer, more expensive NFT’s in your #1 wallet, and you keep your NFT tickets and other important NFT documents in your #3 wallet.</div>
          <div>You search for your NFT by name, tap a search result, and lift your phone to show off a full-screen view of the animation playback, complemented by sound. You swipe over the player to expose a panel of NFT metadata including the 2016 mint date. Your friend is naturally impressed by the 2016 vintage, so you back out and sort your collection by mint date to show off your OG’s. Of course your collection has more than a few, so you’ve optionally tagged a few as ‘Rare’ and ‘Dank’ while categorizing others by style such as ‘Trash art’ or ‘Vaporwave.’ Listing any of these for resale is also pretty easy if you ever tire of them or are simply looking to get more liquid.</div>
          <div>As lunch wraps up, you grab the check. You like this cafe because they take a few different types of cryptocurrency as payment. You offer to pay in Bitcoin so the server flashes a QR code of the restaurant’s BTC address on her tablet POS terminal. You navigate your WLT back to the homescreen, and with another tap you access your cryptocurrency, discreetly tracing your finger over the on-screen keypad to open a wallet address with a modest amount of Bitcoin and select “in-person” mode so your wallet balances are not displayed on-screen for prying eyes to see. You enter a payment amount for the total and scan the server’s QR code to execute the transaction.</div>
          <div>You say goodbye to your friend and switch your NFT WLT backdrop to a piece from a specific obscure designer, since you expect to be seen at your next destination by people who’ll know, and the people at this particular venue also know you by a different identity than your given name. You pull out your WLT at the door, and you are unlocked for transacting by the club membership NFT ID token in your possession – an NFT you were able to obtain as the holder of an early Satoshi’s Closet NFT you acquired when signing up for the WLT beta in March 2021. You’re inside now. It’s time for a refreshment.</div>
          <div>But suddenly you forget, do they take $UNI or $WHALE at the bar?</div>
          <div className='wlt__story__button-container'>
            <div className='wlt__story__button' onClick={this.scrollToTop.bind(this)}>Get on the WLT beta invite list</div>
          </div>
        </div>
        <div className='wlt__footer'>
          <div className='footer__social-container'>
              <a href='https://www.instagram.com/satoshiscloset' target='_blank' rel="noopener noreferrer">
                  <img alt="Satoshi's Closet Instagram" src={require('../../assets/images/instagram.svg')} />
              </a>
              <a href='https://www.twitter.com/satoshiscloset_' target='_blank' rel="noopener noreferrer">
                  <img alt="Satoshi's Closet Twitter" src={require('../../assets/images/twitter.svg')} />
              </a>
          </div>
          <div className='footer__copyright'>&#169; 2022 Satoshi's Closet</div>
        </div>
      </div>
    );
  }
}

export default WLT;
