import React, {Component} from 'react';
import { Link } from 'react-router-dom'

import './index.scss';

import Header from '../shared/Header';
import Footer from '../shared/Footer';

class OurCryptoFuture1 extends Component{
  render() {
    return (
      <div>
        <Header />
        <div className='blog'>
          <div className='blog__inner launchparty'>
            <div className='blog__link post'>
              <Link to='/blog'><span>&#x3c;</span>Back to Blog</Link>
            </div>
            <div>
              <div className='blog__header'>
                Our Crypto Future: Pt. 1 — Block time
              </div>
              <div className='blog__subheader show-links'>
                The concept of time is inextricable from blockchain, with a central premise of blockchain being that a new block is added to the chain at regular expected time intervals - 10 minutes for Bitcoin, 10 to 19 seconds for Ethereum, and other intervals for other blockchains. The blockchain only moves forward, just like time. Actually, we’re not time scientists, so maybe time can also move backwards, sideways, or inside out, but we know that blockchains only move forward. (And if we ever do discover the secret to time travel, rest assured the first thing we’ll do is travel back in time to BUY BITCOIN.)
                <br/><br/>
                Time is also a container, and a lot can happen in a year’s time. It was just over a year ago - some 54,000 or so Bitcoin blocks - that Satoshi’s Closet marked the calendar with the world’s first apparel drop on blockchain, <a href='https://satoshiscloset.com/blog/putting-apparel-on-blockchain'>The Initial Shirt Offering</a>. Since then the world has experienced a global health crisis, economic upheaval, and political and social unrest in combination largely unparalleled over a typical human lifetime. Still, time marches on unceasingly, as do blockchains, in a singular direction → forward. And though we cannot stop time, we do play a role in deciding what it contains.
                <br/><br/>
                We believe in blockchain’s capacity for innovation, especially the promise of cryptocurrency as a means to more equitably distribute the world’s wealth - away from monopolists and rent-seekers, and instead keeping it in the hands of those who create it. Self-sovereignty, freedom of peaceable assembly, and collective governance sit at the heart of this vision of the future - Our Crypto Future.
                <br/><br/>
                The platform Satoshi’s Closet uses to create a bridge to Our Crypto Future is a physical and experiential one - apparel. Not everyone is looking at the logo on your breast pocket, or the saying emblazoned across your shoulder blades, or the tag stitched inconspicuously (or loudly) along the hemline. But some people are. People who take note of something they haven’t seen before and eye it with a curiosity of what is behind it. It is these curiosity-seekers - we innovators, early-adopters, and trend-setters - that will help usher in Our Crypto Future. If this is you, if you are we, we ask you to join us.
                <br/><br/>
                We acknowledge this vision is nebulous. But that’s how a vision begins - as a free-form cloud of dust and potential that ultimately evolves into a more complex physical form. It is that start - that big bang, that genesis block - that is imperative.
                <br/><br/>
                We started with the Initial Shirt Offering. (Actually, we started with <a href='https://satoshiscloset.com/item/0xa5ce9d01d04cb2a62c845bd1b60659938e11879f?a=1'>The Proof of Concept</a>, which we believe to be the world’s first piece of apparel on blockchain.) Where we go next is up to us, and today we are continuing with something real. Something that makes it manifest you are a part of this future.
                <br/><br/>
                Today, available for purchase is <a href='https://satoshiscloset.com/#brand-swag'>Satoshi’s Closet Brand Swag</a>. These basics - a long-sleeve tee, a 5-panel cap, and a crop hoodie - all include a digital “non-fungible token” (also called an NFT) that link their existence to the blockchain. You’ll get a cryptographically unique NFT with your purchase that we’ll send to your existing crypto wallet if you have one (an Ethereum wallet, to be specific), or we’ll set you up with a new wallet so you can hold your token digitally and show it off to your fellow curiosity-seekers. We aim to make participating easy. What matters is that you believe in Our Crypto Future. Wear it, and own it.
                <br/><br/>
              </div>
            </div>
          </div>
        </div>
        <Footer />
      </div>
    );
  }
}

export default OurCryptoFuture1;
