import React, {Component} from 'react';
import { Link } from 'react-router-dom'

import './index.scss';

import Header from '../shared/Header';
import Footer from '../shared/Footer';

class IsoLookbook extends Component{
  render() {
    return (
      <div>
        <Header />
        <div className='blog'>
          <div className='blog__inner lookbook'>
            <div className='blog__link post'>
              <Link to='/blog'><span>&#x3c;</span>Back to Blog</Link>
            </div>
            <div>
              <div className='blog__header'>
                The Initial Shirt Offering Lookbook
              </div>
              <div className='blog__images'>
                <div>
                  <img alt='Lookbook M1B' src='/images/lookbook/M1B.jpg' />
                  <img alt='Lookbook F3B' src='/images/lookbook/F3B.jpg' />
                  <img alt='Lookbook F3A' src='/images/lookbook/F3A.jpg' />
                </div>
                <div>
                  <img alt='Lookbook F4B' src='/images/lookbook/F4B.jpg' />
                  <img alt='Lookbook M3A' src='/images/lookbook/M3A.jpg' />
                  <img alt='Lookbook F1C' src='/images/lookbook/F1C.jpg' />
                </div>
                <div>
                  <img alt='Lookbook M1A' src='/images/lookbook/M1A.jpg' />
                  <img alt='Lookbook F4A' src='/images/lookbook/F4A.jpg' />
                  <img alt='Lookbook F2A' src='/images/lookbook/F2A.jpg' />
                </div>
                <div>
                  <img alt='Lookbook F2B' src='/images/lookbook/F2B.jpg' />
                  <img alt='Lookbook F1A' src='/images/lookbook/F1A.jpg' />
                  <img alt='Lookbook M2A' src='/images/lookbook/M2A.jpg' />
                </div>
                <div>
                  <img alt='Lookbook F1B' src='/images/lookbook/F1B.jpg' />
                  <img alt='Lookbook M3B' src='/images/lookbook/M3B.jpg' />
                  <img alt='Lookbook F2C' src='/images/lookbook/F2C.jpg' />
                </div>
              </div>
            </div>
          </div>
        </div>
        <Footer />
      </div>
    );
  }
}

export default IsoLookbook;
