import React, { Component } from 'react';
import { Link } from 'react-router-dom'
import { connect } from 'react-redux'

import './index.scss';

import Header from '../shared/Header';
import Footer from '../shared/Footer';

import { getBlogPost } from '../../redux/blog'

class BlogPost extends Component {
  render() {
    const post = getBlogPost(this.props.match.params.blog_id);
    return (
      <div>
        <Header />
        <div className='blog'>
          <div className='blog__inner post'>
            <div className='blog__link post'>
              <Link to='/blog'><span>&#x3c;</span>Back to Blog</Link>
            </div>
          </div>
          <div dangerouslySetInnerHTML={{__html: post.html}} />
        </div>
        <Footer />
      </div>
    );
  }
}

function mapStateToProps(state) {
    return {}
}
export default connect(mapStateToProps)(BlogPost)
