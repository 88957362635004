import React, { Component } from 'react';
import * as EmailValidator from 'email-validator';

import '../Contact/index.scss';

import Header from '../shared/Header';
import Footer from '../shared/Footer';

import { sendEmail } from '../../services/aws';

class Contact extends Component {
  state = {}

  onChange(key, event) {
    const value = event.target.value
    this.setState({[key]: value});
  }

  onClick() {
    const {email, submitting, success} = this.state;
    if (!email) {
      this.setState({error: 'Please enter an email address'});
      return;
    }
    if (!EmailValidator.validate(email)) {
      this.setState({error: 'Please enter a valid email address'});
      return;
    }
    if (submitting || success) {
      return;
    }
    const data = `RSVP from ${email}`;
    this.setState({submitting: true, error: null}, () => this.sendEmail(data));
  }

  async sendEmail(email) {
    try {
      await sendEmail('rsvp', email, this.onSuccess.bind(this));
    } catch (err) {
      console.error(err);
    }
  }

  onSuccess() {
      this.setState({success: true, submitting: false});
  }

  render() {
    const {success, submitting, error} = this.state;
    const buttonText = success ? 'Success!' : submitting ? 'sending...' : 'submit';
    return (
      <div>
        <Header />
          <div className="contact">
          <div className='contact__inner'>
            <div className='contact__header rsvp'>Satoshi's Closet Launch Party</div>
            <div className='contact__address'>
              <div className='address_date'>Friday, October 4th</div>
              <div className='address_info bold'>Mr. Throwback</div>
              <div className='address_info'>437 East 9th Street</div>
              <div className='address_info'>New York, NY 10009</div>
              <div className='address_link'>
                <a href='https://goo.gl/maps/jexxnhaSBEZ13DVa7' target='_blank' rel="noopener noreferrer">View on Google Maps</a>
              </div>
            </div>
            <div className='contact__input rsvp'>
              <div className='input_header'>RSVP</div>
              <div className='label'>email address</div>
              <input type='email' placeholder='Enter email address' onChange={this.onChange.bind(this, 'email')} />
            </div>
            <div className='contact__button-container'>
                <div  className='contact__button' onClick={this.onClick.bind(this)}>{buttonText}</div>
                {success &&
                    <div style={{marginTop: 10, fontSize: 12}}>Thanks! We've received your RSVP.</div>
                }
                {error &&
                    <div style={{marginTop: 10, fontSize: 12, color: 'red'}}>{error}</div>
                }
            </div>
            <div className='contact__image'>
              <img alt='Launch Party October 4th' src={require('../../assets/images/launch-party-stcl.png')} />
            </div>
          </div>
        </div>
        <Footer />
      </div>
    );
  }
}

export default Contact;
