import React, {Component} from 'react';
import './index.scss';

class Item extends Component{
  render() {
    const onboard = this.props.onboard
    return (
      <div className='item-container'>
        <a href={this.props.store_url}><img src={`/images/${this.props.image_url}`} /></a>
        <div className='name'>{this.props.name}</div>
        {!onboard &&
          <div className='price'>{this.props.price}</div>
        }
        {onboard &&
          <div className='price'><strike>{this.props.price}</strike></div>
        }
        <div className='item-container__button-container'>
            <div className='item-container__button'>
                <a href={this.props.store_url}>{onboard ? 'buy now' : 'view details'}</a>
            </div>
        </div>
      </div>
    );
  }
}

export default Item;
