import React, { Component } from 'react';

import ItemTray from './ItemTray';

import './index.scss';

class GeneralInfo extends Component {
    render() {
        return (
          <div className='general-info'>
              <div className='gray-container'>
                  <div className='inner'>
                      <img className='full-logo' alt="Satoshi's Closet Logo Full" src={require('../../assets/images/logo_full.svg')} />
                      <div className='gray-container__main-description'>
                          <span>Are you new to crypto & blockchain?</span> <br/>
                          <span>Low-key rocking a lambo and still here?</span> <br/>
                          <span>Satoshi’s Closet is your token to <span className='bold'>Our Crypto Future</span>.</span>
                      </div>
                      <div>
                          Satoshi’s Closet is the world’s first streetwear brand built on blockchain. More than Bitcoin logos and HODL memes, Satoshi’s Closet is a technological and social bridge to Our Crypto Future — an experiential approach to crypto and the future of digital lifestyle and commerce.
                      </div>
                      <div>
                          A future based on principles of fair exchange. A future in which value is determined by the collective will and imagination of people, not by the full faith and credit of an opaque central authority.
                      </div>
                      <div className='believe-container'>
                          <div>
                              <img alt="Satoshi's Closet" src={require('../../assets/images/tomorrowland.gif')} />
                          </div>
                          <div className='bullets'>
                              <div className='bullets__header'>We believe:</div>
                              <div>
                                  <span className='bullets__point'><span>></span>In the promise of cryptocurrency & decentralization.</span>
                                  <span className='bullets__point'><span>></span>That our physical and digital worlds are blurring.</span>
                                  <span className='bullets__point'><span>></span>That the future we create is up to us.</span>
                              </div>
                              <div className='bullets__link'>
                                  <a href='/manifesto.pdf'>read our manifesto / white paper</a>
                              </div>
                          </div>
                      </div>
                  </div>
              </div>
              {this.props.partyTime &&
                  <div className='collage-container'>
                      <img className='desktop' alt='collage desktop' src={require('../../assets/images/website-collage-desktop.jpg')} />
                      <img className='mobile' alt='collage mobile' src={require('../../assets/images/website-collage-mobile.jpg')} />
                  </div>
              }
              <ItemTray />
              <div className='white-container'>
                  <div className='inner'>
                      <div className='white-container__header'>There is much more on the way</div>
                      <div className='white-container__coins'>
                          <img alt="Satoshi's Closet Spinning Coins" src={require('../../assets/images/spinning-coin-1.gif')} />
                          <img alt="Satoshi's Closet Spinning Coins" src={require('../../assets/images/spinning-coin-2.gif')} />
                          <img alt="Satoshi's Closet Spinning Coins" src={require('../../assets/images/spinning-coin-3.gif')} />
                      </div>
                      <div className='white-container__edgy'>hijinx, even.</div>
                      <div className='white-container__burn-text'>in season 3, we are setting something on fire. <span role="img" aria-label='fire emoji'>&#128293;</span></div>
                      <div className='white-container__burn'><img alt="Satoshi's Closet Burn Baby Burn" src={require('../../assets/images/archer-fire.gif')} /></div>
                      <div className='white-container__send-eth'><img alt="Satoshi's Closet Send ETH!" src={require('../../assets/images/send_eth.svg')} /></div>
                  </div>
              </div>
          </div>
        );
    }
}

export default GeneralInfo;
