import React, {Component} from 'react';
import { connect } from 'react-redux'
import { Link } from 'react-router-dom';

import './index.scss';

import Header from '../shared/Header';
import Footer from '../shared/Footer';

class Blog extends Component{
  render() {
    const { posts } = this.props;
    return (
      <div>
        <Header />
        <div className="blog">
          <div className='blog__inner'>
            <div className='blog__header'>Blog</div>
            <div className='blog__container'>
              {posts && posts.map((post, index) => {
                return (
                  <div className='blog__link' key={index}>
                    <Link to={{pathname: '/blog/' + post.id, state: {html: post.html}}}>{post.title}</Link>
                  </div>
                );
              })}
            </div>
          </div>
        </div>
        <Footer />
      </div>
    );
  }
}

function mapStateToProps(state) {
    const {Blog} = state

    return {
        posts: Blog.posts
    }
}
export default connect(mapStateToProps)(Blog)
