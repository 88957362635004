import React, { Component } from 'react';
import { Redirect } from 'react-router-dom';

import './index.scss';

import Header from '../shared/Header';

const CONTRACT_ADDRESS = '0x7abe4b30A1f61c02e43b17515B266736Dd938Dd7';

class Iso extends Component {
  state = {}

  componentDidMount() {
    document.title = 'ISO - Satoshi\'s Closet'
  }

  onChange(evt) {
    this.setState({
      tokenId: evt.target.value
    })
  }

  goToToken() {
    const {tokenId} = this.state;
    const tokenIdInt = parseInt(tokenId);
    if (tokenIdInt > 0 && tokenIdInt <= 50) {
      const url = '/ISO/' + CONTRACT_ADDRESS + '/' + tokenId
      console.log(url)
      this.props.history.push(url);
    }
  }

  render() {
    const {contract_id} = this.props.match.params;
    if (contract_id !== CONTRACT_ADDRESS) {
        return <Redirect to='/' />
    }

    const url = "https://etherscan.io/address/0x7abe4b30A1f61c02e43b17515B266736Dd938Dd7";
    return (
      <div>
        <Header />
        <div className="iso">
          <div className="iso__inner">
            <div className='iso__left'>
              <div className='enter-number-text'>
                <img alt='Enter Shirt Number' src={require('../../assets/images/enter_shirt_number.png')} />
              </div>
              <div className='enter-number-container'>
                  <input type='text' placeholder='#' maxLength='2' onChange={this.onChange.bind(this)} />
              </div>
              <div className='enter-number-text link' onClick={this.goToToken.bind(this)}>
                  <span>go</span>
                  <span>></span>
              </div>
              <div className='iso__left__footer'>
                  <div>
                      <img alt="Satoshi's Closet Logo Black & White" src={require('../../assets/images/logo_bw.svg')} />
                  </div>
                  <div className='iso-sub-text normal'><span>Satoshi's Closet</span> is streetwear on blockchain</div>
                  <div className='iso-sub-text normal underline'><a href='manifesto.pdf'>Read our manifesto</a></div>
              </div>
            </div>
            <div className='iso__right'>
              <img alt="Satoshi's Closet ISO Shirt" src={require('../../assets/images/iso_shirt.png')} />
              <div>
                  <div className='iso-text'>The Initial Shirt Offering</div>
                  <div className='iso-sub-text'><span className='bold'>TOTAL SUPPLY: </span>50</div>
                  <div className='iso-sub-text'><span className='bold'>CONTRACT: </span>{contract_id}</div>
                  <div className='iso-sub-text normal underline' onClick={() => window.open(url, '_blank')}>View on Etherscan</div>
              </div>
              <div className='iso__right__footer'>
                  <div>
                    <a href='https://www.instagram.com/satoshiscloset' target='_blank' rel="noopener noreferrer">
                        <img alt="Satoshi's Closet Instagram" src={require('../../assets/images/instagram.svg')} />
                    </a>
                    <a href='https://www.twitter.com/satoshiscloset_' target='_blank' rel="noopener noreferrer">
                        <img alt="Satoshi's Closet Twitter" src={require('../../assets/images/twitter.svg')} />
                    </a>
                  </div>
                  <div className='iso-sub-text normal'>&#169; 2019 Satoshi's Closet</div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default Iso;
