import { combineReducers } from 'redux';
import { routerReducer } from 'react-router-redux';

import Tom from './tom';
import Iso from './iso';
import Blog from './blog';

const applicationReducers = { Tom, Iso, Blog };

export default function createReducer() {
    // return combineReducers(applicationReducers);
    return combineReducers({...applicationReducers, routing: routerReducer});
}
